import React, { useState, useCallback, useEffect } from "react";
import {
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Dialog,
  DialogContent,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { RiImageAddLine } from "react-icons/ri";
import { MdCancel } from "react-icons/md";
import { BsImages } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import moment from "moment";
import { cloneDeep } from "lodash";

import { getDetailUser, updateData } from "../../../services/userManagementApi";
import { getRole } from "../../../utils/FetchData";
import UpdateDataModal from "../../common/particles.jsx/UpdateDataModal";
import UserStatus from "./UserStatus";
import ModalSIPA from "../../common/particles.jsx/ModalSIPA";
import {
  EXCLUDE_ROLE,
  INVENTORY_ROLE,
  INVOICE_ROLE,
  PATIENT_CARE_ROLE,
  RETUR_ROLE,
  WAREHOUSE_ROLE,
} from "../../constants/constant";

const toDateApi = (date) => moment(date).format("YYYY-MM-DD");

const DetailUser = () => {
  const { id } = useParams();
  const { companyId } = useParams();

  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [isUserPermissionDisabled, setIsUserPermissionDisabled] =
    useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalComponent, setModalComponent] = useState({
    enableEmailValidation: false,
    header: "",
  });

  const [isModalSipaOpen, setIsModalSipaOpen] = useState(false);

  const [data, setData] = useState({
    role: [],
    ktp: "",
    izin: "",
    spesimen_signature: "",
  });

  const { data: userDetail } = useQuery({
    queryKey: ["detail-user", isModalOpen],
    queryFn: () => getDetailUser(id, companyId),
  });

  const isSipaExpired = moment(userDetail?.sipa_expired)
    .subtract(1, "months")
    .format("");

  const { data: roleData } = useQuery({
    queryKey: ["role"],
    queryFn: () => getRole(id),
  });

  const { mutate: mutateUpdateData } = useMutation({
    mutationFn: updateData,
    onSuccess: () => setIsUserPermissionDisabled(true),
  });

  const handleCategoryCheckbox = useCallback(
    (checked, type) => {
      let updatedList = cloneDeep(data.role);
      let roleArr =
        type === "invoice"
          ? INVOICE_ROLE
          : type === "inventory"
          ? INVENTORY_ROLE
          : type === "patientCare"
          ? PATIENT_CARE_ROLE
          : type === "warehouse"
          ? WAREHOUSE_ROLE
          : type === "retur"
          ? RETUR_ROLE
          : [];
      roleArr.map((elem) => {
        const selectedValue = updatedList.findIndex((id) => id === elem);
        if (selectedValue === -1 && checked) updatedList.push(elem);
        if (selectedValue > -1 && !checked) {
          updatedList.splice(selectedValue, 1);
        }
      });
      setData({
        ...data,
        role: updatedList,
      });
    },
    [data.role]
  );

  const handleCheckbox = useCallback(
    (selectedId) => {
      const selectedValue = data.role.findIndex((id) => id === selectedId);

      let updatedList = cloneDeep(data.role);

      if (selectedValue !== -1) {
        updatedList.splice(selectedValue, 1);
      } else {
        updatedList.push(selectedId);
      }
      setData({
        ...data,
        role: updatedList,
      });
    },
    [data.role]
  );

  const handleDate = (date) => {
    const formattedDate = date.toISOString().slice(0, 10);
    setUser({
      ...user,
      expired_sipa: formattedDate,
    });
  };

  useEffect(() => {
    setData({
      ...data,
      role: userDetail && userDetail !== undefined ? userDetail.role : [],
    });
  }, [userDetail]);

  return (
    <div className="container mx-auto w-full mt-16 lg:mt-0">
      <ModalSIPA open={isModalSipaOpen} setOpen={setIsModalSipaOpen} />
      <div className="flex text-blue-500 gap-2">
        <p
          className="hover:cursor-pointer"
          onClick={() => navigate("/user-management")}
        >
          User Management
        </p>
        <p>/</p>
        <p>Detail User</p>
      </div>
      {userDetail && userDetail !== undefined ? (
        <div className="grid grid-cols-1 border border-black rounded-lg p-3 mt-3">
          <div className="flex justify-between">
            <p className="text-[30px]">Informasi Pengguna</p>
            <UserStatus userDetail={userDetail} />
          </div>
          <div className="bg-[#314674] w-full text-white text-center p-3 rounded-md my-3 text-[24px]">
            <p>{userDetail.title_name}</p>
          </div>
          <>
            <div className="flex justify-between items-center rounded-md shadow-md p-3">
              <div>
                <p className="text-[18px] font-semibold">Nama Lengkap</p>
                <p>{userDetail.fullname}</p>
              </div>
            </div>
            <div className="flex justify-between items-center rounded-md shadow-md my-3 p-3">
              <div>
                <p className="text-[18px] font-semibold">Alamat Email</p>
                <p>{userDetail.email}</p>
              </div>
              {userDetail.status_code_user === 5 &&
              userDetail.title_id !== 1 ? (
                <p
                  className="text-[#007AF1] hover:cursor-pointer"
                  onClick={() => {
                    setIsModalOpen(true);
                    setModalComponent({
                      ...modalComponent,
                      enableEmailValidation: true,
                      header: "Email",
                    });
                  }}
                >
                  Ubah
                </p>
              ) : null}
            </div>
            <div className="flex justify-between items-center rounded-md shadow-md mb-3 p-3">
              <div>
                <p className="text-[18px] font-semibold">No. HP</p>
                <p>+ {userDetail.phone}</p>
              </div>
              {userDetail.status_code_user === 5 &&
              userDetail.title_id !== 1 ? (
                <p
                  className="text-[#007AF1] hover:cursor-pointer"
                  onClick={() => {
                    setIsModalOpen(true);
                    setModalComponent({
                      ...modalComponent,
                      enableEmailValidation: false,
                      header: "No. HP",
                    });
                  }}
                >
                  Ubah
                </p>
              ) : null}
            </div>
            <div className="p-4 shadow-md rounded-lg mt-3">
              <div className="flex justify-between items-center">
                <p className="text-[18px] font-semibold">Izin Pengguna</p>
                {userDetail.status_code_user === 5 &&
                userDetail.title_id !== 1 ? (
                  <>
                    {!isUserPermissionDisabled ? (
                      <p
                        className="text-[#007AF1] hover:cursor-pointer"
                        onClick={() => {
                          setData({ ...data, role: userDetail.role });
                          setIsUserPermissionDisabled(true);
                        }}
                      >
                        Cancel
                      </p>
                    ) : (
                      <p
                        className="text-[#007AF1] hover:cursor-pointer"
                        onClick={() => setIsUserPermissionDisabled(false)}
                      >
                        Ubah
                      </p>
                    )}
                  </>
                ) : null}
              </div>
              <hr className="mt-2 my-3" />
              <div className="grid grid-cols-4 gap-x-4 w-full">
                <div className="grid grid-cols-1 gap-x-4">
                  {roleData && roleData !== undefined
                    ? roleData
                        .filter(
                          (item) =>
                            ![
                              ...EXCLUDE_ROLE,
                              ...INVOICE_ROLE,
                              ...INVENTORY_ROLE,
                              ...PATIENT_CARE_ROLE,
                              ...WAREHOUSE_ROLE,
                              ...RETUR_ROLE,
                            ].includes(item.id)
                        )
                        .map((item, index) => {
                          return (
                            <FormGroup key={index}>
                              <FormControlLabel
                                control={<Checkbox />}
                                label={item.name}
                                disabled={isUserPermissionDisabled}
                                checked={data.role.includes(item.id)}
                                onChange={() => {
                                  handleCheckbox(item.id);
                                }}
                              />
                            </FormGroup>
                          );
                        })
                    : null}
                </div>
                <div>
                  <FormGroup sx={{ marginBottom: 2 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={INVENTORY_ROLE.every((elem) =>
                            data.role.includes(elem)
                          )}
                          onChange={(_, checked) => {
                            handleCategoryCheckbox(checked, "invoice");
                          }}
                        />
                      }
                      label="Invoice"
                      disabled={isUserPermissionDisabled}
                    />
                    <div className="grid grid-cols-1 gap-x-4">
                      {roleData && roleData !== undefined
                        ? roleData
                            .filter((item) => INVOICE_ROLE.includes(item.id))
                            .map((item, index) => {
                              return (
                                <FormGroup key={index}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={data.role.includes(item.id)}
                                        onChange={() => handleCheckbox(item.id)}
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                      />
                                    }
                                    sx={{ marginLeft: 2 }}
                                    label={item.name}
                                    disabled={isUserPermissionDisabled}
                                  />
                                </FormGroup>
                              );
                            })
                        : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={RETUR_ROLE.every((elem) =>
                            data.role.includes(elem)
                          )}
                          onChange={(_, checked) => {
                            handleCategoryCheckbox(checked, "retur");
                          }}
                        />
                      }
                      label="Retur"
                      disabled={isUserPermissionDisabled}
                    />
                    <div className="grid grid-cols-1 gap-x-4">
                      {roleData && roleData !== undefined
                        ? roleData
                            .filter((item) => RETUR_ROLE.includes(item.id))
                            .map((item, index) => {
                              return (
                                <FormGroup key={index}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={data.role.includes(item.id)}
                                        onChange={() => handleCheckbox(item.id)}
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                      />
                                    }
                                    sx={{ marginLeft: 2 }}
                                    label={item.name}
                                    disabled={isUserPermissionDisabled}
                                  />
                                </FormGroup>
                              );
                            })
                        : null}
                    </div>
                  </FormGroup>
                </div>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={INVENTORY_ROLE.every((elem) =>
                          data.role.includes(elem)
                        )}
                        onChange={(_, checked) => {
                          handleCategoryCheckbox(checked, "inventory");
                        }}
                      />
                    }
                    label="Inventory"
                    disabled={isUserPermissionDisabled}
                  />
                  <div className="grid grid-cols-1 gap-x-4">
                    {roleData && roleData !== undefined
                      ? roleData
                          .filter((item) => INVENTORY_ROLE.includes(item.id))
                          .map((item, index) => {
                            return (
                              <FormGroup key={index}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={data.role.includes(item.id)}
                                      onChange={() => handleCheckbox(item.id)}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  sx={{ marginLeft: 2 }}
                                  label={item.name}
                                  disabled={isUserPermissionDisabled}
                                />
                              </FormGroup>
                            );
                          })
                      : null}
                  </div>
                </FormGroup>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={PATIENT_CARE_ROLE.every((elem) =>
                          data.role.includes(elem)
                        )}
                        onChange={(_, checked) => {
                          handleCategoryCheckbox(checked, "patientCare");
                        }}
                      />
                    }
                    label="Patient Care"
                    disabled={isUserPermissionDisabled}
                  />
                  <div className="grid grid-cols-1 gap-x-4">
                    {roleData && roleData !== undefined
                      ? roleData
                          .filter((item) => PATIENT_CARE_ROLE.includes(item.id))
                          .map((item, index) => {
                            return (
                              <FormGroup key={index}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={data.role.includes(item.id)}
                                      onChange={() => handleCheckbox(item.id)}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  sx={{ marginLeft: 2 }}
                                  label={item.name}
                                  disabled={isUserPermissionDisabled}
                                />
                              </FormGroup>
                            );
                          })
                      : null}
                  </div>
                </FormGroup>
              </div>
              <hr className="my-4" />
              <div>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={WAREHOUSE_ROLE.every((elem) =>
                          data.role.includes(elem)
                        )}
                        onChange={(_, checked) => {
                          handleCategoryCheckbox(checked, "warehouse");
                        }}
                      />
                    }
                    label="Central Warehouse"
                    disabled={isUserPermissionDisabled}
                  />
                  <div className="grid grid-cols-4 gap-x-4">
                    {roleData && roleData !== undefined
                      ? roleData
                          .filter((item) => WAREHOUSE_ROLE.includes(item.id))
                          .map((item, index) => {
                            return (
                              <FormGroup key={index}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={data.role.includes(item.id)}
                                      onChange={() => handleCheckbox(item.id)}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  sx={{ marginLeft: 2 }}
                                  label={item.name}
                                  disabled={isUserPermissionDisabled}
                                />
                              </FormGroup>
                            );
                          })
                      : null}
                  </div>
                </FormGroup>
              </div>
              <div>
                {isUserPermissionDisabled ? null : (
                  <button
                    className="text-white font-bold bg-[#007AF1] rounded-md p-2 w-full mt-4"
                    onClick={() => {
                      mutateUpdateData({
                        type: "role",
                        staff_id: parseInt(id, 10),
                        role: data.role,
                      });
                    }}
                  >
                    Save changes
                  </button>
                )}
              </div>
            </div>
            {userDetail.title_id === 3 ||
            userDetail.title_id === 4 ||
            userDetail.title_id === 7 ? (
              <>
                <div className="flex justify-between items-center rounded-md shadow-md mb-3 p-3">
                  <div>
                    <p className="text-[18px] font-semibold">No. SIPA</p>
                    <p>{userDetail.sipa_id}</p>
                    <p className="text-[18px] font-semibold mt-3">
                      Masa Berlaku SIPA
                    </p>
                    <p>{toDateApi(userDetail.sipa_expired)}</p>
                  </div>
                  {toDateApi(userDetail.sipa_expired) === isSipaExpired ? (
                    <p
                      className="text-[#007AF1] hover:cursor-pointer"
                      onClick={() => setIsModalSipaOpen(true)}
                    >
                      Ubah
                    </p>
                  ) : null}
                </div>
              </>
            ) : null}
            {userDetail.files && userDetail.files !== undefined ? (
              <div className="mb-4">
                {userDetail.title_id === 1 ||
                userDetail.title_id === 3 ||
                userDetail.title_id === 4 ||
                userDetail.title_id === 7 ? (
                  <div className="w-full rounded-lg border mb-4">
                    <div className="bg-blue-500 flex gap-3 text-[20px] text-white items-center rounded-t-lg p-3">
                      <BsImages />
                      <p>KTP</p>
                    </div>
                    <div className="px-3 py-1 rounded-b-lg">
                      {userDetail.files.ktp.split(".").slice(-1)[0] !==
                      "pdf" ? (
                        <div>
                          {userDetail.files.ktp.length !== 0 ? (
                            <div className="flex flex-col items-center justify-center w-full h-full rounded-b-lg">
                              <div className="flex justify-center w-auto h-[60vh]">
                                <img
                                  src={userDetail.files.ktp}
                                  alt="KTP"
                                  className="w-auto h-full object-contain"
                                />
                              </div>
                            </div>
                          ) : (
                            <label
                              htmlFor="dropzone-file1"
                              className="cursor-pointer flex flex-col items-center justify-center w-full h-full rounded-b-lg"
                            >
                              <div className="flex flex-col items-center justify-center">
                                <RiImageAddLine className="text-[90px] text-blue-500" />
                                <p className="font-medium text-l text-blue-400 mt-3">
                                  Tidak ada gambar
                                </p>
                              </div>
                            </label>
                          )}
                        </div>
                      ) : (
                        <div>
                          <p className="text-red-500 mb-4 text-center mx-24 mt-8">
                            Mohon mendownload file PDF berikut untuk melihat
                            detail KTP
                          </p>
                          <div className="flex justify-center">
                            <a
                              className="bg-blue-500 px-4 py-2 rounded-md text-center text-white hover:bg-slate-600 font-bold"
                              href="{userDetail.files.ktp}"
                            >
                              Download KTP
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
                {userDetail.title_id === 1 ? (
                  <div className="w-full rounded-lg border mb-4">
                    <div className="bg-blue-500 flex gap-3 text-[20px] text-white items-center rounded-t-lg p-3">
                      <BsImages />
                      <p>NPWP</p>
                    </div>
                    <div className="px-3 py-1 rounded-b-lg">
                      {userDetail.files.npwp.split(".").slice(-1)[0] !==
                      "pdf" ? (
                        <div>
                          {userDetail.files.npwp.length !== 0 ? (
                            <div className="flex flex-col items-center justify-center w-full h-full rounded-b-lg">
                              <div className="flex justify-center w-auto h-[60vh]">
                                <img
                                  src={userDetail.files.npwp}
                                  alt="npwp"
                                  className="w-auto h-full object-contain"
                                />
                              </div>
                            </div>
                          ) : (
                            <label
                              htmlFor="dropzone-file1"
                              className="cursor-pointer flex flex-col items-center justify-center w-full h-full rounded-b-lg"
                            >
                              <div className="flex flex-col items-center justify-center">
                                <RiImageAddLine className="text-[90px] text-blue-500" />
                                <p className="font-medium text-l text-blue-400 mt-3">
                                  Tidak ada gambar
                                </p>
                              </div>
                            </label>
                          )}
                        </div>
                      ) : (
                        <div>
                          <p className="text-red-500 mb-4 text-center mx-24 mt-8">
                            Mohon mendownload file PDF berikut untuk melihat
                            detail NPWP
                          </p>
                          <div className="flex justify-center">
                            <a
                              className="bg-blue-500 px-4 py-2 rounded-md text-center text-white hover:bg-slate-600 font-bold"
                              href={userDetail.files.npwp}
                            >
                              Download NPWP
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
                {userDetail.title_id === 3 ||
                userDetail.title_id === 4 ||
                userDetail.title_id === 7 ? (
                  <div className="w-full rounded-lg border mb-4">
                    <div className="bg-blue-500 flex gap-3 text-[20px] text-white items-center rounded-t-lg p-3">
                      <BsImages />
                      <p>Surat Izin Praktek/Tenaga Teknis</p>
                    </div>
                    <div className="px-3 py-1 rounded-b-lg">
                      {userDetail.files.izin.split(".").slice(-1)[0] !==
                      "pdf" ? (
                        <div>
                          {userDetail.files.izin.length !== 0 ? (
                            <div className="flex flex-col items-center justify-center w-full h-full rounded-b-lg">
                              <div className="flex justify-center w-auto h-[60vh]">
                                <img
                                  src={userDetail.files.izin}
                                  alt="izin"
                                  className="w-auto h-full object-contain"
                                />
                              </div>
                            </div>
                          ) : (
                            <label
                              htmlFor="dropzone-file1"
                              className="cursor-pointer flex flex-col items-center justify-center w-full h-full rounded-b-lg"
                            >
                              <div className="flex flex-col items-center justify-center">
                                <RiImageAddLine className="text-[90px] text-blue-500" />
                                <p className="font-medium text-l text-blue-400 mt-3">
                                  Tidak ada gambar
                                </p>
                              </div>
                            </label>
                          )}
                        </div>
                      ) : (
                        <div>
                          <p className="text-red-500 mb-4 text-center mx-24 mt-8">
                            Mohon mendownload file PDF berikut untuk melihat
                            detail Surat Izin Praktek/Tenaga Teknis
                          </p>
                          <div className="flex justify-center">
                            <a
                              className="bg-blue-500 px-4 py-2 rounded-md text-center text-white hover:bg-slate-600 font-bold"
                              href={userDetail.files.izin}
                            >
                              Download Spesimen Tanda Tangan
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
                {userDetail.files.spesimen_signature.length !== 0 ? (
                  <div className="w-full rounded-lg border mb-4">
                    <div className="bg-blue-500 flex gap-3 text-[20px] text-white items-center rounded-t-lg p-3">
                      <BsImages />
                      <p>Tanda Tangan</p>
                    </div>
                    <div className="px-3 py-1 rounded-b-lg">
                      {userDetail.files.spesimen_signature
                        .split(".")
                        .slice(-1)[0] !== "pdf" ? (
                        <div>
                          {userDetail.files.spesimen_signature.length !== 0 ? (
                            <div className="flex flex-col items-center justify-center w-full h-full rounded-b-lg">
                              <div className="flex justify-center w-auto h-[60vh]">
                                <img
                                  src={userDetail.files.spesimen_signature}
                                  alt="spesimen_signature"
                                  className="w-auto h-full object-contain"
                                />
                              </div>
                            </div>
                          ) : (
                            <label
                              htmlFor="dropzone-file1"
                              className="cursor-pointer flex flex-col items-center justify-center w-full h-full rounded-b-lg"
                            >
                              <div className="flex flex-col items-center justify-center">
                                <RiImageAddLine className="text-[90px] text-blue-500" />
                                <p className="font-medium text-l text-blue-400 mt-3">
                                  Tidak ada gambar
                                </p>
                              </div>
                            </label>
                          )}
                        </div>
                      ) : (
                        <div>
                          <p className="text-red-500 mb-4 text-center mx-24 mt-8">
                            Mohon mendownload file PDF berikut untuk melihat
                            detail spesimen tanda tangan
                          </p>
                          <div className="flex justify-center">
                            <a
                              className="bg-blue-500 px-4 py-2 rounded-md text-center text-white hover:bg-slate-600 font-bold"
                              href={userDetail.files.izin}
                            >
                              Download Spesimen Tanda Tangan
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </>
        </div>
      ) : null}
      <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogContent>
          <UpdateDataModal
            modalComponent={modalComponent}
            id={id}
            setIsModalOpen={setIsModalOpen}
            userEmail={userDetail?.email}
            userPhone={userDetail?.phone}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DetailUser;
