import React from "react";
import HOC from "../../components/HOC";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import BreadcrumbBillingManual from "../../components/screen/billingManual/BreadcrumbBillingManual";
import { ThemeProvider } from "@mui/material";
import { theme } from "../../components/constants/muiTheme";
import PoListBillingManual from "../../components/screen/billingManual/PoListBillingManual";

const CreateBillingManualPage = () => {
  return (
    <div className="">
      <PageTemplate
        header={"Buat Billing"}
        mainComponent={
          <ThemeProvider theme={theme}>
            <PoListBillingManual />
          </ThemeProvider>
        }
        breadcrumb={<BreadcrumbBillingManual />}
        pageRole={7}
      />
    </div>
  );
};

export default HOC(CreateBillingManualPage);
