import React from "react";
import HOC from "../../components/HOC";
import DetailInvoice from "../../components/screen/invoice/DetailInvoice";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import BreadcrumbInvoice from "../../components/screen/invoice/BreadcrumbInvoice";
import { ThemeProvider } from "@mui/material";
import { theme } from "../../components/constants/muiTheme";

const DetailInvoicePage = () => {
  return (
    <div className="">
      <PageTemplate
        header={"Detail Faktur"}
        mainComponent={
          <ThemeProvider theme={theme}>
            <DetailInvoice />
          </ThemeProvider>
        }
        breadcrumb={<BreadcrumbInvoice />}
        pageRole={11}
      />
    </div>
  );
};

export default HOC(DetailInvoicePage);
