import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getBillingManualDetailNew,
  updateFakturBilling,
} from "../../../services/billingAPI";
import { CircularProgress, TextField } from "@mui/material";
import moment from "moment";
import ModalPaymentBilling from "./modal/ModalPaymentBilling";
import { MdCancel } from "react-icons/md";
import { LuFileCheck } from "react-icons/lu";
import { FaRegEdit } from "react-icons/fa";
import LoadingBackdrop from "../../common/particles.jsx/LoadingBackdrop";
import ErrorPopup from "../../common/particles.jsx/ErrorPopup";

const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const DetailBillingManual = () => {
  const { id } = useParams();
  const { billId } = useParams();

  const [openPay, setOpenPay] = useState(false);
  const [faktur, setFaktur] = useState("");
  const [isEditFaktur, setIsEditFaktur] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    data,
    isFetching: isFetchingData,
    refetch,
  } = useQuery({
    queryKey: ["detail-bill", id, billId],
    queryFn: () => getBillingManualDetailNew(id, billId),
    refetchOnWindowFocus: false,
  });

  const { mutate: mutateSetFaktur, isLoading } = useMutation({
    mutationFn: updateFakturBilling,
    onSuccess: () => {
      setFaktur("");
      setIsEditFaktur(false);
      refetch();
    },
    onError: (err) => {
      setFaktur("");
      setErrorMessage(err.message.id ? err.message.id : err.message);
    },
  });

  useEffect(() => {
    if (data) setFaktur(data.po_detail.faktur_number);
  }, [data]);

  return (
    <div className="text-[12px]">
      <LoadingBackdrop isLoading={isLoading} />
      <div className="mb-4 flex gap-4 w-full ">
        <div className="w-full">
          <p className="font-bold">Tanggal PO</p>
          {data && !isFetchingData ? (
            <p>{moment(data.po_detail.po_date).format("DD MMMM YYYY")}</p>
          ) : (
            <CircularProgress size={15} />
          )}
        </div>
        <div className="w-full">
          <p className="font-bold">Nomor PO</p>
          {data && !isFetchingData ? (
            <p>{data.po_detail.po_number}</p>
          ) : (
            <CircularProgress size={15} />
          )}
        </div>
        <div className="w-full">
          <p className="font-bold">Distributor</p>
          {data && !isFetchingData ? (
            <p>{data.po_detail.distributor_name}</p>
          ) : (
            <CircularProgress size={15} />
          )}
        </div>
        <div className="w-full">
          <p className="font-bold">Tanggal Invoice</p>
          {data && !isFetchingData ? (
            <p>{moment(data.po_detail.invoice_date).format("DD MMMM YYYY")}</p>
          ) : (
            <CircularProgress size={15} />
          )}
        </div>
        <div className="w-full">
          <p className="font-bold">Nomor Invoice</p>
          {data && !isFetchingData ? (
            <p>{data.po_detail.invoice_number}</p>
          ) : (
            <CircularProgress size={15} />
          )}
        </div>
        <div className="w-full">
          <p className="font-bold">Nomor Faktur Pajak</p>
          {data && !isFetchingData ? (
            <>
              {isEditFaktur ? (
                <div className="flex gap-2 w-full items-center">
                  <TextField
                    fullWidth
                    value={faktur}
                    onChange={(e) => setFaktur(e.target.value)}
                  />
                  <MdCancel
                    className="text-red-500 hover:text-red-700 cursor-pointer text-[18px]"
                    onClick={() => {
                      setFaktur("");
                      setIsEditFaktur(false);
                    }}
                  />
                  <LuFileCheck
                    className="text-blue-500 hover:text-blue-700 cursor-pointer text-[18px]"
                    onClick={() =>
                      mutateSetFaktur({
                        po_id: Number(id),
                        billing_id: Number(data.billing_id),
                        faktur_number: faktur,
                      })
                    }
                  />
                </div>
              ) : (
                <div className="flex gap-2 w-full items-center justify-between">
                  <p>
                    {data.po_detail.faktur_number === ""
                      ? "-"
                      : data.po_detail.faktur_number}
                  </p>
                  <FaRegEdit
                    className="text-[18px] text-blue-500 hover:text-blue-700 cursor-pointer "
                    onClick={() => setIsEditFaktur(true)}
                  />
                </div>
              )}
            </>
          ) : (
            <CircularProgress size={15} />
          )}
        </div>
        {data &&
        !isFetchingData &&
        (data.po_detail.status <= 2 || data.po_detail.status === 5) ? (
          <div
            className="rounded-md text-center p-2 bg-blue-500 text-white cursor-pointer hover:bg-gray-300"
            onClick={() => setOpenPay(true)}
          >
            Bayar
          </div>
        ) : null}
      </div>
      <div className="mb-4 flex gap-4 w-full">
        <div className="w-full">
          <p className="font-bold">Total Tagihan</p>
          {data && !isFetchingData ? (
            <p>{toIDR(data.po_detail.total_amount)}</p>
          ) : (
            <CircularProgress size={15} />
          )}
        </div>
        <div className="w-full">
          <p className="font-bold">Total Dibayar</p>
          {data && !isFetchingData ? (
            <p>{toIDR(data.po_detail.total_payment)}</p>
          ) : (
            <CircularProgress size={15} />
          )}
        </div>
        <div className="w-full">
          <p className="font-bold">Status</p>
          {data && !isFetchingData ? (
            <p>
              {data.po_detail.status === 1
                ? "Belum dibayar"
                : data.po_detail.status === 2
                ? "Overdue"
                : data.po_detail.status === 3
                ? "Lunas"
                : data.po_detail.status === 4
                ? "Draft"
                : data.po_detail.status === 5
                ? "Belum Lunas"
                : "-"}
            </p>
          ) : (
            <CircularProgress size={15} />
          )}
        </div>
        <div className="w-full">
          <p className="font-bold">Jatuh Tempo</p>
          {data && !isFetchingData ? (
            <p>{moment(data.po_detail.po_due_date).format("DD MMMM YYYY")}</p>
          ) : (
            <CircularProgress size={15} />
          )}
        </div>
        <div className="w-full" />
        <div className="w-full" />
      </div>

      <div className="flex w-full mb-8">
        <div className="w-full">
          <div className="block overflow-x-auto w-full rounded-md">
            <table className="w-full overflow-x-auto rounded-md border-collapse">
              <thead className="bg-blue-500 text-white">
                <th className="p-2 text-center">No</th>
                <th className="p-2 text-center whitespace-nowrap">Produk</th>
                <th className="p-2 text-center whitespace-nowrap min-w-[200px]">
                  Batch
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  Tanggal Expired
                </th>
                <th className="p-2 text-center whitespace-nowrap min-w-[200px]">
                  Kuantitas PO
                </th>
                <th className="p-2 text-center whitespace-nowrap">Kemasan</th>
                <th className="p-2 text-center whitespace-nowrap min-w-[200px]">
                  HNA Satuan
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  Diskon (%)
                </th>
                <th className="p-2 text-center whitespace-nowrap">PPN (%)</th>
                <th className="p-2 text-center whitespace-nowrap">
                  Harga Akhir
                </th>
                <th className="p-2 text-center whitespace-nowrap">Sub Total</th>
              </thead>
              <tbody className="border">
                {data && !isFetchingData ? (
                  data.product_detail.map((item, i) =>
                    item.batch.map((batchItem, index) => {
                      return (
                        <tr
                          className={`${
                            i === 0 && index === 0 ? "" : "border-t"
                          } `}
                        >
                          <td className="p-2 text-center">
                            {index === 0 ? i + 1 : ""}
                          </td>
                          <td className="p-2 text-center whitespace-nowrap">
                            {index === 0 ? item.product_name : ""}
                          </td>
                          <td className="p-2 text-center">
                            {batchItem.batch_number}
                          </td>
                          <td className="p-2 text-center">
                            {moment(batchItem.expired_date).format(
                              "DD/MM/YYYY"
                            )}
                          </td>
                          <td className="p-2 text-center">
                            {batchItem.quantity}
                          </td>
                          <td className="p-2 text-center">
                            {batchItem.type_packaging}
                          </td>
                          <td className="p-2 text-center">
                            {toIDR(batchItem.pharmacy_net_price)}
                          </td>
                          <td className="p-2 text-center">
                            {batchItem.discount}
                          </td>
                          <td className="p-2 text-center">{batchItem.ppn}</td>
                          <td className="p-2 text-center">
                            {toIDR(batchItem.net_price)}
                          </td>
                          <td className="p-2 text-center">
                            {toIDR(batchItem.total_amount)}
                          </td>
                        </tr>
                      );
                    })
                  )
                ) : (
                  <tr>
                    <td className="py-2 text-center" colSpan={12}>
                      {isFetchingData ? (
                        <CircularProgress size={20} />
                      ) : (
                        "Tidak ada produk"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {data ? (
        <ModalPaymentBilling
          open={openPay}
          setOpen={setOpenPay}
          billing={data}
          refetch={() => refetch()}
        />
      ) : null}

      <ErrorPopup
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </div>
  );
};

export default DetailBillingManual;
