import { useEffect, useState } from "react";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useQuery, useMutation } from "@tanstack/react-query";
import moment from "moment";
import { IoIosAddCircleOutline, IoIosArrowRoundBack } from "react-icons/io";
import { TiEyeOutline } from "react-icons/ti";
import { BsTrash } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import {
  createNewPo,
  getDistributorList,
} from "../../../../services/purchaseOrderApi";
import EmptyList from "../../../ui/EmptyList";
import AddPurchaseOrderProduct from "./AddPurchaseOrderProduct";
import PurchaseOrderDistributor from "./PurchaseOrderDistributor";
import useDebounce from "../../../hooks/useDebounce";
import { IGNORE_TYPE } from "../../../constants/constant";

const validationSchema = yup.object({
  po_number: yup.string(),
  input_date: yup.string().required("Tanggal wajib diisi"),
  distributor: yup.object({
    name: yup.string().required("Nama distributor wajib diisi"),
    address: yup.string().required("Alamat distributor wajib diisi"),
    phone: yup.string().required("Nomor Telepon distributor wajib diisi"),
  }),
  note: yup.string(),
  product: yup.array().min(1, "Minimum product harus 1"),
});

const curDate = Date.now();
const FETCH_LIMIT = 10;

const CreatePurchaseOrder = ({ isWarehouse }) => {
  const navigate = useNavigate();

  const [productArr, setProductArr] = useState([]);
  const [isAddProduct, setIsAddProduct] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSelectDistModalOpen, setIsSelectDistModalOpen] = useState(false);
  const [searchDist, setSearchDist] = useState("");
  const [qtyType, setQtyType] = useState("");

  const debounce = useDebounce(searchDist, 500);

  const changeStock = (id, action, val) => {
    var temp = [...productArr];
    const index = productArr.findIndex((prod) => prod.product_detail_id === id);
    if (action === "min") temp[index].stock -= 1;
    if (action === "add") temp[index].stock += 1;
    if (action === "text")
      isNaN(Number(val))
        ? (temp[index].stock = 0)
        : (temp[index].stock = Number(val));
    setProductArr(temp.filter((item) => item.stock > 0));
  };

  const { data: distributorListData } = useQuery({
    queryKey: ["distributor-list", debounce],
    queryFn: () => getDistributorList(FETCH_LIMIT, 1, debounce),
  });

  const { mutate: mutateCreateNewPo, isLoading } = useMutation({
    mutationFn: createNewPo,
    onSuccess: () =>
      navigate(`${isWarehouse ? "/central-warehouse" : ""}/purchase-order`),
    onError: () =>
      setErrorMessage(
        "Terjadi kegagalan dalam menyimpan data, silahkan coba dilain waktu"
      ),
  });

  const typeStockChecker = (item) => {
    if (item.type_stock === 0) {
      return item.packaging_es?.type_box;
    } else if (item.type_stock === 1) {
      return item.packaging_es?.type_strip;
    } else {
      return item.packaging_es?.type_unit;
    }
  };

  const generateRandomString = () => {
    const length = 8;
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const formik = useFormik({
    initialValues: {
      // po_number:
      //   "INV/" + moment().format("YYYY") + "/" + generateRandomString(),
      po_number: "",
      input_date: moment().format("YYYY-MM-DD"),
      distributor: {
        name: "",
        address: "",
        phone: "",
      },
      note: "",
      product: [],
      is_distributor: false,
      warehouse: isWarehouse ? isWarehouse : false,
    },
    validationSchema,
    onSubmit: (values) => {
      mutateCreateNewPo({
        ...values,
        product: values.product.map((data) => ({
          id: data.id,
          stock: data.stock,
          type_stock: data.type_stock,
        })),
      });
    },
  });

  useEffect(() => {
    var reqArr = productArr.map((item) => {
      return {
        id: item.product_detail_id,
        stock: item.stock,
        type_stock: item.type_stock,
        packaging_es: item.packaging_es,
      };
    });
    formik.setFieldValue("product", reqArr);
  }, [productArr]);

  return (
    <>
      <h1 className="font-semibold mt-14 lg:mt-0 text-[20px] lg:text-[30px] mb-3">
        {isAddProduct === 0 && (
          <>Create {isWarehouse ? "Central Warehouse" : " "} Purchase Order</>
        )}
        {isAddProduct === 1 && (
          <div className="flex gap-2 items-center">
            <IoIosArrowRoundBack
              className="hover:text-slate-300 cursor-pointer"
              onClick={() => setIsAddProduct(0)}
            />
            Tambah Product
          </div>
        )}
        {isAddProduct === 2 && (
          <div className="flex gap-2 items-center">
            <IoIosArrowRoundBack
              className="hover:text-slate-300 cursor-pointer"
              onClick={() => setIsAddProduct(0)}
            />
            Daftar Distributor
          </div>
        )}
      </h1>
      {isAddProduct === 0 && (
        <form onSubmit={formik.handleSubmit}>
          <div className="shadow-md rounded-md p-4">
            <p className="text-blue-500 font-bold pt-2">
              Create Purchase Order
            </p>
            <hr className="my-2" />
            <div className="lg:flex gap-2">
              <div className="w-full flex items-center">
                <div className="w-1/3  font-bold">Input PO Date</div>
                <div className="w-full">
                  <DesktopDatePicker
                    views={["year", "month", "day"]}
                    sx={{ width: "100%" }}
                    value={formik.values.input_date}
                    onChange={(newValue) => {
                      formik.setFieldValue(
                        "input_date",
                        moment(newValue).format("YYYY-MM-DD")
                      );
                    }}
                    renderInput={(params) => {
                      return <TextField {...params} fullWidth />;
                    }}
                  />
                </div>
              </div>
              {/* <div className="w-full flex items-center my-2">
                <div className="w-full font-bold">PO Number</div>
                <div className="w-full">
                  <TextField
                    disabled
                    fullWidth
                    sx={{ backgroundColor: "#d8d8d8" }}
                    value={formik.values.po_number}
                  />
                </div>
              </div> */}
            </div>
            <div className="lg:flex gap-2 mt-2">
              <div className="w-full flex items-center">
                <div className="w-full font-bold">Select Distributor</div>
                <div
                  className="w-full border rounded-md cursor-pointer hover:bg-gray-100 "
                  onClick={() => setIsSelectDistModalOpen(true)}
                >
                  <p className="p-3">
                    {formik.values.distributor.name === ""
                      ? "No Distributor"
                      : formik.values.distributor.name}
                  </p>
                </div>
              </div>
              <div className="w-full flex items-center my-2">
                <div className="w-full font-bold">
                  No. Telp. Distributor / Principal
                </div>
                <div className="w-full">
                  <TextField
                    fullWidth
                    value={formik.values.distributor.phone}
                    onChange={(e) =>
                      formik.setFieldValue("distributor.phone", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="w-full font-bold mt-4 mb-2">
              Alamat Distributor / Principal
            </div>
            <div className="w-full">
              <TextField
                fullWidth
                multiline
                rows={4}
                value={formik.values.distributor.address}
                onChange={(e) =>
                  formik.setFieldValue("distributor.address", e.target.value)
                }
              />
            </div>
            <div className="flex items-center my-2">
              <Checkbox
                checked={formik.values.is_distributor}
                onChange={() =>
                  formik.setFieldValue(
                    "is_distributor",
                    !formik.values.is_distributor
                  )
                }
              />
              <p className="ml-2">Simpan Distributor</p>
            </div>

            <div className="flex items-center text-blue-500 ">
              <p
                className="cursor-pointer hover:underline"
                onClick={() => setIsAddProduct(2)}
              >
                Lihat Distributor yang disimpan
              </p>
              <TiEyeOutline
                className="ml-1.5 text-[20px] cursor-pointer hover:underline"
                onClick={() => setIsAddProduct(2)}
              />
            </div>

            <div className="flex justify-end mt-4">
              <button
                onClick={() => setIsAddProduct(1)}
                className="flex py-2 px-5 items-center border-2 border-black rounded-md cursor-pointer hover:bg-slate-100"
              >
                <IoIosAddCircleOutline className="mr-2 text-black" />
                Add Product
              </button>
            </div>
          </div>
          <p className="font-bold text-lg my-4">Selected Products</p>
          {productArr.length === 0 ? (
            <EmptyList message={"No Selected Products Yet"} />
          ) : (
            <div className="w-full block overflow-x-auto">
              <table className="w-full border">
                <thead>
                  <tr className="text-center bg-blue-500 text-white">
                    <th className="py-2">NIE</th>
                    <th className="py-2">Product Name</th>
                    <th className="py-2">Packaging</th>
                    <th className="py-2">Quantity</th>
                    <th className="py-2">Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {productArr.map((item, index) => (
                    <tr className="text-center" key={index}>
                      <td className="py-2 border">{item.nie}</td>
                      <td className="py-2 border">{item.name}</td>
                      <td className="py-2 border">{typeStockChecker(item)}</td>
                      <td className="py-2 border">
                        <div className="flex justify-center">
                          <div
                            className="rounded-l-md py-2 px-3 bg-[#FF7A5D] disabled:bg-gray-200 cursor-pointer"
                            onClick={() => {
                              changeStock(item.product_detail_id, "min");
                            }}
                          >
                            -
                          </div>
                          <input
                            type="text"
                            className="bg-slate-100 p-2 w-[15%] text-center"
                            value={item.stock}
                            onChange={(e) =>
                              changeStock(
                                item.product_detail_id,
                                "text",
                                e.target.value
                              )
                            }
                          />
                          <div
                            className="rounded-r-md bg-[#5DFFBB] py-2 px-3 disabled:bg-gray-200 cursor-pointer"
                            onClick={() => {
                              changeStock(item.product_detail_id, "add");
                            }}
                          >
                            +
                          </div>
                        </div>
                      </td>
                      <td className="py-2 border">
                        <div className="w-full flex justify-center">
                          <div
                            onClick={() =>
                              changeStock(item.product_detail_id, "text", 0)
                            }
                            className="bg-red-500 rounded-md p-1 flex cursor-pointer justify-center-items-center text-white hover:bg-red-700"
                          >
                            <BsTrash />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          <p className="text-red-500 text-center">{errorMessage}</p>
          <div className="w-full flex justify-center my-8">
            <button
              className="bg-blue-500 text-white hover:bg-blue-700 cursor-pointer disabled:bg-gray-200 py-2 px-24 rounded-md"
              type="submit"
              disabled={!formik.isValid || isLoading}
            >
              {isLoading ? <CircularProgress size={20} /> : <>Save</>}
            </button>
          </div>
        </form>
      )}
      {isAddProduct === 1 && (
        <AddPurchaseOrderProduct
          productArr={productArr}
          setIsAddProduct={setIsAddProduct}
          setProductArr={setProductArr}
          qtyType={qtyType}
          setQtyType={setQtyType}
          IGNORE_TYPE={IGNORE_TYPE}
          isWarehouse={isWarehouse}
        />
      )}
      {isAddProduct === 2 && (
        <PurchaseOrderDistributor
          distributorListData={distributorListData}
          searchDist={searchDist}
          setSearchDist={setSearchDist}
        />
      )}
      <Dialog
        open={isSelectDistModalOpen}
        onClose={() => setIsSelectDistModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <p className="text-center">Pilih Distributor</p>
        </DialogTitle>
        <DialogContent>
          <TextField
            value={searchDist}
            onChange={(e) => setSearchDist(e.target.value)}
            sx={{ width: "100%" }}
          />
          {distributorListData &&
          distributorListData !== undefined &&
          distributorListData?.length !== 0 ? (
            distributorListData.map((data, index) => (
              <div
                key={index}
                className={`w-full border border-black p-3 flex mt-3 cursor-pointer hover:bg-gray-100 ${
                  formik.values.distributor.name === data.name
                    ? "bg-gray-200"
                    : ""
                }`}
                onClick={() => {
                  formik.setValues({
                    ...formik.values,
                    distributor: {
                      name: data.name,
                      address: data.address,
                      phone: data.phone,
                    },
                  });
                  setIsSelectDistModalOpen(false);
                }}
              >
                <div className="flex gap-2 items-start w-full">
                  <div className="w-full">
                    <p className="font-bold">Nama</p>
                    <p>{data.name}</p>
                  </div>
                  <div className="w-full">
                    <p className="font-bold">No. Handphone</p>
                    <p>{data.phone}</p>
                  </div>
                  <div className="w-full">
                    <p className="font-bold">Alamat</p>
                    <p>{data.address}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div
              className="w-full border border-black p-3 flex mt-3 cursor-pointer"
              onClick={() => {
                formik.setValues({
                  ...formik.values,
                  distributor: {
                    name: searchDist,
                    address: "",
                    phone: "",
                  },
                });
                setIsSelectDistModalOpen(false);
              }}
            >
              <div>
                <p className="font-bold">Nama</p>
                <p>{searchDist}</p>
              </div>
              <div className="mx-3">
                <p className="font-bold">Nomor Handphone</p>
              </div>
              <div>
                <p className="font-bold">Alamat</p>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreatePurchaseOrder;
