import { Autocomplete, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { IoMdAddCircleOutline } from "react-icons/io";
import useDebounce from "../../hooks/useDebounce";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useFormik } from "formik";
import moment from "moment";
import ModalAddProductRetur from "./modal/ModalAddProductRetur";
import { MdCancel } from "react-icons/md";
import * as yup from "yup";
import ErrorPopup from "../../common/particles.jsx/ErrorPopup";
import { useNavigate } from "react-router-dom";
import { createReturSell } from "../../../services/billingAPI";
import ModalListCustomer from "./modal/ModalListCustomer";
import { getCustomerList } from "../../../services/invoiceApi";
import ModalAddCustomer from "./modal/ModalAddCustomer";

const FETCH_LIMIT = 10;

const validationSchema = yup.object({
  invoice_number: yup.string(),
  total_price: yup.number().min(1),
  note_number: yup.string(),
  customer_id: yup.number().min(1),
  retur_date: yup.string().required(),
  data_product: yup.array(),
});

const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const CreateReturSell = () => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [search, setSearch] = useState("");
  const [openAddCust, setOpenAddCust] = useState(false);
  const [openCustList, setOpenCustList] = useState(false);
  const [openAddProduct, setOpenAddProduct] = useState(false);

  const debounce = useDebounce(search, 500);

  const removeProduct = (i) => {
    formik.setFieldValue("data_product", [
      ...formik.values.data_product.slice(0, i),
      ...formik.values.data_product.slice(i + 1),
    ]);
  };

  const getPrice = (item) => {
    return item.type_uom === 0
      ? item.sell_price
      : item.type_uom === 1
      ? item.sell_price_sekunder
      : item.type_uom === 2
      ? item.sell_price_tersier
      : 0;
  };

  const {
    data: dataList,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["customer-list", debounce],
    queryFn: () => getCustomerList(FETCH_LIMIT, 1, debounce, false),
    refetchOnWindowFocus: false,
  });

  const { mutate: mutateCreateRetur } = useMutation({
    mutationFn: createReturSell,
    onSuccess: () => navigate("/retur/sell-retur"),
    onError: (err) => setErrorMessage(err.message.id),
  });

  const formik = useFormik({
    initialValues: {
      invoice_number: "",
      total_price: 0,
      note_number: "",
      customer_id: null, //
      retur_date: moment().format("YYYY-MM-DD"),
      data_product: [],
    },
    validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      mutateCreateRetur(values);
    },
  });

  return (
    <div className="text-[12px] pb-[70px]">
      <form>
        <div className=" p-3">
          <div className="flex gap-2 mb-2">
            <div className="w-full flex gap-2 items-center">
              <Autocomplete
                fullWidth
                loading={isFetching}
                disablePortal
                options={
                  dataList && dataList.data !== null ? dataList.data : []
                }
                onOpen={() => refetch()}
                value={
                  dataList &&
                  dataList.data !== null &&
                  formik.values.customer_id
                    ? dataList.data.find(
                        (item) => item.partner_id === formik.values.customer_id
                      )
                    : null
                }
                onChange={(_, inputValue) => {
                  formik.setFieldValue(
                    "customer_id",
                    inputValue ? inputValue.partner_id : null
                  );
                  formik.setFieldValue("data_product", []);
                }}
                onInputChange={(_, onInputValue, reason) => {
                  if (reason === "input") {
                    setSearch(onInputValue);
                  }
                }}
                getOptionLabel={(option) => option.partner_name || ""}
                renderInput={(params) => (
                  <TextField label="Pilih Customer" {...params} />
                )}
              />
              <div
                className="bg-blue-500 w-[40px] h-[40px] text-white flex items-center justify-center rounded-md p-2 cursor-pointer hover:bg-gray-300"
                onClick={() => setOpenAddCust(true)}
              >
                <IoMdAddCircleOutline className="w-full h-full" />
              </div>
            </div>
            <div className="w-full">
              <DesktopDatePicker
                label="Tanggal Retur"
                onChange={(newDate) =>
                  formik.setFieldValue(
                    "retur_date",
                    newDate ? moment(newDate).format("YYYY-MM-DD") : ""
                  )
                }
                value={
                  formik.values.retur_date === ""
                    ? null
                    : formik.values.retur_date
                }
                sx={{ width: "100%" }}
                renderInput={(params) => {
                  return <TextField fullWidth {...params} />;
                }}
              />
            </div>
            <button
              type="button"
              className="w-1/2 bg-blue-500 text-white flex items-center justify-between p-2.5 gap-8 rounded-xl hover:bg-gray-300 disabled:bg-gray-300"
              disabled={
                !dataList || isFetching || formik.values.customer_id === null
              }
              onClick={() => setOpenAddProduct(true)}
            >
              <p>Pilih Produk</p>
              <IoMdAddCircleOutline className="text-[18px]" />
            </button>
          </div>
          <p
            className="text-blue-500 text-[10px] cursor-pointer hover:underline w-full mb-4"
            onClick={() => setOpenCustList(true)}
          >
            List Customer
          </p>

          <div className="flex w-full mb-8">
            <div className="w-full">
              <div className="block overflow-x-auto w-full rounded-md">
                <table className="w-full overflow-x-auto rounded-md border-collapse">
                  <thead className="bg-blue-500 text-white">
                    <th className="p-2 text-center">No</th>
                    <th className="p-2 text-center whitespace-nowrap">
                      No. Invoice
                    </th>
                    <th className="p-2 text-center whitespace-nowrap">
                      No. Faktur Pajak
                    </th>
                    <th className="p-2 text-center whitespace-nowrap">
                      Produk
                    </th>
                    <th className="p-2 text-center whitespace-nowrap">Batch</th>
                    <th className="p-2 text-center whitespace-nowrap">
                      Expired Date
                    </th>
                    <th className="p-2 text-center whitespace-nowrap">
                      Kemasan
                    </th>
                    <th className="p-2 text-center whitespace-nowrap">
                      Kuantitas
                    </th>
                    <th className="p-2 text-center whitespace-nowrap">Harga</th>
                    <th className="p-2 text-center whitespace-nowrap">
                      Diskon (%)
                    </th>
                    {/* <th className="p-2 text-center whitespace-nowrap">
                      PPN (%)
                    </th> */}
                    <th className="p-2 text-center whitespace-nowrap">
                      Harga Akhir
                    </th>
                    <th className="p-2 text-center whitespace-nowrap">
                      Subtotal
                    </th>
                  </thead>
                  <tbody className="border">
                    {formik.values.data_product.length > 0 ? (
                      formik.values.data_product.map((item, i) => (
                        <tr key={i} className={`${i === 0 ? "" : "border-t"}`}>
                          <td className="p-2 text-center">{i + 1}</td>
                          <td className="p-2 text-center">
                            {item.invoice_number}
                          </td>
                          <td className="p-2 text-center">{item.tax_number}</td>
                          <td className="p-2 text-center">
                            <div className="flex justify-between items-center gap-4">
                              {item.product_name}{" "}
                              <MdCancel
                                className="text-red-500 cursor-pointer hover:text-red-700 text-[18px]"
                                onClick={() => removeProduct(i)}
                              />
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            {item.batch_number}
                          </td>
                          <td className="p-2 text-center">
                            {moment(item.expired_date).format("DD/MM/YYYY")}
                          </td>
                          <td className="p-2 text-center">
                            {item.type_packaging}
                          </td>
                          <td className="p-2 text-center">{item.quantity}</td>
                          <td className="p-2 text-center">
                            {toIDR(getPrice(item))}
                          </td>
                          <td className="p-2 text-center">
                            {item.pharmacy_net_price_discount}
                          </td>
                          {/* <td className="p-2 text-center">
                            {item.pharmacy_net_price_ppn_value}
                          </td> */}
                          <td className="p-2 text-center">
                            {toIDR(
                              getPrice(item) *
                                ((100 - item.pharmacy_net_price_discount) / 100)
                            )}
                          </td>
                          <td className="p-2 text-center">
                            {toIDR(item.total_price)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="border">
                        <td className="p-2 text-center" colSpan={13}>
                          Tidak ada produk
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div
            className={`bg-blue-500 fixed bottom-0 left-0 lg:left-[260px] min-h-[60px] px-[20px] py-[10px] w-full lg:w-[calc(100%-260px)] z-50`}
          >
            <div className="flex items-center justify-between items-center w-full">
              <div className="text-white">
                <p className="text-[14px]">Total</p>
                <p className="text-[18px] font-bold">
                  {toIDR(formik.values.total_price)}
                </p>
              </div>
              <button
                type="button"
                onClick={formik.handleSubmit}
                disabled={
                  !formik.isValid || formik.values.data_product.length === 0
                }
                className="rounded-xl text-[14px] disabled:bg-[#687B8E] text-white py-[10px] px-[42px] bg-[#FF8D40] hover:bg-orange-500"
              >
                Simpan
              </button>
            </div>
          </div>

          <ModalAddCustomer
            open={openAddCust}
            setOpen={setOpenAddCust}
            refetch={() => {}}
          />
          <ModalListCustomer open={openCustList} setOpen={setOpenCustList} />
          <ModalAddProductRetur
            open={openAddProduct}
            setOpen={setOpenAddProduct}
            formik={formik}
            isSell={true}
          />
        </div>
      </form>

      <ErrorPopup
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </div>
  );
};

export default CreateReturSell;
