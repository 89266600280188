import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Delete as DeleteIcon,
  Search as SearchIcon,
  CameraAlt as CameraAltIcon,
  Clear as ClearIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  Autocomplete,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
  MenuItem,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Dialog,
  DialogTitle,
  DialogContent,
  Switch,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { BsImages } from "react-icons/bs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import * as yup from "yup";
import moment from "moment";

import {
  deleteImage,
  uploadImage,
} from "../../../../services/userManagementApi";
import useDebounce from "../../../hooks/useDebounce";
import {
  getCategoryMaster,
  getGroups,
  getNIE,
  getNieDetail,
  getPackaging,
  getPlatform,
  getPrincipal,
} from "../../../../services/InventoryAPI";
import { IGNORE_TYPE } from "../../../constants/constant";

const toDateApi = (date) => moment(date).format("YYYY-MM-DD");

const validationSchema = yup.object({
  nie_number: yup.string().required("NIE wajib diisi"),
  principal_id: yup.string().required("Principal wajib diisi"),
  category_id: yup.array().min(1, "Produk wajib memiliki kategori").required(),
  label_id: yup.number().required("Produk wajib memiliki golongan"),
  product_name: yup.string().required("Nama produk wajib diisi"),
  // product_image: yup.array().min(1, "Upload image produk"),
  product_description: yup.object({
    indication: yup.string().nullable(),
    composition: yup.string().nullable(),
    dosage: yup.string().nullable(),
    usage: yup.string().nullable(),
    contraindication: yup.string().nullable(),
    side_effect: yup.string().nullable(),
    storage_description: yup.string().nullable(),
  }),
  require_prescription: yup.boolean().required("Perlu resep wajib diisi"),
  packaging: yup.object({
    type_box: yup.string().required("Tipe kemasan primer wajib diisi"),
    total_box: yup.number().required("Jumlah primer wajib diisi"),
    type_strip: yup.string().required("Tipe kemasan sekunder wajib diisi"),
    total_strip: yup.number().required("Jumlah sekunder wajib diisi"),
    type_unit: yup.string().required("Tipe kemasan tersier wajib diisi"),
    total_unit: yup.number().required("Jumlah tersier wajib diisi"),
  }),
  price: yup.object({
    platform_price: yup.array(),
    pharmacy_net_price: yup.number().min(1, "HNA wajib diisi"),
    sell_price: yup.number().min(1, "Harga primer wajib diisi"),
    sell_price_sekunder: yup.number(),
    sell_price_tersier: yup.number(),
    price_grosir: yup.array(),
  }),
  batch: yup
    .array()
    .of(
      yup.object({
        quantity: yup.number().required("Quantity batch wajib diisi"),
        number_batch: yup.string().required("Batch number wajib diisi"),
        expired_date: yup.string().required("Expiry date wajib diisi"),
      })
    )
    .min(1),
  barcode_number: yup.string(),
  current_stock: yup.number(),
  status_reminder_stock: yup.boolean(),
  reminder_stock: yup.number(),
  down_payment: yup.number(),
});

const AddNewConsignmentProduct = ({
  formik,
  lastIndex,
  setIsAddProduct,
  isDetail,
  indexDetail,
  selectedItem,
  setSelectedItem,
  count,
  setCount,
  qtyType,
  setQtyType,
  selectedQtyArr,
  setSelectedQtyArr,
}) => {
  const { params } = useParams();

  const [errorMessage, setErrorMessage] = useState("");
  const [search, setSearch] = useState("");
  const [nieNumber, setNieNumber] = useState("");
  const [nieId, setNieId] = useState("");
  const [bpomId, setBpomId] = useState("");
  const [open, setOpen] = useState(false);
  const [openPrincipal, setOpenPrincipal] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [optionPrincipal, setOptionPrincipal] = useState([]);
  const [optionCategory, setOptionCategory] = useState([]);
  const [principalValue, setPrincipalValue] = useState([]);
  const [categoryValue, setCategoryValue] = useState([]);
  const [searchPrincipal, setSearchPrincipal] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [isCreateProductWithNIE, setIsCreateProductWithNIE] = useState(null);
  const [quantity, setQuantity] = useState(0);

  const debounce = useDebounce(search, 300);
  const debounceCategory = useDebounce(searchCategory, 500);
  const debouncePrincipal = useDebounce(searchPrincipal, 500);

  const { data: nie, isFetching: isNieLoading } = useQuery({
    queryKey: ["nie", debounce, open],
    queryFn: () => getNIE(debounce, 10, 1),
    keepPreviousData: true,
    disabled: !!debounce,
  });

  const { data, isFetching: isFetchingNieDetail } = useQuery({
    queryKey: [nieId, bpomId],
    queryFn: () => getNieDetail(nieId, bpomId),
    keepPreviousData: true,
    staleTime: Infinity,
    enabled: !!nieId && !!bpomId,
  });

  const { data: principals, isLoading: isPrincipalLoading } = useQuery({
    queryKey: ["principal", debouncePrincipal, selectedItem],
    queryFn: () => getPrincipal(10, 1, debouncePrincipal),
    // enabled: !!data,
  });

  const { data: categories } = useQuery({
    queryKey: ["category", debounceCategory, data, selectedItem],
    queryFn: () => getCategoryMaster(10, 1, debounceCategory),
    // enabled: !!data,
  });

  const { data: groups } = useQuery({
    queryKey: ["groups", data, selectedItem],
    queryFn: () => getGroups(),
    // enabled: !!data,
  });

  const { data: platforms } = useQuery({
    queryKey: ["platform"],
    keepPreviousData: true,
    queryFn: () => getPlatform(),
  });

  const { data: packaging } = useQuery({
    queryKey: ["packaging", data, selectedItem],
    queryFn: () => getPackaging(),
    keepPreviousData: true,
    // enabled: !!data,
  });

  const pharmacyNetPriceFinal = (
    discountPercent,
    pharmacyNetPriceReal,
    taxValue
  ) => {
    if (!pharmacyNetPriceReal) return 0;
    let discount = pharmacyNetPriceReal * (discountPercent / 100);
    let value = (pharmacyNetPriceReal - discount) * taxValue;

    return pharmacyNetPriceReal - discount + value;
  };

  const tempDetail = useFormik({
    initialValues: {
      nie_number:
        data && data !== undefined && isCreateProductWithNIE
          ? data.nie_number
          : search,
      principal_id:
        data && data !== undefined && isCreateProductWithNIE
          ? data.principal_id
          : "",
      category_id:
        data &&
        data !== undefined &&
        // data.category_id !== [0] &&
        isCreateProductWithNIE
          ? data.category_id
          : null,
      label_id:
        data &&
        data !== undefined &&
        data?.label_id !== 0 &&
        isCreateProductWithNIE
          ? data.label_id
          : "",
      product_name:
        data && data !== undefined && isCreateProductWithNIE
          ? data.product_name
          : "",
      product_image:
        data && data !== undefined && isCreateProductWithNIE ? data.media : [],
      product_description: {
        indication:
          data && data !== null && isCreateProductWithNIE
            ? data.indication
            : "",
        composition:
          data && data !== null && isCreateProductWithNIE
            ? data.composition
            : "",
        dosage:
          data && data !== null && isCreateProductWithNIE ? data.dosage : "-",
        usage:
          data && data !== null && isCreateProductWithNIE ? data.usage : "-",
        contraindication:
          data && data !== null && isCreateProductWithNIE
            ? data.contraindication
            : "",
        side_effect:
          data && data !== null && isCreateProductWithNIE
            ? data.side_effect
            : "",
        storage_description:
          data && data !== null && isCreateProductWithNIE
            ? data.storage_description
            : "",
      },
      require_prescription:
        data && data !== undefined && isCreateProductWithNIE
          ? data.require_prescription
          : false,
      packaging: {
        type_box:
          data && data !== undefined && isCreateProductWithNIE
            ? data.packaging?.premier.replace(/\d/g, "").trim()
            : "",

        total_box: 1,

        type_strip:
          data && data !== undefined && isCreateProductWithNIE
            ? data.packaging?.skunder.replace(/\d/g, "").trim()
            : "",

        total_strip:
          data &&
          data !== undefined &&
          /\d/.test(data.packaging?.skunder) &&
          isCreateProductWithNIE
            ? Number(data.packaging?.skunder.match(/\d/g).join(""))
            : 1,

        type_unit:
          data && data !== undefined && isCreateProductWithNIE
            ? data.packaging?.tersier.replace(/\d/g, "").replace(".", "").trim()
            : "",

        total_unit:
          data &&
          data !== undefined &&
          /\d/.test(data.packaging?.tersier) &&
          isCreateProductWithNIE
            ? Number(
                data.packaging?.tersier.includes(".")
                  ? data.packaging?.tersier.split(".")[0]
                  : data.packaging?.tersier
                      .match(/\d*\,?\d*?/g)[0]
                      .replace(",", ".")
              )
            : 1,
      },
      price: {
        platform_price: [],
        pharmacy_net_price: 0,
        pharmacy_net_price_discount: 0,
        pharmacy_net_price_ppn: false,
        pharmacy_net_price_ppn_value: 0,
        pharmacy_net_price_real: 0,
        sell_price: 0,
        sell_price_sekunder: 0,
        sell_price_tersier: 0,
        price_grosir: [],
      },
      batch: [
        {
          quantity: 0,
          number_batch: "",
          expired_date: "",
        },
      ],
      barcode_number: "",
      current_stock: 1,
      status_reminder_stock: false,
      reminder_stock: 0,
      down_payment: 0,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
  });

  const handleDeleteImage = useCallback(
    (index, value) => {
      tempDetail.setFieldValue("product_image", [
        ...tempDetail.values.product_image.splice(index, 1),
      ]);
      mutateDeleteImage(value);
    },
    [tempDetail.values.product_image]
  );

  const handleUploadImage = useCallback(
    (e) => {
      mutateUploadImage(e.target.files[0]);
    },
    [tempDetail.values.product_image]
  );

  const finalStock = useMemo(() => {
    if (qtyType === tempDetail.values.packaging.type_unit) {
      return count;
    }
    if (qtyType === tempDetail.values.packaging.type_strip) {
      if (IGNORE_TYPE.includes(tempDetail.values.packaging.type_unit)) {
        return count;
      } else {
        return count * tempDetail.values.packaging.total_unit;
      }
    }
    if (qtyType === tempDetail.values.packaging.type_box) {
      if (IGNORE_TYPE.includes(tempDetail.values.packaging.type_unit)) {
        return count * tempDetail.values.packaging.total_strip;
      } else {
        return (
          count *
          tempDetail.values.packaging.total_unit *
          tempDetail.values.packaging.total_strip
        );
      }
    }
    return 0;
  }, [count, tempDetail, qtyType]);

  const intersection = new Set(
    tempDetail.values.price.platform_price.map(({ platform_id }) => platform_id)
  );
  const result = platforms?.filter(({ id }) => !intersection.has(id));

  const handleAddPlatform = useCallback(() => {
    if (platforms && platforms !== undefined && nieNumber)
      tempDetail.setFieldValue("price.platform_price", [
        ...tempDetail.values.price.platform_price,
        {
          platform_id: result[0]?.id,
          price: "",
        },
      ]);
    else tempDetail.setFieldValue("price.platform_price", []);
  }, [tempDetail.values.price.platform_price, result]);

  const isQuantityEqual = () => {
    return (
      tempDetail.values.batch.reduce((acc, num) => acc + num.quantity, 0) ===
      finalStock
    );
  };

  const handleSave = () => {
    var temp = [...formik.values.product_details];
    // !isDetail && indexDetail < 0
    //   ? temp.length === lastIndex
    //     ? temp.push(tempDetail.values)
    //     : (temp[lastIndex] = tempDetail.values)
    //   : (temp[indexDetail] = tempDetail.values);

    if (!isDetail && indexDetail < 0) {
      if (temp.length === lastIndex) {
        temp.push(tempDetail.values);
        setSelectedQtyArr([
          ...selectedQtyArr,
          { qty: count, qtyUnit: qtyType },
        ]);
      } else {
        temp[lastIndex] = tempDetail.values;

        var tempArr = [...selectedQtyArr];
        tempArr[lastIndex] = { qty: count, qtyUnit: qtyType };
        setSelectedQtyArr(tempArr);
      }
    } else {
      temp[indexDetail] = tempDetail.values;
      var tempArr = [...selectedQtyArr];
      tempArr[indexDetail] = { qty: count, qtyUnit: qtyType };
      setSelectedQtyArr(tempArr);
    }
    formik.setFieldValue(
      "product_details",
      temp.map((data) => ({
        ...data,
        price: {
          ...data.price,
          pharmacy_net_price: pharmacyNetPriceFinal(
            data.price.pharmacy_net_price_discount,
            data.price.pharmacy_net_price_real,
            data.price.pharmacy_net_price_ppn_value
          ),
        },
      }))
    );

    setIsAddProduct(false);
    setSelectedItem(null);
    setCount(0);
  };

  const { mutate: mutateDeleteImage } = useMutation({
    mutationFn: deleteImage,
  });

  const { mutate: mutateUploadImage, data: imageData } = useMutation({
    mutationFn: uploadImage,
  });

  useEffect(() => {
    if (imageData === undefined) return;
    var temp = [...tempDetail.values.product_image];
    temp.push(imageData.url);
    tempDetail.setFieldValue("product_image", temp);
  }, [imageData]);

  // useEffect(() => {
  //   if (categories && selectedItem && selectedItem.category_id !== null) {
  //     setOptionCategory(categories && categories !== null ? categories : []);

  //     var temp = [];
  //     if (selectedItem.category_id && selectedItem.category_id !== null)
  //       selectedItem.category_id.map((item) => {
  //         var index = categories.findIndex((cat) => cat.id === item);
  //         if (index >= 0) temp.push(categories[index]);
  //       });
  //     setCategoryValue(temp);
  //   }
  // }, [selectedItem?.category_id]);

  useEffect(() => {
    if (openCategory || !isCreateProductWithNIE)
      setOptionCategory(categories && categories !== null ? categories : []);
    if (openPrincipal || !isCreateProductWithNIE)
      setOptionPrincipal(principals !== undefined ? principals : []);
  }, [categories, principals, openCategory, openPrincipal]);

  useEffect(() => {
    setPrincipalValue({
      id: data && data !== undefined ? data.principal_id : "",
      name: data && data !== undefined ? data.principal_name : "",
    });
  }, [data]);

  useEffect(() => {
    if (isDetail && selectedItem !== null && nie && nie.length > 0) {
      setNieId(nie[0].id);
      setBpomId(nie[0].product_bpom_id);
      setNieNumber(nie[0].nie_number);
      tempDetail.setValues(selectedItem);
      setCount(selectedQtyArr[indexDetail].qty);
      setQtyType(selectedQtyArr[indexDetail].qtyUnit);
    }
    if (isDetail && selectedItem !== null && nie === null) {
      setNieNumber(selectedItem.nie_number);
      tempDetail.setValues(selectedItem);
      setCount(selectedQtyArr[indexDetail].qty);
      setQtyType(selectedQtyArr[indexDetail].qtyUnit);
    }
    if (isDetail && categories) {
      var temp = [];
      if (selectedItem.category_id && selectedItem.category_id !== null)
        selectedItem.category_id.map((item) => {
          var index = categories.findIndex((cat) => cat.id === item);
          if (index >= 0) temp.push(categories[index]);
        });
      setCategoryValue(temp);
    }
  }, [nie, categories]);

  useEffect(() => {
    tempDetail.setFieldValue("current_stock", finalStock);
  }, [finalStock]);

  useEffect(() => {
    if (selectedItem !== null) {
      setSearch(selectedItem.nie_number);
    }
  }, [selectedItem]);

  useEffect(() => {
    tempDetail.setFieldValue(
      "price.sell_price_sekunder",
      Math.ceil(
        Number(
          tempDetail.values.price.sell_price /
            tempDetail.values.packaging.total_strip
        )
      )
    );
    tempDetail.setFieldValue(
      "price.sell_price_tersier",
      Math.ceil(
        Number(
          tempDetail.values.price.sell_price /
            (tempDetail.values.packaging.total_strip *
              tempDetail.values.packaging.total_unit)
        )
      )
    );
  }, [tempDetail.values.price.sell_price]);

  return (
    <>
      <div className="border-[#d3d3d3] border-2 rounded-md p-4 my-8">
        <p className="font-medium text-[20px] lg:text-[25px]">Detail Produk</p>
        <form>
          {(selectedItem === null && !isFetchingNieDetail) ||
          (selectedItem !== null && nieNumber !== "") ? ( //&&
            // categoryValue.length > 0 &&
            // categories) ? (
            <>
              <div className="w-full my-4">
                <div
                  className="cursor-pointer p-4 border border-black rounded-md shadow-md flex justify-between"
                  onClick={() => {
                    setOpen(true);
                    setSearch("");
                  }}
                >
                  {nieNumber !== "" ? (
                    <p>{nieNumber}</p>
                  ) : (
                    <p>Nomor Izin Edar</p>
                  )}
                  <SearchIcon />
                </div>

                <div className="input-foto">
                  <div className="bg-blue-500 p-3 text-white font-medium flex mt-4 items-center gap-3 text-[20px] rounded-t-lg">
                    <span>
                      <BsImages />
                    </span>
                    Foto Produk
                  </div>
                  <div className="flex items-center justify-center h-64 border-2 border-dashed">
                    {tempDetail.values.product_image.map((value, index) => (
                      <div
                        className="flex items-start justify-center w-[25%] mx-[10px]"
                        key={index}
                      >
                        <img
                          alt=""
                          className="rounded-md h-[50%] w-[50%]"
                          src={value}
                        />
                        <button
                          type="button"
                          onClick={() => {
                            if (tempDetail.values.product_image.length === 1)
                              return;
                            handleDeleteImage(index, value);
                          }}
                        >
                          <ClearIcon />
                        </button>
                      </div>
                    ))}
                    <label
                      htmlFor="dropzone-file1"
                      className="cursor-pointer flex flex-col items-center justify-center w-[15%] p-6 border-2 rounded-md"
                    >
                      <input
                        id="dropzone-file1"
                        hidden
                        type="file"
                        accept="image/jpeg, image/png, image/jpg"
                        onChange={(e) => {
                          if (
                            tempDetail.values.product_image.length >= 4 ||
                            nieNumber === ""
                          )
                            return;
                          handleUploadImage(e);
                        }}
                      />
                      <div className="flex justify-center flex-col text-center">
                        <CameraAltIcon
                          fontSize="large"
                          sx={{ color: "black" }}
                        />
                        <p>{tempDetail.values.product_image.length}/4</p>
                      </div>
                    </label>
                  </div>
                </div>

                <TextField
                  id="outlined-basic"
                  label="Nama Obat"
                  variant="outlined"
                  fullWidth
                  className="mt-3"
                  disabled={nieNumber === ""}
                  value={tempDetail.values.product_name}
                  onChange={(e) =>
                    tempDetail.setFieldValue("product_name", e.target.value)
                  }
                />

                <p className="mt-8 mb-4">Tambah Kategori Obat</p>
                <Autocomplete
                  fullWidth
                  multiple
                  disabled={nieNumber === ""}
                  sx={{ marginTop: 3 }}
                  open={openCategory}
                  onOpen={() => setOpenCategory(true)}
                  onClose={() => setOpenCategory(false)}
                  disablePortal
                  options={optionCategory}
                  value={categoryValue}
                  onChange={(_, inputValue) => {
                    setCategoryValue(inputValue);
                    setOptionCategory([]);
                    tempDetail.setFieldValue(
                      "category_id",
                      inputValue !== null ? inputValue.map((val) => val.id) : []
                    );
                  }}
                  onInputChange={(_, onInputValue, reason) => {
                    if (reason === "input") {
                      setSearchCategory(onInputValue);
                      setOptionCategory([]);
                    }
                  }}
                  getOptionLabel={(option) => option.name || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tambah Kategori"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <TextField
                  fullWidth
                  name="indication"
                  disabled={nieNumber === ""}
                  label="Indikasi / Kegunaan"
                  variant="outlined"
                  className="mt-3"
                  value={tempDetail.values.product_description.indication}
                  onChange={(e) =>
                    tempDetail.setFieldValue(
                      "product_description.indication",
                      e.target.value
                    )
                  }
                />
                <TextField
                  fullWidth
                  id="outlined-basic"
                  name="composition"
                  disabled={nieNumber === ""}
                  label="Kandungan / Komposisi"
                  variant="outlined"
                  className="mt-3"
                  value={tempDetail.values.product_description.composition}
                  onChange={(e) =>
                    tempDetail.setFieldValue(
                      "product_description.composition",
                      e.target.value
                    )
                  }
                />
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  name="dosage"
                  label="Dosis"
                  className="mt-3"
                  disabled={nieNumber === ""}
                  value={tempDetail.values.product_description.dosage}
                  onChange={(e) =>
                    tempDetail.setFieldValue(
                      "product_description.dosage",
                      e.target.value
                    )
                  }
                />
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  name="usage"
                  label="Cara Pemakaian"
                  multiline
                  disabled={nieNumber === ""}
                  rows={2}
                  className="mt-3"
                  value={tempDetail.values.product_description.usage}
                  onChange={(e) =>
                    tempDetail.setFieldValue(
                      "product_description.usage",
                      e.target.value
                    )
                  }
                />
                <TextField
                  fullWidth
                  id="outlined-basic"
                  name="containdication"
                  label="Kontradiksi / Jangan digunakan oleh"
                  disabled={nieNumber === ""}
                  variant="outlined"
                  className="mt-3"
                  value={tempDetail.values.product_description.contraindication}
                  onChange={(e) =>
                    tempDetail.setFieldValue(
                      "product_description.contraindication",
                      e.target.value
                    )
                  }
                />
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Efek Samping"
                  name="side_effect"
                  multiline
                  disabled={nieNumber === ""}
                  rows={3}
                  variant="outlined"
                  className="mt-3"
                  value={tempDetail.values.product_description.side_effect}
                  onChange={(e) =>
                    tempDetail.setFieldValue(
                      "product_description.side_effect",
                      e.target.value
                    )
                  }
                />
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Cara Penyimpanan"
                  name="storage_description"
                  variant="outlined"
                  className="mt-3"
                  disabled={nieNumber === ""}
                  value={
                    tempDetail.values.product_description.storage_description
                  }
                  onChange={(e) =>
                    tempDetail.setFieldValue(
                      "product_description.storage_description",
                      e.target.value
                    )
                  }
                />
                <FormControl fullWidth className="mt-3">
                  <InputLabel id="demo-simple-select-label">
                    Golongan Obat
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    name="label_id"
                    id="demo-simple-select"
                    label="Golongan Obat"
                    value={tempDetail.values.label_id}
                    disabled={nieNumber === ""}
                    onChange={(e) =>
                      tempDetail.setFieldValue("label_id", e.target.value)
                    }
                  >
                    <MenuItem
                      value=""
                      disabled
                      sx={{ display: "none" }}
                    ></MenuItem>
                    {groups !== undefined ? (
                      groups &&
                      groups.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem
                        value=""
                        disabled
                        sx={{ display: "none" }}
                      ></MenuItem>
                    )}
                  </Select>
                </FormControl>
                <TextField
                  sx={{ marginTop: 3 }}
                  id="outlined-basic"
                  label="Perlu resep"
                  variant="filled"
                  disabled
                  fullWidth
                  value={
                    tempDetail.values.require_prescription ? `Ya` : `Tidak`
                  }
                  onChange={(e) =>
                    tempDetail.setFieldValue(
                      "require_prescription",
                      e.target.value
                    )
                  }
                />
                <Autocomplete
                  fullWidth
                  sx={{ marginTop: 3 }}
                  loading={isPrincipalLoading}
                  disabled={nieNumber === ""}
                  onOpen={() => setOpenPrincipal(true)}
                  onClose={() => setOpenPrincipal(false)}
                  disablePortal
                  options={optionPrincipal}
                  value={principalValue}
                  onChange={(_, inputValue) => {
                    setPrincipalValue(inputValue);
                    tempDetail.setFieldValue("principal_id", inputValue?.id);
                  }}
                  onInputChange={(_, onInputValue, reason) => {
                    if (reason === "input") {
                      setSearchPrincipal(onInputValue);
                    }
                  }}
                  // getOptionLabel={(option) => (option ? option.name : "")}
                  getOptionLabel={(option) => option.name || ""}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  id="combo-box-demo"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Principal"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isPrincipalLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </div>

              <div className="flex flex-row gap-5">
                <div className="flex flex-col">
                  <h1 className="mt-4 font-bold text-[10px] lg:text-[20px]">
                    Kemasan
                  </h1>
                  <div className="grid grid-cols-2 gap-4">
                    <TextField
                      id="premier"
                      label="Qty"
                      variant="outlined"
                      type="number"
                      className="mt-3"
                      disabled
                      value={tempDetail.values.packaging.total_box}
                      onChange={(e) =>
                        tempDetail.setFieldValue(
                          "packaging.total_box",
                          Number(e.target.value)
                        )
                      }
                    />
                    <FormControl fullWidth className="mt-3">
                      <InputLabel>Primer</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Primer"
                        disabled={nieNumber === ""}
                        value={tempDetail.values.packaging.type_box}
                        onChange={(e) =>
                          tempDetail.setFieldValue(
                            "packaging.type_box",
                            e.target.value
                          )
                        }
                      >
                        <MenuItem
                          disabled
                          value=""
                          sx={{ display: "none" }}
                        ></MenuItem>
                        {packaging && packaging !== undefined ? (
                          packaging
                            .filter((pack) => {
                              return pack.packaging_id === 1;
                            })
                            .map((pack, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={pack.name.toUpperCase()}
                                >
                                  {pack.name.toUpperCase()}
                                </MenuItem>
                              );
                            })
                        ) : (
                          <MenuItem
                            disabled
                            value=""
                            sx={{ display: "none" }}
                          ></MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <TextField
                      id="sekunder"
                      label="Qty"
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      variant="outlined"
                      disabled={nieNumber === ""}
                      className="mt-3"
                      value={Number(tempDetail.values.packaging.total_strip)}
                      onChange={(e) => {
                        tempDetail.setFieldValue(
                          "packaging.total_strip",
                          !isNaN(Number(e.target.value))
                            ? Number(e.target.value)
                            : 0
                        );
                      }}
                    />
                    <FormControl fullWidth className="mt-3">
                      <InputLabel>Sekunder</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Sekunder"
                        disabled={nieNumber === ""}
                        value={tempDetail.values.packaging.type_strip}
                        onChange={(e) =>
                          tempDetail.setFieldValue(
                            "packaging.type_strip",
                            e.target.value
                          )
                        }
                      >
                        <MenuItem
                          disabled
                          value=""
                          sx={{ display: "none" }}
                        ></MenuItem>
                        {packaging && packaging !== undefined ? (
                          packaging
                            .filter((pack) => {
                              return pack.packaging_id === 2;
                            })
                            .map((pack, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={pack.name.toUpperCase(0)}
                                >
                                  {pack.name.toUpperCase()}
                                </MenuItem>
                              );
                            })
                        ) : (
                          <MenuItem
                            disabled
                            value=""
                            sx={{ display: "none" }}
                          ></MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <TextField
                      id="outlined-basic"
                      label="Qty"
                      inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                      variant="outlined"
                      className="mt-3"
                      disabled={nieNumber === ""}
                      value={tempDetail.values.packaging.total_unit}
                      onChange={(e) => {
                        tempDetail.setFieldValue(
                          "packaging.total_unit",
                          !isNaN(Number(e.target.value))
                            ? Number(e.target.value)
                            : 0
                        );
                      }}
                    />
                    <FormControl fullWidth className="mt-3">
                      <InputLabel id="demo-simple-select-label">
                        Tersier
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        disabled={nieNumber === ""}
                        label="Tersier"
                        value={tempDetail.values.packaging.type_unit}
                        onChange={(e) =>
                          tempDetail.setFieldValue(
                            "packaging.type_unit",
                            e.target.value
                          )
                        }
                      >
                        <MenuItem
                          disabled
                          value=""
                          sx={{ display: "none" }}
                        ></MenuItem>
                        {packaging && packaging !== undefined ? (
                          packaging
                            .filter((pack) => {
                              return pack.packaging_id === 3;
                            })
                            .map((pack, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={pack.name.toUpperCase()}
                                >
                                  {pack.name.toUpperCase()}
                                </MenuItem>
                              );
                            })
                        ) : (
                          <MenuItem
                            disabled
                            value=""
                            sx={{ display: "none" }}
                          ></MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </div>
                  <h1 className="mt-4 font-bold text-[10px] lg:text-[20px]">
                    Stock
                  </h1>
                  <div className="grid grid-cols-2 gap-4 items-center">
                    <div className="flex flex-row gap-2 justify-center items-center">
                      <button
                        className="bg-[#FF7A5D] p-3 mr-[10px] rounded-lg disabled:bg-gray-500"
                        disabled={nieNumber === "" || qtyType === ""}
                        type="button"
                        onClick={() => {
                          if (count === 0) return;
                          setCount(
                            (previousCount) => Number(previousCount) - 1
                          );
                        }}
                      >
                        -
                      </button>
                      <input
                        type="text"
                        className="border rounded-lg w-[69px] p-3"
                        disabled={nieNumber === "" || qtyType === ""}
                        value={count}
                        onChange={(e) => setCount(e.target.value)}
                      />
                      <button
                        className="bg-[#5DFFBB] p-3 ml-[10px] rounded-lg disabled:bg-gray-500"
                        type="button"
                        disabled={nieNumber === "" || qtyType === ""}
                        onClick={() => {
                          setCount(
                            (previousCount) => Number(previousCount) + 1
                          );
                        }}
                      >
                        +
                      </button>
                    </div>
                    <FormControl fullWidth className="mt-3">
                      <InputLabel id="demo-simple-select-label">
                        Tipe Unit
                      </InputLabel>
                      <Select
                        label="Tipe Unit"
                        value={qtyType}
                        disabled={
                          nieNumber === "" ||
                          tempDetail.values.packaging.type_box === "" ||
                          tempDetail.values.packaging.type_strip === "" ||
                          tempDetail.values.packaging.type_unit === ""
                        }
                        onChange={(e) => setQtyType(e.target.value)}
                      >
                        <MenuItem
                          value={tempDetail.values.packaging.type_box}
                          onClick={() => {
                            if (
                              IGNORE_TYPE.includes(
                                tempDetail.values.packaging.total_unit
                              )
                            ) {
                              setQuantity(
                                count *
                                  tempDetail.values.packaging.total_strip *
                                  tempDetail.values.packaging.total_unit
                              );
                            } else {
                              setQuantity(
                                count * tempDetail.values.packaging.total_unit
                              );
                            }
                          }}
                          sx={{
                            display:
                              tempDetail.values.packaging.type_box === ""
                                ? "none"
                                : null,
                          }}
                        >
                          {tempDetail.values.packaging.type_box}
                        </MenuItem>
                        <MenuItem
                          value={tempDetail.values.packaging.type_strip}
                          onClick={() => {
                            if (
                              IGNORE_TYPE.includes(
                                tempDetail.values.packaging.total_unit
                              )
                            ) {
                              setQuantity(count);
                            } else {
                              setQuantity(
                                count * tempDetail.values.packaging.total_unit
                              );
                            }
                          }}
                          disabled={
                            tempDetail.values.packaging.type_strip === ""
                          }
                          sx={{
                            display:
                              tempDetail.values.packaging.type_strip === ""
                                ? "none"
                                : null,
                          }}
                        >
                          {tempDetail.values.packaging.type_strip}
                        </MenuItem>
                        {IGNORE_TYPE.includes(
                          tempDetail.values.packaging.type_unit
                        ) ? (
                          <MenuItem
                            value={0}
                            sx={{ display: "none" }}
                          ></MenuItem>
                        ) : (
                          <MenuItem
                            value={tempDetail.values.packaging.type_unit}
                            onClick={() => {
                              setQuantity(count);
                            }}
                            disabled={
                              tempDetail.values.packaging.type_unit === ""
                            }
                            sx={{
                              display:
                                tempDetail.values.packaging.type_unit === ""
                                  ? "none"
                                  : null,
                            }}
                          >
                            {tempDetail.values.packaging.type_unit}
                          </MenuItem>
                        )}
                        <MenuItem value={0} sx={{ display: "none" }}></MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <p className="mt-2 text-red-500 flex w-full">
                    <span className="bg-red-500 rounded-full text-white px-[10px] text-sm py-[3px] font-bold">
                      i
                    </span>
                    &nbsp; Stock Akhir&nbsp;
                    <p>{finalStock}&nbsp;</p>
                    {IGNORE_TYPE.includes(
                      tempDetail.values.packaging.type_unit
                    ) ? (
                      <p>
                        {tempDetail.values.packaging.type_strip.replace(
                          /\d/g,
                          ""
                        )}
                      </p>
                    ) : (
                      <p>
                        {tempDetail.values.packaging.type_unit.replace(
                          /\d/g,
                          ""
                        )}
                      </p>
                    )}
                  </p>
                </div>
              </div>

              {tempDetail.values.batch.map((b, index) => (
                <React.Fragment key={index}>
                  <div className="flex justify-evenly mt-4">
                    <TextField
                      sx={{ width: "40%" }}
                      id="outlined-basic"
                      disabled={nieNumber === ""}
                      label="Nomor Batch"
                      variant="outlined"
                      value={b.number_batch}
                      onChange={(e) =>
                        tempDetail.setFieldValue(
                          "batch",
                          tempDetail.values.batch.map((b1, idx) => {
                            return index === idx
                              ? { ...b, number_batch: e.target.value }
                              : b1;
                          })
                        )
                      }
                    />
                    <TextField
                      sx={{ width: "30%", marginX: 3 }}
                      id="outlined-basic"
                      label="Kuantitas"
                      variant="outlined"
                      disabled={nieNumber === ""}
                      value={
                        // value is based on primary unit
                        qtyType === tempDetail.values.packaging.type_unit ||
                        IGNORE_TYPE.includes(
                          tempDetail.values.packaging.type_unit
                        )
                          ? b.quantity / tempDetail.values.packaging.total_strip
                          : b.quantity /
                            (tempDetail.values.packaging.total_strip *
                              tempDetail.values.packaging.total_unit)
                      }
                      onChange={(e) => {
                        if (qtyType === tempDetail.values.packaging.type_unit) {
                          tempDetail.setFieldValue(
                            "batch",
                            tempDetail.values.batch.map((b1, idx) => {
                              return index === idx
                                ? {
                                    ...b,
                                    quantity: e.target.value,
                                  }
                                : b1;
                            })
                          );
                        }
                        if (
                          qtyType === tempDetail.values.packaging.type_strip
                        ) {
                          if (
                            IGNORE_TYPE.includes(
                              tempDetail.values.packaging.type_unit
                            )
                          ) {
                            tempDetail.setFieldValue(
                              "batch",
                              tempDetail.values.batch.map((b1, idx) => {
                                return index === idx
                                  ? {
                                      ...b,
                                      quantity: e.target.value * 1,
                                    }
                                  : b1;
                              })
                            );
                          } else {
                            tempDetail.setFieldValue(
                              "batch",
                              tempDetail.values.batch.map((b1, idx) => {
                                return index === idx
                                  ? {
                                      ...b,
                                      quantity:
                                        e.target.value *
                                        tempDetail.values.packaging.total_unit,
                                    }
                                  : b1;
                              })
                            );
                          }
                        }
                        if (qtyType === tempDetail.values.packaging.type_box) {
                          if (
                            IGNORE_TYPE.includes(
                              tempDetail.values.packaging.type_unit
                            )
                          ) {
                            tempDetail.setFieldValue(
                              "batch",
                              tempDetail.values.batch.map((b1, idx) => {
                                return index === idx
                                  ? {
                                      ...b,
                                      quantity:
                                        e.target.value *
                                        1 *
                                        tempDetail.values.packaging.total_strip,
                                    }
                                  : b1;
                              })
                            );
                          } else {
                            tempDetail.setFieldValue(
                              "batch",
                              tempDetail.values.batch.map((b1, idx) => {
                                return index === idx
                                  ? {
                                      ...b,
                                      quantity:
                                        e.target.value *
                                        1 *
                                        tempDetail.values.packaging.total_unit *
                                        tempDetail.values.packaging.total_strip,
                                    }
                                  : b1;
                              })
                            );
                          }
                        }
                      }}
                    />
                    <DesktopDatePicker
                      views={["year", "month", "day"]}
                      label="Tanggal Expired"
                      sx={{ width: "100%" }}
                      value={b.expired_date !== "" ? b.expired_date : null}
                      disabled={nieNumber === ""}
                      onChange={(newExpiredDate) =>
                        tempDetail.setFieldValue(
                          "batch",
                          tempDetail.values.batch.map((b1, idx) => {
                            return index === idx
                              ? {
                                  ...b,
                                  expired_date: toDateApi(newExpiredDate),
                                }
                              : b1;
                          })
                        )
                      }
                      renderInput={(params) => {
                        return <TextField {...params} />;
                      }}
                      disablePast={true}
                    />
                    <button
                      type="button"
                      className="ml-[10px]"
                      disabled={nieNumber === ""}
                      onClick={() => {
                        if (tempDetail.values.batch.length === 1) {
                          tempDetail.setFieldValue("batch", []);
                        } else {
                          tempDetail.setFieldValue("batch", [
                            ...tempDetail.values.batch.slice(0, index),
                            ...tempDetail.values.batch.slice(index + 1),
                          ]);
                        }
                      }}
                    >
                      <DeleteIcon sx={{ color: "black" }} />
                    </button>
                    {tempDetail.values.batch.reduce((acc, num) => {
                      return acc + num.quantity;
                    }, 0) > finalStock &&
                    tempDetail.values.batch.length >= count ? (
                      <div className="flex">
                        <p className="text-red-500">
                          Stock melebihi batas maksimal
                        </p>
                      </div>
                    ) : null}
                  </div>
                </React.Fragment>
              ))}
              <p
                className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4 text-end"
                onClick={() => {
                  if (
                    tempDetail.values.batch.length === count ||
                    nieNumber === "" ||
                    tempDetail.values.batch.reduce((acc, num) => {
                      return acc + num.quantity;
                    }, 0) >= finalStock
                  ) {
                    return;
                  }
                  tempDetail.setFieldValue("batch", [
                    ...tempDetail.values.batch,
                    { quantity: "", number_batch: "", expired_date: "" },
                  ]);
                }}
              >
                +Tambah Batch lain
              </p>
              <TextField
                id="barcode"
                fullWidth
                variant="outlined"
                disabled={nieNumber === ""}
                label="Nomor Barcode"
                value={tempDetail.values.barcode_number}
                onChange={(e) =>
                  tempDetail.setFieldValue("barcode_number", e.target.value)
                }
                sx={{ marginTop: 3 }}
              />

              <div className="grid grid-cols-2 justify-between mt-4 items-center">
                <p className="font-bold">Down Payment</p>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Down Payment"
                  disabled={nieNumber === ""}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp.</InputAdornment>
                    ),
                  }}
                  value={tempDetail.values.down_payment}
                  onChange={(e) =>
                    tempDetail.setFieldValue(
                      "down_payment",
                      Number(e.target.value)
                    )
                  }
                />
              </div>
              <div className="grid grid-cols-2 justify-between mt-4 items-center">
                <p className="font-bold">HNA</p>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="HNA"
                  disabled={nieNumber === ""}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp.</InputAdornment>
                    ),
                  }}
                  value={tempDetail.values.price.pharmacy_net_price_real}
                  onChange={(e) => {
                    let numericValue = e.target.value;
                    if (numericValue === "") {
                      numericValue = "0";
                    }
                    tempDetail.setFieldValue(
                      "price.pharmacy_net_price_real",
                      parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                    );
                  }}
                />
              </div>
              <div className="grid grid-cols-2 justify-between mt-4 items-center">
                <p className="font-bold">Discount</p>
                <TextField
                  id="outlined-basic"
                  label="Discount"
                  disabled={nieNumber === ""}
                  variant="outlined"
                  InputProps={
                    ({
                      startAdornment: (
                        <InputAdornment position="start">Rp.</InputAdornment>
                      ),
                    },
                    {
                      endAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    })
                  }
                  value={tempDetail.values.price.pharmacy_net_price_discount}
                  onChange={(e) => {
                    let numericValue = e.target.value;
                    if (numericValue === "") {
                      numericValue = "0";
                    }
                    if (
                      Number(numericValue) >= 0 &&
                      Number(numericValue) <= 100
                    )
                      tempDetail.setFieldValue(
                        "price.pharmacy_net_price_discount",
                        parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                      );
                  }}
                />
              </div>
              <div className="grid grid-cols-2 mt-4 items-center">
                <p className="font-bold">Harga Akhir HNA</p>
                <TextField
                  id="outlined-basic"
                  label="Final Price"
                  disabled={nieNumber === ""}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp.</InputAdornment>
                    ),
                  }}
                  value={pharmacyNetPriceFinal(
                    tempDetail.values.price.pharmacy_net_price_discount,
                    tempDetail.values.price.pharmacy_net_price_real,
                    tempDetail.values.price.pharmacy_net_price_ppn_value
                  )}
                  //onChange={(e) => {}}
                />
              </div>
              <div className="flex justify-between items-center mt-4">
                <p className="font-bold">PPN</p>
                <Switch
                  checked={tempDetail.values.price.pharmacy_net_price_ppn}
                  disabled={nieNumber === ""}
                  onClick={() => {
                    tempDetail.setFieldValue(
                      "price.pharmacy_net_price_ppn",
                      !tempDetail.values.price.pharmacy_net_price_ppn
                    );
                  }}
                />
              </div>

              <div className="grid grid-cols-2 justify-between mt-4 items-center">
                <p className="font-bold">Harga Jual Primer</p>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Harga Jual Primer"
                  disabled={nieNumber === ""}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp.</InputAdornment>
                    ),
                  }}
                  value={tempDetail.values.price.sell_price}
                  onChange={(e) =>
                    tempDetail.setFieldValue(
                      "price.sell_price",
                      Number(e.target.value)
                    )
                  }
                />
              </div>
              <div className="grid grid-cols-2 justify-between mt-4 items-center">
                <p className="font-bold">Harga Jual Sekunder</p>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Harga Jual Sekunder"
                  disabled={nieNumber === ""}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp.</InputAdornment>
                    ),
                  }}
                  value={tempDetail.values.price.sell_price_sekunder}
                  onChange={(e) =>
                    tempDetail.setFieldValue(
                      "price.sell_price_sekunder",
                      Number(e.target.value)
                    )
                  }
                />
              </div>
              <div className="grid grid-cols-2 justify-between mt-4 items-center">
                <p className="font-bold">Harga Jual Tersier</p>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Harga Jual Tersier"
                  disabled={nieNumber === ""}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rp.</InputAdornment>
                    ),
                  }}
                  value={tempDetail.values.price.sell_price_tersier}
                  onChange={(e) =>
                    tempDetail.setFieldValue(
                      "price.sell_price_tersier",
                      Number(e.target.value)
                    )
                  }
                />
              </div>
              <div className="grid mt-4 items-center w-full lg:w-[calc(100vw-310px)])">
                {tempDetail.values.price.price_grosir.map((item, i) => {
                  return (
                    <div className="my-2 w-full">
                      <div className="w-full flex gap-2 items-center">
                        {i > 0 ? (
                          <div className="w-full basis-1/3"></div>
                        ) : (
                          <p className="font-bold mt-2 w-full basis-1/3">
                            Harga Grosir
                          </p>
                        )}
                        <div className="w-full flex gap-2 items-center">
                          <TextField
                            fullWidth
                            value={item.minimum}
                            onChange={(e) =>
                              tempDetail.setFieldValue(
                                "price.price_grosir[" + i + "].minimum",
                                Number(e.target.value)
                              )
                            }
                          />
                          <p>S/D</p>
                          <TextField
                            fullWidth
                            value={item.maximum}
                            onChange={(e) =>
                              tempDetail.setFieldValue(
                                "price.price_grosir[" + i + "].maximum",
                                Number(e.target.value)
                              )
                            }
                          />
                        </div>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          disabled={nieNumber === ""}
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                Rp.
                              </InputAdornment>
                            ),
                          }}
                          value={item.price_grosirs}
                          onChange={(e) =>
                            tempDetail.setFieldValue(
                              "price.price_grosir[" + i + "].price_grosirs",
                              Number(e.target.value)
                            )
                          }
                        />
                        <button
                          type="button"
                          className="ml-[10px]"
                          disabled={nieNumber === ""}
                          onClick={() => {
                            tempDetail.setFieldValue("price.price_grosir", [
                              ...tempDetail.values.price.price_grosir.slice(
                                0,
                                i
                              ),
                              ...tempDetail.values.price.price_grosir.slice(
                                i + 1
                              ),
                            ]);
                          }}
                        >
                          <DeleteIcon sx={{ color: "black" }} />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
              <p
                className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4 text-right w-full"
                onClick={() => {
                  tempDetail.setFieldValue("price.price_grosir", [
                    ...tempDetail.values.price.price_grosir,
                    { minimum: 0, maximum: 0, price_grosirs: 0 },
                  ]);
                }}
              >
                +Tambah harga grosir baru
              </p>

              <div>
                <p
                  className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4 text-end"
                  onClick={handleAddPlatform}
                >
                  +Tambah Platform lain
                </p>
                <div>
                  {platforms &&
                  platforms !== undefined &&
                  tempDetail.values.price.platform_price.length > 0 ? (
                    <>
                      {tempDetail.values.price.platform_price.map(
                        (plat, index) => {
                          return (
                            <div
                              className="flex justify-center items-center mt-[20px] mb-4"
                              key={plat.platform_id}
                            >
                              <FormControl fullWidth sx={{ marginRight: 1 }}>
                                <InputLabel id="demo-simple-select-label">
                                  Kategori
                                </InputLabel>
                                <Select
                                  id="add-category"
                                  value={plat.platform_id}
                                  disabled={nieNumber === ""}
                                  onChange={(e) => {
                                    tempDetail.setFieldValue(
                                      "price.platform_price",
                                      tempDetail.values.price.platform_price.map(
                                        (p1) => {
                                          return p1.platform_id ===
                                            plat.platform_id
                                            ? {
                                                ...plat,
                                                platform_id: e.target.value,
                                              }
                                            : p1;
                                        }
                                      )
                                    );
                                  }}
                                >
                                  {platforms.map((p) => {
                                    return (
                                      <MenuItem
                                        key={p.id}
                                        value={p.id}
                                        disabled={
                                          tempDetail.values.price.platform_price.find(
                                            (f) => f.id === p.id
                                          )
                                            ? true
                                            : false
                                        }
                                        sx={{
                                          display:
                                            tempDetail.values.price.platform_price.find(
                                              (f) => f.id === p.id
                                            )
                                              ? "none"
                                              : null,
                                        }}
                                      >
                                        {p.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">
                                  Jumlah Uang
                                </InputLabel>
                                <OutlinedInput
                                  id="outlined-adornment-password"
                                  type="text"
                                  onChange={(e) =>
                                    tempDetail.setFieldValue(
                                      "price.platform_price",
                                      tempDetail.values.price.platform_price.map(
                                        (p1) => {
                                          return p1.platform_id ===
                                            plat.platform_id
                                            ? {
                                                ...plat,
                                                price: Number(e.target.value),
                                              }
                                            : p1;
                                        }
                                      )
                                    )
                                  }
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                        onClick={() => {
                                          if (
                                            tempDetail.values.price
                                              .platform_price.length === 1
                                          )
                                            tempDetail.setFieldValue(
                                              "price.platform_price",
                                              []
                                            );
                                          else
                                            tempDetail.setFieldValue(
                                              "price.platform_price",
                                              [
                                                ...tempDetail.values.price.platform_price.slice(
                                                  0,
                                                  index
                                                ),
                                                ...tempDetail.values.price.platform_price.slice(
                                                  index + 1
                                                ),
                                              ]
                                            );
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  label="Password"
                                />
                              </FormControl>
                            </div>
                          );
                        }
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </>
          ) : (
            <div className="w-full flex justify-center items-center my-4">
              <p>Please wait while we fetch your data</p>
              <CircularProgress size={20} className="w-full text-center ml-4" />
            </div>
          )}

          <div className="flex w-full gap-3 px-3">
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                setIsAddProduct(false);
                setSelectedItem(null);
              }}
            >
              Batal
            </Button>
            <Button
              variant="contained"
              fullWidth
              onClick={handleSave}
              // disabled={
              //   isFetchingNieDetail || !tempDetail.isValid || !isQuantityEqual()
              // }
            >
              Simpan
            </Button>
          </div>
        </form>
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle className="flex justify-center">
          <button
            type="button"
            onClick={() => setOpen(false)}
            className="mr-[10px]"
          >
            <ClearIcon sx={{ color: "black" }} />
          </button>
          <TextField
            fullWidth
            focused
            autoFocus
            label="Nomor Izin Edar"
            onChange={(e) => setSearch(e.target.value.toUpperCase())}
            value={search}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            inputProps={{
              style: { textTransform: "uppercase" },
            }}
          />
        </DialogTitle>
        <DialogContent sx={{ height: 500 }}>
          {nie && nie !== undefined && !isNieLoading ? (
            nie.map((data) => (
              <div
                className="mt-3 flex flex-col shadow-md rounded-xl p-3 border-2 justify-start cursor-pointer"
                key={data.id}
                onClick={() => {
                  setNieId(data.id);
                  setBpomId(data.product_bpom_id);
                  setNieNumber(data.nie_number);
                  setOpen(false);
                  setIsCreateProductWithNIE(true);
                }}
              >
                <p className="font-bold">{data.nie_number}</p>
                <p className="font-bold">{data.product_name}</p>
                <p className="font-bold">{data.kemasan}</p>
              </div>
            ))
          ) : isNieLoading ? (
            <div className="w-full flex justify-center my-4">
              <CircularProgress size={20} />
            </div>
          ) : (
            <div
              className="mt-3 flex flex-col shadow-md rounded-xl p-3 border-2 justify-start cursor-pointer text-bold"
              onClick={() => {
                setOpen(false);
                setIsCreateProductWithNIE(false);
                setNieNumber(search);
              }}
            >
              <p>{search}</p>
            </div>
          )}
        </DialogContent>
      </Dialog>
      <Snackbar
        open={Boolean(errorMessage)}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setErrorMessage("")}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  );
};

export default AddNewConsignmentProduct;
