import { Box, CircularProgress, Modal, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { RxCross1 } from "react-icons/rx";
import * as yup from "yup";
import { useState } from "react";
import {
  setCustomerDetail,
  setNewCustomer,
} from "../../../../services/invoiceApi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const validationSchema = yup.object({
  partner_name: yup.string().required("Nama customer harus diisi"),
  address: yup.string().required("Alamat customer harus diisi"),
  npwp: yup.string(),
  phone: yup.string().required("No. telepon customer harus diisi"),
  pic: yup.string().required("PIC customer harus diisi"),
  discount: yup.number(),
  sia: yup.string(),
  sipa: yup.string(),
  warehouse: yup.boolean(),
});

const ModalAddCustomer = ({ open, setOpen, id, setId, refetch }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => {
    if (id) setId(false);
    setOpen(false);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      partner_name: id ? id.partner_name : "",
      address: id ? id.address : "",
      npwp: id ? id.npwp : "",
      phone: id ? id.phone : "",
      pic: id ? id.pic : "",
      discount: id ? id.discount : 0,
      sia: id ? id.sia : "",
      sipa: id ? id.sipa : "",
      warehouse: false,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      id
        ? mutateDetail({ ...values, id: Number(id.id) })
        : mutateNewCustomer(values);
    },
  });

  const { mutate: mutateNewCustomer, isLoading: isLoadingNew } = useMutation({
    mutationFn: setNewCustomer,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  const { mutate: mutateDetail, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: setCustomerDetail,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (err) => setErrorMessage(err.id),
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          ...style,
          maxWidth: "75%",
          overflowY: "auto",
          overflowX: "wrap",
          maxHeight: "80vh",
          padding: 0,
        }}
        className="text-left text-[12px] w-3/4 lg:w-1/3"
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="flex items-center px-8 py-4 w-full text-center">
            <p className="w-full text-[20px] font-bold">Tambah Customer</p>
            <RxCross1
              className="cursor-pointer hover:text-red-500 w-fit"
              onClick={handleClose}
            />
          </div>

          <hr className="border-b-2 mb-4" />

          <div className="px-8 mb-4">
            <TextField
              fullWidth
              label={
                <p>
                  Nama Customer <span className="text-red-500">*</span>
                </p>
              }
              sx={{ marginBottom: 2 }}
              value={formik.values.partner_name}
              onChange={(e) => {
                formik.setFieldValue("partner_name", e.target.value);
                setErrorMessage("");
              }}
            />
            <TextField
              fullWidth
              label={
                <p>
                  PIC <span className="text-red-500">*</span>
                </p>
              }
              sx={{ marginBottom: 2 }}
              value={formik.values.pic}
              onChange={(e) => {
                formik.setFieldValue("pic", e.target.value);
                setErrorMessage("");
              }}
            />
            <TextField
              fullWidth
              label={
                <p>
                  Alamat <span className="text-red-500">*</span>
                </p>
              }
              multiline
              rows={4}
              sx={{ marginBottom: 2 }}
              value={formik.values.address}
              onChange={(e) => {
                formik.setFieldValue("address", e.target.value);
                setErrorMessage("");
              }}
            />
            <TextField
              fullWidth
              label={
                <p>
                  No Telepon <span className="text-red-500">*</span>
                </p>
              }
              sx={{ marginBottom: 2 }}
              value={formik.values.phone}
              onChange={(e) => {
                if (/^\d+$/.test(e.target.value) || e.target.value === "") {
                  formik.setFieldValue("phone", e.target.value);
                  setErrorMessage("");
                }
              }}
            />
            <TextField
              fullWidth
              label={<p>NPWP</p>}
              sx={{ marginBottom: 2 }}
              value={formik.values.npwp}
              onChange={(e) => {
                if (
                  e.target.value.length <= 20 &&
                  !isNaN(Number(e.target.value))
                ) {
                  formik.setFieldValue("npwp", e.target.value);
                }
              }}
            />
            <TextField
              fullWidth
              label={<p>Diskon</p>}
              value={formik.values.discount}
              onChange={(e) =>
                formik.setFieldValue(
                  "discount",
                  !isNaN(Number(e.target.value)) &&
                    Number(e.target.value) <= 100
                    ? Number(e.target.value)
                    : 0
                )
              }
            />
            <p className="mt-2 text-red-500">{errorMessage}</p>
          </div>

          <hr className="border-b-2 mb-4" />

          <div className="px-8 mb-4 flex gap-2 items-center">
            <button
              type="button"
              className="w-full p-2 rounded-md border-2 border-black hover:bg-gray-300"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={!formik.isValid || isLoadingNew || isLoadingUpdate}
              className="w-full p-2 rounded-md bg-blue-500 text-white hover:bg-gray-300 hover:border-gray-300 disabled:bg-gray-300"
            >
              {isLoadingNew || isLoadingUpdate ? (
                <CircularProgress size={10} />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default ModalAddCustomer;
