import { Search } from "@mui/icons-material";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { CiFilter } from "react-icons/ci";
import { FaChevronDown } from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { getInvoiceList } from "../../../services/invoiceApi";
import useDebounce from "../../hooks/useDebounce";
import moment from "moment";
import PagePagination from "../../common/particles.jsx/PagePagination";
import { BiPrinter } from "react-icons/bi";
import Cookies from "js-cookie";
import LoadingBackdrop from "../../common/particles.jsx/LoadingBackdrop";
import axios from "axios";
import PrintInvoice from "./PrintInvoice";
import { getSipaAndFullname, getUserProfile } from "../../../services/authApi";
import { useReactToPrint } from "react-to-print";
import PopupRangeCalendar from "../../common/particles.jsx/PopupRangeCalendar";

const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });
const token = () => Cookies.get("accessTokenOfficeOlin");

const InvoiceList = () => {
  const navigate = useNavigate();
  const componentRef = useRef();

  const [anchorElCal, setAnchorElCal] = useState(null);
  const [search, setSearch] = useState("");
  const [reqBody, setReqBody] = useState({
    offset: 1,
    limit: 10,
    status: 0,
    start_date: "",
    end_date: "",
  });
  const [printArr, setPrintArr] = useState([]);
  const [isPrint, setIsPrint] = useState(false);
  const [dataInvArr, setDataInvArr] = useState([]);
  const [resLen, setResLen] = useState(0);

  const debounce = useDebounce(search, 500);

  const handleAddInvoice = (invNum) => {
    var temp = [...printArr];
    if (printArr.includes(invNum))
      temp = temp.filter((item) => item !== invNum);
    else temp.push(invNum);
    setPrintArr(temp);
  };

  const handleSelectAll = (checked) => {
    setPrintArr(
      checked ? [...dataList.data.map((item) => item.invoice_number)] : []
    );
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Faktur OLIN ${moment().format("YYYY-MM-DD hh:mm")}`,
  });

  const generatePrintInvoice = async (invNum) => {
    setIsPrint(true);
    var temp = [];

    axios
      .all([
        (invNum ? [invNum] : printArr).map((num) =>
          axios({
            headers: {
              Authorization: `Bearer ${token()}`,
            },
            method: "GET",
            url: `${
              process.env.REACT_APP_API_PRODUCT
            }invoice/detail-invoice?invoice_number=${num}&company_user_id=${
              sipaAndFullnameData && sipaAndFullnameData !== undefined
                ? profile && profile !== undefined
                  ? profile.id
                  : 0
                : 0
            }&warehouse=false`,
          })
        ),
      ])
      .then((res) => {
        res.map((resData) => {
          setResLen(resData.length);
          resData.map((item) => {
            item.then((promiseRes) => {
              temp.push(promiseRes.data.detail);
              if (temp.length === resData.length) {
                setDataInvArr(temp);
              }
            });
          });
        });
      });
  };

  const {
    data: dataList,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["invoice-list", reqBody, debounce],
    queryFn: () =>
      getInvoiceList(
        reqBody.limit,
        reqBody.offset,
        debounce,
        reqBody.status,
        false,
        reqBody.start_date,
        reqBody.end_date
      ),
    refetchOnWindowFocus: false,
  });

  const { data: profile } = useQuery({
    queryKey: ["profile"],
    queryFn: () => getUserProfile(),
  });

  const { data: sipaAndFullnameData } = useQuery({
    queryKey: ["sipa-fullname", profile?.id],
    queryFn: () =>
      getSipaAndFullname(profile && profile !== undefined ? profile.id : 0),
  });

  useEffect(() => {
    if (dataInvArr.length > 0 && isPrint) {
      handlePrint();
      setIsPrint(false);
      setDataInvArr([]);
      setResLen(0);
    }
  }, [dataInvArr, resLen, isPrint]);

  useEffect(() => {
    setPrintArr([]);
  }, [reqBody, debounce]);

  useEffect(() => {
    setReqBody((prevVal) => ({ ...prevVal, offset: 1 }));
  }, [debounce]);

  return (
    <div className="text-[12px]">
      <LoadingBackdrop isLoading={isPrint} />
      <div className="flex items-center gap-2 mb-2">
        <TextField
          placeholder="Cari data"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <Search />
              </InputAdornment>
            ),
          }}
          fullWidth
        />
        <Select
          value={reqBody.status}
          onChange={(e) =>
            setReqBody((prevVal) => ({ ...prevVal, status: e.target.value }))
          }
          sx={{ width: "100%" }}
        >
          <MenuItem value={0}>Semua Status</MenuItem>
          <MenuItem value={1}>Belum Bayar</MenuItem>
          <MenuItem value={2}>Belum Lunas</MenuItem>
          <MenuItem value={3}>Lunas</MenuItem>
        </Select>
        <div
          className="border rounded-md flex items-center justify-between gap-4 w-full py-2.5 px-2 text-gray-500 cursor-pointer hover:bg-gray-100"
          onClick={(e) => setAnchorElCal(e.currentTarget)}
        >
          <div className="flex items-center gap-2">
            <CiFilter className="text-[18px]" /> Jatuh Tempo
          </div>
          <FaChevronDown />
        </div>
        <button
          className="disabled:bg-gray-300 disabled:text-white text-blue-500 border-2 border-blue-500 rounded-md hover:bg-slate-100 w-fit flex items-center justify-center p-2"
          onClick={generatePrintInvoice}
          disabled={printArr.length === 0 || isPrint}
        >
          {isPrint ? (
            <CircularProgress size={15} />
          ) : (
            <>
              <BiPrinter className="text-[18px]" />
            </>
          )}
        </button>
        <button
          type="button"
          className="border rounded-md flex items-center justify-between gap-2 w-fit py-2.5 px-2 bg-blue-500 text-white hover:bg-gray-300 whitespace-nowrap text-nowrap"
          onClick={() => navigate(`/invoice/invoice/create-invoice`)}
        >
          Tambah Faktur
          <IoMdAddCircleOutline className="text-[18px]" />
        </button>
      </div>

      <FormGroup sx={{ width: "100%", marginBottom: 1 }}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={!dataList || isFetching || (dataList && !dataList.data)}
              checked={
                printArr.length > 0 && printArr.length === dataList.data.length
              }
              onChange={(e, checked) => handleSelectAll(checked)}
            />
          }
          label={"Select All"}
        />
      </FormGroup>

      <div className="flex w-full mb-8">
        <div className="w-full">
          <div className="block overflow-x-auto w-full rounded-md">
            <table className="w-full overflow-x-auto rounded-md border-collapse">
              <thead className="bg-blue-500 text-white">
                <th className="p-2 text-center"></th>
                <th className="p-2 text-center">No</th>
                <th className="p-2 text-center whitespace-nowrap">
                  Tanggal Faktur
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  No. Invoice
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  No. Faktur Pajak
                </th>
                <th className="p-2 text-center whitespace-nowrap">Customer</th>
                <th className="p-2 text-center whitespace-nowrap">
                  Total Tagihan
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  Total Dibayar
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  Jatuh Tempo
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  Tanggal Pembayaran
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  Metode Pembayaran
                </th>
                <th className="p-2 text-center whitespace-nowrap">Status</th>
                <th className="p-2 text-center whitespace-nowrap">Action</th>
              </thead>
              <tbody className="border">
                {dataList && !isFetching && dataList.data ? (
                  dataList.data.map((item, i) => (
                    <tr key={i} className={`${i === 0 ? "" : "border-t"}`}>
                      <td className="p-2 text-center">
                        <Checkbox
                          onChange={() => handleAddInvoice(item.invoice_number)}
                          checked={printArr.includes(item.invoice_number)}
                        />
                      </td>
                      <td className="p-2 text-center">
                        {(reqBody.offset - 1) * reqBody.limit + (i + 1)}
                      </td>
                      <td className="p-2 text-center">
                        {moment(item.invoice_date).format("DD/MM/YYYY")}
                      </td>
                      <td className="p-2 text-center">{item.invoice_number}</td>
                      <td className="p-2 text-center">
                        {item.faktur_number === "" ? "-" : item.faktur_number}
                      </td>
                      <td className="p-2 text-center">{item.customer_name}</td>
                      <td className="p-2 text-center">
                        {toIDR(item.total_grand)}
                      </td>
                      <td className="p-2 text-center">
                        {toIDR(item.total_amount)}
                      </td>
                      <td className="p-2 text-center">
                        {moment(item.due_date).format("DD/MM/YYYY")}
                      </td>
                      <td className="p-2 text-center">
                        {item.paid_at === ""
                          ? "-"
                          : moment(item.paid_at).format("DD/MM/YYYY")}
                      </td>
                      <td className="p-2 text-center">
                        {item.payment_method === "" ? "-" : item.payment_method}
                      </td>
                      <td className="p-2 text-center whitespace-nowrap">
                        {item.status === 1
                          ? "Belum bayar"
                          : item.status === 2
                          ? "Belum lunas"
                          : item.status === 3
                          ? "Lunas"
                          : "-"}
                      </td>
                      <td className="p-2 text-center">
                        <div className="w-full flex gap-2 items-center">
                          <button
                            className="bg-gray-100 border-gray-300 text-gray-500 border-2 rounded-md w-full hover:bg-gray-300 p-1 disabled:border-none disabled:bg-gray-300 disabled:text-white"
                            onClick={() =>
                              navigate(
                                `/invoice/invoice/detail-invoice/${item.invoice_number.replaceAll(
                                  "/",
                                  "="
                                )}`
                              )
                            }
                            type="button"
                          >
                            Detail
                          </button>
                          <button
                            className="bg-blue-100 text-blue-500 border-2 rounded-md w-full hover:bg-blue-300 p-1 disabled:bg-gray-300 disabled:text-white"
                            type="button"
                            onClick={() => {
                              generatePrintInvoice(item.invoice_number);
                            }}
                          >
                            Print
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className="border">
                    <td className="p-2 text-center" colSpan={13}>
                      {isFetching ? (
                        <CircularProgress size={20} />
                      ) : (
                        "Tidak ada data"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {dataList && dataList.total > 0 && dataList.data !== null ? (
        <div className="mb-4">
          <PagePagination
            offset={reqBody.offset}
            setOffset={(e) =>
              setReqBody((prevVal) => ({ ...prevVal, offset: e }))
            }
            limit={reqBody.limit}
            setLimit={(e) =>
              setReqBody((prevVal) => ({ ...prevVal, limit: e }))
            }
            total={dataList.total}
          />
        </div>
      ) : null}

      <PopupRangeCalendar
        anchorElCal={anchorElCal}
        setAnchorElCal={setAnchorElCal}
        startDate={reqBody.start_date}
        endDate={reqBody.end_date}
        setStartDate={(e) => {
          setReqBody((prevValue) => ({ ...prevValue, start_date: e }));
        }}
        setEndDate={(e) => {
          setReqBody((prevValue) => ({ ...prevValue, end_date: e }));
        }}
        resetFunc={() =>
          setReqBody((prevValue) => ({
            ...prevValue,
            offset: 1,
            start_date: "",
            end_date: "",
          }))
        }
      />

      <div className="p-5" ref={componentRef}>
        <PrintInvoice
          dataInvArr={dataInvArr}
          sipaAndFullnameData={sipaAndFullnameData}
        />
      </div>
    </div>
  );
};

export default InvoiceList;
