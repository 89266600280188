import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { CircularProgress, TextField } from "@mui/material";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import moment from "moment";
import { useEffect, useState } from "react";

import {
  getHistoryInvoice,
  getInvoiceDetail,
} from "../../../services/invoiceApi";
import ModalPaymentInvoice from "./modal/ModalPaymentInvoice";
import { getSipaAndFullname, getUserProfile } from "../../../services/authApi";

const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const DetailInvoice = () => {
  const { invNum } = useParams();

  const [offsetHistory, setOffsetHistory] = useState(1);
  const [openPay, setOpenPay] = useState(false);

  const { data: profile } = useQuery({
    queryKey: ["profile"],
    queryFn: () => getUserProfile(),
  });

  const { data: sipaAndFullnameData, isFetching: isFetchingSipa } = useQuery({
    queryKey: ["sipa-fullname", profile?.id],
    queryFn: () =>
      getSipaAndFullname(profile && profile !== undefined ? profile.id : 0),
  });

  const {
    data: dataDetail,
    isFetching: isFetchingDetail,
    refetch,
  } = useQuery({
    queryKey: ["detail-invoice", invNum, sipaAndFullnameData?.id],
    queryFn: () => getInvoiceDetail(invNum.replaceAll("=", "/"), false),
  });

  const {
    mutate: mutateHistory,
    isLoading: isLoadingHistory,
    data: dataHistory,
  } = useMutation({
    mutationFn: getHistoryInvoice,
  });

  useEffect(() => {
    if (dataDetail) {
      mutateHistory({
        customer_id: dataDetail.customer_id,
        offset: offsetHistory,
        limit: 1,
      });
    }
  }, [dataDetail, offsetHistory]);

  return (
    <div className="text-[12px]">
      <div className="flex items-center gap-2 mb-4">
        <DesktopDatePicker
          label="Tanggal Invoice"
          value={dataDetail ? dataDetail.invoice_date : "-"}
          disabled
          sx={{ width: "100%" }}
          renderInput={(params) => {
            return <TextField fullWidth {...params} />;
          }}
        />
        <TextField
          label="Nomor Faktur Pajak"
          value={dataDetail ? dataDetail.faktur_number : "-"}
          disabled
          fullWidth
        />
        <DesktopDatePicker
          label="Jatuh Tempo"
          value={dataDetail ? dataDetail.due_date : "-"}
          disabled
          sx={{ width: "100%" }}
          renderInput={(params) => {
            return <TextField fullWidth {...params} />;
          }}
        />
        <TextField
          fullWidth
          disabled
          value={dataDetail ? dataDetail.customer_name : "-"}
          label="Customer"
        />
        <TextField
          disabled
          label="Kode Sales"
          value={dataDetail ? dataDetail.sales_code : "-"}
          fullWidth
        />
        <button
          type="button"
          className="border rounded-md flex items-center justify-center items-center gap-2  py-2.5 w-full bg-blue-500 text-white hover:bg-gray-300 whitespace-nowrap text-nowrap disabled:bg-gray-300"
          onClick={() => setOpenPay(true)}
          disabled={
            !dataDetail ||
            isFetchingDetail ||
            (dataDetail && dataDetail.status && dataDetail.status === 3)
          }
        >
          Bayar
        </button>
      </div>

      <div className="block md:flex mb-4 items-start gap-4">
        <div className="w-full h-full">
          <div className="w-full flex gap-2 font-bold mb-4 items-center">
            <p className="w-full">
              Customer Diskon :{"  "}
              {isFetchingDetail ? (
                <CircularProgress size={15} />
              ) : dataDetail && !isFetchingDetail ? (
                dataDetail.customer_discount + "%"
              ) : (
                ""
              )}
            </p>
            <p className="w-full">
              Nama Apoteker : {"  "}
              {isFetchingSipa ? (
                <CircularProgress size={15} />
              ) : sipaAndFullnameData && sipaAndFullnameData !== undefined ? (
                sipaAndFullnameData.fullname
              ) : (
                ""
              )}
            </p>
            <p className="w-full">
              No. SIPA : {"  "}
              {isFetchingSipa ? (
                <CircularProgress size={15} />
              ) : sipaAndFullnameData && sipaAndFullnameData !== undefined ? (
                sipaAndFullnameData.sipa_id
              ) : (
                ""
              )}
            </p>
          </div>

          <div className="flex w-full mb-8 min-h-[40vh]">
            <div className="w-full">
              <div className="block overflow-x-auto w-full rounded-md">
                <table className="w-full overflow-x-auto rounded-md border-collapse">
                  <thead className="bg-blue-500 text-white">
                    <th className="p-2 text-center">Nama Produk</th>
                    <th className="p-2 text-center">Kuantitas</th>
                    <th className="p-2 text-center">Batch</th>
                    <th className="p-2 text-center">Expired</th>
                    <th className="p-2 text-center">Harga Satuan</th>
                    <th className="p-2 text-center w-[200px]">Diskon (%)</th>
                    <th className="p-2 text-center">Promo</th>
                    <th className="p-2 text-center">Total</th>
                    <th className="p-2 text-center">Total + Diskon</th>
                  </thead>
                  <tbody className="border">
                    {dataDetail &&
                    !isFetchingDetail &&
                    dataDetail.product.length > 0 ? (
                      dataDetail.product.map((item, i) => {
                        return (
                          <tr
                            key={i}
                            className={`${i === 0 ? "" : "border-t"}`}
                          >
                            <td className="p-2 text-center">
                              {item.product_name}
                            </td>
                            <td className="p-2 text-center">{item.quantity}</td>
                            <td className="p-2 text-center">{item.batch_no}</td>
                            <td className="p-2 text-center">
                              {moment(item.expired_date).format("DD/MM/YYYY")}
                            </td>
                            <td className="p-2 text-center">
                              {toIDR(item.price)}
                            </td>
                            <td className="p-2 text-center">
                              {item.discount > 0 ? item.discount : ""}
                              {item.discount > 0 &&
                              dataDetail.customer_discount > 0
                                ? "% + "
                                : ""}
                              {dataDetail.customer_discount > 0
                                ? dataDetail.customer_discount + "%"
                                : ""}
                              {item.discount === 0 &&
                              dataDetail.customer_discount === 0
                                ? "0%"
                                : ""}
                            </td>
                            <td className="p-2 text-center">
                              {item.promo === "" ? "-" : item.promo}
                            </td>
                            <td className="p-2 text-center">
                              {toIDR(item.quantity * item.price)}
                            </td>
                            <td className="p-2 text-center">
                              {toIDR(
                                item.quantity *
                                  item.price *
                                  ((100 -
                                    item.discount -
                                    (dataDetail &&
                                    !isFetchingDetail &&
                                    !isFetchingDetail &&
                                    dataDetail.customer_discount
                                      ? dataDetail.customer_discount
                                      : 0)) /
                                    100)
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="p-2 text-center" colSpan={9}>
                          {isFetchingDetail ? (
                            <CircularProgress size={20} />
                          ) : (
                            "Tidak ada produk"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="w-full flex items-end justify-end mb-8">
            <div className="w-1/2">
              <div className="flex gap-2 items-center mb-2">
                <p className="w-1/2">Jumlah</p>
                <p>:</p>
                <p>
                  {dataDetail && !isFetchingDetail ? (
                    toIDR(dataDetail.jumlah)
                  ) : isFetchingDetail ? (
                    <CircularProgress size={20} />
                  ) : (
                    ""
                  )}
                </p>
              </div>
              <div className="flex gap-2 items-center mb-2">
                <p className="w-1/2">Diskon</p>
                <p>:</p>
                <p>
                  {dataDetail && !isFetchingDetail ? (
                    toIDR(
                      dataDetail &&
                        !isFetchingDetail &&
                        dataDetail.product.length > 0
                        ? dataDetail.product.reduce(
                            (acc, num) =>
                              acc +
                              num.quantity *
                                num.price *
                                ((num.discount + dataDetail.customer_discount) /
                                  100),
                            0
                          )
                        : 0
                    )
                  ) : isFetchingDetail ? (
                    <CircularProgress size={20} />
                  ) : (
                    toIDR(0)
                  )}
                </p>
              </div>
              <div className="flex gap-2 items-center mb-2">
                <p className="w-1/2">Subtotal</p>
                <p>:</p>
                <p>
                  {dataDetail && !isFetchingDetail ? (
                    toIDR(dataDetail.subtotal)
                  ) : isFetchingDetail ? (
                    <CircularProgress size={20} />
                  ) : (
                    ""
                  )}
                </p>
              </div>
              <div className="flex gap-2 items-center mb-2">
                <p className="w-1/2">PPN</p>
                <p>:</p>
                <p>
                  {dataDetail && !isFetchingDetail ? (
                    toIDR(dataDetail.ppn)
                  ) : isFetchingDetail ? (
                    <CircularProgress size={20} />
                  ) : (
                    ""
                  )}
                </p>
              </div>
              <div className="flex gap-2 items-center mb-2">
                <p className="w-1/2">Voucher</p>
                <p>:</p>
                <p>
                  {dataDetail && !isFetchingDetail ? (
                    toIDR(dataDetail.voucher)
                  ) : isFetchingDetail ? (
                    <CircularProgress size={20} />
                  ) : (
                    ""
                  )}
                </p>
              </div>
              {dataDetail && !isFetchingDetail && dataDetail.status === 2 ? (
                <div className="flex gap-2 items-center mb-2">
                  <p className="w-1/2">Jumlah Dibayar</p>
                  <p>:</p>
                  <p>{toIDR(dataDetail.total_amount)}</p>
                </div>
              ) : null}
              <div className="flex gap-2 items-center mb-2 bg-blue-100 text-blue-500 p-1 rounded-md font-bold">
                <p className="w-1/2">Jumlah Tagihan</p>
                <p>:</p>
                <p>
                  {dataDetail && !isFetchingDetail ? (
                    toIDR(
                      dataDetail.status === 2
                        ? dataDetail.jumlah_bayar - dataDetail.total_amount
                        : dataDetail.jumlah_bayar
                    )
                  ) : isFetchingDetail ? (
                    <CircularProgress size={20} />
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2">
          <div className="border-2 rounded-md p-3 h-full">
            <p className="font-bold mb-2 text-[14px]">
              Riwayat Faktur Pelanggan
            </p>
            <div className="flex justify-end w-full mb-2">
              <div className="flex gap-4 justify-between items-center w-3/4">
                <button
                  type="button"
                  className="border rounded-md p-1 disabled:bg-gray-300 disabled:text-white w-[32px] h-[32px] flex items-center justify-center"
                  disabled={
                    offsetHistory === 1 || !dataHistory || isLoadingHistory
                  }
                  onClick={() => setOffsetHistory(offsetHistory - 1)}
                >
                  <FaChevronLeft />
                </button>
                <p>
                  {dataHistory && dataHistory.data ? (
                    <>
                      {moment(dataHistory.data[0].history_date).format(
                        "DD MMMM YYYY"
                      )}
                    </>
                  ) : (
                    "-"
                  )}
                </p>
                <button
                  type="button"
                  className="border rounded-md p-1 disabled:bg-gray-300 disabled:text-white w-[32px] h-[32px] flex items-center justify-center"
                  disabled={
                    (dataHistory && offsetHistory === dataHistory.count) ||
                    !dataHistory ||
                    isLoadingHistory
                  }
                  onClick={() => setOffsetHistory(offsetHistory + 1)}
                >
                  <FaChevronRight />
                </button>
              </div>
            </div>
            <hr className="my-2" />
            {dataHistory && !isLoadingHistory && dataHistory.data ? (
              <>
                <div className="mb-2">
                  <div className="w-full flex gap-2 items-center">
                    <p className="w-1/3">No. Faktur</p>
                    <p>:</p>
                    <p>{dataHistory.data[0].faktur_number}</p>
                  </div>
                  <div className="w-full flex gap-2 items-center">
                    <p className="w-1/3">Total Bayar</p>
                    <p>:</p>
                    <p>{toIDR(dataHistory.data[0].total_payment)}</p>
                  </div>
                  <div className="w-full flex gap-2 items-center">
                    <p className="w-1/3">Jatuh Tempo</p>
                    <p>:</p>
                    <p>
                      {dataHistory.data[0].due_date === ""
                        ? "-"
                        : moment(dataHistory.data[0].due_date).format(
                            "DD-MM-YYYY"
                          )}
                    </p>
                  </div>
                  <div className="w-full flex gap-2 items-center">
                    <p className="w-1/3">Tanggal Pembayaran</p>
                    <p>:</p>
                    <p>
                      {dataHistory.data[0].payment_date === ""
                        ? "-"
                        : moment(dataHistory.data[0].payment_date).format(
                            "DD-MM-YYYY"
                          )}
                    </p>
                  </div>
                  <div className="w-full flex gap-2 items-center">
                    <p className="w-1/3">Metode Pembayaran</p>
                    <p>:</p>
                    <p>{dataHistory.data[0].payment_method}</p>
                  </div>
                  <div className="w-full flex gap-2 items-center">
                    <p className="w-1/3">Status</p>
                    <p>:</p>
                    <p>
                      {dataHistory.data[0].status === 1
                        ? "Belum Dibayar"
                        : dataHistory.data[0].status === 2
                        ? "Belum Lunas"
                        : dataHistory.data[0].status === 3
                        ? "Lunas"
                        : "-"}
                    </p>
                  </div>
                </div>

                <div className="max-h-[50vh] overflow-y-auto w-full bg-gray-100 rounded-md p-3 mb-4">
                  {dataHistory.data[0].product.map((item, i) => (
                    <div
                      className={`bg-white rounded-md py-2 px-4 ${
                        i === dataHistory.data[0].product.length - 1
                          ? ""
                          : "mb-3"
                      }`}
                      key={i}
                    >
                      <div className="mb-2">
                        <div className="w-full flex gap-2">
                          <p className="w-full">Nama Produk</p>
                          <p className="w-full">Total</p>
                        </div>
                        <div className="w-full flex gap-2 text-[14px] font-bold">
                          <p className="w-full">{item.product_name}</p>
                          <p className="w-full">{toIDR(item.sub_price)}</p>
                        </div>
                      </div>
                      <div className="mb-2">
                        <div className="w-full flex gap-2">
                          <p className="w-full">Kuantitas</p>
                          <p className="w-full">Diskon</p>
                        </div>
                        <div className="w-full flex gap-2 text-[14px] font-bold">
                          <p className="w-full">{item.quantity}</p>
                          <p className="w-full">{item.discount}%</p>
                        </div>
                      </div>
                      <div className="mb-2">
                        <div className="w-full flex gap-2">
                          <p className="w-full">Harga Satuan</p>
                          <p className="w-full">Total + Diskon</p>
                        </div>
                        <div className="w-full flex gap-2 text-[14px] font-bold">
                          <p className="w-full">{toIDR(item.unit_price)}</p>
                          <p className="w-full">{toIDR(item.total_price)}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="w-full flex justify-end">
                  <div className="w-2/3">
                    <div className="w-full flex gap-2 items-center">
                      <p className="w-1/2">Jumlah</p>
                      <p>:</p>
                      <p>{toIDR(dataHistory.data[0].total_amount)}</p>
                    </div>
                    <div className="w-full flex gap-2 items-center">
                      <p className="w-1/2">Diskon</p>
                      <p>:</p>
                      <p>{toIDR(dataHistory.data[0].total_discount)}</p>
                    </div>
                    <div className="w-full flex gap-2 items-center">
                      <p className="w-1/2">Subtotal</p>
                      <p>:</p>
                      <p>{toIDR(dataHistory.data[0].sub_total)}</p>
                    </div>
                    <div className="w-full flex gap-2 items-center">
                      <p className="w-1/2">PPN</p>
                      <p>:</p>
                      <p>{toIDR(dataHistory.data[0].ppn)}</p>
                    </div>
                    <div className="w-full flex gap-2 items-center">
                      <p className="w-1/2">Voucher</p>
                      <p>:</p>
                      <p>{toIDR(dataHistory.data[0].voucher)}</p>
                    </div>
                    <div className="w-full flex gap-2 items-center font-bold">
                      <p className="w-1/2">Jumlah Bayar</p>
                      <p>:</p>
                      <p>{toIDR(dataHistory.data[0].total)}</p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="h-full min-h-[55vh] flex items-center justify-center text-gray-400 text-center w-full">
                {isLoadingHistory ? (
                  <CircularProgress size={20} />
                ) : (
                  <>
                    Silahkan isi form tambah faktur <br />
                    terlebih dahulu untuk melihat riwayat
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {dataDetail ? (
        <ModalPaymentInvoice
          open={openPay}
          setOpen={setOpenPay}
          invoice={dataDetail ? dataDetail : null}
          refetch={() => {
            refetch();
          }}
        />
      ) : null}
    </div>
  );
};

export default DetailInvoice;
