import React from "react";
import HOC from "../../components/HOC";
import DraftBillingManual from "../../components/screen/billingManualCW/DraftBillingManual";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const DraftBillingManualPage = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={(isWarehouse ? "Central Warehouse" : "") + " Manual Billing"}
        mainComponent={<DraftBillingManual isWarehouse={isWarehouse} />}
        pageRole={isWarehouse ? 28 : 7}
      />
    </div>
  );
};

export default HOC(DraftBillingManualPage);
