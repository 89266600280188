import {
  Autocomplete,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getPPNStatus, setPPNStatus } from "../../../services/invoiceApi";
import { useLocation } from "react-router-dom";
import { PPN_OPTION } from "../../constants/constant";

const SettingInvoice = ({ isWarehouse }) => {
  const location = useLocation();

  const [ppn, setPpn] = useState(null);
  const [ppnValue, setPpnValue] = useState(0);
  const [note, setNote] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);

  const handleClose = (_, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
    setErrorMessage("");
  };

  const handleSubmit = () => {
    const body = {
      ppn_active: ppn,
      ppn_value: ppnValue,
      invoice_note: note,
      warehouse: isWarehouse ? isWarehouse : false,
    };
    mutatePPN(body);
  };

  const { mutate: mutatePPN, isLoading } = useMutation({
    mutationFn: setPPNStatus,
    onError: (err) => {
      setErrorMessage(err.message.id);
      setOpen(true);
    },
    onSuccess: () => setOpen(true),
  });

  const { data } = useQuery({
    queryKey: ["ppn-status", location.pathname],
    queryFn: () => getPPNStatus(isWarehouse ? isWarehouse : false),
  });

  useEffect(() => {
    if (data) {
      setPpn(data.ppn_value ? data.ppn_value : 0);
      setNote(data.invoice_note);
      setPpnValue(data.ppn_value ? data.ppn_value : 0);
    }
  }, [data]);

  return (
    <div>
      <p className="font-bold">PPN Status</p>
      <div
        className={`bg-gray-100 flex justify-between my-2 items-center py-2 px-3 rounded-md`}
      >
        <p className="font-bold w-full">PPN</p>
        {/* <Switch checked={ppn} onChange={(e, checked) => setPpn(checked)} /> */}
        {isLoading ? (
          <CircularProgress size={20} />
        ) : (
          <Autocomplete
            disablePortal
            fullWidth
            options={PPN_OPTION}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            value={
              ppnValue !== 0
                ? PPN_OPTION.find((item) => item.value === ppnValue)
                : null
            }
            sx={{ backgroundColor: "white" }}
            onChange={(_, inputValue) => {
              setPpn(inputValue !== null);
              setPpnValue(inputValue ? inputValue.value : 0);
            }}
            getOptionLabel={(option) => (option ? option.name : "Pilih PPN")}
            renderInput={(params) => (
              <TextField {...params} fullWidth placeholder="Pilih PPN" />
            )}
          />
        )}
      </div>

      <div className="my-8">
        <p className="font-bold mb-2">Catatan Faktur</p>
        <TextField
          fullWidth
          value={note}
          onChange={(e) => setNote(e.target.value)}
          placeholder="Tidak ada  catatan faktur"
          multiline
          rows={4}
        />
      </div>

      <div className="flex items-center justify-center gap-5">
        <button
          className="mt-4 p-2 px-5 bg-[#FF8D40] hover:bg-[#b45f06] w-full text-white font-bold rounded-lg"
          onClick={handleSubmit}
        >
          Konfirmasi
        </button>
      </div>

      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={() => {
          setOpen(false);
        }}
        message={
          errorMessage ? errorMessage : "Setting Invoice berhasil diupdate"
        }
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </div>
  );
};

export default SettingInvoice;
