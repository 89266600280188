import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  TextField,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogTitle,
  InputLabel,
  CircularProgress,
  Snackbar,
} from "@mui/material";

import {
  Search as SearchIcon,
  Clear as ClearIcon,
  Close as CloseIcon,
} from "@mui/icons-material";

import { useFormik } from "formik";
import { useMutation, useQuery } from "@tanstack/react-query";

import {
  getPlatform,
  getNIEElasticSearch,
  getNieDetail,
  getPackaging,
  createProduct,
  uploadImage,
  deleteImage,
} from "../../../../services/InventoryAPI";
import useDebounce from "../../../hooks/useDebounce";
import { IGNORE_TYPE } from "../../../constants/constant";

const FETCH_LIMIT = 10;

const AddNewProduct = ({ isWarehouse, setIsAddNewProductView }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [count, setCount] = useState(0);
  const [qtyType, setQtyType] = useState("");
  const [search, setSearch] = useState("");
  const [nieNumber, setNieNumber] = useState("");
  const [nieId, setNieId] = useState("");
  const [bpomId, setBpomId] = useState("");
  const [open, setOpen] = useState(false);
  const [isCreateProductWithNIE, setIsCreateProductWithNIE] = useState(false);
  const [nie, setNie] = useState("");

  const debounce = useDebounce(search, 500);

  const { data: platforms } = useQuery({
    queryKey: ["platform"],
    keepPreviousData: true,
    queryFn: () => getPlatform(),
  });

  const { isFetching: isNieLoading } = useQuery({
    queryKey: ["nie", debounce],
    queryFn: () => getNIEElasticSearch(debounce, FETCH_LIMIT, 1),
    keepPreviousData: true,
    onSuccess: (data) => setNie(data.data),
    enabled: open,
  });

  const { data } = useQuery({
    queryKey: ["nie_detail", nieId, bpomId],
    queryFn: () => getNieDetail(nieId, bpomId),
    keepPreviousData: true,
    enabled: !!nieId && !!bpomId,
  });

  const { data: packaging } = useQuery({
    queryKey: ["packaging", data],
    queryFn: () => getPackaging(),
    keepPreviousData: true,
  });

  const { mutate: mutateCreateProduct } = useMutation({
    mutationFn: createProduct,
    onSuccess: () => setIsAddNewProductView(false),
    onError: (err) => setErrorMessage(err.message.id),
  });

  const { mutate: mutateUploadImage } = useMutation({
    mutationFn: uploadImage,
    onSuccess: (data) => {
      formik.setFieldValue("product_image", [
        ...formik.values.product_image,
        data,
      ]);
    },
  });

  const { mutate: mutateDeleteImage } = useMutation({
    mutationFn: deleteImage,
  });

  const formik = useFormik({
    initialValues: {
      is_from_po: true,
      nie_number:
        data && data !== undefined && isCreateProductWithNIE
          ? data.nie_number
          : search,
      principal_id:
        data && data !== undefined && isCreateProductWithNIE
          ? data.principal_id
          : 0,
      category_id: [155],
      label_id:
        data &&
        data !== undefined &&
        data?.label_id !== 0 &&
        isCreateProductWithNIE
          ? data.label_id
          : 0,
      product_name:
        data && data !== undefined && isCreateProductWithNIE
          ? data.product_name
          : "",
      product_image:
        data && data !== undefined && isCreateProductWithNIE ? data.media : [],
      product_description: {
        indication:
          data &&
          data !== undefined &&
          isCreateProductWithNIE &&
          data?.indication !== null
            ? data.indication
            : "",
        composition:
          data &&
          data !== undefined &&
          isCreateProductWithNIE &&
          data?.usage !== null
            ? data.composition
            : "",
        dosage:
          data &&
          data !== undefined &&
          isCreateProductWithNIE &&
          data?.dosage !== null
            ? data.dosage
            : "-",
        usage:
          data &&
          data !== undefined &&
          isCreateProductWithNIE &&
          data.usage !== null
            ? data.usage
            : "-",
        contraindication:
          data &&
          data !== undefined &&
          isCreateProductWithNIE &&
          data?.contraindication !== null
            ? data.contraindication
            : "",
        side_effect:
          data &&
          data !== undefined &&
          isCreateProductWithNIE &&
          data?.side_effect !== null
            ? data.side_effect
            : "",
        storage_description:
          data &&
          data !== undefined &&
          isCreateProductWithNIE &&
          data?.storage_description !== null
            ? data.storage_description
            : "",
      },
      shelf: "",
      require_prescription:
        data && data !== undefined && isCreateProductWithNIE
          ? data.require_prescription
          : false,
      packaging: {
        type_box:
          data && data !== undefined && isCreateProductWithNIE
            ? data.packaging?.premier.replace(/\d+/g, "").trim()
            : "",

        total_box: 1,

        type_strip:
          data && data !== undefined && isCreateProductWithNIE
            ? data.packaging?.skunder.replace(/\d+/g, "").trim()
            : "",

        total_strip:
          data &&
          data !== undefined &&
          /\d/.test(data.packaging?.skunder) &&
          isCreateProductWithNIE
            ? Number(data.packaging?.skunder.match(/\d/g).join(""))
            : 1,

        type_unit:
          data && data !== undefined && isCreateProductWithNIE
            ? data.packaging?.tersier
                .replace(/[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?\s*/g, "")
                .trim()
            : "",

        total_unit:
          data &&
          data !== undefined &&
          /\d/.test(data.packaging?.tersier) &&
          isCreateProductWithNIE
            ? parseInt(data.packaging?.tersier.match(/\d+/).join(""), 10)
            : 1,
      },
      price: {
        platform_price: [],
        pharmacy_net_price: 0,
        pharmacy_net_price_discount: 0,
        pharmacy_net_price_ppn: false,
        pharmacy_net_price_ppn_value: 0,
        pharmacy_net_price_real: 0,
        sell_price: 0,
        sell_price_sekunder: 0,
        sell_price_tersier: 0,
        price_grosir: [{ minimum: 0, maximum: 0, price_grosirs: 0 }],
      },
      reminder_stock: 5,
      status_reminder_stock: true,
      reminder_uom: 0,
      batch: [{ quantity: 0, number_batch: "", expired_date: "" }],
      barcode_number: "",
      warehouse: isWarehouse ? isWarehouse : false,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      mutateCreateProduct({
        ...values,
        price: {
          ...values.price,
          pharmacy_net_price: pharmacyNetPriceFinal(
            values.price.pharmacy_net_price_discount,
            values.price.pharmacy_net_price_real,
            values.price.pharmacy_net_price_ppn_value
          ),
        },
        product_description: {
          indication:
            values.product_description.indication === ""
              ? "-"
              : values.product_description.indication,
          composition:
            values.product_description.composition === ""
              ? "-"
              : values.product_description.composition,
          dosage:
            values.product_description.dosage === ""
              ? "-"
              : values.product_description.dosage,
          usage:
            values.product_description.usage === ""
              ? "-"
              : values.product_description.usage,
          contraindication:
            values.product_description.contraindication === ""
              ? "-"
              : values.product_description.contraindication,
          side_effect:
            values.product_description.side_effect === ""
              ? "-"
              : values.product_description.side_effect,
          storage_description:
            values.product_description.storage_description === ""
              ? "-"
              : values.product_description.storage_description,
        },
        batch: values.batch.map((data) => {
          if (qtyType === values.packaging.type_unit) {
            return {
              quantity: data.quantity,
              number_batch: data.number_batch,
              expired_date: data.expired_date,
            };
          }
          if (qtyType === values.packaging.type_strip) {
            if (IGNORE_TYPE.includes(values.packaging.type_unit)) {
              return {
                quantity: data.quantity * 1,
                number_batch: data.number_batch,
                expired_date: data.expired_date,
              };
            } else {
              return {
                quantity: data.quantity * values.packaging.total_unit,
                number_batch: data.number_batch,
                expired_date: data.expired_date,
              };
            }
          }
          if (qtyType === values.packaging.type_box) {
            if (IGNORE_TYPE.includes(values.packaging.type_unit)) {
              return {
                quantity: data.quantity * values.packaging.total_strip * 1,
                number_batch: data.number_batch,
                expired_date: data.expired_date,
              };
            } else {
              return {
                quantity:
                  data.quantity *
                  values.packaging.total_strip *
                  values.packaging.total_unit *
                  1,
                number_batch: data.number_batch,
                expired_date: data.expired_date,
              };
            }
          }
          return {
            quantity: data.quantity,
            number_batch: data.number_batch,
            expired_date: data.expired_date,
          };
        }),
      });
    },
  });

  const intersection = new Set(
    formik.values.price.platform_price.map(({ platform_id }) => platform_id)
  );
  const result = platforms?.filter(({ id }) => !intersection.has(id));

  const handleAddPlatform = useCallback(() => {
    if (platforms && platforms !== undefined && nieNumber)
      formik.setFieldValue("price.platform_price", [
        ...formik.values.price.platform_price,
        {
          platform_id: result[0]?.id,
          price: "",
        },
      ]);
    else formik.setFieldValue("price.platform_price", []);
  }, [formik.values.price.platform_price, result]);

  const pharmacyNetPriceFinal = (
    discountPercent,
    pharmacyNetPriceReal,
    taxValue
  ) => {
    if (!pharmacyNetPriceReal) return 0;
    let discount = pharmacyNetPriceReal * (discountPercent / 100);
    let value = (pharmacyNetPriceReal - discount) * taxValue;

    return pharmacyNetPriceReal - discount + value;
  };

  const finalStock = useMemo(() => {
    if (qtyType === formik.values.packaging.type_unit) {
      return count;
    }
    if (qtyType === formik.values.packaging.type_strip) {
      if (IGNORE_TYPE.includes(formik.values.packaging.type_unit)) {
        return count;
      } else {
        return count * formik.values.packaging.total_unit;
      }
    }
    if (qtyType === formik.values.packaging.type_box) {
      if (IGNORE_TYPE.includes(formik.values.packaging.type_unit)) {
        return count * formik.values.packaging.total_strip;
      } else {
        return (
          count *
          formik.values.packaging.total_unit *
          formik.values.packaging.total_strip
        );
      }
    }
    return 0;
  }, [count, formik, qtyType]);

  const calculateBatchStock = useCallback(
    (e, index, b) => {
      let numericValue = e.target.value;
      if (numericValue === "") {
        numericValue = "0";
      }
      if (qtyType === formik.values.packaging.type_unit) {
        return formik.setFieldValue(
          "batch",
          formik.values.batch.map((b1, idx) => {
            return index === idx
              ? {
                  ...b,
                  quantity: parseInt(numericValue.replace(/[^0-9]/g, ""), 10),
                }
              : b1;
          })
        );
      }
      if (qtyType === formik.values.packaging.type_strip) {
        if (IGNORE_TYPE.includes(formik.values.packaging.type_unit)) {
          return formik.setFieldValue(
            "batch",
            formik.values.batch.map((b1, idx) => {
              return index === idx
                ? {
                    ...b,
                    quantity:
                      parseInt(numericValue.replace(/[^0-9]/g, ""), 10) * 1,
                  }
                : b1;
            })
          );
        } else {
          return formik.setFieldValue(
            "batch",
            formik.values.batch.map((b1, idx) => {
              return index === idx
                ? {
                    ...b,
                    quantity:
                      parseInt(numericValue.replace(/[^0-9]/g, ""), 10) *
                      formik.values.packaging.total_unit,
                  }
                : b1;
            })
          );
        }
      }
      if (qtyType === formik.values.packaging.type_box) {
        if (IGNORE_TYPE.includes(formik.values.packaging.type_unit)) {
          return formik.setFieldValue(
            "batch",
            formik.values.batch.map((b1, idx) => {
              return index === idx
                ? {
                    ...b,
                    quantity:
                      parseInt(numericValue.replace(/[^0-9]/g, ""), 10) *
                      1 *
                      formik.values.packaging.total_strip,
                  }
                : b1;
            })
          );
        } else {
          return formik.setFieldValue(
            "batch",
            formik.values.batch.map((b1, idx) => {
              return index === idx
                ? {
                    ...b,
                    quantity:
                      parseInt(numericValue.replace(/[^0-9]/g, ""), 10) *
                      1 *
                      formik.values.packaging.total_unit *
                      formik.values.packaging.total_strip,
                  }
                : b1;
            })
          );
        }
      }
    },
    [formik, qtyType]
  );

  const handleDeleteImage = useCallback(
    (index, value) => {
      formik.setFieldValue("product_id", [
        ...formik.values.product_image.splice(index, 1),
      ]);
      mutateDeleteImage(value);
    },
    [formik.values.product_image]
  );

  useEffect(() => {
    if (debounce === "") setNie([]);
  }, [debounce]);

  return (
    <div className=" mx-auto w-full ">
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-col-1 p-3">
          <div
            className="cursor-pointer p-4 border border-black rounded-md"
            onClick={() => {
              setOpen(true);
              setSearch("");
            }}
          >
            {nieNumber !== "" ? <p>{nieNumber}</p> : <p>Nomor Izin Edar</p>}
          </div>
          <TextField
            id="outlined-basic"
            label="Nama product"
            name="product_name"
            variant="outlined"
            className="mt-3"
            disabled={nieNumber === ""}
            value={formik.values.product_name}
            onChange={(e) =>
              formik.setFieldValue("product_name", e.target.value)
            }
          />
          <div className="flex flex-row gap-5">
            <div className="flex flex-col">
              <h1 className="mt-4 font-bold text-[10px] lg:text-[20px]">
                Kemasan
              </h1>
              <div className="grid grid-cols-2 gap-4">
                <TextField
                  id="premier"
                  label="Qty"
                  variant="outlined"
                  className="mt-3"
                  disabled
                  value={formik.values.packaging.total_box}
                  onChange={(e) => {
                    let numericValue = e.target.value;
                    if (numericValue === "") {
                      numericValue = "0";
                    }
                    formik.setFieldValue(
                      "packaging.total_box",
                      parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                    );
                  }}
                />
                <FormControl fullWidth className="mt-3">
                  <InputLabel>Primer</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Primer"
                    disabled={nieNumber === ""}
                    value={formik.values.packaging.type_box.toUpperCase()}
                    onChange={(e) =>
                      formik.setFieldValue("packaging.type_box", e.target.value)
                    }
                  >
                    {!packaging?.find(
                      (pack) =>
                        pack.packaging_id === 1 &&
                        pack.name.toUpperCase() ===
                          formik.values.packaging.type_box.toUpperCase()
                    ) ? (
                      <MenuItem
                        disabled
                        value={formik.values.packaging.type_box.toUpperCase()}
                        sx={{ display: "none" }}
                      >
                        {formik.values.packaging.type_box.toUpperCase()}
                      </MenuItem>
                    ) : null}
                    {packaging && packaging !== undefined ? (
                      packaging
                        .filter((pack) => {
                          return pack.packaging_id === 1;
                        })
                        .map((pack, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={pack.name.toUpperCase()}
                            >
                              {pack.name.toUpperCase()}
                            </MenuItem>
                          );
                        })
                    ) : (
                      <MenuItem
                        disabled
                        value=""
                        sx={{ display: "none" }}
                      ></MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <TextField
                  id="sekunder"
                  label="Qty"
                  variant="outlined"
                  disabled={nieNumber === ""}
                  className="mt-3"
                  value={formik.values.packaging.total_strip}
                  onChange={(e) => {
                    let numericValue = e.target.value;
                    if (numericValue === "") {
                      numericValue = "0";
                    }
                    formik.setFieldValue(
                      "packaging.total_strip",
                      parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                    );
                  }}
                />
                <FormControl fullWidth className="mt-3">
                  <InputLabel>Sekunder</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Sekunder"
                    disabled={nieNumber === ""}
                    value={formik.values.packaging.type_strip.toUpperCase()}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "packaging.type_strip",
                        e.target.value
                      )
                    }
                  >
                    {!packaging?.find(
                      (pack) =>
                        pack.packaging_id === 2 &&
                        pack.name.toUpperCase() ===
                          formik.values.packaging.type_strip.toUpperCase()
                    ) ? (
                      <MenuItem
                        disabled
                        value={formik.values.packaging.type_strip.toUpperCase()}
                        sx={{ display: "none" }}
                      >
                        {formik.values.packaging.type_strip.toUpperCase()}
                      </MenuItem>
                    ) : null}
                    {packaging && packaging !== undefined ? (
                      packaging
                        .filter((pack) => {
                          return pack.packaging_id === 2;
                        })
                        .map((pack, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={pack.name.toUpperCase(0)}
                            >
                              {pack.name.toUpperCase()}
                            </MenuItem>
                          );
                        })
                    ) : (
                      <MenuItem
                        disabled
                        value=""
                        sx={{ display: "none" }}
                      ></MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <TextField
                  id="outlined-basic"
                  label="Qty"
                  variant="outlined"
                  className="mt-3"
                  disabled={nieNumber === ""}
                  value={formik.values.packaging.total_unit}
                  onChange={(e) => {
                    let numericValue = e.target.value;
                    if (numericValue === "") {
                      numericValue = "0";
                    }
                    formik.setFieldValue(
                      "packaging.total_unit",
                      parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                    );
                  }}
                />
                <FormControl fullWidth className="mt-3">
                  <InputLabel id="demo-simple-select-label">Tersier</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    disabled={nieNumber === ""}
                    label="Tersier"
                    value={formik.values.packaging.type_unit.toUpperCase()}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "packaging.type_unit",
                        e.target.value
                      )
                    }
                  >
                    {!packaging?.find(
                      (pack) =>
                        pack.packaging_id === 3 &&
                        pack.name.toUpperCase() ===
                          formik.values.packaging.type_unit.toUpperCase()
                    ) ? (
                      <MenuItem
                        disabled
                        value={formik.values.packaging.type_unit.toUpperCase()}
                        sx={{ display: "none" }}
                      >
                        {formik.values.packaging.type_unit.toUpperCase()}
                      </MenuItem>
                    ) : null}
                    {packaging && packaging !== undefined ? (
                      packaging
                        .filter((pack) => {
                          return pack.packaging_id === 3;
                        })
                        .map((pack, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={pack.name.toUpperCase()}
                            >
                              {pack.name.toUpperCase()}
                            </MenuItem>
                          );
                        })
                    ) : (
                      <MenuItem
                        disabled
                        value=""
                        sx={{ display: "none" }}
                      ></MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 mt-10 gap-4">
            <button
              className="bg-red-700 text-white p-2 rounded-lg hover:bg-gray-300"
              type="button"
              onClick={() => setIsAddNewProductView(false)}
            >
              Batal
            </button>
            <button
              className="bg-orange-400 text-white p-2 rounded-lg disabled:bg-gray-500 hover:bg-gray-300"
              type="submit"
              disabled={
                formik.values.packaging.total_box === 0 ||
                formik.values.packaging.type_box === "" ||
                formik.values.packaging.total_strip === 0 ||
                formik.values.packaging.type_strip === "" ||
                formik.values.packaging.total_unit === 0 ||
                formik.values.packaging.type_unit === ""
              }
            >
              Konfirmasi
            </button>
          </div>
        </div>
      </form>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle className="flex justify-center">
          <button
            type="button"
            onClick={() => setOpen(false)}
            className="mr-[10px]"
          >
            <ClearIcon sx={{ color: "black" }} />
          </button>
          <TextField
            fullWidth
            focused
            label="Nomor Izin Edar"
            onChange={(e) => setSearch(e.target.value.toUpperCase())}
            value={search}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            inputProps={{
              style: { textTransform: "uppercase" },
            }}
          />
        </DialogTitle>
        <DialogContent sx={{ height: 500 }}>
          {isNieLoading ? (
            <div className="flex items-center justify-center h-full">
              <CircularProgress />
            </div>
          ) : (
            <>
              {nie && nie !== undefined && nie.length > 0
                ? nie.map((data) => (
                    <div
                      className="mt-3 flex flex-col shadow-md rounded-xl p-3 border-2 justify-start cursor-pointer"
                      key={data.id}
                      onClick={() => {
                        setNieId(data.id);
                        setBpomId(data.product_bpom_id);
                        setNieNumber(data.nie_number);
                        setOpen(false);
                        setIsCreateProductWithNIE(true);
                      }}
                    >
                      <p className="font-bold">{data.nie_number}</p>
                      <p className="font-bold">{data.product_name}</p>
                      <p className="font-bold">{data.kemasan}</p>
                    </div>
                  ))
                : null}
              {nie.length === 0 ? (
                <div
                  className="mt-3 flex flex-col shadow-md rounded-xl p-3 border-2 justify-start cursor-pointer text-bold"
                  onClick={() => {
                    setOpen(false);
                    setIsCreateProductWithNIE(false);
                    setNieNumber(search);
                  }}
                >
                  <p className="font-bold">{search}</p>
                </div>
              ) : null}
            </>
          )}
        </DialogContent>
      </Dialog>
      <Snackbar
        open={Boolean(errorMessage)}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
        autoHideDuration={3000}
        labelId="demo-simple-select-label"
        name="label_id"
        id="demo-simple-select"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setErrorMessage("")}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </div>
  );
};

export default AddNewProduct;
