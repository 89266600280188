import { useEffect, useState } from "react";
import { BsTrashFill } from "react-icons/bs";
import moment from "moment";
import {
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

const tempBool = true;

const PromoCard = ({ formik, promo, index }) => {
  const [isCollapse, setIsCollapse] = useState(false);

  const handlePromoTypeChange = (val) => {
    formik.setFieldValue("promo[" + index + "].promo_type", val);
    if (val === 1) {
      formik.setFieldValue("promo[" + index + "].quantity_bought", 0);
      formik.setFieldValue("promo[" + index + "].quantity_get", 0);
      formik.setFieldValue("promo[" + index + "].max_item", 0);
      formik.setFieldValue("promo[" + index + "].is_multiple", false);
    }
    if (val === 2) {
      formik.setFieldValue("promo[" + index + "].on_us", 0);
      formik.setFieldValue("promo[" + index + "].off_us", 0);
      formik.setFieldValue("promo[" + index + "].total_discount", 0);
    }
  };

  const handleDeleteItem = () => {
    formik.setFieldValue("promo", [
      ...formik.values.promo.slice(0, index),
      ...formik.values.promo.slice(index + 1),
    ]);
  };

  useEffect(() => {
    formik.setFieldValue(
      "promo[" + index + "].total_discount",
      promo.off_us + promo.on_us
    );
  }, [promo.off_us, promo.on_us]);

  return (
    <div className="p-4 border-2 border-gray-200 rounded-md">
      {!isCollapse ? (
        <>
          <div className="flex justify-between">
            <div className="w-3/4">
              <p className="font-bold text-xl truncate">{promo.product_name}</p>
            </div>
            {tempBool ? (
              <div
                className="bg-red-500 hover:bg-red-700 text-white p-2 rounded-md cursor-pointer"
                onClick={handleDeleteItem}
              >
                <BsTrashFill />
              </div>
            ) : null}
          </div>
          <div className="mt-4">
            <div className="flex">
              <p className="basis-1/3">Batch No: </p>
              <p>{promo.batch_number}</p>
            </div>
            <div className="flex">
              <p className="basis-1/3">Kemasan: </p>
              <p>{promo.packaging}</p>
            </div>
            <div className="flex">
              <p className="basis-1/3">Tanggal Kadaluwarsa: </p>
              <p> {moment(promo.expired_date).format("DD MMMM YYYY")}</p>
            </div>

            <div className="mt-4 mb-2">
              <FormControl fullWidth>
                <InputLabel>Promo Type</InputLabel>
                <Select
                  value={promo.promo_type}
                  label="Promo Type"
                  onChange={(e) => handlePromoTypeChange(e.target.value)}
                >
                  <MenuItem value={0} disabled>
                    Select promo type
                  </MenuItem>
                  <MenuItem value={1}>Discount</MenuItem>
                  <MenuItem value={2}>Free Item</MenuItem>
                </Select>
              </FormControl>
            </div>

            {promo.promo_type === 1 ? (
              <>
                <div className="my-2 flex gap-2 items-center justify-between">
                  <TextField
                    fullWidth
                    label="On Us"
                    type="number"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    value={promo.on_us ? promo.on_us.toString() : 0}
                    onChange={(e) =>
                      !isNaN(Number(e.target.value)) &&
                      Number(e.target.value) <= 100
                        ? formik.setFieldValue(
                            "promo[" + index + "].on_us",
                            Number(e.target.value)
                          )
                        : formik.setFieldValue("promo[" + index + "].on_us", 0)
                    }
                  />
                  <TextField
                    fullWidth
                    label="Off Us"
                    type="number"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    value={promo.off_us ? promo.off_us.toString() : 0}
                    onChange={(e) =>
                      !isNaN(Number(e.target.value)) &&
                      Number(e.target.value) <= 100
                        ? formik.setFieldValue(
                            "promo[" + index + "].off_us",
                            Number(e.target.value)
                          )
                        : formik.setFieldValue("promo[" + index + "].off_us", 0)
                    }
                  />
                </div>
              </>
            ) : promo.promo_type === 2 ? (
              <>
                <div className="my-2 flex gap-2 items-center justify-between">
                  <TextField
                    fullWidth
                    label="Buy Qty"
                    value={promo.quantity_bought}
                    onChange={(e) =>
                      !isNaN(Number(e.target.value))
                        ? formik.setFieldValue(
                            "promo[" + index + "].quantity_bought",
                            Number(e.target.value)
                          )
                        : formik.setFieldValue(
                            "promo[" + index + "].quantity_bought",
                            0
                          )
                    }
                  />
                  <TextField
                    fullWidth
                    label="Get Qty"
                    value={promo.quantity_get}
                    onChange={(e) =>
                      !isNaN(Number(e.target.value))
                        ? formik.setFieldValue(
                            "promo[" + index + "].quantity_get",
                            Number(e.target.value)
                          )
                        : formik.setFieldValue(
                            "promo[" + index + "].quantity_get",
                            0
                          )
                    }
                  />
                </div>
                <div className="my-2 flex gap-2 items-center justify-between">
                  <TextField
                    fullWidth
                    label="Free Item Limit"
                    value={promo.max_item}
                    onChange={(e) =>
                      !isNaN(Number(e.target.value))
                        ? formik.setFieldValue(
                            "promo[" + index + "].max_item",
                            Number(e.target.value)
                          )
                        : formik.setFieldValue(
                            "promo[" + index + "].max_item",
                            0
                          )
                    }
                  />
                  <div className="w-full">
                    <Checkbox
                      onChange={(_, checked) =>
                        formik.setFieldValue(
                          "promo[" + index + "].is_multiple",
                          checked
                        )
                      }
                    />
                    Allow Multiple
                  </div>
                </div>
              </>
            ) : (
              <p className="text-gray-300">
                Pilih promo type untuk mengatur ketentuan promo
              </p>
            )}
            {promo.promo_type !== 0 ? (
              <div className="flex gap-2 my-2">
                <DesktopDatePicker
                  views={["year", "month", "day"]}
                  disablePast
                  label="Start Date"
                  className="w-full"
                  onChange={(date) =>
                    formik.setFieldValue(
                      "promo[" + index + "].period_start",
                      moment(date).format("YYYY-MM-DD") + " 00:00:00"
                    )
                  }
                  value={promo.period_start}
                  renderInput={(params) => {
                    return <TextField {...params} />;
                  }}
                />
                <DesktopDatePicker
                  views={["year", "month", "day"]}
                  label="End Date"
                  minDate={moment(promo.period_start).add(1, "d")}
                  className="w-full"
                  onChange={(date) =>
                    formik.setFieldValue(
                      "promo[" + index + "].period_end",
                      moment(date).format("YYYY-MM-DD") + " 23:59:59"
                    )
                  }
                  value={promo.period_end}
                  renderInput={(params) => {
                    return <TextField {...params} />;
                  }}
                />
              </div>
            ) : null}
          </div>

          <p
            className="text-right text-blue-400 hover:underline cursor-pointer"
            onClick={() => setIsCollapse(true)}
          >
            Show Less
          </p>
        </>
      ) : (
        <div className="flex justify-between items-center">
          <div className="w-2/3">
            <p className="font-bold text-xl truncate w-full">
              {promo.product_name}
            </p>
            <p className="inline-block">Promo Type: </p>{" "}
            <p
              className={`inline-block ${
                promo.promo_type === 0 ? "text-red-500" : "text-gray-500"
              }`}
            >
              {promo.promo_type === 1
                ? "Discount"
                : promo.promo_type === 2
                ? "Free Item"
                : "Tipe promo belum diatur untuk produk ini"}
            </p>
          </div>
          <p
            className="text-blue-400 hover:underline cursor-pointer"
            onClick={() => setIsCollapse(false)}
          >
            Show More
          </p>
        </div>
      )}
    </div>
  );
};

export default PromoCard;
