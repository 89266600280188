import {
  CircularProgress,
  InputAdornment,
  Popper,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getPoList } from "../../../services/purchaseOrderApi";
import { useEffect, useState } from "react";
import { Search } from "@mui/icons-material";
import moment from "moment";
import PagePagination from "../../common/particles.jsx/PagePagination";

const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const PoListBillingManual = () => {
  const navigate = useNavigate();

  const [offset, setOffset] = useState(1);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [moreProductArr, setMoreProductArr] = useState([]);
  const [anchorElMoreProduct, setAnchorElMoreProduct] = useState(null);

  const {
    mutate: mutatePoList,
    data: dataPoList,
    isLoading,
  } = useMutation({
    mutationFn: getPoList,
  });

  useEffect(() => {
    mutatePoList({
      search: search,
      limit: fetchLimit,
      offset: offset,
      type: 4,
      sort: 0,
      start_date: "",
      end_date: "",
      warehouse: false,
    });
  }, [mutatePoList, search, offset, fetchLimit]);

  return (
    <div className="text-[12px]">
      <div className="flex justify-end">
        <TextField
          sx={{ marginBottom: 2, width: "40%" }}
          value={search}
          placeholder="Cari No. PO/Nama Distributor"
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <div className="flex w-full mb-2">
        <div className="w-full">
          <div className="block overflow-x-auto w-full rounded-md">
            <table className="w-full overflow-x-auto rounded-md border-collapse">
              <thead className="bg-blue-500 text-white">
                <th className="p-2 text-center">No</th>
                <th className="p-2 text-center whitespace-nowrap">
                  Tanggal PO
                </th>
                <th className="p-2 text-center whitespace-nowrap">Nomor PO</th>
                <th className="p-2 text-center whitespace-nowrap">
                  Distributor
                </th>
                <th className="p-2 text-center whitespace-nowrap">Produk</th>
                <th className="p-2 text-center whitespace-nowrap">Status</th>
                <th className="p-2 text-center whitespace-nowrap">Total</th>
                <th className="p-2 text-center whitespace-nowrap">Action</th>
              </thead>
              <tbody className="border">
                {dataPoList &&
                dataPoList.total > 0 &&
                dataPoList.data !== null ? (
                  dataPoList.data.map((item, i) => {
                    return (
                      <tr key={i} className="border">
                        <td className="p-2 text-center">{i + 1}</td>
                        <td className="p-2 text-center">
                          {moment(item.po_date).format("DD MMM YYYY")}
                        </td>
                        <td className="p-2 text-center">{item.po_number}</td>
                        <td className="p-2 text-center">
                          {item.distributor_name}
                        </td>
                        <td className="p-2 text-left">
                          <div className="w-full flex justify-between gap-4 items-center">
                            <p>{item.product_name[0]}</p>
                            {item.product_name.length > 1 ? (
                              <p
                                className="text-blue-500 cursor-pointer"
                                onMouseEnter={(e) => {
                                  setMoreProductArr(item.product_name.slice(1));
                                  setAnchorElMoreProduct(e.currentTarget);
                                }}
                                onMouseLeave={() =>
                                  setAnchorElMoreProduct(null)
                                }
                              >
                                +{item.product_name.length - 1} Lainnya
                              </p>
                            ) : null}
                          </div>
                        </td>
                        <td
                          className={`p-2 text-center font-bold ${
                            !item.get_purchase_order_billing_lists ||
                            item.get_purchase_order_billing_lists.length === 0
                              ? "text-green-500"
                              : item.is_partial
                              ? "text-orange-500"
                              : "text-black"
                          }`}
                        >
                          {!item.get_purchase_order_billing_lists ||
                          item.get_purchase_order_billing_lists.length === 0
                            ? "Open"
                            : item.is_partial
                            ? "Partial"
                            : "Closed"}
                        </td>
                        <td className="p-2 text-center">{toIDR(item.total)}</td>
                        <td className="p-2 text-center">
                          <button
                            className="p-2 w-fit rounded-md bg-slate-100 border-blue-500 text-blue-500 hover:bg-gray-300"
                            fullWidth
                            onClick={() => {
                              navigate(
                                `/manual-billing/create-billing/${item.id}/${item.is_partial}`
                              );
                            }}
                          >
                            Pilih
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : isLoading ? (
                  <tr className="border">
                    <td colSpan={8} className="p-2 text-center">
                      <CircularProgress size={20} />
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan={8} className="text-red-500 p-2 text-center">
                      Tidak ada data PO ditemukan
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {dataPoList && dataPoList.total > 0 && dataPoList.data !== null ? (
        <div className="mb-4">
          <PagePagination
            offset={offset}
            setOffset={(e) => setOffset(e)}
            limit={fetchLimit}
            setLimit={(e) => setFetchLimit(e)}
            total={dataPoList.total}
          />
        </div>
      ) : null}

      <Popper
        placement="bottom"
        open={Boolean(anchorElMoreProduct)}
        anchorEl={anchorElMoreProduct}
        sx={{ maxWidth: "200px" }}
        modifiers={[
          {
            name: "arrow",
            enabled: true,
            // options: {
            //   element: arrowRef,
            // },
          },
        ]}
      >
        <div className="bg-white text-[10px] p-2 rounded-md border text-wrap whitespace-wrap overflow-hidden break-words">
          {moreProductArr.map((item, i) => `${i + 1}. ${item}`).join(", ")}
        </div>
      </Popper>
    </div>
  );
};

export default PoListBillingManual;
