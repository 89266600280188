import {
  Box,
  CircularProgress,
  InputAdornment,
  Modal,
  TextField,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { RxCross1 } from "react-icons/rx";
import { useState } from "react";
import useDebounce from "../../../hooks/useDebounce";
import { Search } from "@mui/icons-material";
import PagePagination from "../../../common/particles.jsx/PagePagination";
import ModalDeleteCustomer from "./ModalDeleteCustomer";
import { getCustomerList } from "../../../../services/invoiceApi";
import ModalAddCustomer from "./ModalAddCustomer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalListCustomer = ({ open, setOpen }) => {
  const [search, setSearch] = useState("");
  const [fetchLimit, setFetchLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedCust, setSelectedCust] = useState(null);

  const debounce = useDebounce(search, 500);

  const handleClose = () => {
    setOpen(false);
  };

  const {
    data: dataCustomer,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["customer-list", offset, fetchLimit, debounce, open],
    queryFn: () => getCustomerList(fetchLimit, 1, debounce, false),
    enabled: open === true,
  });

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            maxWidth: "75%",
            overflowY: "auto",
            overflowX: "wrap",
            maxHeight: "80vh",
            padding: 0,
          }}
          className="text-left text-[12px] w-3/4"
        >
          <div className="flex items-center px-8 py-4 w-full text-center">
            <p className="w-full text-[20px] font-bold">List Customer</p>
            <RxCross1
              className="cursor-pointer hover:text-red-500 w-fit"
              onClick={handleClose}
            />
          </div>

          <hr className="border-b-2 mb-4" />

          <div className="px-8 mb-4">
            <TextField
              fullWidth
              placeholder="Cari Customer"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                setOffset(1);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              sx={{ marginBottom: 2 }}
            />

            <div className="flex w-full mb-8">
              <div className="w-full">
                <div className="block overflow-x-auto w-full rounded-md">
                  <table className="w-full overflow-x-auto rounded-md border-collapse border ">
                    <thead className="bg-blue-500 text-white">
                      <th className="p-2 text-center">No</th>
                      <th className="p-2 text-center whitespace-nowrap">
                        Nama Customer
                      </th>
                      <th className="p-2 text-center whitespace-nowrap">PIC</th>
                      <th className="p-2 text-center whitespace-nowrap">
                        No Telepon
                      </th>
                      <th className="p-2 text-center whitespace-nowrap">
                        Alamat
                      </th>
                      <th className="p-2 text-center whitespace-nowrap">
                        Action
                      </th>
                    </thead>
                    <tbody>
                      {dataCustomer && !isFetching && dataCustomer.total > 0 ? (
                        dataCustomer.data.map((item, i) => (
                          <tr>
                            <td className="p-2 text-center">
                              {(offset - 1) * 10 + (i + 1)}
                            </td>
                            <td className="p-2 text-center">
                              {item.partner_name}
                            </td>
                            <td className="p-2 text-center">{item.pic}</td>
                            <td className="p-2 text-center">{item.phone}</td>
                            <td className="p-2 text-center">{item.address}</td>
                            <td className="p-2 text-center">
                              <div className="w-full flex gap-2 items-center">
                                <button
                                  className="bg-gray-100 text-gray-500 border-2 rounded-md w-full hover:bg-gray-300 py-1 px-4 disabled:bg-gray-300 disabled:text-white"
                                  type="button"
                                  onClick={() => {
                                    setSelectedCust(item);
                                    setOpenEdit(true);
                                  }}
                                >
                                  Edit
                                </button>
                                <button
                                  className="bg-red-100 border-red-500 text-red-500 border-2 rounded-md w-full hover:bg-gray-300 py-1 px-3 disabled:border-none disabled:bg-gray-300 disabled:text-white"
                                  onClick={() => {
                                    setSelectedCust(item);
                                    setOpenDelete(true);
                                  }}
                                  type="button"
                                >
                                  Delete
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="p-2 text-center" colSpan={5}>
                            {isFetching ? (
                              <CircularProgress size={20} />
                            ) : (
                              "Tidak ada customer"
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {dataCustomer && !isFetching && dataCustomer.total > 0 ? (
              <PagePagination
                offset={offset}
                setOffset={(e) => setOffset(e)}
                limit={fetchLimit}
                setLimit={(e) => setFetchLimit(e)}
                total={dataCustomer.total}
              />
            ) : null}
          </div>
        </Box>
      </Modal>

      <ModalAddCustomer
        open={openEdit}
        setOpen={setOpenEdit}
        id={selectedCust}
        setId={setSelectedCust}
        refetch={() => refetch()}
      />

      <ModalDeleteCustomer
        open={openDelete}
        setOpen={setOpenDelete}
        cust={selectedCust}
        setCust={setSelectedCust}
        refetch={() => refetch()}
      />
    </>
  );
};

export default ModalListCustomer;
