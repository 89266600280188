import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { BsFillPersonLinesFill } from "react-icons/bs";
import {
  Paper,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  TextField,
  Pagination,
  CircularProgress,
  Select,
  MenuItem,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";

import {
  getCustomerDetail,
  getCustomerTransaction,
} from "../../../services/customerApi";
import BreadcrumbCustomer from "./BreadcrumbCustomer";
import { useQuery } from "@tanstack/react-query";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import PagePagination from "../../common/particles.jsx/PagePagination";

const toDate = (dateStr) => moment(dateStr).utc().format("DD MMMM YYYY");
const toApiDate = (dateStr) => moment(dateStr).format("YYYY-MM-DD");

const DetailCustomer = () => {
  const { id = "" } = useParams();

  const [offset, setOffset] = useState("1");
  const [limit, setLimit] = useState(10);
  const [tanggalMulai, setTanggalMulai] = useState("");
  const [tanggalAkhir, setTanggalAkhir] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { data: customerDetail, isLoadingCustomer } = useQuery({
    queryKey: ["customerDetail"],
    queryFn: () => getCustomerDetail(id),
  });
  const { data: customerTransactions, isLoading: isLoadingTransactions } =
    useQuery({
      queryKey: [
        "customer-transactions",
        tanggalMulai,
        tanggalAkhir,
        limit,
        offset,
      ],
      queryFn: () =>
        getCustomerTransaction(
          Number(offset),
          id,
          limit,
          tanggalMulai,
          tanggalAkhir
        ),
      keepPreviousData: true,
    });

  useEffect(() => {
    if (
      toApiDate(startDate) === "Invalid date" ||
      toApiDate(endDate) === "Invalid date"
    ) {
      setTanggalMulai("");
      setTanggalAkhir("");
    } else {
      setTanggalMulai(toApiDate(startDate));
      setTanggalAkhir(toApiDate(endDate));
    }
  }, [startDate, endDate]);

  return (
    <>
      <BreadcrumbCustomer />
      <div className="flex justify-between items-center">
        {customerDetail && !isLoadingCustomer && customerDetail.customer ? (
          <>
            <div className="flex justify-between items-center w-full my-4">
              <div className="w-full">
                <p className="font-bold text-xl mb-2">
                  {customerDetail.customer[0].name}
                </p>
                <div className="flex gap-2 w-full">
                  <p className="w-1/4">Email</p>
                  <p>:</p>
                  <p>{customerDetail.customer[0].email}</p>
                </div>
                <div className="flex gap-2 w-full">
                  <p className="w-1/4">No. Telepon</p>
                  <p>:</p>
                  <p>{customerDetail.customer[0].phone}</p>
                </div>
                <div className="flex gap-2 w-full">
                  <p className="w-1/4">Alamat</p>
                  <p>:</p>
                  <p>{customerDetail.customer[0].address}</p>
                </div>
                <div className="flex gap-2 w-full">
                  <p className="w-1/4">NIK</p>
                  <p>:</p>
                  <p>{customerDetail.customer[0].nik}</p>
                </div>
                <div className="flex gap-2 w-full">
                  <p className="w-1/4">Tanggal Lahir</p>
                  <p>:</p>
                  <p>-</p>
                </div>
                <div className="flex gap-2 w-full">
                  <p className="w-1/4">Gender</p>
                  <p>:</p>
                  <p>-</p>
                </div>
                <div className="flex gap-2 w-full">
                  <p className="w-1/4">No. Rekam Medis</p>
                  <p>:</p>
                  <p>-</p>
                </div>
              </div>
              <div className="flex gap-4 w-full justify-end">
                <div>
                  <p>Total Belanja</p>
                  {customerTransactions && !isLoadingTransactions ? (
                    <p className="text-amber-500 text-xl">
                      {customerTransactions.count.total_amount.toLocaleString(
                        "id-ID",
                        {
                          style: "currency",
                          currency: "IDR",
                        }
                      )}
                    </p>
                  ) : isLoadingTransactions ? (
                    <CircularProgress size={20} />
                  ) : (
                    <p className="text-amber-500 text-xl">0</p>
                  )}
                </div>
                <div>
                  <p>Total</p>
                  {customerTransactions && !isLoadingTransactions ? (
                    <p className="text-xl">
                      {customerTransactions.transaction?.length}
                    </p>
                  ) : isLoadingTransactions ? (
                    <CircularProgress size={20} />
                  ) : (
                    <p className="text-xl">0</p>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : isLoadingCustomer ? (
          <CircularProgress size={20} />
        ) : (
          <p className="w-full text-center my-4">
            Data detail pelanggan tidak ditemukan
          </p>
        )}
      </div>

      <div className="flex flex-row justify-end gap-2 my-3 mt-5">
        <>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              views={["year", "month", "day"]}
              label="Pilih Tanggal Mulai"
              value={startDate}
              onChange={(newStartDate) => {
                setStartDate(newStartDate);
              }}
              renderInput={(params) => {
                return <TextField {...params} />;
              }}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              views={["year", "month", "day"]}
              label="Pilih Tanggal Berakhir"
              value={endDate}
              onChange={(newEndDate) => {
                setEndDate(newEndDate);
              }}
              renderInput={(params) => {
                return <TextField {...params} />;
              }}
              disabled={startDate === null ? true : false}
              shouldDisableDate={(date) => moment(date).isBefore(startDate)}
            />
          </LocalizationProvider>
        </>
      </div>

      <>
        <div className="flex w-full mb-8">
          <div className="w-full">
            <div className="block overflow-x-auto w-full">
              <table className="w-full border-collapse">
                <thead className="bg-blue-500 text-white">
                  <th className="p-2">ID Transaction</th>
                  <th className="p-2 text-left">Tanggal</th>
                  <th className="p-2 text-left">Produk</th>
                  <th className="p-2 text-left">Qty</th>
                  <th className="p-2 text-left">Value</th>
                  <th className="p-2 text-left">Kasir</th>
                </thead>
                <tbody>
                  {customerTransactions && !isLoadingTransactions ? (
                    customerTransactions.transaction.map((item, i) => {
                      return (
                        <tr
                          key={i}
                          className="border-collapse border border-gray-500"
                        >
                          <td
                            className="p-2 text-left border text-blue-500 underline hover:text-blue-700 cursor-pointer"
                            onClick={() =>
                              window.open(
                                `/pelanggan/detail-order/${item.transaction_id}`,
                                "_blank"
                              )
                            }
                          >
                            {item.transaction_id}
                          </td>
                          <td className="p-2 text-left border">
                            {toDate(item.created_at)}
                          </td>
                          <td className="p-2 text-left border">
                            {item.product}
                          </td>
                          <td className="p-2 text-left border">
                            {item.quantity}
                          </td>
                          <td className="p-2 text-left border">
                            {item.price
                              ? item.price.toLocaleString("id-ID", {
                                  style: "currency",
                                  currency: "IDR",
                                })
                              : 0}
                          </td>
                          <td className="p-2 text-left border">
                            {item.fullname}
                          </td>
                        </tr>
                      );
                    })
                  ) : isLoadingTransactions ? (
                    <tr>
                      <td className="p-2 border text-center" colSpan={6}>
                        <CircularProgress size={20} />
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td className="p-2 border text-center" colSpan={6}>
                        Tidak ada transaksi
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {customerTransactions &&
        !isLoadingTransactions &&
        customerTransactions.count.total_transaction > 0 ? (
          <PagePagination
            offset={offset}
            setOffset={(e) => {
              setOffset(e);
            }}
            limit={limit}
            setLimit={(e) => {
              setLimit(e);
            }}
            total={customerTransactions.count.total_transaction}
          />
        ) : null}
      </>
    </>
  );
};

export default DetailCustomer;
