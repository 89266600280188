import React from "react";
import HOC from "../../components/HOC";
import CreateInvoice from "../../components/screen/invoiceCW/CreateInvoice";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const CreateInvoicePageCW = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={"Create Invoice Central Warehouse"}
        mainComponent={<CreateInvoice isWarehouse={isWarehouse} />}
        pageRole={42}
      />
    </div>
  );
};

export default HOC(CreateInvoicePageCW);
