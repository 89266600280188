import Cookies from "js-cookie";
import axios from "axios";

const token = () => Cookies.get("accessTokenOfficeOlin");

export const getBillingList = async (status, limit, offset) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_ACCOUNT}billings?status=${status}&limit=${limit}&offset=${offset}`,
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const getBillingManualList = async (status, limit, offset) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_PRODUCT}/purchase-order/get-billing?status=${status}&limit=${limit}&offset=${offset}`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const getBillingDetail = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_ACCOUNT}detail/billing?bill_id=${id}`,
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const getBillingManualDetail = async (id, warehouse) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/get-billing-detail?billing_id=${id}&warehouse=${warehouse}`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const getBillingManualDetailNew = async (id, billId) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/detail-billing?po_id=${id}&billing_id=${billId}`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const getBillingPaymentMethod = async (search) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/bank-list?keyword=${search}&limit=200&offset=1`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const getBillingInvoiceDetail = async (billNum) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_ACCOUNT}detail/billing/invoice?billing_id=${billNum}`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const getBillingPaymentDetail = async (vaNum) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_ACCOUNT}detail/billing/payment`,
      data: { va: vaNum },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const setPaymentBilling = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_ACCOUNT}create/payment/billing`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const payBilling = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/payment-billing`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getBillingStatus = async (va) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_ACCOUNT}billing/status`,
      data: { va: va },
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const payManualBilling = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/pay-billing`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const fetchReturList = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}product/retur`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};
export const fetchReturListSell = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}product/retur-customer`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const getExportBilling = async ({ id, warehouse }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${
        process.env.REACT_APP_API_PRODUCT
      }purchase-order/csv/export?po_id=${id}&warehouse=${
        warehouse ? warehouse : false
      }`,
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const getProductForRetur = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      data: body,
      url: `${process.env.REACT_APP_API_PRODUCT}product/get-product-retur`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const getProductForReturSell = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      data: body,
      url: `${process.env.REACT_APP_API_PRODUCT}product/get-product-retur-customer`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data.message;
  }
};

export const createRetur = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}product/create-retur`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const createReturSell = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}product/create-retur-customer`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    throw err.response.data;
  }
};

export const getReturPdf = async (note_number) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_PRODUCT}product/get-retur-pdf?note_number=${note_number}`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    return err.response.data.message;
  }
};

export const getReturSellDetail = async (note_number) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "GET",
      url: `${process.env.REACT_APP_API_PRODUCT}product/detail-retur-customer?note_number=${note_number}`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    return err.response.data.message;
  }
};

export const importPO = async ({ file, id, warehouse }) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
        "Content-Type": "multipart/form-data",
      },
      method: "PUT",
      url: `${
        process.env.REACT_APP_API_PRODUCT
      }purchase-order/csv/update?po_id=${id}&warehouse=${
        warehouse ? warehouse : false
      }`,
      data: {
        excel_purchase_order: file,
      },
    };
    const res = await axios(config);
    return res.data;
  } catch (err) {
    throw err.response.data;
  }
};

export const getNewBilling = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/new-get-billing`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    return err.response.data.message;
  }
};

export const getListBilling = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/list-billing`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    return err.response.data.message;
  }
};

export const setBillingManual = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/billing`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    return err.response.data.message;
  }
};

export const getNewPoDetail = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/get-detail`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    return err.response.data.message;
  }
};

export const updatePurchaseOrderNew = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "PUT",
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/new-update`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    return err.response.data;
  }
};

export const updateBillingNew = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "POST",
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/billing`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    return err.response.data;
  }
};

export const deleteBillingList = async (id) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "DELETE",
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/soft-delete?po_id=${id}`,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    return err.response.data;
  }
};

export const updateFakturBilling = async (body) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
      method: "PUT",
      url: `${process.env.REACT_APP_API_PRODUCT}purchase-order/new-faktur-number`,
      data: body,
    };
    const res = await axios(config);
    return res.data.detail;
  } catch (err) {
    return err.response.data;
  }
};
