import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Breadcumbs from "../../common/particles.jsx/Breadcumbs";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  Checkbox,
  FormGroup,
  FormControlLabel,
  MenuItem,
  Snackbar,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { getRole, getTitle } from "../../../utils/FetchData";
import { RiImageAddLine } from "react-icons/ri";
import { MdCancel } from "react-icons/md";
import { BsImages } from "react-icons/bs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useFormik } from "formik";
import * as yup from "yup";
import { cloneDeep, includes, update } from "lodash";

import { createUser } from "../../../services/userManagementApi";
import { uploadImage, deleteImage } from "../../../services/InventoryAPI";
import { getOutletList } from "../../../services/outletApi";
import {
  EXCLUDE_ROLE,
  INVENTORY_ROLE,
  INVOICE_ROLE,
  PATIENT_CARE_ROLE,
  RETUR_ROLE,
  WAREHOUSE_ROLE,
} from "../../constants/constant";

const validationSchema = yup.object({
  company_id: yup
    .number()
    .nullable(true)
    .transform((_, val) => (val > 0 ? val : null)),
  title_id: yup.number().required("jabatan wajib diisi"),
  role: yup.array().min(1, "Peran wajib diisi"),
  fullname: yup.string().required("nama lengkap wajib diisi"),
  email: yup.string().email().required("email wajib diisi"),
  phone: yup
    .string()
    .required("No. telpon wajib diisi")
    .min(9, "nomor telpon kurang dari 9 digit"),
  sipa_id: yup.string().when("title_id", {
    is: (value) => value === 3 || value === 4 || value === 7,
    then: yup.string().required("sipa wajib diisi"),
  }),
  sipa_expired: yup.string().when("title_id", {
    is: (value) => value === 3 || value === 4 || value === 7,
    then: yup.string().required("Masa kadaluarsa SIPA wajib diisi"),
  }),
});

const KTP = "KTP";
const IZIN = "IZIN";
const S = "SPESIMEN";

const CreateUser = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [type, setType] = useState("");

  const titleId = localStorage.getItem("title");

  const { mutate: mutateCreateUser, isLoading: isCreateUserLoading } =
    useMutation({
      mutationFn: createUser,
      onSuccess: () => {
        navigate("/user-management");
      },
      onError: (err) => setErrorMessage(err.message.id),
    });

  const formik = useFormik({
    initialValues: {
      company_id: 0,
      title_id: 0,
      role: [],
      fullname: "",
      email: "",
      phone: "",
      sipa_id: "",
      sipa_expired: moment().format("YYYY-MM-DD"),
      files: {
        ktp: "",
        izin: "",
        spesimen_signature: "",
      },
    },
    validationSchema,
    onSubmit: (values) => {
      mutateCreateUser(values);
    },
  });

  const { data: roleData } = useQuery({
    queryKey: ["role"],
    queryFn: () => getRole(),
  });

  const { mutate: mutateDeleteImage } = useMutation({
    mutationFn: deleteImage,
  });

  const { mutate: mutateUploadImage } = useMutation({
    mutationFn: uploadImage,
    onSuccess: (data) => {
      if (type === KTP) formik.setFieldValue("files.ktp", data);
      else if (type === IZIN) formik.setFieldValue("files.izin", data);
      else if (type === S)
        formik.setFieldValue("files.spesimen_signature", data);
    },
  });

  const { data: title } = useQuery({
    queryKey: ["title"],
    queryFn: () => getTitle(),
  });

  const { data: dataOutlet } = useQuery({
    queryFn: () => getOutletList(),
    queryKey: ["outlets"],
  });

  const handleSelectRole = useCallback(
    (selectedId) => {
      const selectedValue = formik.values.role.findIndex(
        (id) => id === selectedId
      );

      let updatedList = cloneDeep(formik.values.role);

      if (selectedValue !== -1) {
        updatedList.splice(selectedValue, 1);
      } else {
        updatedList.push(selectedId);
      }
      formik.setFieldValue("role", updatedList);
    },
    [formik.values.role]
  );

  const handleSelectCategory = useCallback(
    (checked, type) => {
      let updatedList = cloneDeep(formik.values.role);
      let roleArr =
        type === "invoice"
          ? INVOICE_ROLE
          : type === "inventory"
          ? INVENTORY_ROLE
          : type === "patientCare"
          ? PATIENT_CARE_ROLE
          : type === "warehouse"
          ? WAREHOUSE_ROLE
          : type === "retur"
          ? RETUR_ROLE
          : [];
      roleArr.map((elem) => {
        const selectedValue = updatedList.findIndex((id) => id === elem);
        if (selectedValue === -1 && checked) updatedList.push(elem);
        if (selectedValue > -1 && !checked) {
          updatedList.splice(selectedValue, 1);
        }
      });
      formik.setFieldValue("role", updatedList);
    },
    [formik.values.role]
  );

  const handleUploadImage = (e) => {
    mutateUploadImage(e.target.files[0]);
  };

  const handleDeleteImageKtp = async () => {
    mutateDeleteImage(formik.values.files.ktp);
    formik.setFieldValue("files.ktp", "");
  };

  const handleDeleteImageSipa = () => {
    mutateDeleteImage(formik.values.files.izin);
    formik.setFieldValue("files.izin", "");
  };

  const handleDeleteImageSpesimen = () => {
    mutateDeleteImage(formik.values.files.spesimen_signature);
    formik.setFieldValue("files.spesimen_signature", "");
  };

  useEffect(() => {
    if (
      dataOutlet &&
      dataOutlet.filter((outlet) => outlet.status === 1).length === 1
    )
      formik.setFieldValue(
        "company_id",
        Number(dataOutlet.find((outlet) => outlet.status === 1).company_id)
      );
  }, [dataOutlet]);

  return (
    <div className="container mx-auto w-full mt-16 lg:mt-0">
      <div className="flex text-blue-500 gap-2">
        <p
          className="hover:cursor-pointer"
          onClick={() => navigate("/user-management")}
        >
          User Management
        </p>
        <p>/</p>
        <p>Create User</p>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1">
          {Number(titleId) === 1 && dataOutlet && dataOutlet.length > 1 ? (
            <FormControl fullWidth className="mt-3">
              <InputLabel id="demo-simple-select-label">
                Pilih Outlet
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="outlet_id"
                label="Pilih Outlet"
                value={formik.values.company_id}
                onChange={(e) =>
                  formik.setFieldValue("company_id", Number(e.target.value))
                }
                error={
                  formik.touched.company_id && Boolean(formik.errors.company_id)
                }
              >
                {dataOutlet &&
                dataOutlet.length > 1 &&
                !dataOutlet.every((outlet) => outlet.status !== 1) ? (
                  dataOutlet
                    .filter((item) => item.status === 1)
                    .map((outlet) => {
                      return (
                        <MenuItem
                          value={outlet.company_id}
                          key={outlet.company_id}
                        >
                          {outlet.company_name}
                        </MenuItem>
                      );
                    })
                ) : (
                  <MenuItem value={0} disabled>
                    Tidak ada outlet Lain
                  </MenuItem>
                )}
                <MenuItem
                  value={0}
                  sx={{ display: "none" }}
                  disabled
                ></MenuItem>
              </Select>
            </FormControl>
          ) : null}
          <FormControl fullWidth className="mt-3">
            <InputLabel id="demo-simple-select-label">Pilih Jabatan</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="category_id"
              label="Pilih Jabatan"
              value={formik.values.title_id}
              onChange={(e) =>
                formik.setFieldValue("title_id", Number(e.target.value))
              }
              error={formik.touched.title_id && Boolean(formik.errors.title_id)}
            >
              {title && title !== undefined
                ? title.map((item, index) => (
                    <MenuItem value={item.id} key={index}>
                      {item.name}
                    </MenuItem>
                  ))
                : null}
              <MenuItem value={0} sx={{ display: "none" }} disabled></MenuItem>
            </Select>
          </FormControl>
          {formik.touched.title_id && Boolean(formik.errors.title_id) ? (
            <p className="text-[12px] mt-[3px] mx-[14px] text-[#D32F2F] mb-[1px]">
              {formik.touched.title_id && formik.errors.title_id}
            </p>
          ) : null}

          <>
            <div
              className={`p-4 shadow-md rounded-lg mt-3 ${
                formik.touched.role && Boolean(formik.errors.role)
                  ? "border border-[#D32F2F]"
                  : null
              }`}
            >
              <p className="text-[18px] font-semibold">Pilih role</p>
              <hr className="mt-2 my-3" />
              <div className="grid grid-cols-4 gap-x-4 w-full">
                <div className="grid grid-cols-1 gap-x-4">
                  {roleData && roleData !== undefined
                    ? roleData
                        .filter(
                          (item) =>
                            ![
                              ...EXCLUDE_ROLE,
                              ...INVOICE_ROLE,
                              ...INVENTORY_ROLE,
                              ...PATIENT_CARE_ROLE,
                              ...WAREHOUSE_ROLE,
                              ...RETUR_ROLE,
                            ].includes(item.id)
                        )
                        .map((item, index) => {
                          return (
                            <FormGroup key={index}>
                              <FormControlLabel
                                control={<Checkbox />}
                                label={item.name}
                                onChange={() => {
                                  handleSelectRole(item.id);
                                }}
                              />
                            </FormGroup>
                          );
                        })
                    : null}
                </div>
                <div>
                  <FormGroup sx={{ marginBottom: 2 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={INVOICE_ROLE.every((elem) =>
                            formik.values.role.includes(elem)
                          )}
                          onChange={(_, checked) => {
                            handleSelectCategory(checked, "invoice");
                          }}
                        />
                      }
                      label="Invoice"
                    />
                    <div className="grid grid-cols-1 gap-x-4">
                      {roleData && roleData !== undefined
                        ? roleData
                            .filter((item) => INVOICE_ROLE.includes(item.id))
                            .map((item, index) => {
                              return (
                                <FormGroup key={index}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={formik.values.role.includes(
                                          item.id
                                        )}
                                        onChange={() =>
                                          handleSelectRole(item.id)
                                        }
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                      />
                                    }
                                    sx={{ marginLeft: 2 }}
                                    label={item.name}
                                  />
                                </FormGroup>
                              );
                            })
                        : null}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={RETUR_ROLE.every((elem) =>
                            formik.values.role.includes(elem)
                          )}
                          onChange={(_, checked) => {
                            handleSelectCategory(checked, "retur");
                          }}
                        />
                      }
                      label="Retur"
                    />
                    <div className="grid grid-cols-1 gap-x-4">
                      {roleData && roleData !== undefined
                        ? roleData
                            .filter((item) => RETUR_ROLE.includes(item.id))
                            .map((item, index) => {
                              return (
                                <FormGroup key={index}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={formik.values.role.includes(
                                          item.id
                                        )}
                                        onChange={() =>
                                          handleSelectRole(item.id)
                                        }
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                      />
                                    }
                                    sx={{ marginLeft: 2 }}
                                    label={item.name}
                                  />
                                </FormGroup>
                              );
                            })
                        : null}
                    </div>
                  </FormGroup>
                </div>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={INVENTORY_ROLE.every((elem) =>
                          formik.values.role.includes(elem)
                        )}
                        onChange={(_, checked) => {
                          handleSelectCategory(checked, "inventory");
                        }}
                      />
                    }
                    label="Inventory"
                  />
                  <div className="grid grid-cols-1 gap-x-4">
                    {roleData && roleData !== undefined
                      ? roleData
                          .filter((item) => INVENTORY_ROLE.includes(item.id))
                          .map((item, index) => {
                            return (
                              <FormGroup key={index}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={formik.values.role.includes(
                                        item.id
                                      )}
                                      onChange={() => handleSelectRole(item.id)}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  sx={{ marginLeft: 2 }}
                                  label={item.name}
                                />
                              </FormGroup>
                            );
                          })
                      : null}
                  </div>
                </FormGroup>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={PATIENT_CARE_ROLE.every((elem) =>
                          formik.values.role.includes(elem)
                        )}
                        onChange={(_, checked) => {
                          handleSelectCategory(checked, "patientCare");
                        }}
                      />
                    }
                    label="Patient Care"
                  />
                  <div className="grid grid-cols-1 gap-x-4">
                    {roleData && roleData !== undefined
                      ? roleData
                          .filter((item) => PATIENT_CARE_ROLE.includes(item.id))
                          .map((item, index) => {
                            return (
                              <FormGroup key={index}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={formik.values.role.includes(
                                        item.id
                                      )}
                                      onChange={() => handleSelectRole(item.id)}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  sx={{ marginLeft: 2 }}
                                  label={item.name}
                                />
                              </FormGroup>
                            );
                          })
                      : null}
                  </div>
                </FormGroup>
              </div>
              {formik.touched.role && Boolean(formik.errors.role) ? (
                <p className="text-[12px] mt-[3px] mx-[14px] text-[#D32F2F] mb-[1px]">
                  {formik.touched.role && formik.errors.role}
                </p>
              ) : null}
              <hr className="my-4" />
              <div>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={WAREHOUSE_ROLE.every((elem) =>
                          formik.values.role.includes(elem)
                        )}
                        onChange={(_, checked) => {
                          handleSelectCategory(checked, "warehouse");
                        }}
                      />
                    }
                    label="Central Warehouse"
                  />
                  <div className="grid grid-cols-4 gap-x-4">
                    {roleData && roleData !== undefined
                      ? roleData
                          .filter((item) => WAREHOUSE_ROLE.includes(item.id))
                          .map((item, index) => {
                            return (
                              <FormGroup key={index}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={formik.values.role.includes(
                                        item.id
                                      )}
                                      onChange={() => handleSelectRole(item.id)}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  }
                                  sx={{ marginLeft: 2 }}
                                  label={item.name}
                                />
                              </FormGroup>
                            );
                          })
                      : null}
                  </div>
                </FormGroup>
              </div>
            </div>

            <TextField
              id="outlined-basic"
              label="Nama Pekerja"
              variant="outlined"
              className="mt-3"
              value={formik.values.fullname}
              onChange={(e) => formik.setFieldValue("fullname", e.target.value)}
              error={formik.touched.fullname && Boolean(formik.errors.fullname)}
              helperText={formik.touched.fullname && formik.errors.fullname}
            />
            {formik.values.title_id === 3 ||
            formik.values.title_id === 4 ||
            formik.values.title_id === 7 ? (
              <>
                <TextField
                  id="outlined-basic"
                  label="Nomor SIPA"
                  variant="outlined"
                  className="mt-3"
                  value={formik.values.sipa_id}
                  onChange={(e) =>
                    formik.setFieldValue("sipa_id", e.target.value)
                  }
                  error={
                    formik.touched.sipa_id && Boolean(formik.errors.sipa_id)
                  }
                  helperText={formik.touched.sipa_id && formik.errors.sipa_id}
                />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    views={["year", "month", "day"]}
                    label="Masa Berlaku SIPA"
                    value={formik.values.sipa_expired}
                    onChange={(newStartDate) => {
                      formik.setFieldValue("sipa_expired", newStartDate);
                    }}
                    renderInput={(params) => {
                      return <TextField {...params} className="mt-4" />;
                    }}
                    disablePast
                  />
                </LocalizationProvider>
              </>
            ) : null}
            <TextField
              id="outlined-basic"
              label="Nomor HP Apoteker"
              variant="outlined"
              className="mt-3"
              value={formik.values.phone}
              onChange={(e) => formik.setFieldValue("phone", e.target.value)}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
            <TextField
              id="outlined-basic"
              label="Email Apoteker"
              variant="outlined"
              className="mt-3"
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            {/* <div className="grid grid-cols-1 mt-4">
                <div className="relative">
                  <p className="text-xs bg-white absolute bottom-[-10px] left-2 z-10 p-[2px]">Tanggal Expired</p>
                </div>

                <DatePicker
                  className="border-2 border-gray-300 rounded-md p-2 h-[55px] w-full"
                  // selected={form.expired_date ? new Date(form.expired_date) : null}

                  value={form.sipa_expired}
                  onChange={handleDate}
                  dateFormat="yyyy/MM/dd"
                />
              </div>
 */}
            {formik.values.title_id === 3 ||
            formik.values.title_id === 4 ||
            formik.values.title_id === 7 ? (
              <div>
                <p className="font-bold text-[20px] mt-5">
                  Unggah Kelengkapan Data
                </p>
                <div>
                  <div className="bg-blue-500 p-3 text-white font-medium flex mt-4 items-center gap-3 text-[20px] rounded-t-lg">
                    <span>
                      <BsImages />
                    </span>
                    KTP
                  </div>
                  <div className="flex items-center">
                    {formik.values.files.ktp !== "" ? (
                      <div className="flex flex-col items-center justify-center w-full h-64 border-2 rounded-b-lg">
                        <div className="flex justify-center">
                          <img
                            src={formik.values.files.ktp}
                            alt="KTP"
                            className="w-52"
                          />
                          <div className="relative">
                            <button
                              type="button"
                              onClick={(e) => handleDeleteImageKtp(e)}
                            >
                              <MdCancel className="text-red-500 hover:text-gray-800 text-[25px]" />
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <label
                        htmlFor="dropzone-file1"
                        className="cursor-pointer flex flex-col items-center justify-center w-full h-64 border-2 rounded-b-lg"
                        onClick={() => setType("KTP")}
                      >
                        <div className="flex flex-col items-center justify-center">
                          <RiImageAddLine className="text-[90px] text-blue-500" />
                          <p className="font-medium text-l text-blue-400 mt-3">
                            Upload foto product
                          </p>
                          <p className="text-center">
                            <span className="">Ukuran foto maksimal 1 mb</span>
                          </p>
                        </div>
                        <input
                          id="dropzone-file1"
                          type="file"
                          hidden
                          onChange={(e) => handleUploadImage(e)} // accept="accept=' image/jpeg, image/png, image/jpg"
                        />
                      </label>
                    )}
                  </div>
                </div>
                <div>
                  <div className="bg-blue-500 p-3 text-white font-medium flex mt-4 items-center gap-3 text-[20px] rounded-t-lg">
                    <span>
                      <BsImages />
                    </span>
                    Surat Izin Praktek/Tenaga Teknis
                  </div>
                  <div className="flex items-center">
                    {formik.values.files.izin !== "" ? (
                      <div className="flex flex-col items-center justify-center w-full h-64 border-2 rounded-b-lg">
                        <div className="flex justify-center">
                          <img
                            src={formik.values.files.izin}
                            alt="sipa"
                            className="w-52"
                          />
                          <div className="relative">
                            <button
                              type="button"
                              onClick={(e) => handleDeleteImageSipa(e)}
                            >
                              <MdCancel className="text-red-500 hover:text-gray-800 text-[25px]" />
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <label
                        htmlFor="dropzone-file2"
                        className="cursor-pointer flex flex-col items-center justify-center w-full h-64 border-2 rounded-b-lg"
                        onClick={() => setType("IZIN")}
                      >
                        <div className="flex flex-col items-center justify-center">
                          <RiImageAddLine className="text-[90px] text-blue-500" />
                          <p className="font-medium text-l text-blue-400 mt-3">
                            Upload foto product
                          </p>
                          <p className="text-center">
                            <span className="">Ukuran foto maksimal 1 mb</span>
                          </p>
                        </div>
                        <input
                          id="dropzone-file2"
                          type="file"
                          hidden
                          onChange={(e) => handleUploadImage(e)} // accept="accept=' image/jpeg, image/png, image/jpg"
                        />
                      </label>
                    )}
                  </div>
                </div>
                <div>
                  <div className="bg-blue-500 p-3 text-white font-medium flex mt-4 items-center gap-3 text-[20px] rounded-t-lg">
                    <span>
                      <BsImages />
                    </span>
                    Upload Spesimen Tanda Tangan *
                  </div>
                  <div className="flex items-center">
                    {formik.values.files.spesimen_signature !== "" ? (
                      <div className="flex flex-col items-center justify-center w-full h-64 border-2 rounded-b-lg">
                        <div className="flex justify-center">
                          <img
                            src={formik.values.files.spesimen_signature}
                            alt="Upload Spesimen Tanda Tangan"
                            className="w-52"
                          />
                          <div className="relative">
                            <button
                              type="button"
                              onClick={(e) => handleDeleteImageSpesimen(e)}
                            >
                              <MdCancel className="text-red-500 hover:text-gray-800 text-[25px]" />
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <label
                        htmlFor="dropzone-file3"
                        className="cursor-pointer flex flex-col items-center justify-center w-full h-64 border-2 rounded-b-lg"
                        onClick={() => setType("SPESIMEN")}
                      >
                        <div className="flex flex-col items-center justify-center">
                          <RiImageAddLine className="text-[90px] text-blue-500" />
                          <p className="font-medium text-l text-blue-400 mt-3">
                            Upload foto product
                          </p>
                          <p className="text-center">
                            <span className="">Ukuran foto maksimal 1 mb</span>
                          </p>
                        </div>
                        <input
                          id="dropzone-file3"
                          type="file"
                          hidden
                          onChange={(e) => handleUploadImage(e)}
                          // accept="accept=' image/jpeg, image/png, image/jpg"
                        />
                      </label>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </>
          <div className="grid grid-cols-2 mt-20 gap-12">
            <button
              type="button"
              className="bg-red-700 text-white font-semibold p-3 rounded-lg"
              onClick={() => navigate("/user-management")}
            >
              Batal
            </button>
            <button
              className="bg-orange-400 text-white font-semibold p-3 rounded-lg disabled:bg-gray-300"
              disabled={
                isCreateUserLoading ||
                (Number(titleId) === 1 &&
                  (formik.values.company_id === 0 ||
                    formik.values.company_id === null))
                  ? true
                  : false
              }
              type="submit"
            >
              {isCreateUserLoading ? "Loading..." : "Konfirmasi"}
            </button>
          </div>
        </div>
      </form>
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={5000}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={(_, reason) => {
                if (reason === "clickaway") return;
                setErrorMessage("");
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </div>
  );
};

export default CreateUser;
