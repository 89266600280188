import {
  Autocomplete,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import BreadcrumbCentralWarehouse from "../BreadcrumbCentralWarehouse";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { getOutletList } from "../../../../services/outletApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import { IoAddCircleOutline } from "react-icons/io5";
import { BsPencil, BsTrash } from "react-icons/bs";
import { LuMinusCircle, LuPlusCircle } from "react-icons/lu";
import {
  deleteCartWarehouse,
  getCartWarehouse,
  setWarehouse,
  updateCartWarehouse,
} from "../../../../services/centralWarehouseApi";
import { useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { cloneDeep } from "lodash";
import ModalConfirmationAction from "../../../common/particles.jsx/ModalConfirmationAction";
import LoadingBackdrop from "../../../common/particles.jsx/LoadingBackdrop";
import ModalAddProductWarehouse from "../ModalAddProductWarehouse";
import ModalAddWarehouse from "../ModalAddWarehouse";
import ModalDeleteProductWarehouse from "../ModalDeleteProductWarehouse";
import PagePagination from "../../../common/particles.jsx/PagePagination";

const AddReturWarehouse = () => {
  const navigate = useNavigate();

  const [returReq, setReturReq] = useState({
    type: 3,
    target: 0,
    date: moment().format("YYYY-MM-DD"),
  });
  const [cartLimit, setCartLimit] = useState(10);
  const [cartOffset, setCartOffset] = useState(1);
  const [openOverStok, setOpenOverStock] = useState(false);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [editProduct, setEditProduct] = useState(null);
  const [deleteProduct, setDeleteProduct] = useState(null);
  const [addProductArr, setAddProductArr] = useState([]);

  const getStockOnHands = (id) => {
    const product = addProductArr.find((prod) => prod.id === id);
    return product.packaging.find((pack) => pack.uom === product.uom).stock;
  };

  const changeStock = (id, action, val) => {
    var temp = [...addProductArr];
    const index = addProductArr.findIndex((prod) => prod.id === id);
    const maxVal = getStockOnHands(id);

    if (action === "min") temp[index].quantity -= 1;
    if (action === "add") {
      if (temp[index].quantity + 1 > maxVal) setOpenOverStock(true);

      temp[index].quantity =
        temp[index].quantity + 1 <= maxVal ? temp[index].quantity + 1 : maxVal;
    }
    if (action === "text") {
      if (!isNaN(Number(val)) && Number(val) <= maxVal)
        temp[index].quantity = Number(val);
      if (isNaN(Number(val))) temp[index].quantity = 0;
      if (Number(val) > maxVal) setOpenOverStock(true);
    }
    setAddProductArr(temp);
  };

  const handlePackaging = (id, e) => {
    var temp = [...addProductArr];
    const index = addProductArr.findIndex((prod) => prod.id === id);
    temp[index].uom = e.target.value;
    temp[index].quantity = 0;
    setAddProductArr(temp);
  };

  const refetchList = () => {
    setCartLimit(10);
    setCartOffset(1);
    mutateCart({
      type: 3,
      limit: cartLimit,
      offset: cartOffset,
    });
  };

  const { data: dataOutlet, isFetching: isFetchingOutlet } = useQuery({
    queryFn: () => getOutletList(),
    queryKey: ["outlets"],
  });

  const { mutate: mutateUpdateCart, isLoading: isLoadingUpdate } = useMutation({
    mutationFn: updateCartWarehouse,
    onSuccess: () => {
      setEditProduct(null);
      refetchList();
    },
  });

  const { mutate: mutateDeleteCart, isLoading: isLoadingDelete } = useMutation({
    mutationFn: deleteCartWarehouse,
    onSuccess: () => refetchList(),
  });

  const { mutate: mutateClear, isLoading: isLoadingClear } = useMutation({
    mutationFn: deleteCartWarehouse,
    onSuccess: () => {
      setConfirm(false);
      setOpenSuccess(true);
    },
  });

  const {
    mutate: mutateCart,
    data: dataCartList,
    isLoading: isLoadingCart,
  } = useMutation({
    mutationFn: getCartWarehouse,
  });

  const { mutate: mutateRetur, isLoading: isLoadingRetur } = useMutation({
    mutationFn: setWarehouse,
    onSuccess: () => {
      mutateClear({ type: 3 });
    },
    onError: () => {
      setConfirm(false);
    },
  });

  useEffect(() => {
    mutateCart({
      type: 3,
      limit: cartLimit,
      offset: cartOffset,
    });
  }, [cartLimit, cartOffset]);

  useEffect(() => {
    if (confirm) mutateRetur(returReq);
  }, [confirm]);

  return (
    <>
      <LoadingBackdrop
        isLoading={isLoadingRetur || isLoadingDelete || isLoadingClear}
      />
      <BreadcrumbCentralWarehouse />
      <div className="flex gap-4 items- mb-4">
        <Autocomplete
          disabled
          fullWidth
          loading={isFetchingOutlet}
          disablePortal
          options={[{ id: 0, company_name: "Gudang" }]}
          value={{ id: returReq.target, company_name: "Gudang" }}
          getOptionLabel={(option) => option.company_name || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Outlet Destination"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isFetchingOutlet ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <div className="w-full">
          <DesktopDatePicker
            views={["year", "month", "day"]}
            fullWidth
            disablePast
            onChange={(newDate) =>
              setReturReq((prevValue) => ({
                ...prevValue,
                date: moment(newDate).format("YYYY-MM-DD"),
              }))
            }
            value={returReq.date}
            renderInput={(params) => {
              return <TextField {...params} label="Retur Date" fullWidth />;
            }}
          />
        </div>
      </div>

      <div className="flex justify-between items-center w-full mb-4">
        <p className="font-bold">Selected Products</p>
        <button
          className="rounded-md bg-blue-500 text-white flex items-center gap-2 py-2 px-4 hover:bg-gray-300"
          onClick={() => setOpenAddProduct(true)}
        >
          Add Product <IoAddCircleOutline />
        </button>
      </div>

      {dataCartList && !isLoadingCart && dataCartList.cart ? (
        <div className="flex w-full mb-4">
          <div className="w-full">
            <div className="block overflow-x-auto w-full rounded-md">
              <table className="w-full overflow-x-auto rounded-md border-collapse">
                <thead className="bg-blue-500 text-white">
                  <th className="py-2 text-center">No</th>
                  <th className="px-3 py-2 text-left whitespace-nowrap">
                    Product Name
                  </th>
                  <th className="px-3 py-2 text-left whitespace-nowrap">
                    Batch No
                  </th>
                  <th className="px-3 py-2 text-left whitespace-nowrap">
                    Expired Date
                  </th>
                  <th className="px-3 py-2 text-center whitespace-nowrap">
                    UOM
                  </th>
                  <th className="px-3 py-2 text-center whitespace-nowrap w-[250px]">
                    Qty
                  </th>
                  <th className="px-3 py-2 text-left whitespace-nowrap">
                    Final Price
                  </th>
                  <th className="px-3 py-2 text-left whitespace-nowrap">
                    Total
                  </th>
                  <th></th>
                </thead>
                <tbody>
                  {dataCartList.cart.map((item, i) => (
                    <tr
                      key={i}
                      className={`${
                        editProduct && editProduct !== item.id
                          ? "bg-gray-100"
                          : ""
                      }`}
                    >
                      <td className="px-3 py-2 border text-center">{i + 1}</td>
                      <td className="px-3 py-2 border">{item.product_name}</td>
                      <td className="px-3 py-2 border">{item.batch}</td>
                      <td className="px-3 py-2 border">
                        {moment(item.expired_date).format("DD/MM/YY")}
                      </td>
                      <td className="px-3 py-2 border text-center w-[22%]">
                        {editProduct &&
                        editProduct === item.id &&
                        item.packaging ? (
                          <FormControl fullWidth>
                            <Select
                              disabled={editProduct !== item.id}
                              value={addProductArr[i].uom}
                              onChange={(e) => {
                                handlePackaging(addProductArr[i].id, e);
                              }}
                            >
                              {addProductArr[i].packaging.map((pack) =>
                                pack.uom < 2 ? (
                                  <MenuItem value={pack.uom}>
                                    {pack.name}
                                  </MenuItem>
                                ) : null
                              )}
                            </Select>
                          </FormControl>
                        ) : (
                          item.packaging.find((pack) => pack.uom === item.uom)
                            .name
                        )}
                      </td>
                      <td className="px-3 py-2 border text-center">
                        {editProduct !== item.id ? (
                          item.quantity
                        ) : (
                          <div className="flex justify-center bg-slate-100 rounded-md border-2 p-1">
                            <button
                              disabled={addProductArr[i].quantity === 0}
                              className="text-red-500 hover:text-red-700"
                              onClick={() => {
                                changeStock(addProductArr[i].id, "min");
                              }}
                            >
                              <LuMinusCircle className="text-[16px]" />
                            </button>
                            <input
                              type="text"
                              className="bg-slate-100 p-2 w-[80px] text-center"
                              value={addProductArr[i].quantity}
                              onChange={(e) =>
                                changeStock(
                                  addProductArr[i].id,
                                  "text",
                                  e.target.value
                                )
                              }
                            />
                            <button
                              className="text-blue-500 hover:text-blue-700"
                              onClick={() => {
                                changeStock(addProductArr[i].id, "add");
                              }}
                            >
                              <LuPlusCircle className="text-[16px]" />
                            </button>
                          </div>
                        )}
                      </td>
                      <td className="px-3 py-2 border">
                        {item.hna.toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </td>
                      <td className="px-3 py-2 border">
                        {(
                          (editProduct && editProduct === item.id
                            ? addProductArr[i].quantity
                            : item.quantity) * item.hna
                        ).toLocaleString("id-ID", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </td>
                      <td className="px-3 py-2 border text-center">
                        <div className="w-full flex justify-center items-center gap-2 text-[16px]">
                          <button
                            className="bg-blue-500 hover:bg-gray-300 text-white p-2 rounded-md disabled:bg-gray-300"
                            disabled={
                              editProduct &&
                              (editProduct !== item.id ||
                                addProductArr[i].quantity === 0)
                            }
                            onClick={() => {
                              if (editProduct === null) {
                                setAddProductArr(cloneDeep(dataCartList.cart));
                                setEditProduct(item.id);
                              }
                              if (editProduct && editProduct === item.id)
                                mutateUpdateCart({
                                  type: 3,
                                  id: addProductArr[i].id,
                                  uom: addProductArr[i].uom,
                                  quantity: addProductArr[i].quantity,
                                });
                            }}
                          >
                            {editProduct === item.id ? (
                              isLoadingUpdate ? (
                                <CircularProgress size={15} />
                              ) : (
                                <FaCheck />
                              )
                            ) : (
                              <BsPencil />
                            )}
                          </button>
                          <button
                            className="bg-red-500 hover:bg-gray-300 text-white p-2 rounded-md disabled:bg-gray-300"
                            disabled={editProduct !== null}
                            onClick={() => setDeleteProduct(item.id)}
                          >
                            <BsTrash />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center text-gray-400 mb-4">
          {isLoadingCart ? (
            <CircularProgress size={20} />
          ) : (
            <p>There's no product added yet</p>
          )}
        </div>
      )}

      {dataCartList && !isLoadingCart && dataCartList.count > cartLimit ? (
        <PagePagination
          offset={cartOffset}
          setOffset={(e) => setCartOffset(e)}
          limit={cartLimit}
          setLimit={(e) => setCartLimit(e)}
          total={dataCartList.count}
        />
      ) : null}

      <div className="flex justify-end mt-4">
        <div className="w-1/2 flex gap-2">
          <button
            className="w-full rounded-md border-2 p-2 hover:bg-gray-300"
            onClick={() => mutateDeleteCart({ type: 3 })}
          >
            Clear
          </button>
          <button
            className="w-full rounded-md text-white bg-blue-500 hover:bg-gray-300 p-2 disabled:bg-gray-300"
            disabled={
              returReq.target === null || (dataCartList && !dataCartList.cart)
            }
            onClick={() => setOpenAdd(true)}
          >
            Save
          </button>
        </div>
      </div>

      <ModalAddProductWarehouse
        open={openAddProduct}
        setOpen={setOpenAddProduct}
        type={3}
        refetch={() => {
          setCartOffset(1);
          setCartLimit(10);
          mutateCart({
            type: 3,
            limit: cartLimit,
            offset: cartOffset,
          });
        }}
      />

      <ModalAddWarehouse
        open={openAdd}
        setOpen={setOpenAdd}
        setConfirm={setConfirm}
        title="Confirm Retur"
        message={`The selected product will be returned, make sure you select the product properly`}
      />

      <ModalDeleteProductWarehouse
        open={deleteProduct}
        setOpen={setDeleteProduct}
        product={deleteProduct}
        type={3}
        refetch={() => {
          refetchList();
        }}
      />

      <ModalConfirmationAction
        open={openSuccess}
        setOpen={setOpenSuccess}
        message={"Product successfully allocated"}
        returnRoute={`/central-warehouse/retur-warehouse`}
      />

      <ModalConfirmationAction
        open={openOverStok}
        setOpen={setOpenOverStock}
        message={"Insufficient stock quantity"}
      />
    </>
  );
};

export default AddReturWarehouse;
