import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  InputAdornment,
  Snackbar,
  IconButton,
  Switch,
  Autocomplete,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Delete as DeleteIcon, Close as CloseIcon } from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useQuery, useMutation } from "@tanstack/react-query";
import moment from "moment";

import {
  getPlatform,
  fetchUpdateProduct,
  updateStocksPrice,
} from "../../../../services/InventoryAPI";
import { IGNORE_TYPE, PPN_OPTION } from "../../../constants/constant";
import PopupSingleCalendar from "../../../common/particles.jsx/PopupSingleCalendar";

const toDateApi = (date) => moment(date).format("YYYY-MM-DD");

const UpdateSeveralStock = ({ isWarehouse }) => {
  const navigate = useNavigate();
  const [dataBuffer, setDataBuffer] = useState([]);
  const [stock, setStock] = useState([]);
  const [count, setCount] = useState([]);
  const [type, setType] = useState([]);
  const [errorState, setErrorState] = useState(false);
  const [qty, setQty] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [openSingleCal, setOpenSingleCal] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState(-1);

  const { data: platforms } = useQuery({
    queryKey: ["platform"],
    queryFn: () => getPlatform(),
    keepPreviousData: true,
  });

  const { mutate: mutateFetchUpdateProduct, data: updateProductData } =
    useMutation({
      mutationFn: fetchUpdateProduct,
      onSuccess: (data) => {
        setDataBuffer(
          data && data !== null
            ? data.map((data) => ({
                id: data.product_id,
                stock: data.stock,
                name_product: data.product_name,
                count: 0,
                sell_price: data.sell_price,
                sell_price_sekunder: data.sell_price_sekunder,
                sell_price_tersier: data.sell_price_tersier,
                pharmacy_net_price: 0,
                pharmacy_net_price_discount: data.pharmacy_net_price_discount,
                pharmacy_net_price_ppn: data.pharmacy_net_price_ppn,
                pharmacy_net_price_ppn_value: data.pharmacy_net_price_ppn_value
                  ? data.pharmacy_net_price_ppn_value
                  : 0,
                pharmacy_net_price_real: data.pharmacy_net_price_real,
                packaging: {
                  total_box: data.packaging?.total_box,
                  total_strip: data.packaging?.total_strip,
                  total_unit: data.packaging?.total_unit,
                },
                platform_price:
                  data.platform !== null
                    ? data.platform.map((p) => ({
                        platform_id: p.platform_id,
                        name: p.name,
                        price: p.price,
                        is_active: p.is_active,
                      }))
                    : [],
                price_grosir:
                  data.price_grosir !== null
                    ? data.price_grosir.map((pg) => ({
                        minimum: pg.minimum,
                        maximum: pg.maximum,
                        price_grosirs: pg.price_grosirs,
                      }))
                    : [],
                batch: [
                  {
                    id: 0,
                    expired_date: "",
                    number_batch: "",
                    quantity: 0,
                    dummy_quantity: 0,
                  },
                ],
              }))
            : []
        );
        setCount(
          data && data !== null
            ? data.map((data) => ({
                id: data.product_id,
                count: 0,
              }))
            : []
        );
        setType(
          data && data !== null
            ? data.map((data) => ({
                id: data.product_id,
                type: "",
              }))
            : []
        );
        setStock(
          data && data !== undefined
            ? data.map((data) => ({
                id: data.product_id,
                batch: [
                  {
                    id: 0,
                    quantity: 0,
                  },
                ],
              }))
            : []
        );
        setQty(
          updateProductData && updateProductData !== undefined
            ? {
                totalBox: updateProductData[0].packaging?.total_box,
                totalStrip: updateProductData[0].packaging?.total_strip,
                totalUnit: updateProductData[0].packaging?.total_unit,
              }
            : {}
        );
      },
    });

  const pharmacyNetPriceFinal = (
    discountPercent,
    pharmacyNetPriceReal,
    taxValue
  ) => {
    if (!pharmacyNetPriceReal) return 0;
    let discount =
      pharmacyNetPriceReal * discountPercent ? discountPercent / 100 : 0;
    let value = (pharmacyNetPriceReal - discount) * taxValue;

    return pharmacyNetPriceReal - discount + value;
  };

  const { mutate: mutateUpdateStocks } = useMutation({
    mutationFn: updateStocksPrice,
    onSuccess: () =>
      navigate(
        `${isWarehouse ? "/central-warehouse" : ""}/inventory/produk-aktif`
      ),
  });

  const handleAddPlatform = useCallback(
    (data, index) => {
      const intersection = new Set(
        dataBuffer.map((p) => p.platform_price.map((u) => u.platform_id))[index]
      );
      const result = platforms?.filter(({ id }) => !intersection.has(id));

      setDataBuffer(
        dataBuffer.map((a) => {
          return a.id === data.id
            ? {
                ...data,
                platform_price: [
                  ...data.platform_price,
                  {
                    platform_id: result[0]?.id,
                    name: result[0]?.name,
                    price: "",
                    is_active: true,
                  },
                ],
              }
            : a;
        })
      );
    },
    [dataBuffer]
  );

  const validateDetailUpdateStock = (detailUpdateStock) => {
    let isValid = true;

    detailUpdateStock?.forEach((detail) => {
      if (
        detail.count > 0 &&
        detail.batch?.some(
          (it) =>
            it.number_batch === "" ||
            it.dummy_quantity === 0 ||
            it.expired_date === ""
        )
      ) {
        isValid = false;
      }
      if (
        detail.count <= 0 &&
        detail.batch?.some(
          (it) =>
            it.number_batch !== "" ||
            it.dummy_quantity !== 0 ||
            it.expired_date !== ""
        )
      ) {
        isValid = false;
      }
      if (
        detail.batch?.some((it) => it.dummy_quantity !== 0) &&
        detail.batch?.filter((it) => it.dummy_quantity !== 0).length > 0 &&
        detail.batch
          ?.filter((it) => it.dummy_quantity !== 0)
          ?.reduce((acc, curr) => acc + curr.dummy_quantity, 0) !== detail.count
      ) {
        isValid = false;
      }
      if (!isWarehouse && detail.sell_price <= 0) {
        isValid = false;
      }
    });
    return isValid;
  };

  useEffect(() => {
    const id = sessionStorage
      .getItem("id")
      .split(",")
      .map((item) => {
        return parseInt(item, 10);
      });
    mutateFetchUpdateProduct({
      id,
      warehouse: isWarehouse ? isWarehouse : false,
    });
  }, []);

  const quantityValue = (index, count) => {
    if (type[index]?.type === updateProductData[index]?.packaging?.type_unit) {
      return count;
    }
    if (type[index]?.type === updateProductData[index]?.packaging?.type_strip) {
      if (
        IGNORE_TYPE.includes(updateProductData[index]?.packaging?.type_unit)
      ) {
        return count;
      } else {
        return count * updateProductData[index]?.packaging?.total_unit;
      }
    }
    if (type[index]?.type === updateProductData[index]?.packaging?.type_box) {
      if (
        IGNORE_TYPE.includes(updateProductData[index]?.packaging?.type_unit)
      ) {
        return count * updateProductData[index]?.packaging?.total_strip;
      } else {
        return (
          count *
          updateProductData[index]?.packaging?.total_strip *
          updateProductData[index]?.packaging?.total_unit
        );
      }
    }
  };

  return (
    <>
      {updateProductData && updateProductData !== undefined ? (
        <>
          {dataBuffer.map((data, index) => (
            <React.Fragment key={data.id}>
              <div className="grid grid-cols-1 mb-20 mt-5">
                <div className="flex flex-col flex-wrap gap-2 mt-4">
                  <p className="font-bold text-[20px]">Nama Produk</p>
                  <p>{updateProductData[index].product_name}</p>
                </div>
                <p className="font-bold text-[20px] mt-4">Update Stock</p>
                <hr className="border-2 border-black mt-2" />
                <>
                  <React.Fragment>
                    <div className="flex flex-wrap gap-2 mt-4">
                      <p>Stock saat ini :</p>
                      <p className="font-bold">
                        {updateProductData[index] !== undefined
                          ? updateProductData[index].stock
                          : 0}
                      </p>
                      {IGNORE_TYPE.includes(
                        updateProductData[index]?.packaging?.type_unit
                      ) ? (
                        <p className="font-bold">
                          {updateProductData[index]?.packaging?.type_strip}
                        </p>
                      ) : (
                        <p className="font-bold">
                          {updateProductData[index]?.packaging?.type_unit}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-wrap gap-2 mt-4 items-center gap-3">
                      <p>Tambah Stock</p>
                      <div className="flex flex-row">
                        <input
                          type="text"
                          className="border rounded-lg w-[69px] p-3"
                          disabled={type[index].type.length === 0}
                          value={count[index].count}
                          onChange={(e) => {
                            let numericValue = e.target.value;
                            if (numericValue === "") {
                              numericValue = "0";
                            }
                            setCount(
                              count.map((a) => {
                                return a.id === data.id
                                  ? {
                                      ...a,
                                      count: parseInt(
                                        numericValue.replace(/[^0-9]/g, ""),
                                        10
                                      ),
                                    }
                                  : a;
                              })
                            );
                            if (
                              type[index]?.type ===
                              updateProductData[index]?.packaging?.type_unit
                            ) {
                              setDataBuffer(
                                dataBuffer.map((a) => {
                                  return a.id === data.id
                                    ? {
                                        ...data,
                                        stock:
                                          updateProductData[index]?.stock +
                                          parseInt(
                                            numericValue.replace(/[^0-9]/g, ""),
                                            10
                                          ),
                                        count: parseInt(
                                          numericValue.replace(/[^0-9]/g, ""),
                                          10
                                        ),
                                      }
                                    : a;
                                })
                              );
                            }
                            if (
                              type[index]?.type ===
                              updateProductData[index]?.packaging?.type_strip
                            ) {
                              if (
                                IGNORE_TYPE.includes(
                                  updateProductData[index]?.packaging?.type_unit
                                )
                              ) {
                                setDataBuffer(
                                  dataBuffer.map((a) => {
                                    return a.id === data.id
                                      ? {
                                          ...data,
                                          stock:
                                            updateProductData[index]?.stock +
                                            parseInt(
                                              numericValue.replace(
                                                /[^0-9]/g,
                                                ""
                                              ),
                                              10
                                            ),
                                          count: parseInt(
                                            numericValue.replace(/[^0-9]/g, ""),
                                            10
                                          ),
                                        }
                                      : a;
                                  })
                                );
                              } else {
                                setDataBuffer(
                                  dataBuffer.map((a) => {
                                    return a.id === data.id
                                      ? {
                                          ...data,
                                          stock:
                                            updateProductData[index]?.stock +
                                            parseInt(
                                              numericValue.replace(
                                                /[^0-9]/g,
                                                ""
                                              ),
                                              10
                                            ) *
                                              updateProductData[index]
                                                ?.packaging?.total_unit,
                                          count: parseInt(
                                            numericValue.replace(/[^0-9]/g, ""),
                                            10
                                          ),
                                        }
                                      : a;
                                  })
                                );
                              }
                            }
                            if (
                              type[index]?.type ===
                              updateProductData[index]?.packaging?.type_box
                            ) {
                              if (
                                IGNORE_TYPE.includes(
                                  updateProductData[index]?.packaging?.type_unit
                                )
                              ) {
                                setDataBuffer(
                                  dataBuffer.map((a) => {
                                    return a.id === data.id
                                      ? {
                                          ...data,
                                          stock:
                                            updateProductData[index]?.stock +
                                            parseInt(
                                              numericValue.replace(
                                                /[^0-9]/g,
                                                ""
                                              ),
                                              10
                                            ) *
                                              updateProductData[index]
                                                ?.packaging?.total_strip,
                                          count: parseInt(
                                            numericValue.replace(/[^0-9]/g, ""),
                                            10
                                          ),
                                        }
                                      : a;
                                  })
                                );
                              } else {
                                setDataBuffer(
                                  dataBuffer.map((a) => {
                                    return a.id === data.id
                                      ? {
                                          ...data,
                                          stock:
                                            updateProductData[index]?.stock +
                                            parseInt(
                                              numericValue.replace(
                                                /[^0-9]/g,
                                                ""
                                              ),
                                              10
                                            ) *
                                              updateProductData[index]
                                                ?.packaging?.total_unit *
                                              updateProductData[index]
                                                ?.packaging?.total_strip,
                                          count: parseInt(
                                            numericValue.replace(/[^0-9]/g, ""),
                                            10
                                          ),
                                        }
                                      : a;
                                  })
                                );
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-2 mt-4 mb-4">
                      <p>Stock Akhir :</p>
                      <p className="font-bold">{dataBuffer[index]?.stock}</p>
                      {IGNORE_TYPE.includes(
                        updateProductData[index]?.packaging?.type_unit
                      ) ? (
                        <p className="font-bold">
                          {updateProductData[index]?.packaging?.type_strip}
                        </p>
                      ) : (
                        <p className="font-bold">
                          {updateProductData[index]?.packaging?.type_unit}
                        </p>
                      )}
                    </div>
                    <FormControl fullWidth>
                      <InputLabel>Tipe Kemasan</InputLabel>
                      <Select
                        label="Tipe Kemasan"
                        value={type[index]?.type}
                        onChange={(e) => {
                          setType(
                            type.map((a) => {
                              return a.id === data.id
                                ? { ...a, type: e.target.value }
                                : a;
                            })
                          );
                        }}
                      >
                        <MenuItem
                          value={0}
                          disabled
                          sx={{ display: "none" }}
                        ></MenuItem>
                        <MenuItem
                          value={updateProductData[index]?.packaging?.type_box}
                          onClick={() => {
                            if (
                              IGNORE_TYPE.includes(
                                updateProductData[index]?.packaging?.type_unit
                              )
                            ) {
                              setDataBuffer(
                                dataBuffer.map((a) => {
                                  return a.id === data.id
                                    ? {
                                        ...data,
                                        stock:
                                          updateProductData[index]?.stock +
                                          updateProductData[index]?.packaging
                                            ?.total_strip *
                                            count[index].count,
                                      }
                                    : a;
                                })
                              );
                            } else {
                              setDataBuffer(
                                dataBuffer.map((a) => {
                                  return a.id === data.id
                                    ? {
                                        ...data,
                                        stock:
                                          updateProductData[index]?.stock +
                                          updateProductData[index]?.packaging
                                            ?.total_unit *
                                            updateProductData[index]?.packaging
                                              ?.total_strip *
                                            count[index].count,
                                      }
                                    : a;
                                })
                              );
                            }
                          }}
                          // disabled={qtyType === data.packaging.total_unit}
                        >
                          {updateProductData[index]?.packaging?.type_box}
                        </MenuItem>
                        <MenuItem
                          value={
                            updateProductData[index]?.packaging?.type_strip
                          }
                          onClick={() => {
                            if (
                              IGNORE_TYPE.includes(
                                updateProductData[index]?.packaging?.type_unit
                              )
                            ) {
                              setDataBuffer(
                                dataBuffer.map((a) => {
                                  return a.id === data.id
                                    ? {
                                        ...data,
                                        stock:
                                          updateProductData[index]?.stock +
                                          count[index].count,
                                      }
                                    : a;
                                })
                              );
                            } else {
                              setDataBuffer(
                                dataBuffer.map((a) => {
                                  return a.id === data.id
                                    ? {
                                        ...data,
                                        stock:
                                          updateProductData[index]?.stock +
                                          updateProductData[index]?.packaging
                                            ?.total_unit *
                                            count[index].count,
                                      }
                                    : a;
                                })
                              );
                            }
                          }}
                          // disabled={qtyType === data.packaging.total_strip}
                        >
                          {updateProductData[index]?.packaging?.type_strip}
                        </MenuItem>
                        {IGNORE_TYPE.includes(
                          updateProductData[index]?.packaging?.type_unit
                        ) ? null : (
                          <MenuItem
                            value={
                              updateProductData[index]?.packaging?.type_unit
                            }
                            onClick={() => {
                              setDataBuffer(
                                dataBuffer.map((a) => {
                                  return a.id === data.id
                                    ? {
                                        ...data,
                                        stock:
                                          updateProductData[index]?.stock +
                                          updateProductData[index]?.packaging
                                            ?.total_box *
                                            count[index].count,
                                      }
                                    : a;
                                })
                              );
                            }}

                            // disabled={qtyType === data.packaging.total_box}
                          >
                            {updateProductData[index]?.packaging?.type_unit}
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <div className="grid grid-cols-1 mt-4">
                      {dataBuffer[index].batch.reduce((acc, num) => {
                        return (
                          acc +
                          num.quantity /
                            (updateProductData[index].packaging?.total_box *
                              updateProductData[index].packaging?.total_strip *
                              updateProductData[index].packaging?.total_unit)
                        );
                      }, 0) > count[index].count &&
                      dataBuffer[index].batch.length >= count[index].count ? (
                        <div className="flex">
                          <p className="text-red-500">
                            Stock melebihi batas maksimal
                          </p>
                        </div>
                      ) : null}
                      {dataBuffer[index]?.batch?.map((b, idx) => (
                        <div key={idx}>
                          <div className="flex justify-evenly mt-4">
                            <TextField
                              sx={{ width: "40%" }}
                              id="outlined-basic"
                              label="Nomor Batch"
                              variant="outlined"
                              value={b.number_batch}
                              onChange={(e) => {
                                setDataBuffer(
                                  dataBuffer.map((a) => ({
                                    ...a,
                                    batch: a.batch.map((detail) => {
                                      return data.id === a.id &&
                                        detail.id === a.batch[idx]?.id
                                        ? {
                                            ...detail,
                                            number_batch: e.target.value,
                                          }
                                        : detail;
                                    }),
                                  }))
                                );
                              }}
                            />
                            <TextField
                              sx={{ width: "30%", marginX: 3 }}
                              id="outlined-basic"
                              label="Kuantitas"
                              variant="outlined"
                              value={stock[index]?.batch[idx]?.quantity}
                              onChange={(e) => {
                                setDataBuffer(
                                  dataBuffer.map((a) => ({
                                    ...a,
                                    batch: a.batch.map((detail) => {
                                      return data.id === a.id &&
                                        detail.id === a.batch[idx]?.id
                                        ? {
                                            ...detail,
                                            quantity: Number(e.target.value),
                                            dummy_quantity: Number(
                                              e.target.value
                                            ),
                                          }
                                        : detail;
                                    }),
                                  }))
                                );
                                setStock(
                                  stock.map((a) => ({
                                    ...a,
                                    batch: a.batch.map((detail) => {
                                      return data.id === a.id &&
                                        detail.id === a.batch[idx]?.id
                                        ? {
                                            ...detail,
                                            quantity: Number(e.target.value),
                                          }
                                        : detail;
                                    }),
                                  }))
                                );
                              }}
                            />
                            <TextField
                              label="Tanggal Expired"
                              fullWidth
                              onClick={() => {
                                setOpenSingleCal(true);
                                setSelectedBatch({ index: index, idx: idx });
                              }}
                              value={
                                b.expired_date === ""
                                  ? ""
                                  : moment(b.expired_date).format("DD-MM-YYYY")
                              }
                            />
                            <button
                              type="button"
                              className="ml-[10px]"
                              onClick={() => {
                                // if (dataBuffer[index].batch.length === 1) {
                                //   setDataBuffer(
                                //     dataBuffer.map((a) => {
                                //       return a.id === data.id &&
                                //         b.id &&
                                //         data.batch[idx]?.id
                                //         ? {
                                //             ...data,
                                //             batch: [],
                                //           }
                                //         : a;
                                //     }),
                                //   );
                                //   return;
                                // }
                                setDataBuffer(
                                  dataBuffer.filter((a) => {
                                    return a.id === data.id &&
                                      b.id &&
                                      data.batch[idx]?.id
                                      ? {
                                          ...a,
                                          batch: a.batch.splice(idx, 1),
                                        }
                                      : data;
                                  })
                                );
                                setStock(
                                  stock.filter((a) => {
                                    return a.id === data.id &&
                                      b.id &&
                                      data.batch[idx]?.id
                                      ? {
                                          ...a,
                                          batch: a.batch.splice(idx, 1),
                                        }
                                      : a;
                                  })
                                );
                              }}
                            >
                              <DeleteIcon sx={{ color: "black" }} />
                            </button>
                          </div>
                          {dataBuffer[index].batch.length ===
                            count[index].count ||
                          (dataBuffer[index].batch.reduce((acc, num) => {
                            return (
                              acc +
                              num.quantity /
                                (updateProductData[index].packaging?.total_box *
                                  updateProductData[index].packaging
                                    ?.total_strip *
                                  updateProductData[index].packaging
                                    ?.total_unit)
                            );
                          }, 0) > count[index].count &&
                            dataBuffer[index].length >= count[index].count) ? (
                            <p className="text-red-500"></p>
                          ) : null}
                        </div>
                      ))}
                      <p
                        className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4 text-end"
                        onClick={() => {
                          if (
                            dataBuffer[index].batch.length ===
                              count[index].count ||
                            dataBuffer[index].batch.reduce((acc, num) => {
                              return (
                                acc +
                                num.quantity /
                                  (updateProductData[index].packaging
                                    ?.total_box *
                                    updateProductData[index].packaging
                                      ?.total_strip *
                                    updateProductData[index].packaging
                                      ?.total_unit)
                              );
                            }, 0) >= count[index].count
                          ) {
                            setErrorState(true);
                            return;
                          }
                          setErrorState(false);
                          setDataBuffer(
                            dataBuffer.map((a) => {
                              return a.id === data.id
                                ? {
                                    ...data,
                                    batch: [
                                      ...data.batch,
                                      {
                                        id:
                                          Math.max(
                                            ...data.batch.map((x) => x.id)
                                          ) + 1,
                                        quantity: 0,
                                        number_batch: "",
                                        expired_date: "",
                                      },
                                    ],
                                  }
                                : a;
                            })
                          );
                          setStock(
                            stock.map((a) => {
                              return a.id === data.id
                                ? {
                                    ...a,
                                    batch: [
                                      ...a.batch,
                                      {
                                        id:
                                          Math.max(
                                            ...a.batch.map((x) => x.id)
                                          ) + 1,
                                        quantity: 0,
                                      },
                                    ],
                                  }
                                : a;
                            })
                          );
                        }}
                      >
                        +Tambah Batch lain
                      </p>
                    </div>
                    <TextField
                      id="outlined-basic"
                      fullWidth
                      label="HNA"
                      autoComplete="off"
                      variant="outlined"
                      value={
                        dataBuffer[index]
                          ? dataBuffer[index].pharmacy_net_price_real
                          : 0
                      }
                      className="mt-3"
                      onChange={(e) => {
                        let numericValue = e.target.value;
                        if (numericValue === "") {
                          numericValue = "0";
                        }
                        setDataBuffer(
                          dataBuffer.map((a) => {
                            return a.id === data.id
                              ? {
                                  ...data,
                                  pharmacy_net_price_real: parseInt(
                                    numericValue.replace(/[^0-9]/g, ""),
                                    10
                                  ),
                                }
                              : a;
                          })
                        );
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Rp.</InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      id="outlined-basic"
                      fullWidth
                      sx={{ marginTop: 3 }}
                      label="Discount"
                      type="number"
                      onFocus={(e) =>
                        e.target.addEventListener(
                          "wheel",
                          function (e) {
                            e.preventDefault();
                          },
                          { passive: false }
                        )
                      }
                      variant="outlined"
                      InputProps={
                        ({
                          startAdornment: (
                            <InputAdornment position="start">
                              Rp.
                            </InputAdornment>
                          ),
                        },
                        {
                          endAdornment: (
                            <InputAdornment position="start">%</InputAdornment>
                          ),
                        })
                      }
                      value={
                        dataBuffer[index]
                          ? dataBuffer[
                              index
                            ].pharmacy_net_price_discount.toString()
                          : 0
                      }
                      onChange={(e) => {
                        let numericValue = e.target.value;

                        if (
                          !isNaN(Number(numericValue)) &&
                          Number(numericValue) >= 0 &&
                          Number(numericValue) <= 100
                        )
                          setDataBuffer(
                            dataBuffer.map((a) => {
                              return a.id === data.id
                                ? {
                                    ...data,
                                    pharmacy_net_price_discount:
                                      Number(numericValue),
                                  }
                                : a;
                            })
                          );
                      }}
                    />
                    <div className="flex items-center mt-3">
                      {/* <div>
                        <p className="font-bold">PPN 11%</p>
                        <Switch
                          checked={
                            dataBuffer[index]
                              ? dataBuffer[index].pharmacy_net_price_ppn
                              : 0
                          }
                          onClick={() => {
                            setDataBuffer(
                              dataBuffer.map((a) => {
                                return a.id === data.id
                                  ? {
                                      ...data,
                                      pharmacy_net_price_ppn:
                                        !data.pharmacy_net_price_ppn,
                                    }
                                  : a;
                              })
                            );
                          }}
                        />
                      </div> */}
                      <div className="grid grid-cols-2 items-center">
                        <p className="font-bold">PPN</p>
                        <Autocomplete
                          fullWidth
                          disablePortal
                          options={PPN_OPTION}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          value={
                            dataBuffer[index]
                              ? dataBuffer[index]
                                  .pharmacy_net_price_ppn_value !== 0
                                ? PPN_OPTION.find(
                                    (item) =>
                                      item.value ===
                                      dataBuffer[index]
                                        .pharmacy_net_price_ppn_value
                                  )
                                : null
                              : 0
                          }
                          onChange={(_, inputValue) => {
                            setDataBuffer(
                              dataBuffer.map((a) => {
                                return a.id === data.id
                                  ? {
                                      ...data,
                                      pharmacy_net_price_ppn:
                                        inputValue !== null,
                                      pharmacy_net_price_ppn_value: inputValue
                                        ? inputValue.value
                                        : 0,
                                    }
                                  : a;
                              })
                            );
                          }}
                          getOptionLabel={(option) =>
                            option ? option.name : "Pilih PPN"
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              placeholder="Pilih PPN"
                              sx={{ minWidth: "200px" }}
                            />
                          )}
                        />
                      </div>
                      <TextField
                        id="outlined-basic"
                        sx={{ width: "20%", marginLeft: 2 }}
                        label="Harga Akhir HNA"
                        autoComplete="off"
                        variant="outlined"
                        value={pharmacyNetPriceFinal(
                          dataBuffer[index].pharmacy_net_price_discount,
                          dataBuffer[index].pharmacy_net_price_real,
                          dataBuffer[index].pharmacy_net_price_ppn_value
                        )}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Rp.
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    {isWarehouse ? null : (
                      <>
                        {" "}
                        <TextField
                          id="outlined-basic"
                          fullWidth
                          label="Harga Jual Primer"
                          autoComplete="off"
                          variant="outlined"
                          value={
                            dataBuffer[index] ? dataBuffer[index].sell_price : 0
                          }
                          className="mt-3"
                          onChange={(e) => {
                            let numericValue = e.target.value;
                            if (numericValue === "") {
                              numericValue = "0";
                            }
                            setDataBuffer(
                              dataBuffer.map((a) => {
                                return a.id === data.id
                                  ? {
                                      ...data,
                                      sell_price: parseInt(
                                        numericValue.replace(/[^0-9]/g, ""),
                                        10
                                      ),
                                    }
                                  : a;
                              })
                            );
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                Rp.
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          id="outlined-basic"
                          fullWidth
                          label="Harga Jual Sekunder"
                          autoComplete="off"
                          variant="outlined"
                          value={
                            dataBuffer[index]
                              ? dataBuffer[index].sell_price_sekunder
                              : 0
                          }
                          className="mt-3"
                          onChange={(e) => {
                            let numericValue = e.target.value;
                            if (numericValue === "") {
                              numericValue = "0";
                            }
                            setDataBuffer(
                              dataBuffer.map((a) => {
                                return a.id === data.id
                                  ? {
                                      ...data,
                                      sell_price_sekunder: parseInt(
                                        numericValue.replace(/[^0-9]/g, ""),
                                        10
                                      ),
                                    }
                                  : a;
                              })
                            );
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                Rp.
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          id="outlined-basic"
                          fullWidth
                          label="Harga Jual Tersier"
                          autoComplete="off"
                          variant="outlined"
                          value={
                            dataBuffer[index]
                              ? dataBuffer[index].sell_price_tersier
                              : 0
                          }
                          className="mt-3"
                          onChange={(e) => {
                            let numericValue = e.target.value;
                            if (numericValue === "") {
                              numericValue = "0";
                            }
                            setDataBuffer(
                              dataBuffer.map((a) => {
                                return a.id === data.id
                                  ? {
                                      ...data,
                                      sell_price_tersier: parseInt(
                                        numericValue.replace(/[^0-9]/g, ""),
                                        10
                                      ),
                                    }
                                  : a;
                              })
                            );
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                Rp.
                              </InputAdornment>
                            ),
                          }}
                        />
                      </>
                    )}
                  </React.Fragment>
                </>

                {isWarehouse ? null : (
                  <div>
                    <p
                      className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4"
                      onClick={() => {
                        setDataBuffer(
                          dataBuffer.map((a) => {
                            return a.id === data.id
                              ? {
                                  ...data,
                                  price_grosir: [
                                    ...data.price_grosir,
                                    {
                                      minimum: 0,
                                      maximum: 0,
                                      price_grosirs: 0,
                                    },
                                  ],
                                }
                              : a;
                          })
                        );
                      }}
                    >
                      +Tambah harga grosir baru
                    </p>
                  </div>
                )}
                {isWarehouse ? null : (
                  <>
                    {" "}
                    {data.price_grosir.length > 0 ? (
                      <p className="font-bold mt-2 w-full basis-1/3">
                        Harga Grosir
                      </p>
                    ) : null}
                    {data.price_grosir.map((gros, idx) => {
                      return (
                        <div
                          className="flex justify-center items-center mt-[20px]"
                          key={idx}
                        >
                          <div className="w-full flex gap-2 items-center">
                            <div className="w-full flex gap-2 items-center">
                              <TextField
                                fullWidth
                                value={gros.minimum}
                                onChange={(e) => {
                                  let numericValue = e.target.value;
                                  if (numericValue === "") {
                                    numericValue = "0";
                                  }
                                  setDataBuffer(
                                    dataBuffer.map((a) => ({
                                      ...a,
                                      price_grosir: a.price_grosir.map(
                                        (p, i) => {
                                          return i === idx && data.id === a.id
                                            ? {
                                                ...p,
                                                minimum: parseInt(
                                                  numericValue.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                  ),
                                                  10
                                                ),
                                              }
                                            : p;
                                        }
                                      ),
                                    }))
                                  );
                                }}
                              />
                              <p>S/D</p>
                              <TextField
                                fullWidth
                                value={gros.maximum}
                                onChange={(e) => {
                                  let numericValue = e.target.value;
                                  if (numericValue === "") {
                                    numericValue = "0";
                                  }

                                  setDataBuffer(
                                    dataBuffer.map((a) => ({
                                      ...a,
                                      price_grosir: a.price_grosir.map(
                                        (p, i) => {
                                          return i === idx && data.id === a.id
                                            ? {
                                                ...p,
                                                maximum: parseInt(
                                                  numericValue.replace(
                                                    /[^0-9]/g,
                                                    ""
                                                  ),
                                                  10
                                                ),
                                              }
                                            : p;
                                        }
                                      ),
                                    }))
                                  );
                                }}
                              />
                            </div>
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              variant="outlined"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    Rp.
                                  </InputAdornment>
                                ),
                              }}
                              value={gros.price_grosirs}
                              onChange={(e) => {
                                let numericValue = e.target.value;
                                if (numericValue === "") {
                                  numericValue = "0";
                                }
                                setDataBuffer(
                                  dataBuffer.map((a) => ({
                                    ...a,
                                    price_grosir: a.price_grosir.map((p, i) => {
                                      return i === idx && data.id === a.id
                                        ? {
                                            ...p,
                                            price_grosirs: parseInt(
                                              numericValue.replace(
                                                /[^0-9]/g,
                                                ""
                                              ),
                                              10
                                            ),
                                          }
                                        : p;
                                    }),
                                  }))
                                );
                              }}
                            />
                            <button
                              type="button"
                              className="ml-[10px]"
                              onClick={() => {
                                setDataBuffer(
                                  dataBuffer.filter((a) => {
                                    return a.id === data.id
                                      ? {
                                          ...a,
                                          price_grosir: a.price_grosir.splice(
                                            idx,
                                            1
                                          ),
                                        }
                                      : data;
                                  })
                                );
                              }}
                            >
                              <DeleteIcon sx={{ color: "black" }} />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                    <div>
                      {platforms && platforms !== undefined ? (
                        <>
                          <p
                            className="text-blue-500 text-endtext-end  hover:underline hover:text-blue-700 cursor-pointer mt-4"
                            onClick={() => handleAddPlatform(data, index)}
                          >
                            +Tambah Platform lain
                          </p>
                        </>
                      ) : null}
                      {platforms && platforms !== null ? (
                        <>
                          {data.platform_price.map((plat, idx) => {
                            return (
                              <div
                                className="flex justify-center items-center mt-[20px]"
                                key={idx}
                              >
                                <FormControl fullWidth sx={{ marginRight: 1 }}>
                                  <InputLabel id="demo-simple-select-label">
                                    Kategori
                                  </InputLabel>
                                  <Select
                                    id="add-category"
                                    label="Kategori"
                                    value={plat.platform_id}
                                    onChange={(e) => {
                                      setDataBuffer(
                                        dataBuffer.map((a) => ({
                                          ...a,
                                          platform_price: a.platform_price.map(
                                            (p) => {
                                              return p.platform_id ===
                                                a.platform_price[idx]
                                                  ?.platform_id &&
                                                data.id === a.id
                                                ? {
                                                    ...p,
                                                    platform_id: e.target.value,
                                                  }
                                                : p;
                                            }
                                          ),
                                        }))
                                      );
                                    }}
                                  >
                                    {platforms.map((p) => {
                                      return (
                                        <MenuItem
                                          key={p.id}
                                          value={p.id}
                                          disabled={
                                            dataBuffer.map((u) =>
                                              u.platform_price.find(
                                                (f) => f.platform_id === p.id
                                              )
                                            )[index]
                                              ? true
                                              : false
                                          }
                                          sx={{
                                            display: dataBuffer.map((u) =>
                                              u.platform_price.find(
                                                (f) => f.platform_id === p.id
                                              )
                                            )[index]
                                              ? "none"
                                              : null,
                                          }}
                                        >
                                          {p.name}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                                <TextField
                                  id="outlined-basic"
                                  fullWidth
                                  label="Harga Jual"
                                  autoComplete="off"
                                  variant="outlined"
                                  value={
                                    dataBuffer[index]?.platform_price[idx]
                                      ?.price
                                  }
                                  onChange={(e) => {
                                    setDataBuffer(
                                      dataBuffer.map((a) => ({
                                        ...a,
                                        platform_price: a.platform_price.map(
                                          (p) => {
                                            return p.platform_id ===
                                              a.platform_price[idx]
                                                ?.platform_id &&
                                              data.id === a.id
                                              ? {
                                                  ...p,
                                                  price: Number(e.target.value),
                                                }
                                              : p;
                                          }
                                        ),
                                      }))
                                    );
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        Rp.
                                      </InputAdornment>
                                    ),
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          edge="end"
                                          onClick={() => {
                                            setDataBuffer(
                                              dataBuffer.filter((a) => {
                                                return a.id === data.id &&
                                                  plat.platform_id &&
                                                  data.platform_price[idx]
                                                    ?.platform_id
                                                  ? {
                                                      ...a,
                                                      platform_price:
                                                        a.platform_price.splice(
                                                          idx,
                                                          1
                                                        ),
                                                    }
                                                  : data;
                                              })
                                            );
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </div>
                            );
                          })}
                        </>
                      ) : null}
                    </div>
                  </>
                )}
              </div>
            </React.Fragment>
          ))}
          <div className="grid grid-cols-2 mt-10 gap-12">
            <button
              className="bg-red-700 text-white font-semibold p-3 rounded-lg"
              onClick={() =>
                navigate(
                  `${
                    isWarehouse ? "/central-warehouse" : ""
                  }/inventory/produk-aktif`
                )
              }
            >
              Batal
            </button>
            <button
              className="bg-orange-400 text-white font-semibold p-3 rounded-lg disabled:bg-gray-500"
              onClick={() => {
                if (validateDetailUpdateStock(dataBuffer)) {
                  mutateUpdateStocks({
                    warehouse: isWarehouse ? isWarehouse : false,
                    product_update: dataBuffer.map((data, index) => ({
                      id: data.id,
                      name_product: data.name_product,
                      pharmacy_net_price: pharmacyNetPriceFinal(
                        data.pharmacy_net_price_discount,
                        data.pharmacy_net_price_real,
                        data.pharmacy_net_price_ppn_value
                      ),
                      pharmacy_net_price_discount:
                        data.pharmacy_net_price_discount,
                      pharmacy_net_price_ppn: data.pharmacy_net_price_ppn,
                      pharmacy_net_price_ppn_value:
                        data.pharmacy_net_price_ppn_value,
                      pharmacy_net_price_real: data.pharmacy_net_price_real,
                      ppn: data.ppn,
                      platform_price: data.platform_price,
                      sell_price: data.sell_price,
                      sell_price_sekunder: data.sell_price_sekunder,
                      sell_price_tersier: data.sell_price_tersier,
                      price_grosir: data.price_grosir,
                      stock: data.stock,
                      batch: data.batch.map((list) => ({
                        id: list.id,
                        expired_date: list.expired_date,
                        number_batch: list.number_batch,
                        quantity: quantityValue(index, list.quantity),
                      })),
                    })),
                  });
                } else {
                  setErrorMessage("terdapat data input tidak valid");
                }
              }}
            >
              Simpan
            </button>
          </div>
        </>
      ) : (
        <p>produk tidak ditemukan!</p>
      )}
      <Snackbar
        open={Boolean(errorMessage)}
        onClose={() => setErrorMessage("")}
        message={errorMessage}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setErrorMessage("")}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />

      <PopupSingleCalendar
        open={openSingleCal}
        setOpen={setOpenSingleCal}
        handleClose={() => {
          setSelectedBatch(-1);
          setOpenSingleCal(false);
        }}
        value={
          selectedBatch >= 0
            ? dataBuffer[selectedBatch.index]?.batch[selectedBatch.idx]
                ?.expired_date !== ""
              ? dataBuffer[selectedBatch.index].batch[selectedBatch.idx]
                  .expired_date
              : null
            : null
        }
        handleChange={(e) => {
          setDataBuffer(
            dataBuffer.map((a) => ({
              ...a,
              batch: a.batch.map((detail) => {
                return dataBuffer[selectedBatch.index].id === a.id &&
                  detail.id === a.batch[selectedBatch.idx]?.id
                  ? {
                      ...detail,
                      expired_date: toDateApi(e),
                    }
                  : detail;
              }),
            }))
          );
        }}
        disablePast={true}
      />
    </>
  );
};

export default UpdateSeveralStock;
