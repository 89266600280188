import React from "react";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import HOC from "../../components/HOC";
import ImportPurchaseOrder from "../../components/screen/centralWarehouse/purchaseOrderCW/ImportPurchaseOrder";

const ImportPurchaseOrderPage = () => {
  return (
    <div className="">
      <PageTemplate
        header={"Import Purchase Order"}
        mainComponent={<ImportPurchaseOrder />}
        pageRole={9}
      />
    </div>
  );
};

export default HOC(ImportPurchaseOrderPage);
