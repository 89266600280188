import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getReturPdf } from "../../../services/billingAPI";
import moment from "moment";
import { CircularProgress } from "@mui/material";

const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const ReturDetailProduct = () => {
  const { id } = useParams();

  const { data, isFetching } = useQuery({
    queryKey: ["retur-pdf", id],
    queryFn: () => getReturPdf(id.replaceAll("-", "/")),
    enabled: id !== 0,
    refetchOnMount: false,
  });

  return (
    <div className="text-[12px]">
      <div className="w-2/3 flex gap-4 mb-4">
        <div className="w-full">
          <div className="flex items-center gap-4">
            <p className="w-1/3">Tanggal retur</p>
            <p>
              :{" "}
              {data && !isFetching ? (
                moment(data.created_at).format("DD MMMM YYYY")
              ) : isFetching ? (
                <CircularProgress size={10} />
              ) : (
                "-"
              )}
            </p>
          </div>
          <div className="flex items-center gap-4">
            <p className="w-1/3">No nota retur</p>
            <p>
              :{" "}
              {data && !isFetching ? (
                data.note_number
              ) : isFetching ? (
                <CircularProgress size={10} />
              ) : (
                "-"
              )}
            </p>
          </div>
          <div className="flex items-center gap-4">
            <p className="w-1/3">Distributor</p>
            <p>
              :{" "}
              {data && !isFetching ? (
                data.distributor_name
              ) : isFetching ? (
                <CircularProgress size={10} />
              ) : (
                "-"
              )}
            </p>
          </div>
        </div>
        <div className="w-full">
          <div className="flex items-center gap-4">
            <p className="w-1/3">Nominal retur</p>
            <p>
              :{" "}
              {data && !isFetching ? (
                toIDR(data.total_price)
              ) : isFetching ? (
                <CircularProgress size={10} />
              ) : (
                "-"
              )}
            </p>
          </div>
        </div>
      </div>

      <div className="flex w-full mb-8">
        <div className="w-full">
          <div className="block overflow-x-auto w-full rounded-md">
            <table className="w-full overflow-x-auto rounded-md border-collapse">
              <thead className="bg-blue-500 text-white">
                <th className="p-2 text-center">No</th>
                <th className="p-2 text-center whitespace-nowrap">
                  No. Invoice
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  No. Faktur Pajak
                </th>
                <th className="p-2 text-center whitespace-nowrap">Produk</th>
                <th className="p-2 text-center whitespace-nowrap">Batch</th>
                <th className="p-2 text-center whitespace-nowrap">
                  Expired Date
                </th>
                <th className="p-2 text-center whitespace-nowrap">Kemasan</th>
                <th className="p-2 text-center whitespace-nowrap">Kuantitas</th>
                <th className="p-2 text-center whitespace-nowrap">
                  HNA Satuan
                </th>
                <th className="p-2 text-center whitespace-nowrap">
                  Diskon (%)
                </th>
                <th className="p-2 text-center whitespace-nowrap">PPN (%)</th>
                <th className="p-2 text-center whitespace-nowrap">
                  Harga Akhir
                </th>
                <th className="p-2 text-center whitespace-nowrap">Subtotal</th>
              </thead>
              <tbody className="border">
                {data && !isFetching && data.product_list ? (
                  data.product_list.map((item, i) => (
                    <tr key={i} className={`${i === 0 ? "" : "border-t"}`}>
                      <td className="p-2 text-center">{i + 1}</td>
                      <td className="p-2 text-center">{item.invoice_number}</td>
                      <td className="p-2 text-center">{item.tax_number}</td>
                      <td className="p-2 text-center">{item.product_name}</td>
                      <td className="p-2 text-center">{item.batch_number}</td>
                      <td className="p-2 text-center">
                        {moment(item.expired_date).format("DD/MM/YYYY")}
                      </td>
                      <td className="p-2 text-center">{item.type_packaging}</td>
                      <td className="p-2 text-center">{item.quantity}</td>
                      <td className="p-2 text-center">
                        {toIDR(item.pharmacy_net_price)}
                      </td>
                      <td className="p-2 text-center">
                        {item.pharmacy_net_price_discount}
                      </td>
                      <td className="p-2 text-center">
                        {item.pharmacy_net_price_ppn_value}
                      </td>
                      <td className="p-2 text-center">
                        {toIDR(item.pharmacy_net_price_real)}
                      </td>
                      <td className="p-2 text-center">{toIDR(item.price)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="p-2 text-center" colSpan={13}>
                      {isFetching ? (
                        <CircularProgress size={20} />
                      ) : (
                        "Tidak ada produk"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturDetailProduct;
