import { Breadcrumbs, Link } from "@mui/material";
import { useLocation } from "react-router-dom";

const BreadcrumbInvoice = () => {
  const { pathname } = useLocation();

  return (
    <Breadcrumbs sx={{ color: "#1761FD", marginBottom: 2, fontSize: 12 }}>
      <Link
        href="/home"
        underline="hover"
        color="#1761FD"
        sx={{ color: "#1761FD" }}
      >
        Homepage
      </Link>
      <Link
        color="#1761FD"
        underline="none"
        href="/invoice-invoice"
        sx={{ color: "#1761FD" }}
      >
        Invoice List
      </Link>
      {pathname.includes("/invoice/invoice/create-invoice") ? (
        <Link
          underline="none"
          color="#1761FD"
          href="/invoice/invoice/create-invoice"
          sx={{ color: "#1761FD" }}
        >
          Tambah Faktur
        </Link>
      ) : null}
      {pathname.includes("/invoice/invoice/detail-invoice") ? (
        <Link underline="none" color="#1761FD" sx={{ color: "#1761FD" }}>
          Detail Faktur
        </Link>
      ) : null}
    </Breadcrumbs>
  );
};

export default BreadcrumbInvoice;
