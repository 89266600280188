import React from "react";
import HOC from "../../components/HOC";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import BreadcrumbBillingManual from "../../components/screen/billingManual/BreadcrumbBillingManual";
import { ThemeProvider } from "@mui/material";
import { theme } from "../../components/constants/muiTheme";
import DetailBillingManual from "../../components/screen/billingManual/DetailBillingManual";

const BillingManualDetailPage = () => {
  return (
    <div className="">
      <PageTemplate
        header={"Detail Billing"}
        mainComponent={
          <ThemeProvider theme={theme}>
            <DetailBillingManual />
          </ThemeProvider>
        }
        breadcrumb={<BreadcrumbBillingManual />}
        pageRole={7}
      />
    </div>
  );
};

export default HOC(BillingManualDetailPage);
