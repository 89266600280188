import React from "react";
import { ThemeProvider } from "@mui/material";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import { theme } from "../../components/constants/muiTheme";
import BreadcrumbRetur from "../../components/screen/Retur/BreadcrumbRetur";
import HOC from "../../components/HOC";
import ReturDetailSell from "../../components/screen/Retur/ReturDetailSell";

const ReturDetailSellPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Detail Retur Penjualan"
        mainComponent={
          <ThemeProvider theme={theme}>
            <ReturDetailSell />
          </ThemeProvider>
        }
        breadcrumb={<BreadcrumbRetur />}
        pageRole={47}
      />
    </div>
  );
};

export default HOC(ReturDetailSellPage);
