import {
  Autocomplete,
  createFilterOptions,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import moment from "moment";
import { createRef, useEffect, useState } from "react";
import { IoMdAddCircleOutline } from "react-icons/io";
import ModalAddDistributor from "./modal/ModalAddDistributor";
import ModalListDistributor from "./modal/ModalListDistributor";
import {
  createNewPoV2,
  getDistributorListV2,
  getPoProducts,
} from "../../../services/purchaseOrderApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import useDebounce from "../../hooks/useDebounce";
import { MdOutlineCancel } from "react-icons/md";
import LoadingBackdrop from "../../common/particles.jsx/LoadingBackdrop";
import { useNavigate } from "react-router-dom";
import ModalAddProduct from "./modal/ModalAddProduct";
import { PPN_OPTION_INT } from "../../constants/constant";

const FETCH_LIMIT = 10;
const filter = createFilterOptions();

const AddPurchaseOrder = () => {
  const navigate = useNavigate();

  const [openAddDistributor, setOpenAddDistributor] = useState(false);
  const [openDistributorList, setOpenDistributorList] = useState(false);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [search, setSearch] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [productArr, setProductArr] = useState([]);
  const [productRef, setProductRef] = useState([]);
  const [isRemove, setIsRemove] = useState(false);

  const debounce = useDebounce(search, 500);
  const debounceProduct = useDebounce(searchProduct, 500);

  const getPercentValue = (val, percent) => {
    return val * (percent / 100);
  };

  const getGrandTotal = () => {
    return formik.values.product.reduce((acc, num) => acc + num.sub_total, 0);
  };

  const formik = useFormik({
    initialValues: {
      input_date: moment().format("YYYY-MM-DD"),
      distributor: null,
      product: [],
      payment_method: 0,
      due_date: null,
      total_price: 0,
    },
    onSubmit: (values) => {
      mutatePo(values);
    },
  });

  const { data: dataList, isFetching } = useQuery({
    queryKey: ["distributor-list", debounce],
    queryFn: () => getDistributorListV2(FETCH_LIMIT, 1, debounce),
    refetchOnWindowFocus: false,
  });

  const { data: dataListProduct, isFetching: isFetchingProduct } = useQuery({
    queryKey: ["po-product", debounceProduct],
    queryFn: () => getPoProducts(FETCH_LIMIT, 1, debounceProduct, false),
  });

  const { mutate: mutatePo, isLoading } = useMutation({
    mutationFn: createNewPoV2,
    onSuccess: () => {
      navigate(`/purchase-order`);
    },
    onError: (err) => {},
  });

  useEffect(() => {
    formik.setFieldValue("total_price", getGrandTotal());
    setProductRef(
      Array(formik.values.product.length)
        .fill()
        .map((_, i) => productRef[i] || createRef())
    );
  }, [formik.values.product]);

  useEffect(() => {
    if (productRef.length > 0 && !isRemove)
      productRef[productRef.length - 1].current?.focus();
    setIsRemove(false);
  }, [productRef.length]);

  return (
    <div>
      <LoadingBackdrop isLoading={isLoading} />
      <form>
        <div className="text-[12px] p-3">
          <div className="pb-[70px]">
            <div className="flex gap-2 mb-4">
              <div className="w-full flex flex-col items-end gap-2">
                <div className="w-full flex gap-2 items-center">
                  <Autocomplete
                    fullWidth
                    loading={isFetching}
                    disablePortal
                    options={
                      dataList && dataList.data !== null ? dataList.data : []
                    }
                    value={formik.values.distributor}
                    onChange={(_, inputValue) => {
                      formik.setFieldValue("distributor", inputValue);
                    }}
                    onInputChange={(_, onInputValue, reason) => {
                      if (reason === "input") {
                        setSearch(onInputValue);
                      }
                    }}
                    getOptionLabel={(option) => option.name || ""}
                    renderInput={(params) => (
                      <TextField label="Pilih Distributor" {...params} />
                    )}
                  />
                  <div
                    className="bg-blue-500 w-[40px] h-[40px] text-white flex items-center justify-center rounded-md p-2 cursor-pointer hover:bg-gray-300"
                    onClick={() => setOpenAddDistributor(true)}
                  >
                    <IoMdAddCircleOutline className="w-full h-full" />
                  </div>
                </div>
                <p
                  className="text-blue-500 text-[10px] cursor-pointer hover:underline"
                  onClick={() => setOpenDistributorList(true)}
                >
                  List Distributor
                </p>
              </div>
              <div className="w-full">
                <DesktopDatePicker
                  views={["year", "month", "day"]}
                  label="Tanggal PO"
                  onChange={(newDate) =>
                    formik.setFieldValue(
                      "input_date",
                      newDate ? moment(newDate).format("YYYY-MM-DD") : ""
                    )
                  }
                  value={
                    formik.values.input_date === ""
                      ? null
                      : formik.values.input_date
                  }
                  sx={{ width: "100%" }}
                  renderInput={(params) => {
                    return <TextField fullWidth {...params} />;
                  }}
                />
              </div>

              <div className="w-full">
                <Select
                  fullWidth
                  value={formik.values.payment_method}
                  onChange={(e) => {
                    const newVal = e.target.value;
                    formik.setFieldValue("payment_method", newVal);
                    formik.setFieldValue(
                      "due_date",
                      newVal === 1 ? "" : moment().format("YYYY-MM-DD")
                    );
                  }}
                >
                  <MenuItem value={0} disabled>
                    Pilih metode pembayaran
                  </MenuItem>
                  <MenuItem value={1}>Tunai</MenuItem>
                  <MenuItem value={2}>Kredit</MenuItem>
                </Select>
              </div>
              <div className="w-full">
                <DesktopDatePicker
                  views={["year", "month", "day"]}
                  label="Jatuh Tempo Pembayaran"
                  onChange={(newDate) =>
                    formik.setFieldValue(
                      "due_date",
                      newDate ? moment(newDate).format("YYYY-MM-DD") : ""
                    )
                  }
                  value={
                    formik.values.due_date === ""
                      ? null
                      : formik.values.due_date
                  }
                  disabled={formik.values.payment_method !== 2}
                  sx={{ width: "100%" }}
                  renderInput={(params) => {
                    return <TextField fullWidth {...params} />;
                  }}
                />
              </div>
            </div>

            <div className="flex w-full mb-8">
              <div className="w-full">
                <div className="block overflow-x-auto w-full rounded-md">
                  <table className="w-full overflow-x-auto rounded-md border-collapse border">
                    <thead className="bg-blue-500 text-white">
                      <th className="p-2 text-center">No</th>
                      <th className="p-2 text-center whitespace-nowrap">
                        Produk
                      </th>
                      <th className="p-2 text-center whitespace-nowrap">
                        Satuan
                      </th>
                      <th className="p-2 text-center whitespace-nowrap">
                        Kuantitas
                      </th>
                      <th className="p-2 text-center whitespace-nowrap">
                        HNA Satuan
                      </th>
                      <th className="p-2 text-center whitespace-nowrap">
                        Diskon (%)
                      </th>
                      <th className="p-2 text-center whitespace-nowrap">
                        PPN (%)
                      </th>
                      <th className="p-2 text-center whitespace-nowrap">
                        Harga Akhir
                      </th>
                      <th className="p-2 text-center whitespace-nowrap">
                        Subtotal
                      </th>
                    </thead>
                    <tbody>
                      {formik.values.product.map((item, i) => (
                        <tr>
                          <td className="p-2 text-center">{i + 1}</td>
                          <td className="p-2 text-left w-[200px]">
                            <div className="flex gap-2 items-center">
                              <Autocomplete
                                fullWidth
                                disableClearable
                                loading={isFetchingProduct}
                                openOnFocus={true}
                                disablePortal
                                onOpen={() => {
                                  setSearchProduct("");
                                }}
                                options={
                                  dataListProduct &&
                                  dataListProduct.data !== null
                                    ? dataListProduct.data
                                    : []
                                }
                                filterOptions={(options, params) => {
                                  const filtered = [
                                    ...filter(options, params),
                                    {
                                      name: "Tambah Produk Baru",
                                      product_detail_id: -1,
                                    },
                                  ];

                                  return filtered;
                                }}
                                value={productArr[i]}
                                onChange={(_, inputValue) => {
                                  var tempArr = [...productArr];

                                  if (
                                    inputValue &&
                                    inputValue.product_detail_id < 0
                                  ) {
                                    setOpenAddProduct(true);
                                  }

                                  tempArr[i] =
                                    inputValue &&
                                    inputValue.product_detail_id >= 0
                                      ? {
                                          name: inputValue.name,
                                          id: inputValue.product_detail_id,
                                          packaging: inputValue.packaging_es,
                                        }
                                      : null;
                                  setProductArr(tempArr);

                                  formik.setFieldValue(`product[${i}]`, {
                                    id:
                                      inputValue &&
                                      inputValue.product_detail_id >= 0
                                        ? inputValue.product_detail_id
                                        : null,
                                    stock: 0,
                                    type_stock: 0,
                                    hna:
                                      inputValue &&
                                      inputValue.product_detail_id >= 0
                                        ? inputValue.data_hna.pharmacy_net_price
                                        : 0,
                                    discount:
                                      inputValue &&
                                      inputValue.product_detail_id >= 0
                                        ? inputValue.data_hna
                                            .pharmacy_net_price_discount
                                        : 0,
                                    ppn:
                                      inputValue &&
                                      inputValue.product_detail_id >= 0
                                        ? inputValue.data_hna
                                            .pharmacy_net_price_ppn_value > 0 &&
                                          inputValue.data_hna
                                            .pharmacy_net_price_ppn_value < 1
                                          ? inputValue.data_hna
                                              .pharmacy_net_price_ppn_value *
                                            100
                                          : inputValue.data_hna
                                              .pharmacy_net_price_ppn_value
                                        : 0,
                                    net_price:
                                      inputValue &&
                                      inputValue.product_detail_id >= 0
                                        ? inputValue.data_hna
                                            .pharmacy_net_price_real
                                        : 0,
                                    sub_total: 0,
                                  });
                                  if (
                                    inputValue &&
                                    inputValue.product_detail_id >= 0
                                  )
                                    window.scrollTo(
                                      0,
                                      document.body.scrollHeight
                                    ); // scroll to bottom on selection
                                }}
                                onInputChange={(_, onInputValue, reason) => {
                                  if (reason === "input") {
                                    setSearchProduct(onInputValue);
                                  }
                                }}
                                getOptionLabel={(option) => option.name || ""}
                                renderInput={(params) => (
                                  <TextField
                                    sx={{ minWidth: "200px" }}
                                    label="Pilih Produk"
                                    {...params}
                                    inputRef={productRef[i]}
                                  />
                                )}
                              />
                              <MdOutlineCancel
                                className="text-red-500 hover:text-gray-300 cursor-pointer text-[18px]"
                                onClick={() => {
                                  setProductArr([
                                    ...productArr.slice(0, i),
                                    ...productArr.slice(i + 1),
                                  ]);
                                  formik.setFieldValue("product", [
                                    ...formik.values.product.slice(0, i),
                                    ...formik.values.product.slice(i + 1),
                                  ]);
                                  setIsRemove(true);
                                }}
                              />
                            </div>
                          </td>
                          <td className="p-2 text-center">
                            <Select
                              fullWidth
                              value={item.type_stock}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `product[${i}].type_stock`,
                                  Number(e.target.value)
                                )
                              }
                              disabled={!item.id}
                            >
                              {productArr[i] ? null : (
                                <MenuItem value={0} disabled>
                                  Pilih Satuan
                                </MenuItem>
                              )}
                              {productArr[i] ? (
                                <MenuItem value={0}>
                                  {productArr[i].packaging.type_box}
                                </MenuItem>
                              ) : null}
                              {productArr[i] ? (
                                <MenuItem value={1}>
                                  {productArr[i].packaging.type_strip}
                                </MenuItem>
                              ) : null}
                              {productArr[i] ? (
                                <MenuItem value={2}>
                                  {productArr[i].packaging.type_unit}
                                </MenuItem>
                              ) : null}
                            </Select>
                          </td>
                          <td className="p-2 text-center">
                            <TextField
                              fullWidth
                              value={item.stock}
                              onChange={(e) => {
                                if (!isNaN(Number(e.target.value))) {
                                  formik.setFieldValue(
                                    `product[${i}].stock`,
                                    Number(e.target.value)
                                  );
                                  formik.setFieldValue(
                                    `product[${i}].sub_total`,
                                    Number(e.target.value) * item.net_price
                                  );
                                }
                              }}
                            />
                          </td>
                          <td className="p-2 text-center">
                            <TextField
                              fullWidth
                              value={item.hna}
                              onChange={(e) => {
                                if (!isNaN(Number(e.target.value))) {
                                  var netPrice =
                                    Number(e.target.value) -
                                    getPercentValue(
                                      Number(e.target.value),
                                      item.discount
                                    ) +
                                    getPercentValue(
                                      Number(e.target.value) -
                                        getPercentValue(
                                          Number(e.target.value),
                                          item.discount
                                        ),
                                      item.ppn
                                    );
                                  formik.setValues((prevVal) => ({
                                    ...prevVal,
                                    product: [
                                      ...formik.values.product.slice(0, i),
                                      {
                                        ...formik.values.product[i],
                                        hna: Number(e.target.value),
                                        net_price: netPrice,
                                        sub_total: item.stock * netPrice,
                                      },
                                      ...formik.values.product.slice(i + 1),
                                    ],
                                  }));
                                }
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment>Rp</InputAdornment>
                                ),
                              }}
                            />
                          </td>
                          <td className="p-2 text-center">
                            <TextField
                              fullWidth
                              type="number"
                              onFocus={(e) =>
                                e.target.addEventListener(
                                  "wheel",
                                  function (e) {
                                    e.preventDefault();
                                  },
                                  { passive: false }
                                )
                              }
                              value={
                                item.discount ? item.discount.toString() : 0
                              }
                              onChange={(e) => {
                                if (
                                  !isNaN(Number(e.target.value)) &&
                                  Number(e.target.value) <= 100
                                ) {
                                  var netPrice =
                                    item.hna -
                                    getPercentValue(
                                      item.hna,
                                      Number(e.target.value)
                                    ) +
                                    getPercentValue(
                                      item.hna -
                                        getPercentValue(
                                          item.hna,
                                          Number(e.target.value)
                                        ),
                                      item.ppn
                                    );

                                  formik.setValues((prevVal) => ({
                                    ...prevVal,
                                    product: [
                                      ...formik.values.product.slice(0, i),
                                      {
                                        ...formik.values.product[i],
                                        discount: Number(e.target.value),
                                        net_price: netPrice,
                                        sub_total: item.stock * netPrice,
                                      },
                                      ...formik.values.product.slice(i + 1),
                                    ],
                                  }));
                                }
                              }}
                            />
                          </td>
                          <td className="p-2 text-center">
                            <Autocomplete
                              disablePortal
                              fullWidth
                              options={PPN_OPTION_INT}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              value={
                                item.ppn !== 0
                                  ? PPN_OPTION_INT.find(
                                      (ppn) => ppn.value === item.ppn
                                    )
                                  : null
                              }
                              sx={{ backgroundColor: "white" }}
                              onChange={(_, inputValue) => {
                                var netPrice =
                                  item.hna -
                                  getPercentValue(item.hna, item.discount) +
                                  getPercentValue(
                                    item.hna -
                                      getPercentValue(item.hna, item.discount),
                                    inputValue ? inputValue.value : 0
                                  );

                                formik.setValues((prevVal) => ({
                                  ...prevVal,
                                  product: [
                                    ...formik.values.product.slice(0, i),
                                    {
                                      ...formik.values.product[i],
                                      ppn: inputValue ? inputValue.value : 0,
                                      net_price: netPrice,
                                      sub_total: item.stock * netPrice,
                                    },
                                    ...formik.values.product.slice(i + 1),
                                  ],
                                }));
                              }}
                              getOptionLabel={(option) =>
                                option ? option.name : "Pilih PPN"
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  placeholder="Pilih PPN"
                                />
                              )}
                            />
                          </td>
                          <td className="p-2 text-center">
                            <TextField
                              fullWidth
                              disabled
                              value={item.net_price}
                              onChange={(e) => {
                                if (!isNaN(Number(e.target.value)))
                                  formik.setFieldValue(
                                    `product[${i}].net_price`,
                                    Number(e.target.value)
                                  );
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment>Rp</InputAdornment>
                                ),
                              }}
                            />
                          </td>
                          <td className="p-2 text-center">
                            <TextField
                              fullWidth
                              disabled
                              value={item.sub_total}
                              onChange={(e) => {
                                if (!isNaN(Number(e.target.value)))
                                  formik.setFieldValue(
                                    `product[${i}].sub_total`,
                                    Number(e.target.value)
                                  );
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment>Rp</InputAdornment>
                                ),
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                      {formik.values.product.every((item) => item.id > 0) ||
                      formik.values.product.length === 0 ? (
                        <tr>
                          <td className="p-2 text-center">+</td>
                          <td className="p-2 text-left" colSpan={8}>
                            <div
                              className="py-1 px-2 rounded-md border-2 border-black w-fit min-w-[150px] cursor-pointer hover:bg-gray-100"
                              onClick={() => {
                                formik.setFieldValue("product", [
                                  ...formik.values.product,
                                  {
                                    id: null,
                                    stock: 0,
                                    type_stock: 0,
                                    hna: 0,
                                    discount: 0,
                                    ppn: 0,
                                    net_price: 0,
                                    sub_total: 0,
                                  },
                                ]);
                                setProductArr([...productArr, null]);
                                window.scrollTo(0, document.body.scrollHeight); // scroll to bottom on click
                              }}
                            >
                              Pilih Produk
                            </div>
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`bg-blue-500 fixed bottom-0 left-0 lg:left-[260px] min-h-[60px] px-[20px] py-[10px] w-full lg:w-[calc(100%-260px)] z-50`}
        >
          <div className="flex items-center justify-between items-center w-full">
            <div className="text-white">
              <p className="text-[14px]">Total</p>
              <p className="text-[18px] font-bold">
                {getGrandTotal().toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                })}
              </p>
            </div>
            <button
              type="button"
              onClick={formik.handleSubmit}
              disabled={
                formik.values.product.length === 0 ||
                formik.values.distributor === null ||
                (formik.values.payment_method !== 1 &&
                  formik.values.due_date === "") ||
                formik.values.payment_method === 0
              }
              className="rounded-xl text-[14px] disabled:bg-[#687B8E] text-white py-[10px] px-[42px] bg-[#FF8D40] hover:bg-orange-500"
            >
              Simpan
            </button>
          </div>
        </div>
      </form>

      <ModalAddDistributor
        open={openAddDistributor}
        setOpen={setOpenAddDistributor}
      />

      <ModalListDistributor
        open={openDistributorList}
        setOpen={setOpenDistributorList}
      />

      <ModalAddProduct
        openModal={openAddProduct}
        setOpenModal={setOpenAddProduct}
      />
    </div>
  );
};

export default AddPurchaseOrder;
