import { Search } from "@mui/icons-material";
import {
  CircularProgress,
  FormControl,
  FormLabel,
  InputAdornment,
  Popover,
  TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { FaChevronDown, FaChevronLeft, FaUser } from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";
import { LuImagePlus } from "react-icons/lu";
import useDebounce from "../../../hooks/useDebounce";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  deleteImageDicom,
  getDetailInitialAnalysis,
  getPatientCareDoctor,
  getPatientList,
  setInitialAnalysis,
  uploadImageDicom,
} from "../../../../services/patientCare";
import ModalAddPatient from "../patientCarePatient/ModalAddPatient";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import ModalAddEditDoctor from "../patientCareDoctor/ModalAddEditDoctor";
import PatientAnalysisHistory from "./PatientAnalysisHistory";
import ModalSavePatientCare from "../ModalSavePatientCare";
import ModalConfirmationAction from "../../../common/particles.jsx/ModalConfirmationAction";
import LoadingBackdrop from "../../../common/particles.jsx/LoadingBackdrop";
import { MdCancel, MdOutlineHideImage } from "react-icons/md";
import { DecryptImage } from "../../../../utils/DecryptImage";
import cornerstone, { loadImage } from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
import dicomParser from "dicom-parser";
// import "cornerstone-tools/dist/cornerstoneTools.css";

// Set up the CornerstoneWADOImageLoader (using WADO-URI)
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";

// Initialize CornerstoneWADOImageLoader
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

const validationSchema = yup.object({
  patient_id: yup.number().min(1),
  doctor_id: yup.number().min(1),
  check_date: yup.string(),
  subjective: yup.object({
    birth_history: yup.string(),
    existing_illness: yup.string(),
    development_history: yup.string(),
    current_medication: yup.string(),
    main_complaint: yup.string(),
    social_history: yup.string(),
    family_history: yup.string(),
  }),
  objective: yup.object({
    systole: yup.string(),
    diastole: yup.string(),
    respiratory_rate: yup.string(),
    body_temp: yup.string(),
    pulse: yup.string(),
    oxygen: yup.string(),
    pain_level: yup.string(),
    pain_location: yup.string(),
    head_circumference: yup.string(),
    height: yup.string(),
    weight: yup.string(),
    physical_exam: yup.string(),
    support_exam: yup.string(),
  }),
  img: yup.array(),
  exam_img: yup.array(),
});

const toDate = moment().format("YYYY-MM-DD");
const toDateApi = (date) => moment(date).format("YYYY-MM-DD");

const AddInitialAnalysis = ({ isDetail }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const cornerstoneRefs = useRef([]);
  const cornerstoneRefsExam = useRef([]);

  const [anchorElPatient, setAnchorElPatient] = useState(null);
  const [anchorElDoctor, setAnchorElDoctor] = useState(null);
  const [searchDoctor, setSearchDoctor] = useState("");
  const [searchPatient, setSearchPatient] = useState("");
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [openAddPatient, setOpenAddPatient] = useState(false);
  const [openAddDoctor, setOpenAddDoctor] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isSave, setIsSave] = useState(false);
  const [selectedImg, setSelectedImg] = useState(null);
  const [arrDicom, setArrDicom] = useState([]);
  const [arrDicomExam, setArrDicomExam] = useState([]);

  const debounceDoctor = useDebounce(searchDoctor, 500);
  const debouncePatient = useDebounce(searchPatient, 500);

  const decryptText = async (encryptText) => {
    try {
      const decrypted = await DecryptImage(
        encryptText,
        process.env.REACT_APP_SECRET_KEY_IMG
      );
      return decrypted;
    } catch (error) {
      console.error("Decryption failed:", error);
      return null;
    }
  };

  const handleClosePatient = () => {
    setAnchorElPatient(null);
    setSearchPatient("");
  };

  const handleCloseDoctor = () => {
    setAnchorElDoctor(null);
    setSearchDoctor("");
  };

  const fetchPatient = (searchStr) => {
    mutateListPatient({
      search: searchStr,
      offset: 1,
      limit: 10,
      start_date: "",
      end_date: "",
      sort: 1,
      gender: 0,
      status: 0,
    });
  };

  const fetchDoctor = (searchStr) => {
    mutateListDoctor({
      search: searchStr,
      limit: 10,
      offset: 1,
      specialization_id: 0,
    });
  };

  const {
    mutate: mutateListDoctor,
    data: dataListDoctor,
    isLoading: isLoadingDoctor,
  } = useMutation({
    mutationFn: getPatientCareDoctor,
  });

  const {
    mutate: mutateListPatient,
    data: dataListPatient,
    isLoadingPatient: isLoadingPatient,
  } = useMutation({
    mutationFn: getPatientList,
  });

  const { mutate: mutateAnalysis, isLoading: isLoading } = useMutation({
    mutationFn: setInitialAnalysis,
    onSuccess: () => {
      setOpenSave(false);
      setOpenConfirm(true);
    },
    onError: () => {
      setIsSave(false);
    },
  });

  const { mutate: mutateUploadImg, isLoading: isLoadingImg } = useMutation({
    mutationFn: uploadImageDicom,
    onSuccess: (values) => {
      formik.setFieldValue("img", [...formik.values.img, ...values.url]);
    },
  });

  const { mutate: mutateDeleteImg, isLoading: isLoadingDeleteImg } =
    useMutation({
      mutationFn: deleteImageDicom,
      onSuccess: () => {
        formik.setFieldValue("img", [
          ...formik.values.img.slice(0, selectedImg),
          ...formik.values.img.slice(selectedImg + 1),
        ]);
        setSelectedImg(null);
      },
      onError: () => {
        setSelectedImg(null);
      },
    });

  const { mutate: mutateUploadImgExam, isLoading: isLoadingImgExam } =
    useMutation({
      mutationFn: uploadImageDicom,
      onSuccess: (values) => {
        formik.setFieldValue("exam_img", [
          ...formik.values.exam_img,
          ...values.url,
        ]);
      },
    });

  const { mutate: mutateDeleteImgExam, isLoading: isLoadingDeleteImgExam } =
    useMutation({
      mutationFn: deleteImageDicom,
      onSuccess: () => {
        formik.setFieldValue("img", [
          ...formik.values.exam_img.slice(0, selectedImg),
          ...formik.values.exam_img.slice(selectedImg + 1),
        ]);
        setSelectedImg(null);
      },
      onError: () => {
        setSelectedImg(null);
      },
    });

  const { data: dataDetail, isFetching } = useQuery({
    queryKey: ["detail-analysis", id],
    queryFn: () => getDetailInitialAnalysis(id),
    enabled: id !== null && id !== undefined,
    refetchOnWindowFocus: false,
  });

  const formik = useFormik({
    initialValues: {
      patient_id: dataDetail ? dataDetail.patient_id : 0,
      doctor_id: dataDetail ? dataDetail.doctor_id : 0,
      check_date: dataDetail ? toDateApi(dataDetail.check_date) : toDate,
      subjective: {
        birth_history: dataDetail ? dataDetail.subjective.birth_history : "",
        existing_illness: dataDetail
          ? dataDetail.subjective.existing_illness
          : "",
        development_history: dataDetail
          ? dataDetail.subjective.development_history
          : "",
        current_medication: dataDetail
          ? dataDetail.subjective.current_medication
          : "",
        main_complaint: dataDetail ? dataDetail.subjective.main_complaint : "",
        social_history: dataDetail ? dataDetail.subjective.social_history : "",
        family_history: dataDetail ? dataDetail.subjective.family_history : "",
      },
      objective: {
        systole: dataDetail ? dataDetail.objective.systole : "",
        diastole: dataDetail ? dataDetail.objective.diastole : "",
        respiratory_rate: dataDetail
          ? dataDetail.objective.respiratory_rate
          : "",
        body_temp: dataDetail ? dataDetail.objective.body_temp : "",
        pulse: dataDetail ? dataDetail.objective.pulse : "",
        oxygen: dataDetail ? dataDetail.objective.oxygen : "",
        pain_level: dataDetail ? dataDetail.objective.pain_level : "",
        pain_location: dataDetail ? dataDetail.objective.pain_location : "",
        head_circumference: dataDetail
          ? dataDetail.objective.head_circumference
          : "",
        height: dataDetail ? dataDetail.objective.height : "",
        weight: dataDetail ? dataDetail.objective.weight : "",
        physical_exam: dataDetail ? dataDetail.objective.physical_exam : "",
        support_exam: dataDetail ? dataDetail.objective.support_exam : "",
      },
      img: [],
      exam_img: [],
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (values) => {
      setOpenSave(true);
    },
  });

  useEffect(() => {
    if (dataDetail && !isFetching) {
      fetchDoctor(dataDetail.doctor_name);
      fetchPatient(dataDetail.patient_name);
      formik.setFieldValue("img", dataDetail.img);
      formik.setFieldValue("exam_img", dataDetail.exam_img);
    }
  }, [dataDetail, isFetching, id]);

  useEffect(() => {
    // Decrypting images
    const loadImages = async (imgArr, setDicom, refElem) => {
      imgArr.map(async (encryptedUrl, index) => {
        const decryptedUrl = await decryptText(encryptedUrl);

        if (decryptedUrl) {
          const element = refElem.current[index];
          cornerstone.enable(element);
          const imageId = `wadouri:${decryptedUrl}`;

          cornerstone
            .loadImage(imageId)
            .then((image) => {
              setDicom((prevArrDicom) => [...prevArrDicom, image]);
              cornerstone.displayImage(element, image);
            })
            .catch((err) => {
              console.error("Error loading DICOM image:", err);
              setDicom((prevArrDicom) => [...prevArrDicom, null]);
            });
        }
      });
    };

    loadImages(formik.values.img, setArrDicom, cornerstoneRefs);
    loadImages(formik.values.exam_img, setArrDicomExam, cornerstoneRefsExam);
  }, [
    formik.values.img,
    formik.values.exam_img,
    cornerstoneRefs,
    cornerstoneRefsExam,
  ]);

  useEffect(() => {
    if (
      dataListPatient &&
      dataListDoctor &&
      !isLoadingPatient &&
      !isLoadingDoctor &&
      id !== undefined
    ) {
      if (
        dataListPatient.data.length > 0 &&
        dataListDoctor.doctor.length === 1
      ) {
        setSelectedPatient(dataListPatient.data[0]);
        setSelectedDoctor(dataListDoctor.doctor[0]);
      }
    }
  }, [dataListPatient, dataListDoctor, id]);

  useEffect(() => {
    fetchDoctor(debounceDoctor);
  }, [debounceDoctor]);

  useEffect(() => {
    fetchPatient(debouncePatient);
  }, [debouncePatient]);

  useEffect(() => {
    if (isSave) {
      id
        ? mutateAnalysis({ id: Number(id), ...formik.values })
        : mutateAnalysis(formik.values);
    }
  }, [isSave]);

  return (
    <>
      <LoadingBackdrop
        isLoading={isLoading || isLoadingDeleteImg || isLoadingDeleteImgExam}
      />
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-3 w-full items-center gap-2 mb-4">
          <div className="w-full flex items-center gap-2">
            <div
              className="border-2 rounded-md p-[8px] flex justify-between items-center w-full hover:bg-gray-100 cursor-pointer"
              onClick={(e) => {
                if (!isDetail) {
                  setAnchorElPatient(e.currentTarget);
                  fetchPatient("");
                }
              }}
            >
              {selectedPatient ? (
                <p>{selectedPatient.patient_name}</p>
              ) : (
                <p>
                  Patient <span className="text-red-500"> *</span>
                </p>
              )}
              <FaChevronDown />
            </div>
            <div className="rounded-md text-white bg-blue-500 hover:bg-gray-300 cursor-pointer w-[40px] h-[40px] p-1">
              <IoMdAddCircleOutline
                className="w-full h-full"
                onClick={() => {
                  handleClosePatient();
                  setOpenAddPatient(true);
                }}
              />
            </div>
          </div>
          <div className="w-full flex items-center gap-2">
            <div
              className="border-2 rounded-md p-[8px] flex justify-between items-center w-full hover:bg-gray-100 cursor-pointer"
              onClick={(e) => {
                if (!isDetail) {
                  setAnchorElDoctor(e.currentTarget);
                  fetchDoctor("");
                }
              }}
            >
              {selectedDoctor ? (
                <p>{selectedDoctor.name}</p>
              ) : (
                <p>
                  Doctor <span className="text-red-500"> *</span>
                </p>
              )}
              <FaChevronDown />
            </div>
            <div className="rounded-md text-white bg-blue-500 hover:bg-gray-300 cursor-pointer w-[40px] h-[40px] p-1">
              <IoMdAddCircleOutline
                className="w-full h-full"
                onClick={() => setOpenAddDoctor(true)}
              />
            </div>
          </div>
          <DesktopDatePicker
            views={["year", "month", "day"]}
            disabled={isDetail}
            label="Check Date"
            sx={{ width: "100%" }}
            value={formik.values.check_date}
            onChange={(newValue) =>
              formik.setFieldValue("check_date", toDateApi(newValue))
            }
            renderInput={(params) => {
              return <TextField {...params} />;
            }}
          />
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
          <div className="w-full col-span-2">
            <div className="flex gap-2 mb-4">
              <div className="w-full">
                <p className="font-bold mb-2">Patient Information</p>
                {selectedPatient ? (
                  <>
                    {" "}
                    <div className="flex gap-2">
                      <p className="w-1/3">No Rekam Medis</p>
                      <p>:</p>
                      <p>{selectedPatient.medical_number}</p>
                    </div>
                    <div className="flex gap-2">
                      <p className="w-1/3">Tanggal Lahir</p>
                      <p>:</p>
                      <p>{selectedPatient.date_of_birth}</p>
                    </div>
                    <div className="flex gap-2">
                      <p className="w-1/3">Gender</p>
                      <p>:</p>
                      <p>
                        {selectedPatient.gender === 1
                          ? "Laki-laki"
                          : "Perempuan"}
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <p className="w-1/3">No Telepon</p>
                      <p>:</p>
                      <p>
                        {selectedPatient.phone_number === ""
                          ? "-"
                          : selectedPatient.phone_number}
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <p className="w-1/3">Email</p>
                      <p>:</p>
                      <p>
                        {selectedPatient.email === ""
                          ? "-"
                          : selectedPatient.email}
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <p className="w-1/3">NIK</p>
                      <p>:</p>
                      <p>{selectedPatient.nik}</p>
                    </div>
                    <div className="flex gap-2">
                      <p className="w-1/3">Alamat</p>
                      <p>:</p>
                      <p>{selectedPatient.address}</p>
                    </div>
                  </>
                ) : (
                  <p className="text-gray-300">Select a patient</p>
                )}
              </div>
              <div className="w-full">
                <p className="font-bold mb-2">Doctor Information</p>
                {selectedDoctor ? (
                  <>
                    {" "}
                    <div className="flex gap-2">
                      <p className="w-1/3">Nama Dokter</p>
                      <p>:</p>
                      <p>{selectedDoctor.name}</p>
                    </div>
                    <div className="flex gap-2">
                      <p className="w-1/3">No SIP</p>
                      <p>:</p>
                      <p>
                        {selectedDoctor.sip === "" ? "-" : selectedDoctor.sip}
                      </p>
                    </div>
                    <div className="flex gap-2">
                      <p className="w-1/3">Specialization</p>
                      <p>:</p>
                      <p>
                        {selectedDoctor.specialization === ""
                          ? "-"
                          : selectedDoctor.specialization}
                      </p>
                    </div>
                  </>
                ) : (
                  <p className="text-gray-300">Select a doctor</p>
                )}
              </div>
            </div>
            <div className="mb-4">
              <p className="font-bold text-lg mb-4">Subjective (S)</p>
              <div className="flex gap-2 mb-4">
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Riwayat Kelahiran</FormLabel>
                  <TextField
                    placeholder="-"
                    disabled={isDetail}
                    fullWidth
                    value={formik.values.subjective.birth_history}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "subjective.birth_history",
                        e.target.value
                      )
                    }
                  />
                </FormControl>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Keluhan Utama</FormLabel>
                  <TextField
                    placeholder="-"
                    disabled={isDetail}
                    fullWidth
                    value={formik.values.subjective.main_complaint}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "subjective.main_complaint",
                        e.target.value
                      )
                    }
                  />
                </FormControl>
              </div>
              <div className="flex gap-2 mb-4">
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Riwayat Penyakit Sebelumnya</FormLabel>
                  <TextField
                    placeholder="-"
                    disabled={isDetail}
                    fullWidth
                    value={formik.values.subjective.existing_illness}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "subjective.existing_illness",
                        e.target.value
                      )
                    }
                  />
                </FormControl>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Riwayat Sosial</FormLabel>
                  <TextField
                    placeholder="-"
                    disabled={isDetail}
                    fullWidth
                    value={formik.values.subjective.social_history}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "subjective.social_history",
                        e.target.value
                      )
                    }
                  />
                </FormControl>
              </div>
              <div className="flex gap-2 mb-4">
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Riwayat Tumbuh Kembang</FormLabel>
                  <TextField
                    placeholder="-"
                    disabled={isDetail}
                    fullWidth
                    value={formik.values.subjective.development_history}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "subjective.development_history",
                        e.target.value
                      )
                    }
                  />
                </FormControl>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Riwayat Keluarga</FormLabel>
                  <TextField
                    placeholder="-"
                    disabled={isDetail}
                    fullWidth
                    value={formik.values.subjective.family_history}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "subjective.family_history",
                        e.target.value
                      )
                    }
                  />
                </FormControl>
              </div>

              <div className="flex gap-2 mb-4">
                <FormControl sx={{ width: "50%" }}>
                  <FormLabel>Konsumsi Obat Saat Ini</FormLabel>
                  <TextField
                    placeholder="-"
                    disabled={isDetail}
                    fullWidth
                    value={formik.values.subjective.current_medication}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "subjective.current_medication",
                        e.target.value
                      )
                    }
                  />
                </FormControl>
              </div>
            </div>
            <div className="mb-4">
              <p className="font-bold text-lg mb-4">Objective (O)</p>
              <p className="font-bold mb-2">Tanda-tanda Vital</p>
              <div className="flex gap-2 mb-4">
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Tekanan Darah (Diastolik)</FormLabel>
                  <div className="flex gap-2 items-center">
                    <TextField
                      placeholder="-"
                      disabled={isDetail}
                      sx={{ width: "75%" }}
                      value={formik.values.objective.diastole}
                      onChange={(e) => {
                        let numericValue = e.target.value.replace(",", ".");
                        if (Number(numericValue > 0) || numericValue === "") {
                          formik.setFieldValue(
                            "objective.diastole",
                            numericValue.includes(".") && numericValue !== ""
                              ? numericValue.replace(".", ",")
                              : numericValue
                          );
                        }
                      }}
                    />
                    <p>mmHG</p>
                  </div>
                </FormControl>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Tekanan Darah (Sistolik)</FormLabel>
                  <div className="flex gap-2 items-center">
                    <TextField
                      placeholder="-"
                      disabled={isDetail}
                      sx={{ width: "75%" }}
                      value={formik.values.objective.systole}
                      onChange={(e) => {
                        let numericValue = e.target.value.replace(",", ".");
                        if (Number(numericValue > 0) || numericValue === "") {
                          formik.setFieldValue(
                            "objective.systole",
                            numericValue.includes(".") && numericValue !== ""
                              ? numericValue.replace(".", ",")
                              : numericValue
                          );
                        }
                      }}
                    />
                    <p>mmHG</p>
                  </div>
                </FormControl>
              </div>
              <div className="flex gap-2 mb-4">
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Suhu Tubuh</FormLabel>
                  <div className="flex gap-2 items-center">
                    <TextField
                      placeholder="-"
                      disabled={isDetail}
                      sx={{ width: "75%" }}
                      value={formik.values.objective.body_temp}
                      onChange={(e) => {
                        let numericValue = e.target.value.replace(",", ".");
                        if (Number(numericValue > 0) || numericValue === "") {
                          formik.setFieldValue(
                            "objective.body_temp",
                            numericValue.includes(".") && numericValue !== ""
                              ? numericValue.replace(".", ",")
                              : numericValue
                          );
                        }
                      }}
                    />
                    <p>&#176;C</p>
                  </div>
                </FormControl>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Denyut Nadi</FormLabel>
                  <div className="flex gap-2 items-center">
                    <TextField
                      placeholder="-"
                      disabled={isDetail}
                      sx={{ width: "75%" }}
                      value={formik.values.objective.pulse}
                      onChange={(e) => {
                        let numericValue = e.target.value.replace(",", ".");
                        if (Number(numericValue > 0) || numericValue === "") {
                          formik.setFieldValue(
                            "objective.pulse",
                            numericValue.includes(".") && numericValue !== ""
                              ? numericValue.replace(".", ",")
                              : numericValue
                          );
                        }
                      }}
                    />
                    <p>x/menit</p>
                  </div>
                </FormControl>
              </div>
              <div className="flex gap-2 mb-4">
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Nafas</FormLabel>
                  <div className="flex gap-2 items-center">
                    <TextField
                      placeholder="-"
                      disabled={isDetail}
                      sx={{ width: "75%" }}
                      value={formik.values.objective.respiratory_rate}
                      onChange={(e) => {
                        let numericValue = e.target.value.replace(",", ".");
                        if (Number(numericValue > 0) || numericValue === "") {
                          formik.setFieldValue(
                            "objective.respiratory_rate",
                            numericValue.includes(".") && numericValue !== ""
                              ? numericValue.replace(".", ",")
                              : numericValue
                          );
                        }
                      }}
                    />
                    <p>x/menit</p>
                  </div>
                </FormControl>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel>Kadar Oksigen</FormLabel>
                  <div className="flex gap-2 items-center">
                    <TextField
                      placeholder="-"
                      disabled={isDetail}
                      sx={{ width: "75%" }}
                      value={formik.values.objective.oxygen}
                      onChange={(e) => {
                        let numericValue = e.target.value.replace(",", ".");
                        if (
                          (Number(numericValue > 0) &&
                            Number(numericValue <= 100)) ||
                          numericValue === ""
                        ) {
                          formik.setFieldValue(
                            "objective.oxygen",
                            numericValue.includes(".") && numericValue !== ""
                              ? numericValue.replace(".", ",")
                              : numericValue
                          );
                        }
                      }}
                    />
                    <p>Sp02 (%)</p>
                  </div>
                </FormControl>
              </div>
            </div>
            <p className="font-bold mb-2">Nyeri</p>
            <div className="flex gap-2 mb-4">
              <FormControl sx={{ width: "100%" }}>
                <FormLabel>Tingkat Nyeri</FormLabel>
                <div className="flex gap-2 items-center">
                  <TextField
                    placeholder="-"
                    disabled={isDetail}
                    sx={{ width: "75%" }}
                    value={formik.values.objective.pain_level}
                    onChange={(e) => {
                      if (
                        !isNaN(Number(e.target.value)) &&
                        Number(e.target.value) <= 10
                      )
                        formik.setFieldValue(
                          "objective.pain_level",
                          e.target.value
                        );
                    }}
                  />
                  <p>1 s/d 10</p>
                </div>
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel>Lokasi Nyeri</FormLabel>
                <TextField
                  placeholder="-"
                  disabled={isDetail}
                  sx={{ width: "75%" }}
                  value={formik.values.objective.pain_location}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "objective.pain_location",
                      e.target.value
                    )
                  }
                />
              </FormControl>
            </div>
            <p className="font-bold mb-2">Lainnya</p>
            <div className="flex gap-2 mb-4">
              <FormControl sx={{ width: "100%" }}>
                <FormLabel>Lingkar Kepala</FormLabel>
                <div className="flex gap-2 items-center">
                  <TextField
                    placeholder="-"
                    disabled={isDetail}
                    sx={{ width: "75%" }}
                    value={formik.values.objective.head_circumference}
                    onChange={(e) => {
                      let numericValue = e.target.value.replace(",", ".");
                      if (Number(numericValue > 0) || numericValue === "") {
                        formik.setFieldValue(
                          "objective.head_circumference",
                          numericValue.includes(".") && numericValue !== ""
                            ? numericValue.replace(".", ",")
                            : numericValue
                        );
                      }
                    }}
                  />
                  <p>cm</p>
                </div>
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel>Berat Badan</FormLabel>
                <div className="flex gap-2 items-center">
                  <TextField
                    placeholder="-"
                    disabled={isDetail}
                    sx={{ width: "75%" }}
                    value={formik.values.objective.weight}
                    onChange={(e) => {
                      let numericValue = e.target.value.replace(",", ".");
                      if (Number(numericValue > 0) || numericValue === "") {
                        formik.setFieldValue(
                          "objective.weight",
                          numericValue.includes(".") && numericValue !== ""
                            ? numericValue.replace(".", ",")
                            : numericValue
                        );
                      }
                    }}
                  />
                  <p>kg</p>
                </div>
              </FormControl>
            </div>
            <div className="flex gap-2 mb-4">
              <FormControl sx={{ width: "100%" }}>
                <FormLabel>Tinggi Badan</FormLabel>
                <div className="flex gap-2 items-center">
                  <TextField
                    placeholder="-"
                    disabled={isDetail}
                    sx={{ width: "75%" }}
                    value={formik.values.objective.height}
                    onChange={(e) => {
                      let numericValue = e.target.value.replace(",", ".");
                      if (Number(numericValue > 0) || numericValue === "") {
                        formik.setFieldValue(
                          "objective.height",
                          numericValue.includes(".") && numericValue !== ""
                            ? numericValue.replace(".", ",")
                            : numericValue
                        );
                      }
                    }}
                  />
                  <p>cm</p>
                </div>
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel>Pemeriksaan Fisik</FormLabel>
                <TextField
                  placeholder="-"
                  disabled={isDetail}
                  sx={{ width: "75%" }}
                  value={formik.values.objective.physical_exam}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "objective.physical_exam",
                      e.target.value
                    )
                  }
                />
              </FormControl>
            </div>
            <div className="mb-4">
              <FormControl sx={{ width: "100%" }}>
                <FormLabel>Pemeriksaan Penunjang</FormLabel>
                <TextField
                  placeholder="-"
                  disabled={isDetail}
                  sx={{ width: "87.5%" }}
                  value={formik.values.objective.support_exam}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "objective.support_exam",
                      e.target.value
                    )
                  }
                />
              </FormControl>
            </div>
            <div className="w-full mb-4">
              <p className="font-bold text-lg">Hasil Laboratorium</p>
              <hr />
              <div className="w-full grid grid-cols-2 lg:grid-cols-5 gap-3 mt-2">
                {formik.values.img.length > 0 ? (
                  formik.values.img.map((item, i) => (
                    <div className="relative">
                      <div className="w-full h-[200px]">
                        <div
                          key={i}
                          ref={(element) =>
                            (cornerstoneRefs.current[i] = element)
                          }
                          style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: "black",
                            objectFit: "contain",
                          }}
                        >
                          {arrDicom[i] ? null : (
                            <div className="flex justify-center items-center w-full h-[200px] p-3">
                              <MdOutlineHideImage className="w-[48px] h-[48px]" />
                            </div>
                          )}
                        </div>
                      </div>
                      {isDetail ? null : (
                        <MdCancel
                          className="cursor-pointer text-red-500 bg-white rounded-full absolute -top-2 -right-3 w-[24px] h-[24px]"
                          onClick={() => {
                            mutateDeleteImg(formik.values.img[i]);
                            setSelectedImg(i);
                          }}
                        />
                      )}
                    </div>
                  ))
                ) : isDetail ? (
                  <p className="col-span-5">Tidak ada hasil laboratorium</p>
                ) : null}

                {isDetail ? null : (
                  <>
                    <input
                      type="file"
                      id="doctor-img-upload"
                      hidden
                      accept="*/dicom,.dcm, image/dcm, */dcm, .dicom"
                      onChange={(e) => {
                        mutateUploadImg(e.target.files[0]);
                      }}
                    />
                    <label for="doctor-img-upload">
                      <div className="w-full h-[200px] flex justify-center items-center text-center rounded-md border-2 cursor-pointer hover:bg-gray-100">
                        {isLoadingImg ? (
                          <CircularProgress size={20} />
                        ) : (
                          <LuImagePlus className="w-[48px] h-[48px]" />
                        )}
                      </div>
                    </label>
                  </>
                )}
              </div>
            </div>

            <div className="w-full mb-4">
              <p className="font-bold text-lg">Hasil Pemeriksaan</p>
              <hr />
              <div className="w-full grid grid-cols-2 lg:grid-cols-5 gap-3 mt-2">
                {formik.values.exam_img.length > 0 ? (
                  formik.values.exam_img.map((item, i) => (
                    <div className="relative">
                      <div className="w-full h-[200px]">
                        <div
                          key={i}
                          ref={(element) =>
                            (cornerstoneRefsExam.current[i] = element)
                          }
                          style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: "black",
                            objectFit: "contain",
                          }}
                        >
                          {arrDicomExam[i] ? null : (
                            <div className="flex justify-center items-center w-full h-[200px] p-3">
                              <MdOutlineHideImage className="w-[48px] h-[48px]" />
                            </div>
                          )}
                        </div>
                      </div>
                      {isDetail ? null : (
                        <MdCancel
                          className="cursor-pointer text-red-500 bg-white rounded-full absolute -top-2 -right-3 w-[24px] h-[24px]"
                          onClick={() => {
                            mutateDeleteImgExam(formik.values.img[i]);
                            setSelectedImg(i);
                          }}
                        />
                      )}
                    </div>
                  ))
                ) : isDetail ? (
                  <p className="col-span-5">Tidak ada hasil pemeriksaan</p>
                ) : null}

                {isDetail ? null : (
                  <>
                    <input
                      type="file"
                      id="doctor-img-upload-exam"
                      hidden
                      accept="*/dicom,.dcm, image/dcm, */dcm, .dicom"
                      onChange={(e) => {
                        mutateUploadImgExam(e.target.files[0]);
                      }}
                    />
                    <label for="doctor-img-upload-exam">
                      <div className="w-full h-[200px] flex justify-center items-center text-center rounded-md border-2 cursor-pointer hover:bg-gray-100">
                        {isLoadingImgExam ? (
                          <CircularProgress size={20} />
                        ) : (
                          <LuImagePlus className="w-[48px] h-[48px]" />
                        )}
                      </div>
                    </label>
                  </>
                )}
              </div>
            </div>
            {isDetail ? null : (
              <div className="w-full flex justify-end gap-2">
                <button
                  type="button"
                  className="w-fit px-12 border-2 rounded-md py-2 hover:bg-gray-100"
                  onClick={() => navigate(`/patient-care/initial-analysis/`)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="w-fit px-12 rounded-md py-2 bg-blue-500 disabled:bg-gray-300 text-white hover:bg-blue-700"
                  disabled={!formik.isValid || isLoading}
                >
                  Save
                </button>
              </div>
            )}
          </div>
          <div className="w-full col-span-2 lg:col-span-1">
            <PatientAnalysisHistory
              selectedPatient={selectedPatient}
              isDoctor={false}
            />
          </div>
        </div>
      </form>

      <Popover
        open={Boolean(anchorElPatient)}
        anchorEl={anchorElPatient}
        onClose={handleClosePatient}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="min-w-[40vw] w-full max-h-[50vh] overflow-y-auto">
          <div className="sticky top-0 bg-white">
            <div className="px-8 py-3">
              <div className="grid grid-cols-3 items-center mb-2">
                <FaChevronLeft
                  className="hover:text-red-500 cursor-pointer w-[14px] h-full"
                  onClick={handleClosePatient}
                />
                <p className="text-center font-bold text-lg">List Patient</p>
              </div>
              <TextField
                fullWidth
                sx={{ marginBottom: 1 }}
                placeholder="Find Patient"
                value={searchPatient}
                onChange={(e) => setSearchPatient(e.target.value)}
                inputProps={{ style: { padding: 8 } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <hr />
          </div>
          {dataListPatient &&
          !isLoadingPatient &&
          dataListPatient.data &&
          dataListPatient.count > 0 ? (
            dataListPatient.data.map((item) => (
              <div
                className={`px-8 py-2 border-b-2 last:border:none flex gap-4  ${
                  formik.values.patient_id === item.id ? "bg-gray-200" : ""
                } ${
                  item.enable
                    ? "cursor-pointer hover:bg-gray-100"
                    : "bg-gray-200 cursor-default"
                }`}
                onClick={() => {
                  if (item.enable) {
                    formik.setFieldValue("patient_id", item.id);
                    setSelectedPatient(item);
                    handleClosePatient();
                  }
                }}
              >
                <div className="w-full">
                  <div className="mb-3">
                    <p className="font-bold mb-1">{item.patient_name}</p>
                    {item.member ? (
                      <p className="w-fit border-[#FF8D40] border-2 rounded-md text-[#FF8D40] text-center text-sm px-4 py-1">
                        Member
                      </p>
                    ) : null}
                  </div>{" "}
                  <div className="flex gap-2">
                    <p className="w-1/3">No Rekam Medis</p>
                    <p>:</p>
                    <p>{item.medical_number}</p>
                  </div>
                  <div className="flex gap-2">
                    <p className="w-1/3">Tanggal Lahir</p>
                    <p>:</p>
                    <p>{item.date_of_birth}</p>
                  </div>
                  <div className="flex gap-2">
                    <p className="w-1/3">Gender</p>
                    <p>:</p>
                    <p>{item.gender === 1 ? "Laki-laki" : "Perempuan"}</p>
                  </div>
                  <div className="flex gap-2">
                    <p className="w-1/3">No Telepon</p>
                    <p>:</p>
                    <p>{item.phone_number === "" ? "-" : item.phone_number}</p>
                  </div>
                  <div className="flex gap-2">
                    <p className="w-1/3">Email</p>
                    <p>:</p>
                    <p>{item.email === "" ? "-" : item.email}</p>
                  </div>
                  <div className="flex gap-2">
                    <p className="w-1/3">NIK</p>
                    <p>:</p>
                    <p>{item.nik}</p>
                  </div>
                  <div className="flex gap-2">
                    <p className="w-1/3">Alamat</p>
                    <p>:</p>
                    <p>{item.address}</p>
                  </div>
                </div>
              </div>
            ))
          ) : isLoadingPatient ? (
            <div className="flex justify-center py-2">
              <CircularProgress size={20} />
            </div>
          ) : (
            <div className="flex justify-center py-2 text-gray-300">
              <p>No Patient Found</p>
            </div>
          )}
        </div>
      </Popover>

      <Popover
        open={Boolean(anchorElDoctor)}
        anchorEl={anchorElDoctor}
        onClose={handleCloseDoctor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{ width: "100%", minWidth: "50vw" }}
      >
        <div className="min-w-[40vw] w-full max-h-[50vh] overflow-y-auto">
          <div className="sticky top-0 bg-white">
            <div className="px-8 py-3">
              <div className="grid grid-cols-3 items-center mb-2">
                <FaChevronLeft
                  className="hover:text-red-500 cursor-pointer w-[14px] h-full"
                  onClick={handleCloseDoctor}
                />
                <p className="text-center font-bold text-lg">List Dokter</p>
              </div>
              <TextField
                fullWidth
                sx={{ marginBottom: 1 }}
                placeholder="Find Doctor"
                value={searchDoctor}
                onChange={(e) => setSearchDoctor(e.target.value)}
                inputProps={{ style: { padding: 8 } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <hr />
          </div>
          {dataListDoctor &&
          !isLoadingDoctor &&
          dataListDoctor.doctor &&
          dataListDoctor.count > 0 ? (
            dataListDoctor.doctor.map((item) => (
              <div
                className={`px-8 py-2 border-b-2 last:border:none flex gap-4 cursor-pointer hover:bg-gray-100 ${
                  formik.values.doctor_id === item.id ? "bg-gray-200" : ""
                }`}
                onClick={() => {
                  formik.setFieldValue("doctor_id", item.id);
                  setSelectedDoctor(item);
                  handleCloseDoctor();
                }}
              >
                <div
                  className={`rounded-md w-[48px] h-[48px] bg-gray-200 ${
                    item.image === "" ? "p-2" : ""
                  }`}
                >
                  {item.image === "" ? (
                    <FaUser className="w-full h-full text-white" />
                  ) : (
                    <img
                      src={item.image}
                      className="w-full h-full rounded-md object-cover"
                    />
                  )}
                </div>
                <div className="w-full">
                  <p className="font-bold mb-2">{item.name}</p>
                  <div className="flex gap-2">
                    <p className="w-1/3">Email</p>
                    <p>:</p>
                    <p>{item.email === "" ? "-" : item.email}</p>
                  </div>
                  <div className="flex gap-2">
                    <p className="w-1/3">Phone Number</p>
                    <p>:</p>
                    <p>{item.phone === "" ? "-" : item.phone}</p>
                  </div>
                  <div className="flex gap-2">
                    <p className="w-1/3">No SIP</p>
                    <p>:</p>
                    <p>{item.sip === "" ? "-" : item.sip}</p>
                  </div>
                  <div className="flex gap-2">
                    <p className="w-1/3">No KTP</p>
                    <p>:</p>
                    <p>{item.ktp === "" ? "-" : item.ktp}</p>
                  </div>
                  <div className="flex gap-2">
                    <p className="w-1/3">Specialization</p>
                    <p>:</p>
                    <p>
                      {item.specialization === "" ? "-" : item.specialization}
                    </p>
                  </div>
                  <div className="flex gap-2">
                    <p className="w-1/3">Address</p>
                    <p>:</p>
                    <p>{item.address === "" ? "-" : item.address}</p>
                  </div>
                </div>
              </div>
            ))
          ) : isLoadingDoctor ? (
            <div className="flex justify-center py-2">
              <CircularProgress size={20} />
            </div>
          ) : (
            <div className="flex justify-center py-2 text-gray-300">
              <p>No Doctors Found</p>
            </div>
          )}
        </div>
      </Popover>

      <ModalSavePatientCare
        open={openSave}
        setOpen={setOpenSave}
        setConfirm={setIsSave}
        title="Simpan Data Initial Analysis"
        message="Simpan data initial analysis yang baru dibuat?"
      />

      <ModalConfirmationAction
        open={openConfirm}
        setOpen={setOpenConfirm}
        returnRoute="/patient-care/initial-analysis"
        message="Data analysis Disimpan"
      />

      <ModalAddPatient
        open={openAddPatient}
        setOpen={setOpenAddPatient}
        refetch={() => fetchPatient("")}
      />

      <ModalAddEditDoctor
        open={openAddDoctor}
        setOpen={setOpenAddDoctor}
        isEdit={true}
        refetch={() => fetchDoctor("")}
      />
    </>
  );
};

export default AddInitialAnalysis;
