import React from "react";
import HOC from "../../components/HOC";
import CreateInvoice from "../../components/screen/invoice/CreateInvoice";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../components/constants/muiTheme";
import BreadcrumbInvoice from "../../components/screen/invoice/BreadcrumbInvoice";

const CreateInvoicePage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Tambah Faktur"
        mainComponent={
          <ThemeProvider theme={theme}>
            <CreateInvoice isWarehouse={false} />
          </ThemeProvider>
        }
        breadcrumb={<BreadcrumbInvoice />}
        pageRole={11}
      />
    </div>
  );
};

export default HOC(CreateInvoicePage);
