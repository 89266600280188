import {
  Box,
  Checkbox,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { RxCross1 } from "react-icons/rx";
import { useEffect, useState } from "react";
import { IoMdAddCircleOutline } from "react-icons/io";
import { Search } from "@mui/icons-material";
import { getProductList } from "../../../../services/invoiceApi";
import useDebounce from "../../../hooks/useDebounce";
import moment from "moment";
import PagePagination from "../../../common/particles.jsx/PagePagination";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalAddProductInvoice = ({ open, setOpen, formik }) => {
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(1);
  const [fetchLimit, setFetchLimit] = useState(10);
  const [addProductArr, setAddProductArr] = useState([]);

  const debounce = useDebounce(search, 500);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    formik.setFieldValue(
      "product",
      addProductArr.filter((item) => item.quantity > 0)
    );
    setAddProductArr([]);
    setOffset(1);
    setFetchLimit(10);
    setSearch("");
    handleClose();
  };

  const findItem = (item) => {
    return addProductArr.find((prod) => prod.product_id === item.product_id);
  };

  const handleSelectAll = (checked) => {
    var temp = [...addProductArr];

    // loop thru data, if udh adad di temp skip sisanya append
    data.data
      .filter((item) => item.stock > 0)
      .map((item, i) => {
        const index = temp.findIndex(
          (prod) => prod.product_id === item.product_id
        );

        if (index < 0 && checked)
          temp.push({
            product_id: item.product_id,
            product_name: item.product_name,
            batch_number: item.batch_no,
            expired_date: item.batch_exp,
            price: item.primary_sell_price,
            on_us: item.on_us,
            off_us: item.off_us,
            promo: item.promo,
            product_units: item.product_units,
            type_uom: 0,
            quantity: 1,
            stock: item.stock,
            discount: item.on_us + item.off_us,
          });
        if (index >= 0 && !checked) {
          temp = [...temp.slice(0, index), ...temp.slice(index + 1)];
        }
      });

    setAddProductArr(temp);
  };

  const handleCheckItem = (item) => {
    var temp = [...addProductArr];
    const index = temp.findIndex((prod) => prod.product_id === item.product_id);

    if (index < 0) {
      temp.push({
        product_id: item.product_id,
        product_name: item.product_name,
        batch_number: item.batch_no,
        expired_date: item.batch_exp,
        price: item.product_units.primary_sell_price,
        on_us: item.on_us,
        off_us: item.off_us,
        promo: item.promo,
        product_units: item.product_units,
        type_uom: 0,
        quantity: 0,
        stock: item.stock,
        discount: item.on_us + item.off_us,
      });
    } else {
      temp = [...temp.slice(0, index), ...temp.slice(index + 1)];
    }

    setAddProductArr(temp);
  };

  const handleChangeValue = (val, type, item) => {
    var temp = [...addProductArr];
    const index = temp.findIndex((prod) => prod.product_id === item.product_id);

    temp[index][type] = val;

    setAddProductArr(temp);
  };

  const findStock = (item) => {
    return addProductArr[
      addProductArr.findIndex((prod) => prod.product_id === item.product_id)
    ]?.type_uom === 0 ||
      addProductArr.findIndex((prod) => prod.product_id === item.product_id) < 0
      ? item.stock
      : item.stock * item.product_units.total_secondary;
  };

  const { data, isFetching } = useQuery({
    queryKey: ["promo-product", debounce, offset, fetchLimit],
    queryFn: () => getProductList(fetchLimit, offset, debounce, false),
    refetchOnWindowFocus: false,
    enabled: open,
  });

  useEffect(() => {
    setOffset(1);
  }, [debounce]);

  useEffect(() => {
    if (open) setAddProductArr(formik.values.product);
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          ...style,
          maxWidth: "75%",
          overflowY: "auto",
          overflowX: "wrap",
          maxHeight: "80vh",
          padding: 0,
        }}
        className="text-left text-[12px] w-3/4 lg:w-1/3"
      >
        <div className="flex justify-between items-center text-center px-8 py-4">
          <p className="font-bold text-lg w-full">Tambah Produk</p>
          <RxCross1
            className="cursor-pointer hover:text-red-500 w-fit"
            onClick={handleClose}
          />
        </div>
        <hr className="mb-4" />

        <div className="px-8 mb-4">
          <div className="flex items-center gap-2 mb-4">
            <TextField
              fullWidth
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Cari produk berdasarkan (Nama atau NIE)"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            <button
              type="button"
              className="border rounded-md flex items-center justify-between gap-2 w-fit py-2.5 px-2 bg-blue-500 text-white hover:bg-gray-300 whitespace-nowrap text-nowrap"
              onClick={handleSave}
            >
              Tambah Produk
              <IoMdAddCircleOutline className="text-[18px]" />
            </button>
          </div>

          <div className="flex w-full mb-4">
            <div className="w-full">
              <div className="block overflow-x-auto w-full rounded-md">
                <table className="w-full overflow-x-auto rounded-md border-collapse">
                  <thead className="bg-blue-500 text-white">
                    <th className="p-2 text-center">
                      <Checkbox
                        onChange={(_, checked) => handleSelectAll(checked)}
                        disabled={isFetching || !data || (data && !data.data)}
                        checked={
                          data &&
                          !isFetching &&
                          data.data &&
                          data.data.filter((item) => item.stock > 0)
                            ? data.data
                                .filter((item) => item.stock > 0)
                                .map((item) => item.product_id)
                                .every((r) =>
                                  addProductArr
                                    .map((item) => item.product_id)
                                    .includes(r)
                                )
                            : false
                        }
                      />
                    </th>
                    <th className="p-2 text-center">Nama Produk</th>
                    <th className="p-2 text-center">No. Batch</th>
                    <th className="p-2 text-center">Expired</th>
                    <th className="p-2 text-center">Kemasan</th>
                    <th className="p-2 text-center">Stock</th>
                    <th className="p-2 text-center w-[200px]">Kuantitas</th>
                  </thead>
                  <tbody className="border">
                    {data && !isFetching && data.data ? (
                      data.data.map((item, i) => (
                        <tr key={i} className={`${i === 0 ? "" : "border-t"}`}>
                          <td className="p-2 text-center">
                            <Checkbox
                              disabled={item.stock === 0}
                              onChange={() => handleCheckItem(item)}
                              checked={addProductArr
                                .map((prod) => prod.product_id)
                                .includes(item.product_id)}
                            />
                          </td>
                          <td className="p-2 text-center">
                            {item.product_name}
                          </td>
                          <td className="p-2 text-center">{item.batch_no}</td>
                          <td className="p-2 text-center">
                            {moment(item.batch_exp).format("DD/MM/YYYY")}
                          </td>
                          <td className="p-2 text-center">
                            <Select
                              sx={{ width: "100%" }}
                              defaultValue={0}
                              value={
                                !findItem(item)
                                  ? 0
                                  : addProductArr[
                                      addProductArr.findIndex(
                                        (prod) =>
                                          prod.product_id === item.product_id
                                      )
                                    ].type_uom
                              }
                              disabled={!findItem(item)}
                              onChange={(e) => {
                                handleChangeValue(
                                  e.target.value,
                                  "type_uom",
                                  item
                                );
                                handleChangeValue(
                                  e.target.value === 0
                                    ? item.product_units.primary_sell_price
                                    : e.target.value === 1
                                    ? item.product_units.secondary_sell_price
                                    : item.product_units.terniary_sell_price,
                                  "price",
                                  item
                                );
                                handleChangeValue(
                                  findStock(item),
                                  "stock",
                                  item
                                );
                              }}
                            >
                              <MenuItem value={0}>
                                {item.product_units.primary_type}
                              </MenuItem>
                              <MenuItem value={1}>
                                {item.product_units.secondary_type}
                              </MenuItem>
                            </Select>
                          </td>
                          <td className="p-2 text-center">
                            {findStock(item)}{" "}
                            {addProductArr[
                              addProductArr.findIndex(
                                (prod) => prod.product_id === item.product_id
                              )
                            ]?.type_uom === 0 ||
                            addProductArr.findIndex(
                              (prod) => prod.product_id === item.product_id
                            ) < 0
                              ? item.product_units.primary_type
                              : item.product_units.secondary_type}
                          </td>
                          <td className="px-2 py-4 text-center align-top">
                            <div className="flex items-center gap-2 w-full">
                              <div
                                className={`w-[24px] h-[24px] p-0.5 rounded-md flex items-center justify-center text-white ${
                                  !findItem(item) ||
                                  (findItem(item) &&
                                    findItem(item).quantity === 0)
                                    ? "bg-gray-300 cursor-default"
                                    : " bg-red-500 cursor-pointer hover:bg-red-700"
                                }`}
                                onClick={() => {
                                  if (findItem(item).quantity - 1 >= 0)
                                    handleChangeValue(
                                      findItem(item).quantity - 1,
                                      "quantity",
                                      item
                                    );
                                }}
                              >
                                <FiMinusCircle className="text-[18px]" />
                              </div>
                              <input
                                className="w-full text-center focus:outline-none bg-white"
                                disabled={!findItem(item)}
                                value={
                                  findItem(item) ? findItem(item).quantity : 0
                                }
                                onChange={(e) => {
                                  if (!isNaN(Number(e.target.value)))
                                    handleChangeValue(
                                      Number(e.target.value) >
                                        findItem(item).stock
                                        ? Math.floor(findItem(item).stock)
                                        : Number(e.target.value),
                                      "quantity",
                                      item
                                    );
                                }}
                              />
                              <div
                                className={`w-[24px] h-[24px] p-0.5 rounded-md flex items-center justify-center text-white ${
                                  !findItem(item) ||
                                  (findItem(item) &&
                                    findItem(item).quantity ===
                                      Math.floor(findItem(item).stock))
                                    ? "bg-gray-300 cursor-default"
                                    : " bg-blue-500 cursor-pointer hover:bg-blue-700"
                                }`}
                                onClick={() => {
                                  if (
                                    !findItem(item) ||
                                    (findItem(item) &&
                                      findItem(item).quantity ===
                                        Math.floor(findItem(item).stock))
                                  )
                                    return;
                                  handleChangeValue(
                                    findItem(item).quantity + 1,
                                    "quantity",
                                    item
                                  );
                                }}
                              >
                                <FiPlusCircle className="text-[18px]" />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="border">
                        <td className="p-2 text-center" colSpan={7}>
                          {isFetching ? (
                            <CircularProgress size={20} />
                          ) : (
                            "Tidak ada produk"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {data && data.total > 0 && data.data !== null ? (
            <div className="mb-4">
              <PagePagination
                offset={offset}
                setOffset={(e) => setOffset(e)}
                limit={fetchLimit}
                setLimit={(e) => setFetchLimit(e)}
                total={data.total}
              />
            </div>
          ) : null}
        </div>
      </Box>
    </Modal>
  );
};

export default ModalAddProductInvoice;
