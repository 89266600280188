import React from "react";
import HOC from "../../components/HOC";
import CreateReturProduct from "../../components/screen/Retur/CreateReturProduct";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../components/constants/muiTheme";
import BreadcrumbRetur from "../../components/screen/Retur/BreadcrumbRetur";

const CreateReturProductPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Buat Retur Pembelian"
        mainComponent={
          <ThemeProvider theme={theme}>
            <CreateReturProduct />
          </ThemeProvider>
        }
        breadcrumb={<BreadcrumbRetur />}
        pageRole={46}
        noPadding={true}
      />
    </div>
  );
};

export default HOC(CreateReturProductPage);
