import React from "react";
import DetailPurchaseOrder from "../../components/screen/centralWarehouse/purchaseOrderCW/DetailPurchaseOrder";
import HOC from "../../components/HOC";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const DetailPurchaseOrderPageCW = ({ isWarehouse }) => {
  return (
    <div className="">
      <PageTemplate
        header={"PO Central Warehouse Detail"}
        mainComponent={<DetailPurchaseOrder isWarehouse={isWarehouse} />}
        pageRole={29}
      />
    </div>
  );
};

export default HOC(DetailPurchaseOrderPageCW);
