import {
  Checkbox,
  CircularProgress,
  InputAdornment,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { getPoProducts } from "../../../../services/purchaseOrderApi";
import { IoIosAddCircleOutline } from "react-icons/io";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import AddNewProduct from "./AddNewProduct";

const FETCH_LIMIT = 10;

const AddPurchaseOrderProduct = ({
  setIsAddProduct,
  setProductArr,
  productArr,
  setQtyType,
  IGNORE_TYPE,
  isWarehouse,
}) => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [items, setItems] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [fetchNext, setFetchNext] = useState(false);
  const [addProductArr, setAddProductArr] = useState(productArr);
  const [isAddNewProductView, setIsAddNewProductView] = useState(false);

  const handleCheckItem = (checked, item) => {
    var temp = [...addProductArr];
    if (checked) {
      temp.push({ ...item, stock: 0, type_stock: 0 });
    } else {
      const index = temp.findIndex(
        (prod) => prod.product_detail_id === item.product_detail_id
      );
      temp = [...temp.slice(0, index), ...temp.slice(index + 1)];
    }
    setAddProductArr(temp);
  };

  const changeStock = (id, action, val) => {
    var temp = [...addProductArr];
    const index = addProductArr.findIndex(
      (prod) => prod.product_detail_id === id
    );
    if (action === "min") temp[index].stock -= 1;
    if (action === "add") temp[index].stock += 1;
    if (action === "text")
      isNaN(Number(val))
        ? (temp[index].stock = 0)
        : (temp[index].stock = Number(val));
    setAddProductArr(temp);
  };

  const handlePackaging = (id, e) => {
    var temp = [...addProductArr];
    const index = addProductArr.findIndex(
      (prod) => prod.product_detail_id === id
    );
    temp[index].type_stock = e.target.value;
    setAddProductArr(temp);
  };

  const handleAddProduct = () => {
    setProductArr(addProductArr.filter((item) => item.stock > 0));
    setIsAddProduct(0);
  };

  const { data, isFetching } = useQuery({
    queryKey: ["po-product", search],
    queryFn: () =>
      getPoProducts(FETCH_LIMIT, 1, search, isWarehouse ? isWarehouse : false),
    onSuccess: (data) => {
      setItems(data.data);
      if (data.total <= FETCH_LIMIT) {
        setHasMore(false);
      }
      if (data.total > FETCH_LIMIT) setFetchNext(true);
    },
    onError: () => {
      setErrorMessage("Gagal mengambil produk lainnya");
    },
  });

  const { data: nextData, isFetching: isFetchingNext } = useQuery({
    queryKey: ["next-po-product", offset],
    queryFn: () =>
      getPoProducts(
        FETCH_LIMIT,
        offset,
        search,
        isWarehouse ? isWarehouse : false
      ),
    enabled: fetchNext,
    retry: 3,
    onError: () => {
      setFetchNext(false);
      setHasMore(false);
    },
    onSuccess: (nextData) => {
      var temp = [...items];
      temp.push(...nextData.data);
      setItems(temp);
      setFetchNext(false);
    },
  });

  useEffect(() => {
    if (nextData && nextData.data === null) {
      setHasMore(false);
    }
    if (nextData && nextData.data !== null) {
      nextData.data.length > 0 ? setHasMore(true) : setHasMore(false);
    }
  }, [nextData]);

  useEffect(() => {
    if (fetchNext) setOffset(offset + 1);
  }, [fetchNext]);

  useEffect(() => {
    setHasMore(true);
    setOffset(1);
  }, [search, isAddNewProductView]);

  return (
    <>
      {isAddNewProductView ? (
        <AddNewProduct
          isWarehouse={isWarehouse}
          setIsAddNewProductView={setIsAddNewProductView}
        />
      ) : (
        <>
          <div className="flex w-full itmes-center gap-2">
            <TextField
              value={search}
              label="Search Product (by name, NIE)"
              fullWidth
              onChange={(e) => {
                setSearch(e.target.value.toLowerCase());
                setErrorMessage("");
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
            />

            <button
              onClick={() =>
                // navigate(
                //   `${
                //     isWarehouse ? "/central-warehouse" : ""
                //   }/purchase-order/create-po/add-product`
                // )
                setIsAddNewProductView(true)
              }
              className=" w-full text-xs basis-1/3 bg-[#0085DD] flex justify-center p-2 px-4 items-center border-2 border-black rounded-md cursor-pointer hover:bg-[#0676c0]"
            >
              <IoIosAddCircleOutline className="mr-2 text-black" />
              Add New Products
            </button>
            <button
              onClick={handleAddProduct}
              className=" w-full text-xs basis-1/3 bg-[#99FFC2] flex justify-center p-2 px-4 items-center border-2 border-black rounded-md cursor-pointer hover:bg-[#88dba9]"
            >
              <IoIosAddCircleOutline className="mr-2 text-black" />
              Add Selected Products
            </button>
          </div>
          <div className="block overflow-x-auto my-4">
            <InfiniteScroll
              className="w-full"
              dataLength={items && items.length ? items.length : 0}
              next={() => setFetchNext(true)}
              hasMore={hasMore}
              scrollThreshold={0.9}
              loader={<CircularProgress size={20} />}
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Tidak ada produk lagi</b>
                </p>
              }
            >
              <table className="w-full border-collapse">
                <thead>
                  <th className="p-2 border w-[80px] text-center">Select</th>
                  <th className="p-2 border text-center">Product Name</th>
                  <th className="p-2 border w-[22%] text-center">Packaging</th>
                  <th className="p-2 border w-[20%] text-center">
                    Select Quantity
                  </th>
                </thead>
                <tbody>
                  {items && items.length > 0 ? (
                    items.map((item, i) => {
                      return (
                        <tr>
                          <td className="p-2 text-center border w-[80px]">
                            <Checkbox
                              onChange={(_, checked) =>
                                handleCheckItem(checked, item)
                              }
                              checked={
                                addProductArr.find(
                                  (prod) =>
                                    prod.product_detail_id ===
                                    item.product_detail_id
                                ) !== undefined
                              }
                            />
                          </td>
                          <td className="p-2 text-center border">
                            {" "}
                            {item.name}
                          </td>
                          <td className="p-2 text-center border w-[22%]">
                            {item.packaging_es !== null ? (
                              <FormControl fullWidth>
                                {/* <InputLabel id="demo-simple-select-label">
                          Packaging
                        </InputLabel> */}
                                <Select
                                  disabled={
                                    !addProductArr.find(
                                      (prod) =>
                                        prod.product_detail_id ===
                                        item.product_detail_id
                                    )
                                  }
                                  value={
                                    !addProductArr.find(
                                      (prod) =>
                                        prod.product_detail_id ===
                                        item.product_detail_id
                                    )
                                      ? 0
                                      : addProductArr[
                                          addProductArr.findIndex(
                                            (prod) =>
                                              prod.product_detail_id ===
                                              item.product_detail_id
                                          )
                                        ].type_stock
                                  }
                                  // label="Packaging"
                                  onChange={(e) => {
                                    handlePackaging(item.product_detail_id, e);
                                  }}
                                >
                                  <MenuItem
                                    value={0}
                                    onClick={() =>
                                      setQtyType(item.packaging_es.type_box)
                                    }
                                  >
                                    {item.packaging_es.type_box}
                                  </MenuItem>
                                  <MenuItem
                                    value={1}
                                    onClick={() =>
                                      setQtyType(item.packaging_es.type_strip)
                                    }
                                  >
                                    {item.packaging_es.type_strip}
                                  </MenuItem>
                                  {IGNORE_TYPE.includes(
                                    item.packaging_es.type_unit
                                  ) ? (
                                    <MenuItem disabled sx={{ display: "none" }}>
                                      {item.packaging_es.type_unit}
                                    </MenuItem>
                                  ) : (
                                    <MenuItem
                                      value={2}
                                      onClick={() =>
                                        setQtyType(item.packaging_es.type_unit)
                                      }
                                    >
                                      {item.packaging_es.type_unit}
                                    </MenuItem>
                                  )}
                                </Select>
                              </FormControl>
                            ) : null}
                          </td>
                          <td className="p-2 text-center border w-[20%]">
                            <div className="flex justify-center">
                              <button
                                disabled={
                                  !addProductArr.find(
                                    (prod) =>
                                      prod.product_detail_id ===
                                      item.product_detail_id
                                  ) ||
                                  addProductArr.find(
                                    (prod) =>
                                      prod.product_detail_id ===
                                      item.product_detail_id
                                  ).stock === 0
                                }
                                className="rounded-l-md py-2 px-3 bg-[#FF7A5D] disabled:bg-gray-200"
                                onClick={() => {
                                  changeStock(item.product_detail_id, "min");
                                }}
                              >
                                -
                              </button>
                              <input
                                type="text"
                                className="bg-slate-100 p-2 w-[80px] text-center"
                                value={
                                  !addProductArr.find(
                                    (prod) =>
                                      prod.product_detail_id ===
                                      item.product_detail_id
                                  )
                                    ? 0
                                    : addProductArr[
                                        addProductArr.findIndex(
                                          (prod) =>
                                            prod.product_detail_id ===
                                            item.product_detail_id
                                        )
                                      ].stock
                                }
                                onChange={(e) =>
                                  changeStock(
                                    item.product_detail_id,
                                    "text",
                                    e.target.value
                                  )
                                }
                              />
                              <button
                                disabled={
                                  !addProductArr.find(
                                    (prod) =>
                                      prod.product_detail_id ===
                                      item.product_detail_id
                                  )
                                }
                                className="rounded-r-md bg-[#5DFFBB] py-2 px-3 disabled:bg-gray-200"
                                onClick={() => {
                                  changeStock(item.product_detail_id, "add");
                                }}
                              >
                                +
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : !isFetching && !isFetchingNext ? (
                    <tr>
                      <td className="p-2 border text-center w-full" colSpan={4}>
                        Tidak ada produk dengan nama/NIE yang dicari
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </InfiniteScroll>
            <p className="text-red-500 text-center">{errorMessage}</p>
          </div>
        </>
      )}
    </>
  );
};

export default AddPurchaseOrderProduct;
