import { Breadcrumbs, Link } from "@mui/material";
import { useLocation } from "react-router-dom";

const BreadcrumbRetur = () => {
  const { pathname } = useLocation();

  return (
    <Breadcrumbs sx={{ color: "#1761FD", marginBottom: 2, fontSize: 12 }}>
      <Link
        href="/home"
        underline="hover"
        color="#1761FD"
        sx={{ color: "#1761FD" }}
      >
        Homepage
      </Link>
      {pathname.includes("/retur/buy-retur") ? (
        <Link
          color="#1761FD"
          underline="none"
          href="/retur/buy-retur"
          sx={{ color: "#1761FD" }}
        >
          Retur Pembelian
        </Link>
      ) : null}
      {pathname.includes("/retur/sell-retur") ? (
        <Link
          color="#1761FD"
          underline="none"
          href="/retur/buy-retur"
          sx={{ color: "#1761FD" }}
        >
          Retur Penjualan
        </Link>
      ) : null}
      {pathname.includes("/retur/buy-retur/create-retur") ? (
        <Link
          underline="none"
          color="#1761FD"
          href="/retur/buy-retur/create-retur"
          sx={{ color: "#1761FD" }}
        >
          Buat Retur Pembelian
        </Link>
      ) : null}
      {pathname.includes("/retur/sell-retur/create-retur") ? (
        <Link
          underline="none"
          color="#1761FD"
          href="/retur/buy-retur/create-retur"
          sx={{ color: "#1761FD" }}
        >
          Buat Retur Penjualan
        </Link>
      ) : null}
      {pathname.includes("/retur/buy-retur/detail-retur/") ? (
        <Link underline="none" color="#1761FD" sx={{ color: "#1761FD" }}>
          Detail Retur Pembelian
        </Link>
      ) : null}
      {pathname.includes("/retur/sell-retur/detail-retur/") ? (
        <Link underline="none" color="#1761FD" sx={{ color: "#1761FD" }}>
          Detail Retur Pembelian
        </Link>
      ) : null}
    </Breadcrumbs>
  );
};

export default BreadcrumbRetur;
