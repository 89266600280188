import {
  Box,
  CircularProgress,
  Modal,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputAdornment,
  Dialog,
  DialogContent,
  DialogTitle,
  InputLabel,
} from "@mui/material";
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import { RxCross1 } from "react-icons/rx";
import { useCallback, useMemo, useState } from "react";
import {
  getPlatform,
  getNIEElasticSearch,
  getNieDetail,
  getPackaging,
  createProduct,
} from "../../../../services/InventoryAPI";
import useDebounce from "../../../hooks/useDebounce";
import { IGNORE_TYPE } from "../../../constants/constant";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const FETCH_LIMIT = 10;

const ModalAddProduct = ({ openModal, setOpenModal }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => {
    setSearch("");
    setNieNumber("");
    setNieId("");
    setBpomId("");
    setOpen(false);
    setIsCreateProductWithNIE(false);
    setNie("");
    formik.resetForm();
    setOpenModal(false);
  };

  const [qtyType, setQtyType] = useState("");
  const [search, setSearch] = useState("");
  const [nieNumber, setNieNumber] = useState("");
  const [nieId, setNieId] = useState("");
  const [bpomId, setBpomId] = useState("");
  const [open, setOpen] = useState(false);
  const [isCreateProductWithNIE, setIsCreateProductWithNIE] = useState(false);
  const [nie, setNie] = useState("");

  const debounce = useDebounce(search, 500);

  const { data: platforms } = useQuery({
    queryKey: ["platform"],
    keepPreviousData: true,
    queryFn: () => getPlatform(),
  });

  const { isFetching: isNieLoading } = useQuery({
    queryKey: ["nie", debounce],
    queryFn: () => getNIEElasticSearch(debounce, FETCH_LIMIT, 1),
    keepPreviousData: true,
    onSuccess: (data) => setNie(data.data),
    enabled: open,
  });

  const { data } = useQuery({
    queryKey: ["nie_detail", nieId, bpomId],
    queryFn: () => getNieDetail(nieId, bpomId),
    keepPreviousData: true,
    enabled: !!nieId && !!bpomId,
  });

  const { data: packaging } = useQuery({
    queryKey: ["packaging", data],
    queryFn: () => getPackaging(),
    keepPreviousData: true,
  });

  const { mutate: mutateCreateProduct, isLoading: isLoadingCreate } =
    useMutation({
      mutationFn: createProduct,
      onSuccess: () => handleClose(),
      onError: (err) => setErrorMessage(err.message.id),
    });

  const formik = useFormik({
    initialValues: {
      is_from_po: true,
      nie_number:
        data && data !== undefined && isCreateProductWithNIE
          ? data.nie_number
          : search,
      principal_id:
        data && data !== undefined && isCreateProductWithNIE
          ? data.principal_id
          : 0,
      category_id: [155],
      label_id:
        data &&
        data !== undefined &&
        data?.label_id !== 0 &&
        isCreateProductWithNIE
          ? data.label_id
          : 0,
      product_name:
        data && data !== undefined && isCreateProductWithNIE
          ? data.product_name
          : "",
      product_image:
        data && data !== undefined && isCreateProductWithNIE ? data.media : [],
      product_description: {
        indication:
          data &&
          data !== undefined &&
          isCreateProductWithNIE &&
          data?.indication !== null
            ? data.indication
            : "",
        composition:
          data &&
          data !== undefined &&
          isCreateProductWithNIE &&
          data?.usage !== null
            ? data.composition
            : "",
        dosage:
          data &&
          data !== undefined &&
          isCreateProductWithNIE &&
          data?.dosage !== null
            ? data.dosage
            : "-",
        usage:
          data &&
          data !== undefined &&
          isCreateProductWithNIE &&
          data.usage !== null
            ? data.usage
            : "-",
        contraindication:
          data &&
          data !== undefined &&
          isCreateProductWithNIE &&
          data?.contraindication !== null
            ? data.contraindication
            : "",
        side_effect:
          data &&
          data !== undefined &&
          isCreateProductWithNIE &&
          data?.side_effect !== null
            ? data.side_effect
            : "",
        storage_description:
          data &&
          data !== undefined &&
          isCreateProductWithNIE &&
          data?.storage_description !== null
            ? data.storage_description
            : "",
      },
      shelf: "",
      require_prescription:
        data && data !== undefined && isCreateProductWithNIE
          ? data.require_prescription
          : false,
      packaging: {
        type_box:
          data && data !== undefined && isCreateProductWithNIE
            ? data.packaging?.premier.replace(/\d+/g, "").trim()
            : "",

        total_box: 1,

        type_strip:
          data && data !== undefined && isCreateProductWithNIE
            ? data.packaging?.skunder.replace(/\d+/g, "").trim()
            : "",

        total_strip:
          data &&
          data !== undefined &&
          /\d/.test(data.packaging?.skunder) &&
          isCreateProductWithNIE
            ? Number(data.packaging?.skunder.match(/\d/g).join(""))
            : 1,

        type_unit:
          data && data !== undefined && isCreateProductWithNIE
            ? data.packaging?.tersier
                .replace(/[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?\s*/g, "")
                .trim()
            : "",

        total_unit:
          data &&
          data !== undefined &&
          /\d/.test(data.packaging?.tersier) &&
          isCreateProductWithNIE
            ? parseInt(data.packaging?.tersier.match(/\d+/).join(""), 10)
            : 1,
      },
      price: {
        platform_price: [],
        pharmacy_net_price: 0,
        pharmacy_net_price_discount: 0,
        pharmacy_net_price_ppn: false,
        pharmacy_net_price_ppn_value: 0,
        pharmacy_net_price_real: 0,
        sell_price: 0,
        sell_price_sekunder: 0,
        sell_price_tersier: 0,
        price_grosir: [{ minimum: 0, maximum: 0, price_grosirs: 0 }],
      },
      reminder_stock: 5,
      status_reminder_stock: true,
      reminder_uom: 0,
      batch: [{ quantity: 0, number_batch: "", expired_date: "" }],
      barcode_number: "",
      warehouse: false,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      mutateCreateProduct({
        ...values,
        price: {
          ...values.price,
          pharmacy_net_price: pharmacyNetPriceFinal(
            values.price.pharmacy_net_price_discount,
            values.price.pharmacy_net_price_real,
            values.price.pharmacy_net_price_ppn_value
          ),
        },
        product_description: {
          indication:
            values.product_description.indication === ""
              ? "-"
              : values.product_description.indication,
          composition:
            values.product_description.composition === ""
              ? "-"
              : values.product_description.composition,
          dosage:
            values.product_description.dosage === ""
              ? "-"
              : values.product_description.dosage,
          usage:
            values.product_description.usage === ""
              ? "-"
              : values.product_description.usage,
          contraindication:
            values.product_description.contraindication === ""
              ? "-"
              : values.product_description.contraindication,
          side_effect:
            values.product_description.side_effect === ""
              ? "-"
              : values.product_description.side_effect,
          storage_description:
            values.product_description.storage_description === ""
              ? "-"
              : values.product_description.storage_description,
        },
        batch: values.batch.map((data) => {
          if (qtyType === values.packaging.type_unit) {
            return {
              quantity: data.quantity,
              number_batch: data.number_batch,
              expired_date: data.expired_date,
            };
          }
          if (qtyType === values.packaging.type_strip) {
            if (IGNORE_TYPE.includes(values.packaging.type_unit)) {
              return {
                quantity: data.quantity * 1,
                number_batch: data.number_batch,
                expired_date: data.expired_date,
              };
            } else {
              return {
                quantity: data.quantity * values.packaging.total_unit,
                number_batch: data.number_batch,
                expired_date: data.expired_date,
              };
            }
          }
          if (qtyType === values.packaging.type_box) {
            if (IGNORE_TYPE.includes(values.packaging.type_unit)) {
              return {
                quantity: data.quantity * values.packaging.total_strip * 1,
                number_batch: data.number_batch,
                expired_date: data.expired_date,
              };
            } else {
              return {
                quantity:
                  data.quantity *
                  values.packaging.total_strip *
                  values.packaging.total_unit *
                  1,
                number_batch: data.number_batch,
                expired_date: data.expired_date,
              };
            }
          }
          return {
            quantity: data.quantity,
            number_batch: data.number_batch,
            expired_date: data.expired_date,
          };
        }),
      });
    },
  });

  const intersection = new Set(
    formik.values.price.platform_price.map(({ platform_id }) => platform_id)
  );
  const result = platforms?.filter(({ id }) => !intersection.has(id));

  const pharmacyNetPriceFinal = (
    discountPercent,
    pharmacyNetPriceReal,
    taxValue
  ) => {
    if (!pharmacyNetPriceReal) return 0;
    let discount = pharmacyNetPriceReal * (discountPercent / 100);
    let value = (pharmacyNetPriceReal - discount) * taxValue;

    return pharmacyNetPriceReal - discount + value;
  };

  return (
    <>
      <Modal open={openModal} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            maxWidth: "75%",
            overflowY: "auto",
            overflowX: "wrap",
            maxHeight: "80vh",
            padding: 0,
          }}
          className="text-left text-[12px] w-3/4 lg:w-1/3"
        >
          <form onSubmit={formik.handleSubmit}>
            <div className="flex items-center px-8 py-4 w-full text-center">
              <p className="w-full text-[20px] font-bold">Tambah Produk</p>
              <RxCross1
                className="cursor-pointer hover:text-red-500 w-fit"
                onClick={handleClose}
              />
            </div>

            <hr className="border-b-2 mb-4" />

            <div className="px-8 mb-4">
              <div
                className="px-3 py-2 rounded-md border text-gray-500 cursor-pointer hover:bg-gray-100 flex items-center gap-2 mb-4"
                onClick={() => {
                  setOpen(true);
                  setSearch("");
                }}
              >
                <SearchIcon />
                {nieNumber !== "" ? <p>{nieNumber}</p> : <p>Nomor Izin Edar</p>}
              </div>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Nama product"
                name="product_name"
                variant="outlined"
                disabled={nieNumber === ""}
                value={formik.values.product_name}
                onChange={(e) =>
                  formik.setFieldValue("product_name", e.target.value)
                }
                sx={{ marginBottom: 2 }}
              />
              <div className="flex w-full justify-end items-end">
                <div className="w-2/3">
                  <p className="font-bold text-[14px]">Kemasan</p>
                  <div className="grid grid-cols-2 gap-4">
                    <FormControl fullWidth className="mt-3">
                      <InputLabel>Primer</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Primer"
                        disabled={nieNumber === ""}
                        value={formik.values.packaging.type_box.toUpperCase()}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "packaging.type_box",
                            e.target.value
                          )
                        }
                      >
                        {!packaging?.find(
                          (pack) =>
                            pack.packaging_id === 1 &&
                            pack.name.toUpperCase() ===
                              formik.values.packaging.type_box.toUpperCase()
                        ) ? (
                          <MenuItem
                            disabled
                            value={formik.values.packaging.type_box.toUpperCase()}
                            sx={{ display: "none" }}
                          >
                            {formik.values.packaging.type_box.toUpperCase()}
                          </MenuItem>
                        ) : null}
                        {packaging && packaging !== undefined ? (
                          packaging
                            .filter((pack) => {
                              return pack.packaging_id === 1;
                            })
                            .map((pack, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={pack.name.toUpperCase()}
                                >
                                  {pack.name.toUpperCase()}
                                </MenuItem>
                              );
                            })
                        ) : (
                          <MenuItem
                            disabled
                            value=""
                            sx={{ display: "none" }}
                          ></MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <TextField
                      id="premier"
                      label="Qty"
                      variant="outlined"
                      className="mt-3"
                      disabled
                      value={formik.values.packaging.total_box}
                      onChange={(e) => {
                        let numericValue = e.target.value;
                        if (numericValue === "") {
                          numericValue = "0";
                        }
                        formik.setFieldValue(
                          "packaging.total_box",
                          parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                        );
                      }}
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <FormControl fullWidth className="mt-3">
                      <InputLabel>Sekunder</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Sekunder"
                        disabled={nieNumber === ""}
                        value={formik.values.packaging.type_strip.toUpperCase()}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "packaging.type_strip",
                            e.target.value
                          )
                        }
                      >
                        {!packaging?.find(
                          (pack) =>
                            pack.packaging_id === 2 &&
                            pack.name.toUpperCase() ===
                              formik.values.packaging.type_strip.toUpperCase()
                        ) ? (
                          <MenuItem
                            disabled
                            value={formik.values.packaging.type_strip.toUpperCase()}
                            sx={{ display: "none" }}
                          >
                            {formik.values.packaging.type_strip.toUpperCase()}
                          </MenuItem>
                        ) : null}
                        {packaging && packaging !== undefined ? (
                          packaging
                            .filter((pack) => {
                              return pack.packaging_id === 2;
                            })
                            .map((pack, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={pack.name.toUpperCase(0)}
                                >
                                  {pack.name.toUpperCase()}
                                </MenuItem>
                              );
                            })
                        ) : (
                          <MenuItem
                            disabled
                            value=""
                            sx={{ display: "none" }}
                          ></MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <TextField
                      id="sekunder"
                      label="Qty"
                      variant="outlined"
                      disabled={nieNumber === ""}
                      className="mt-3"
                      value={formik.values.packaging.total_strip}
                      onChange={(e) => {
                        let numericValue = e.target.value;
                        if (numericValue === "") {
                          numericValue = "0";
                        }
                        formik.setFieldValue(
                          "packaging.total_strip",
                          parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                        );
                      }}
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <FormControl fullWidth className="mt-3">
                      <InputLabel id="demo-simple-select-label">
                        Tersier
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        disabled={nieNumber === ""}
                        label="Tersier"
                        value={formik.values.packaging.type_unit.toUpperCase()}
                        onChange={(e) =>
                          formik.setFieldValue(
                            "packaging.type_unit",
                            e.target.value
                          )
                        }
                      >
                        {!packaging?.find(
                          (pack) =>
                            pack.packaging_id === 3 &&
                            pack.name.toUpperCase() ===
                              formik.values.packaging.type_unit.toUpperCase()
                        ) ? (
                          <MenuItem
                            disabled
                            value={formik.values.packaging.type_unit.toUpperCase()}
                            sx={{ display: "none" }}
                          >
                            {formik.values.packaging.type_unit.toUpperCase()}
                          </MenuItem>
                        ) : null}
                        {packaging && packaging !== undefined ? (
                          packaging
                            .filter((pack) => {
                              return pack.packaging_id === 3;
                            })
                            .map((pack, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={pack.name.toUpperCase()}
                                >
                                  {pack.name.toUpperCase()}
                                </MenuItem>
                              );
                            })
                        ) : (
                          <MenuItem
                            disabled
                            value=""
                            sx={{ display: "none" }}
                          ></MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    <TextField
                      id="outlined-basic"
                      label="Qty"
                      variant="outlined"
                      className="mt-3"
                      disabled={nieNumber === ""}
                      value={formik.values.packaging.total_unit}
                      onChange={(e) => {
                        let numericValue = e.target.value;
                        if (numericValue === "") {
                          numericValue = "0";
                        }
                        formik.setFieldValue(
                          "packaging.total_unit",
                          parseInt(numericValue.replace(/[^0-9]/g, ""), 10)
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <p className="mt-2 text-red-500">{errorMessage}</p>
            </div>

            <hr className="border-b-2 mb-4" />

            <div className="px-8 mb-4 flex gap-2 items-center">
              <button
                type="button"
                className="w-full p-2 rounded-md border-2 border-black hover:bg-gray-300"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={
                  formik.values.packaging.total_box === 0 ||
                  formik.values.packaging.type_box === "" ||
                  formik.values.packaging.total_strip === 0 ||
                  formik.values.packaging.type_strip === "" ||
                  formik.values.packaging.total_unit === 0 ||
                  formik.values.packaging.type_unit === "" ||
                  isLoadingCreate
                }
                className="w-full p-2 rounded-md bg-blue-500 text-white hover:bg-gray-300 hover:border-gray-300 disabled:bg-gray-300"
              >
                {isLoadingCreate ? <CircularProgress size={10} /> : "Save"}
              </button>
            </div>
          </form>
        </Box>
      </Modal>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth={"sm"}
      >
        {/* <DialogTitle className="flex justify-center">
          <button
            type="button"
            onClick={() => setOpen(false)}
            className="mr-[10px]"
          >
            <ClearIcon sx={{ color: "black" }} />
          </button>
          <TextField
            fullWidth
            focused
            label="Nomor Izin Edar"
            onChange={(e) => setSearch(e.target.value.toUpperCase())}
            value={search}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            inputProps={{
              style: { textTransform: "uppercase" },
            }}
          />
        </DialogTitle> */}
        <DialogContent sx={{ height: 525, padding: 0 }}>
          <div className="flex items-center px-8 py-4 w-full text-center">
            <p className="w-full text-[20px] font-bold">Pilih Produk</p>
            <RxCross1
              className="cursor-pointer hover:text-red-500 w-fit text-[18px]"
              onClick={() => setOpen(false)}
            />
          </div>

          <hr className="mb-4" />
          <div className="px-8">
            <TextField
              fullWidth
              focused
              placeholder="Cari produk"
              onChange={(e) => setSearch(e.target.value.toUpperCase())}
              value={search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                style: { textTransform: "uppercase" },
              }}
              sx={{ marginBottom: 2 }}
            />

            {isNieLoading ? (
              <div className="flex items-center justify-center h-full">
                <CircularProgress />
              </div>
            ) : (
              <>
                {nie && nie !== undefined && nie.length > 0
                  ? nie.map((data) => (
                      <div
                        className="mt-3 flex flex-col shadow-md rounded-xl p-3 border-2 justify-start cursor-pointer"
                        key={data.id}
                        onClick={() => {
                          setNieId(data.id);
                          setBpomId(data.product_bpom_id);
                          setNieNumber(data.nie_number);
                          setOpen(false);
                          setIsCreateProductWithNIE(true);
                        }}
                      >
                        <p className="font-bold text-[14px] mb-2">
                          {data.product_name}
                        </p>
                        <p className="">{data.kemasan}</p>
                      </div>
                    ))
                  : null}
                {nie.length === 0 ? (
                  <div
                    className="mt-3 flex flex-col shadow-md rounded-xl p-3 border-2 justify-start cursor-pointer text-bold"
                    onClick={() => {
                      setOpen(false);
                      setIsCreateProductWithNIE(false);
                      setNieNumber(search);
                    }}
                  >
                    <p className="font-bold">{search}</p>
                  </div>
                ) : null}
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalAddProduct;
