import React, { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { Snackbar, IconButton, CircularProgress } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import Cookies from "js-cookie";
import cookie from "js-cookie";
import Logo from "../../../assets/icons/olinlogo.png";
import Input from "../../common/particles.jsx/Input";
import Carousel from "../../common/particles.jsx/Carousel";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HOC from "../../HOC";
import { useDispatch } from "react-redux";
import { logout, setUser } from "../../../redux/features/auth/authSlice";
import { checkStatusByEmail, login } from "../../../services/authApi";
import SendbirdChat from "@sendbird/chat";
import { GroupChannelModule } from "@sendbird/chat/groupChannel";

const EmailLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const userId = localStorage.getItem("company_id");
  const fullname = localStorage.getItem("company_name");
  const profileImg = localStorage.getItem("img_profile");

  const [statusCode, setStatusCode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(true);

  const { mutate: mutateCheckStatusByEmail } = useMutation({
    mutationFn: checkStatusByEmail,
    onSuccess: (data) => {
      if (
        data.status_code === 10 ||
        data.status_code === 11 ||
        data.status_code_user === 4
      ) {
        setStatusCode(true);
        setShowPassword(false);
        setErrorMessage(data.status);
      } else {
        setStatusCode(false);
        setShowPassword(true);
      }
    },
    onError: (err) => {
      setStatusCode(true);
      setErrorMessage(err.message.id);
    },
  });

  const {
    mutate: mutateLogin,
    isLoading: isLoginLoading,
    isError: isLoginError,
  } = useMutation({
    mutationFn: login,
    onSuccess: (data) => {
      if (data.detail === undefined) return;
      dispatch(
        setUser({
          accessTokenOfficeOlin: data.detail.token.access,
          accessExpired: data.detail.token.access_expired,
          renewTokenOfficeOlin: data.detail.token.renew,
          renewExpired: data.detail.token.renew_expired,
          img_profile: data.detail.foto_profile,
          name: data.detail.nama_lengkap,
          company_name: data.detail.company_name,
          job: data.detail.title_name,
          status_code: data.detail.status_code,
          title: data.detail.title,
          company_id: data.detail.company_id,
          roles: data.detail.roles,
          package: data.detail.package,
          status_code_user: data.detail.status_code_user,
          status_code: data.detail.status_code,
        })
      );
      if (Cookies.get("accessTokenOfficeOlin") !== undefined || null || "") {
        navigate("/home");
      } else {
        navigate("/login");
      }
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      mutateLogin(values);
    },
  });

  const handleClose = (_, reason) => {
    if (reason === "clickaway") return;
    setStatusCode(false);
    setErrorMessage("");
  };

  const sb = SendbirdChat.init({
    appId: process.env.REACT_APP_SENDBIRD_APP_ID,
    modules: [new GroupChannelModule()],
  });

  async function connectToSendbird(currentUserId, currentRole) {
    try {
      await sb.connect(currentUserId);

      const params = {
        nickname: fullname || `User ${currentUserId}`,
        profileUrl: profileImg === null ? "" : profileImg,
      };

      await sb.updateCurrentUserInfo(params);
      const data = {
        role: "seller",
      };

      await sb.currentUser.createMetaData(data);
    } catch (error) {
      console.error("Failed to connect:", error);
    }
  }

  useEffect(() => {
    const storedUserId = userId;
    const storedRole = "seller";
    if (storedUserId) {
      connectToSendbird(storedUserId, storedRole).then(() => {});
    }
  }, [userId, fullname, profileImg]);

  // Remove cookie on loading screen
  useEffect(() => {
    if (
      (pathname === "/login" || pathname === "/") &&
      !window.location.href.includes("?")
    ) {
      window.location.href = `${pathname}?nocache=${Date.now()}`;
      dispatch(logout());
    }
  }, [pathname]);

  useEffect(() => {
    const form = document.querySelector("form");
    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
      }
    };
    form.addEventListener("keydown", handleKeyDown);
    return () => {
      form.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="bg-white ">
      <div className="flex flex-col-reverse lg:flex-row justify-center h-screen">
        <div className="order-first md:order-last bg-cover lg:block lg:w-2/3">
          <div className="flex items-center h-full px-20 bg-[#EAF3FE] bg-opacity-40">
            <div className="w-full my-10">
              <Carousel />
            </div>
          </div>
        </div>
        <div className="flex mt-20 w-full max-w-md px-6 mx-auto lg:w-2/6 ">
          <div className="flex-1">
            <div className="flex flex-col items-center justify-center text-center">
              <img
                src={Logo}
                alt=""
                className="mt-16 lg:my-0 w-[100px] lg:w-[200px]"
              />
              <h2 className="text-4xl font-bold text-center  text-black mt-10 lg:mt-28">
                Login to OLIN
              </h2>

              <p className="mt-3 text-gray-500 ">
                Sign in to access your account
              </p>
            </div>
            {isLoginLoading ? (
              <div className="flex justify-center items-center h-[47%] mb-[20px]">
                <CircularProgress />
              </div>
            ) : (
              <form onSubmit={formik.handleSubmit} id="form">
                <div className="mt-8">
                  <Input
                    name="email"
                    label="E-mail"
                    placeholder="Example@mail.com"
                    hasPassword={false}
                    passwordVisibility={passwordVisibility}
                    setPasswordVisibility={setPasswordVisibility}
                    value={formik.values.email}
                    onChange={(e) =>
                      formik.setFieldValue("email", e.target.value)
                    }
                    disabled={!statusCode && showPassword}
                  />
                  {showPassword ? (
                    <p
                      className="text-end text-blue-400 hover:cursor-pointer"
                      onClick={() => {
                        setShowPassword(false);
                      }}
                    >
                      Ubah
                    </p>
                  ) : null}
                  <div>
                    <>
                      {!statusCode && showPassword ? (
                        <>
                          <div className="flex justify-between w-full items-center">
                            <Input
                              name="password"
                              label="Password"
                              hasPassword={true}
                              passwordVisibility={passwordVisibility}
                              setPasswordVisibility={setPasswordVisibility}
                              placeholder="Password anda"
                              value={formik.values.password}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "password",
                                  e.target.value
                                );
                                setErrorMessage("");
                              }}
                            />
                          </div>
                          {isLoginError ? (
                            <p className="text-sm text-red-500">
                              {errorMessage}
                            </p>
                          ) : null}

                          <div>
                            <p className="text-red-500 hover:underline cursor-pointer text-end text-sm mt-2">
                              <Link to="/forgot-password">Lupa password?</Link>
                            </p>
                          </div>
                        </>
                      ) : null}
                    </>
                  </div>
                  <div className="mt-6">
                    {!statusCode && showPassword ? (
                      <button
                        className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md  focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50 disabled:bg-gray-300"
                        type="submit"
                        disabled={formik.values.password === ""}
                      >
                        Sign-in
                      </button>
                    ) : (
                      <button
                        className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md  focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50 disabled:bg-gray-300"
                        type="button"
                        disabled={
                          formik.values.email === "" || undefined || null
                        }
                        onClick={() =>
                          mutateCheckStatusByEmail({
                            email: formik.values.email,
                          })
                        }
                      >
                        Selanjutnya
                      </button>
                    )}
                  </div>
                  {/* <div className="relative mt-6 h-px bg-gray-300">
                    <div className="absolute left-0 top-0 flex justify-center w-full -mt-2">
                      <span className="bg-white px-4 text-xs text-gray-500 uppercase">
                        Belum Registrasi?
                      </span>
                    </div>
                  </div> */}
                  {/* <div className="mt-6">
                    <button
                      className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-gray-300 rounded-md focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                      disabled
                    >
                      Daftar
                    </button>
                  </div> */}
                  <div className="flex flex-col xl:flex-row items-center justify-center gap-2 my-12">
                    <p className="font-medium text-center">
                      Pelajari tentang OLIN
                    </p>
                    <p className="border-1 text-center p-2 rounded-xl border-orange-500">
                      Unduh Aplikasi
                    </p>
                  </div>
                  {/* <div className="mt-6 text-sm text-center text-gray-400">
                    Don&#x27;t have an account yet?{" "}
                    <p className="text-blue-500 underline focus:outline-none hover:underline focus:underline hover:underline">
                      Sign up
                    </p>
                    .
                  </div> */}
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
      <Snackbar
        open={statusCode}
        autoHideDuration={5000}
        onClose={() => {
          setStatusCode(false);
          setErrorMessage("");
        }}
        message={errorMessage}
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </div>
  );
};

export default HOC(EmailLogin, "Login-required");
