import React, { useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { logout, renewTokenUser } from "./redux/features/auth/authSlice";
import { useDispatch } from "react-redux";
import { useServiceWorker } from "./useServiceWorker";
import cookie from "js-cookie";
// =========================== Pages =========================== //

// Login & Forgot Password
import EmailLogin from "./components/screen/login/EmailLogin";
import ForgotPassword from "./components/screen/forgotPassword/ForgotPassword";
import ForgotPasswordLogin from "./components/screen/forgotPassword/ForgotPasswordLogin";
import CreateNewPassword from "./components/screen/forgotPassword/CreateNewPassword";
import ForgotPasswordContext from "./components/context/ForgotPasswordProvider";

// Profile
import AccountProfilePage from "./pages/profile/AccountProfilePage";
import AccountProfileDetailPage from "./pages/profile/AccountProfileDetailPage";
import RequestPasswordChangePage from "./pages/profile/passwordChange/RequestPasswordChangePage";
import RequestEmailChangePage from "./pages/profile/EmailChange/RequestEmailChangePage";
import RequestPhoneChange from "./components/screen/profile/PhoneChange/RequestPhoneChange";
import VerificationMethodPage from "./pages/profile/VerificationMethodPage";
import PaymentSuccess from "./components/screen/profile/PaymentSuccess";

import DetailUser from "./components/screen/userManagement/DetailUser";

import SubscriptionPage from "./pages/profile/SubscriptionPage";
import CheckoutSubscriptionPage from "./pages/profile/CheckoutSubscriptionPage";
import SubscriptionPurchasePage from "./pages/profile/SubscriptionPurchasePage";
import InvoiceSubscriptionPage from "./pages/profile/InvoiceSubscriptionPage";

import SuccessPage from "./components/screen/profile/SuccessPage";

// Inventory
import ProductActivePage from "./pages/inventory/produkAktif/ProductActivePage";
import ProductHabisPage from "./pages/inventory/produkHabis/ProductHabisPage";
import ProductHoldPage from "./pages/inventory/produkHold/ProductHoldPage";
import ProductExpiredPage from "./pages/inventory/produkExpired/ProductExpiredPage";
import ProductInactivePage from "./pages/inventory/produkInactive/ProductInactivePage";
import DetailsProductActive from "./pages/inventory/produkAktif/DetailsProductActive";
import UserManagement from "./pages/userManagement/UserManagement";
import TambahPengguna from "./pages/userManagement/TambahPengguna";
import DetailUserPage from "./pages/userManagement/DetailUserPage";
import PaymentMethod from "./pages/settings/PaymentMethod";
import InvoiceSetting from "./pages/settings/InvoiceSetting";
import Reporting from "./pages/reporting/Reporting";
import ReportingProfitnLossPage from "./pages/reporting/ReportingProfitnLoss/ReportingProfitnLossPage";
import ReportingProductPage from "./pages/reporting/LogShift/ProductReportingPage";
import Customer from "./pages/customer/Customer";
import DetailCustomer from "./pages/customer/DetailCustomer";
import Discount from "./pages/discount/Discount";
import AddDiscount from "./pages/discount/AddDiscount";
import DetailDiscount from "./pages/discount/DetailDiscount";
import ProductActiveProvider from "../src/components/context/ProductActiveProvider";
import UpdateStockActive from "./pages/inventory/produkAktif/UpdateStockActive";
import AddProductActive from "./pages/inventory/produkAktif/AddProductActive";
import ImportActiveProduct from "./pages/inventory/produkAktif/ImportActiveProduct";
import ExportProductPage from "./pages/inventory/produkAktif/ExportProductPage";
import DetailLogShiftPage from "./pages/reporting/LogShift/DetailLogShiftPage";
import PrintCreatePurchasePage from "./pages/inventory/produkHabis/PrintCreatePurchase";
import PrintReturPage from "./pages/inventory/produkExpired/PrintReturPage";
import StockAdjustmentPage from "./pages/inventory/StockAdjustment/StockAdjustmentPage";
import CreateStockAdjustmentPage from "./pages/inventory/StockAdjustment/CreateAdjustmentStock";
import StockOpnamePage from "./pages/inventory/StockOpname/StockOpnamePage";
import StockOpnameRecapPage from "./pages/inventory/StockOpname/StockOpnameRecapPage";
import CreateStockOpnamePage from "./pages/inventory/StockOpname/CreateStockOpnamePage";
import CardStockPage from "./pages/inventory/CardStock/CardStockPage";
import CardStockNewPage from "./pages/inventory/CardStock/CardStockNewPage";
import CardStockDetailPage from "./pages/inventory/CardStock/CardStockDetailPage";
import CardStockDetailNewPage from "./pages/inventory/CardStock/CardStockDetailNewPage";
import ConsignmentPage from "./pages/inventory/Consignment/ConsignmentPage";
import AddNewConsignmentPage from "./pages/inventory/Consignment/AddNewConsignmentPage";
import DetailConsignmentPage from "./pages/inventory/Consignment/DetailConsignmentPage";
import DetailConsignmentProductPage from "./pages/inventory/Consignment/DetailConsignmentProductPage";
import EditConsignmentProductPage from "./pages/inventory/Consignment/EditConsignmentProductPage";
import EditPriceonsignmentProductPage from "./pages/inventory/Consignment/EditPriceConsignmentProductPage";
import UpdateProductHabis from "./pages/inventory/produkHabis/UpdateProductHabis";
import InventorySummaryPage from "./pages/inventory/InventorySummary/InventorySummaryPage";
import InventorySummaryDetailPage from "./pages/inventory/InventorySummary/InventorySummaryDetailPage";
import SyncGoodDoctorPage from "./pages/sinkronisasi/SyncGoodDoctorPage.jsx";

//Operational Cost
import OperationalCostPage from "./pages/reporting/OperationalCost/OperationalCostPage";
import CreateOperationalCostPage from "./pages/reporting/OperationalCost/CreateOperationalCostPage";

// Billing
import UnpaidBillingPage from "./pages/billing/UnpaidBillingPage";
import PaidBillingPage from "./pages/billing/PaidBillingPage";
import OverdueBillingPage from "./pages/billing/OverdueBillingPage";
import WaitingBillingPage from "./pages/billing/WaitingBillingPage";
import DetailBillingPage from "./pages/billing/DetailBillingPage";
import SummaryBillingPage from "./pages/billing/SummaryBillingPage";
import PaymentStatus from "./pages/billing/PaymentStatus";
import PaidInvoicePage from "./pages/billing/PaidInvoicePage";
import DetailBillingInvoicePage from "./pages/billing/DetailBillingInvoicePage";
import EditBillingManualDraftPage from "./pages/billingManual/EditBillingManualDraftPage.jsx";

// Retur
import ReturProductPage from "./pages/retur/ReturProductPage.jsx";
import CreateReturProductPage from "./pages/retur/CreateReturProductPage.jsx";
import ReturDetailProductPage from "./pages/retur/ReturDetailProductPage.jsx";

import ReturSellPage from "./pages/retur/ReturSellPage.jsx";
import CreateReturSellPage from "./pages/retur/CreateReturSellPage.jsx";
import ReturDetailSellPage from "./pages/retur/ReturDetailSellPage.jsx";

// Manual Billing
import BillingManualListPage from "./pages/billingManual/BillingManualListPage.jsx";
import CreateBillingManualPage from "./pages/billingManual/CreateBillingManualPage.jsx";
import BillingManualDetailPOPage from "./pages/billingManual/BillingManualDetailPOPage.jsx";
import BillingManualDetailPage from "./pages/billingManual/BillingManualDetailPage.jsx";

//Purchase
import Purchase from "./pages/purchase/Purchase";
import CategoryPage from "./pages/purchase/CategoryPage";
import PrincipalPage from "./pages/purchase/PrincipalPage.jsx";
import SelectedPrincipalPage from "./pages/purchase/SelectedPrincipalPage";
import SelectedCategoryPage from "./pages/purchase/SelectedCategoryPage";
import DetailPurchaseProductPage from "./pages/purchase/DetailProductPurchasePage";
import CartDetailsPage from "./pages/purchase/CartDetailsPage";
import CheckoutPurchasePage from "./pages/purchase/CheckoutPurchasePage";
import PurchasePaymentPage from "./pages/purchase/PurchasePaymentPage";
import ListTransactionPurchasePage from "./pages/purchase/ListTransactionPurchasePage";
import WaitingPaymentPurchasePage from "./pages/purchase/WaitingPaymentPurchasePage";
import DetailTransactionPage from "./pages/purchase/DetailTransactionPage";
import ComplainPurchasePage from "./pages/purchase/ComplainPurchasePage";
import InvoiceTransactionPurchasePage from "./pages/purchase/InvoiceTransactionPurchasePage";

//Order
import OrderPage from "./pages/Order/OrderPage";
import OrderDetailPage from "./pages/Order/OrderDetailPage";

import NotFound from "./pages/NotFound";
import FreezeAccount from "./pages/FreezeAccount.jsx";

import Dashboard from "./pages/dashboard/Dashboard";
import HistoryTransactionDashboardPage from "./pages/dashboard/HistoryTransactionDashboardPage";
import InvoiceTransactionPage from "./pages/dashboard/InvoiceTransactionPage";
import DetailPaymentDashboardPage from "./pages/dashboard/DetailPaymentDashboardPage";
import EditDetailProductInactive from "./components/screen/inventory/productInactive/EditDetailProductInactive";
import EditDetailsProductActive from "./pages/inventory/produkAktif/EditDetailsProductActive";
import EditDetailProductStockOut from "./components/screen/inventory/productHabis/EditDetailProductStockOut";
import EditDetailProductHold from "./components/screen/inventory/productHold/EditDetailProductHold";
import EditDetailProductHoldPage from "./pages/inventory/produkHold/EditDetailProductHoldPage";
import DetailsProductExpiredPage from "./pages/inventory/produkExpired/DetailsProductExpiredPage";
import DetailsProductHabisPage from "./pages/inventory/produkHabis/DetailsProductHabisPage";
import DetailsProductHoldPage from "./pages/inventory/produkHold/DetailsProductHoldPage";
import DetailsProductInactivePage from "./pages/inventory/produkInactive/DetailsProductInactivePage";
import LogShiftPage from "./pages/reporting/LogShift/LogShiftPage";
import UpdateSeveralStockActive from "./pages/inventory/produkAktif/UpdateSeveralStockActive";
import CreatePurchasePage from "./pages/inventory/produkHabis/CreatePurchasePage";
import ReturPage from "./pages/inventory/produkExpired/ReturPage";

import ProductHabisProvider from "./components/context/ProductHabisProvider";
import ProductExpiredProvider from "./components/context/ProductExpiredProvider";
import VerificationMethodProvider from "./components/context/VerificationMethodProvider";
import DetailInvoiceReportSalesPage from "./pages/reporting/DetailInvoiceReportSalesPage";
import PaymentStatusProvider from "./components/context/PaymentStatusProvider";
import SelectedProductProvider from "./components/context/SelectedProductProvider";
import VoucherProductProvider from "./components/context/VoucherProductProvider";

// Purchase Order
import PurchaseOrder from "./pages/purchaseOrder/PurchaseOrder";
import ImportPurchaseOrderPage from "./pages/purchaseOrder/ImportPurchaseOrderPage";
import CreatePurchaseOrder from "./pages/purchaseOrder/CreatePurchaseOrder";
import DetailPurchaseOrderPage from "./pages/purchaseOrder/DetailPurchaseOrder";
import DetailInvoicePurchaseOrder from "./pages/purchaseOrder/DetailInvoicePurchaseOrder.jsx";

import CustomerInvoicePage from "./pages/invoice/CustomerInvoicePage";
import InvoicePage from "./pages/invoice/InvoicePage";
import SettingPromoPage from "./pages/invoice/SettingPromoPage";
import CreateCustomerInvoicePage from "./pages/invoice/CreateCustomerInvoicePage";
import CreateInvoicePage from "./pages/invoice/CreateInvoicePage";
import DetailInvoicePage from "./pages/invoice/DetailInvoicePage";
import CreatePromoInvoicePage from "./pages/invoice/CreatePromoInvoicePage";
import SettingInvoicePage from "./pages/invoice/SettingInvoicePage";
import DetailCustomerInvoicePage from "./pages/invoice/DetailCustomerInvoicePage";

//Chat
import ChatPage from "./pages/Chat/Chat";

import DetailOrder from "./components/screen/customer/DetailOrder";
import ChatboxProvider from "./components/context/ChatboxProvider";

// Patient Care
import PatientCareServicesPage from "./pages/patientCare/PatientCareServicesPage";
import PatientCareDoctorDatabasePage from "./pages/patientCare/PatientCareDoctorDatabasePage.jsx";
import PatientCarePatientDatabasePage from "./pages/patientCare/PatientCarePatientDatabasePage.jsx";
import InitialAnalysisListPage from "./pages/patientCare/initialAnalysis/InitialAnalysisListPage.jsx";
import AddInitialAnalysisListPage from "./pages/patientCare/initialAnalysis/AddInitialAnalysisListPage.jsx";
import DoctorAnalysisListPage from "./pages/patientCare/doctorAnalysis/DoctorAnalysisListPage.jsx";
import AddDoctorAnalysisPage from "./pages/patientCare/doctorAnalysis/AddDoctorAnalysisPage.jsx";
import MedicalRecordListPage from "./pages/patientCare/medicalRecord/MedicalRecordListPage.jsx";
import DetailMedicalRecordPage from "./pages/patientCare/medicalRecord/DetailMedicalRecordPage.jsx";
import PrescriptionHistoryListPage from "./pages/patientCare/prescriptonHistory/PrescriptionHistoryListPage.jsx";
import DetailPrescriptionPage from "./pages/patientCare/prescriptonHistory/DetailPrescriptionPage.jsx";
import MakeCopyPrescriptionPage from "./pages/patientCare/prescriptonHistory/MakeCopyPrescriptionPage.jsx";

// Central Warehouse
import AllBillingManualPage from "./pages/billingManualCW/AllBillingManualPage";
import UnpaidBillingManualPage from "./pages/billingManualCW/UnpaidBillingManualPage";
import OverdueBillingManualPage from "./pages/billingManualCW/OverdueBillingManualPage";
import PaidBillingManualPage from "./pages/billingManualCW/PaidBillingManualPage";
import DraftBillingManualPage from "./pages/billingManualCW/DraftBillingManualPage";
import DetailBillingManualPage from "./pages/billingManualCW/DetailBillingManualPage";
import PoListBillingPage from "./pages/billingManualCW/PoListBillingPage";
import CreateDraftBillingPage from "./pages/billingManualCW/CreateDraftBillingPage";
import CreateBillingManualPageCW from "./pages/billingManualCW/CreateBillingManualPageCW";

import PurchaseOrderCW from "./pages/purchaseOrderCW/PurchaseOrderCW.jsx";
import CreatePurchaseOrderCW from "./pages/purchaseOrderCW/CreatePurchaseOrderCW.jsx";
import DetailPurchaseOrderCW from "./pages/purchaseOrderCW/DetailPurchaseOrderCW.jsx";

import InvoicePageCW from "./pages/invoiceCW/InvoicePageCW.jsx";
import CreateInvoicePageCW from "./pages/invoiceCW/CreateInvoicePageCW.jsx";
import DetailInvoicePageCW from "./pages/invoiceCW/DetailInvoicePageCW.jsx";

import AllocationListPage from "./pages/centralWarehouse/allocation/AllocationListPage.jsx";
import AddAllocationPage from "./pages/centralWarehouse/allocation/AddAllocationPage.jsx";
import DetailAllocationPage from "./pages/centralWarehouse/allocation/DetailAllocationPage.jsx";
import TransferListPage from "./pages/centralWarehouse/transfer/TransferListPage.jsx";
import ReturnWarehouseListPage from "./pages/centralWarehouse/returnWarehouse/ReturnWarehouseListPage.jsx";
import InboxWarehousePage from "./pages/centralWarehouse/inbox/InboxWarehousePage.jsx";
import DetailInboxWarehousePage from "./pages/centralWarehouse/inbox/DetailInboxWarehousePage.jsx";
import AddTransferPage from "./pages/centralWarehouse/transfer/AddTransferPage.jsx";
import DetailTransferPage from "./pages/centralWarehouse/transfer/DetailTransferPage.jsx";
import AddReturnWarehousePage from "./pages/centralWarehouse/returnWarehouse/AddReturnWarehousePage.jsx";
import DetailReturnWarehousePage from "./pages/centralWarehouse/returnWarehouse/DetailReturnWarehousePage.jsx";
import RequestAllocationListPage from "./pages/centralWarehouse/request/RequestAllocationListPage.jsx";
import RequestTransferListPage from "./pages/centralWarehouse/request/RequestTransferListPage.jsx";
import AddRequestTransferPage from "./pages/centralWarehouse/request/AddRequestTransferPage.jsx";
import AddRequestAllocationPage from "./pages/centralWarehouse/request/AddRequestAllocationPage.jsx";
import DetailRequestAllocationPage from "./pages/centralWarehouse/request/DetailRequestAllocationPage.jsx";
import DetailRequestTransferPage from "./pages/centralWarehouse/request/DetailRequestTransferPage.jsx";

// Sales
import Sales from "./pages/sales/Sales.jsx";
import DetailSales from "./pages/sales/DetailSales.jsx";

const App = () => {
  // const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  /**
   * DISABLED SERVICE WORKER ON 5/11/2024
   *
   * REASON:
   * 1. OLIN's PWA doesn't need to be available offline based on discussion
   * 2. The service worker fetches every 1s to check for updates which creates an
   * uneccessary big load on server
   *
   * To prevent service worker errors
   * the service worker is unregistered upon encounter on first render
   * */
  // useEffect(() => {
  //   if (showReload && waitingWorker) {
  //     navigator.serviceWorker.getRegistration().then((reg) => {
  //       if (reg) reg.unregister();
  //     });
  //   }
  // }, [waitingWorker, showReload]);

  // DEPRECATED FUNCTION: Reload page when new service worker is available
  // useEffect(() => {
  //   if (showReload && waitingWorker) {
  //     alert("A new version of this app is available. Please reload to update.");

  //     const registration = navigator.serviceWorker.getRegistration();
  //     console.log(registration);
  //     if (registration) {
  //       registration.unregister();
  //       window.location.reload(); // hard reload
  //     }
  //   }
  // }, [waitingWorker, showReload]);

  //Renew Token
  setInterval(() => {
    dispatch(renewTokenUser());
  }, 600000);

  useEffect(() => {
    const statusUser = localStorage.getItem("status_code_user");
    const statusCompany = localStorage.getItem("status_code");

    if (
      (!statusUser || !statusCompany) &&
      ![
        "/",
        "/login",
        "/forgot-password",
        "/forgot-password/new-password",
        "/forgot-password/login",
      ].includes(pathname)
    ) {
      dispatch(logout());
      navigate("/");
    }

    if (
      statusCompany &&
      (Number(statusCompany) === 3 || Number(statusCompany) === 2) &&
      !["/login", "/profile", "/subscription"].some((link) =>
        pathname.includes(link)
      )
    )
      navigate(`/no-access`);

    if (
      pathname === `/no-access` &&
      Number(statusCompany) !== 3 &&
      Number(statusCompany) !== 2
    )
      navigate(`/home`);
  }, [pathname]);

  return (
    <div>
      <Routes>
        {/* 404 */}
        <Route path="*" element={<NotFound />} />
        {/* Account Access Blocked Error */}
        <Route path="/no-access" element={<FreezeAccount />} />
        {/* Login & Forgot Password */}
        <Route path="/login" element={<EmailLogin />} />
        <Route path="/" element={<Navigate to="/login" />} />
        {/* Forgot password */}
        <Route
          path="/forgot-password"
          element={
            <ForgotPasswordContext>
              <ForgotPassword />
            </ForgotPasswordContext>
          }
        />
        <Route
          path="/forgot-password/new-password"
          element={
            <ForgotPasswordContext>
              <CreateNewPassword />
            </ForgotPasswordContext>
          }
        />
        <Route
          path="/forgot-password/login"
          element={<ForgotPasswordLogin />}
        />

        {/* Dashboard */}
        <Route path="/home" element={<Dashboard />} />
        <Route
          path="/history-dashboard"
          element={<HistoryTransactionDashboardPage />}
        />
        <Route
          path="/invoice-transaction/:id"
          element={<InvoiceTransactionPage />}
        />
        <Route
          path="/detail-transaction"
          element={<DetailPaymentDashboardPage />}
        />

        {/* Inventory - Inventory List */}
        <Route path="/inventory/produk-aktif" element={<ProductActivePage />} />
        <Route path="/inventory/produk-habis" element={<ProductHabisPage />} />
        <Route path="/inventory/produk-hold" element={<ProductHoldPage />} />
        <Route
          path="/inventory/produk-expired"
          element={<ProductExpiredPage />}
        />
        <Route
          path="/inventory/produk-inactive"
          element={<ProductInactivePage />}
        />
        <Route
          path="/inventory/produk-aktif/detail-product/:id"
          element={<DetailsProductActive />}
        />
        <Route
          path="/inventory/produk-expired/detail-product/:id"
          element={<DetailsProductExpiredPage />}
        />
        <Route
          path="/inventory/produk-inactive/detail-product/:id"
          element={<DetailsProductInactivePage />}
        />
        <Route
          path="/inventory/produk-stockout/detail-product/:id"
          element={<DetailsProductHabisPage />}
        />
        <Route
          path="/inventory/produk-hold/detail-product/:id"
          element={<DetailsProductHoldPage />}
        />
        <Route
          path="/inventory/produk-aktif/edit-product-details/:id"
          element={<EditDetailsProductActive />}
        />
        <Route
          path="/inventory/produk-inactive/edit-product-details/:id"
          element={<EditDetailProductInactive />}
        />
        <Route
          path="/inventory/produk-stockout/edit-product-details/:id"
          element={<EditDetailProductStockOut />}
        />
        <Route
          path="/inventory/produk-hold/edit-product-details/:id"
          element={<EditDetailProductHoldPage />}
        />
        <Route
          path="/inventory/update-stock"
          element={
            <ProductActiveProvider>
              <UpdateStockActive />
            </ProductActiveProvider>
          }
        />
        <Route
          path="/inventory/update-stock-habis"
          element={
            <ProductActiveProvider>
              <UpdateProductHabis />
            </ProductActiveProvider>
          }
        />
        <Route path="/inventory/tambah-produk" element={<AddProductActive />} />
        <Route
          path="/inventory/update-stock-several"
          element={<UpdateSeveralStockActive />}
        />
        <Route path="/inventory/export" element={<ExportProductPage />} />
        <Route path="/inventory/import" element={<ImportActiveProduct />} />
        <Route
          path="/inventory/create-purchase"
          element={
            <ProductHabisProvider>
              <CreatePurchasePage />
            </ProductHabisProvider>
          }
        />
        <Route
          path="/inventory/retur"
          element={
            <ProductExpiredProvider>
              <ReturPage />
            </ProductExpiredProvider>
          }
        />
        <Route
          path="/inventory/print-SP"
          element={
            <ProductHabisProvider>
              <PrintCreatePurchasePage />
            </ProductHabisProvider>
          }
        />
        <Route
          path="/inventory/print-retur"
          element={
            <ProductExpiredProvider>
              <PrintReturPage />
            </ProductExpiredProvider>
          }
        />

        {/* Inventory - Penyesuaian Stock */}
        <Route
          path="/inventory/stock-adjustment"
          element={<StockAdjustmentPage />}
        />
        <Route
          path="/inventory/stock-adjustment/create-adjustment"
          element={<CreateStockAdjustmentPage />}
        />

        {/* Inventory - Stock Opname */}
        <Route path="/inventory/stock-opname" element={<StockOpnamePage />} />
        <Route
          path="/inventory/stock-opname/stock-opname-recap/:psId"
          element={<StockOpnameRecapPage />}
        />
        <Route
          path="/inventory/stock-opname/create/:psId"
          element={<CreateStockOpnamePage />}
        />

        {/* Inventory - Kartu Stock */}
        <Route path="/inventory/card-stock" element={<CardStockNewPage />} />
        <Route
          path="/inventory/card-stock/card-stock-detail-new/:productId"
          element={<CardStockDetailNewPage />}
        />
        {/* <Route path="/inventory/card-stock" element={<CardStockPage />} />
        <Route
          path="/inventory/card-stock-detail/:productId"
          element={<CardStockDetailPage />}
        /> */}

        {/* Inventory - Inventory Summary */}
        <Route path="/inventory/summary" element={<InventorySummaryPage />} />
        <Route
          path="/inventory/summary/:productId/:companyId"
          element={<InventorySummaryDetailPage />}
        />

        {/* Inventory - Sinkronisasi Akun */}
        <Route
          path="/inventory/sinkronisasi/good-doctor"
          element={<SyncGoodDoctorPage />}
        />

        {/* Patient Care - RME */}
        <Route
          path="/patient-care/medical-record"
          element={<MedicalRecordListPage />}
        />
        <Route
          path="/patient-care/medical-record/detail/:id"
          element={<DetailMedicalRecordPage />}
        />

        {/* Patient Care - Patient DB */}
        <Route
          path="/patient-care/patient-database"
          element={<PatientCarePatientDatabasePage />}
        />

        {/* Patient Care - Doctor DB */}
        <Route
          path="/patient-care/doctor-database"
          element={<PatientCareDoctorDatabasePage />}
        />

        {/* Patient Care - Initial Analysis */}
        <Route
          path="/patient-care/initial-analysis"
          element={<InitialAnalysisListPage />}
        />
        <Route
          path="/patient-care/initial-analysis/create-analysis"
          element={<AddInitialAnalysisListPage />}
        />
        <Route
          path="/patient-care/initial-analysis/edit-analysis/:id"
          element={<AddInitialAnalysisListPage />}
        />
        <Route
          path="/patient-care/initial-analysis/detail-analysis/:id"
          element={<AddInitialAnalysisListPage isDetail={true} />}
        />

        {/* Patient Care - Doctor Analysis */}
        <Route
          path="/patient-care/doctor-analysis"
          element={<DoctorAnalysisListPage />}
        />
        <Route
          path="/patient-care/doctor-analysis/create-analysis"
          element={<AddDoctorAnalysisPage />}
        />
        <Route
          path="/patient-care/doctor-analysis/edit-analysis/:id"
          element={<AddDoctorAnalysisPage />}
        />
        <Route
          path="/patient-care/doctor-analysis/detail-analysis/:id"
          element={<AddDoctorAnalysisPage isDetail={true} />}
        />

        {/* Patient Care - Services */}
        <Route
          path="/patient-care/services"
          element={<PatientCareServicesPage />}
        />

        {/* Patient Care - Prescription History */}
        <Route
          path="/patient-care/prescription-history"
          element={<PrescriptionHistoryListPage />}
        />
        <Route
          path="/patient-care/prescription-history/detail/:id"
          element={<DetailPrescriptionPage />}
        />
        <Route
          path="/patient-care/prescription-history/make-copy/:id"
          element={<MakeCopyPrescriptionPage />}
        />

        {/* Central Warehouse - Billing */}
        <Route
          path="/central-warehouse/manual-billing/all-billing"
          element={<AllBillingManualPage isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/manual-billing/unpaid"
          element={<UnpaidBillingManualPage isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/manual-billing/overdue"
          element={<OverdueBillingManualPage isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/manual-billing/paid"
          element={<PaidBillingManualPage isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/manual-billing/draft"
          element={<DraftBillingManualPage isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/manual-billing/detail/:id"
          element={<DetailBillingManualPage isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/manual-billing/list"
          element={<PoListBillingPage isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/manual-billing/create-billing/:id/:flag"
          element={<CreateBillingManualPageCW isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/manual-billing/create-billing-draft/:id"
          element={<CreateDraftBillingPage isWarehouse={true} />}
        />

        {/* Central Warehouse - Purchase Order */}
        <Route
          path="/central-warehouse/purchase-order/"
          element={<PurchaseOrderCW isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/purchase-order/create-po"
          element={<CreatePurchaseOrderCW isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/purchase-order/detail-po/:param"
          element={<DetailPurchaseOrderCW isWarehouse={true} />}
        />

        {/* Central Warehouse - Inventory - Inventory List */}
        <Route
          path="/central-warehouse/inventory/produk-aktif"
          element={<ProductActivePage isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/inventory/produk-habis"
          element={<ProductHabisPage isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/inventory/produk-hold"
          element={<ProductHoldPage isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/inventory/produk-expired"
          element={<ProductExpiredPage isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/inventory/produk-inactive"
          element={<ProductInactivePage isWarehouse={true} />}
        />

        <Route
          path="/central-warehouse/inventory/produk-aktif/detail-product/:id"
          element={<DetailsProductActive isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/inventory/produk-inactive/detail-product/:id"
          element={<DetailsProductInactivePage isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/inventory/produk-stockout/detail-product/:id"
          element={<DetailsProductHabisPage isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/inventory/produk-hold/detail-product/:id"
          element={<DetailsProductHoldPage isWarehouse={true} />}
        />

        <Route
          path="/central-warehouse/inventory/produk-aktif/edit-product-details/:id"
          element={<EditDetailsProductActive isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/inventory/produk-inactive/edit-product-details/:id"
          element={<EditDetailProductInactive isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/inventory/produk-stockout/edit-product-details/:id"
          element={<EditDetailProductStockOut isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/inventory/produk-hold/edit-product-details/:id"
          element={<EditDetailProductHoldPage isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/inventory/tambah-produk"
          element={<AddProductActive isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/inventory/export"
          element={<ExportProductPage isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/inventory/import"
          element={<ImportActiveProduct isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/inventory/retur"
          element={
            <ProductExpiredProvider>
              <ReturPage isWarehouse={true} />
            </ProductExpiredProvider>
          }
        />
        <Route
          path="/central-warehouse/inventory/update-stock"
          element={
            <ProductActiveProvider>
              <UpdateStockActive isWarehouse={true} />
            </ProductActiveProvider>
          }
        />
        <Route
          path="/central-warehouse/inventory/update-stock-habis"
          element={
            <ProductActiveProvider>
              <UpdateProductHabis isWarehouse={true} />
            </ProductActiveProvider>
          }
        />
        <Route
          path="/central-warehouse/inventory/update-stock-several"
          element={<UpdateSeveralStockActive isWarehouse={true} />}
        />

        {/* Central Warehouse - Inventory - Kartu Stok */}
        <Route
          path="/central-warehouse/inventory/card-stock"
          element={<CardStockNewPage isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/inventory/card-stock/card-stock-detail-new/:productId"
          element={<CardStockDetailNewPage isWarehouse={true} />}
        />

        {/* Central Warehouse - Inventory - Inventory Summary */}
        <Route
          path="/central-warehouse/inventory/summary"
          element={<InventorySummaryPage isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/inventory/summary/:productId/:companyId"
          element={<InventorySummaryDetailPage isWarehouse={true} />}
        />

        {/* Central Warehouse - Inventory - Penyesuaian Stock */}
        <Route
          path="/central-warehouse/inventory/stock-adjustment"
          element={<StockAdjustmentPage isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/inventory/stock-adjustment/create-adjustment"
          element={<CreateStockAdjustmentPage isWarehouse={true} />}
        />

        {/* Central Warehouse - Inventory - Stock Opname */}
        <Route
          path="/central-warehouse/inventory/stock-opname"
          element={<StockOpnamePage isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/inventory/stock-opname/stock-opname-recap/:psId"
          element={<StockOpnameRecapPage isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/inventory/stock-opname/create/:psId"
          element={<CreateStockOpnamePage isWarehouse={true} />}
        />

        {/* Central Warehouse - Invoice - Customer */}
        <Route
          path="/central-warehouse/invoice/customer"
          element={<CustomerInvoicePage isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/invoice/customer/detail-customer/:id"
          element={<DetailCustomerInvoicePage isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/invoice/customer/create-customer"
          element={<CreateCustomerInvoicePage isWarehouse={true} />}
        />

        {/* Central Warehouse - Invoice - Invoice */}
        <Route
          path="/central-warehouse/invoice/invoice"
          element={<InvoicePageCW />}
        />
        <Route
          path="/central-warehouse/invoice/invoice/create-invoice"
          element={<CreateInvoicePageCW />}
        />
        <Route
          path="/central-warehouse/invoice/invoice/detail-invoice/:invNum"
          element={<DetailInvoicePageCW isWarehouse={true} />}
        />

        {/* Central Warehouse - Invoice - Setting Promo */}
        <Route
          path="/central-warehouse/invoice/promo"
          element={<SettingPromoPage isWarehouse={true} />}
        />
        <Route
          path="/central-warehouse/invoice/promo/create-promo"
          element={<CreatePromoInvoicePage isWarehouse={true} />}
        />

        {/* Central Warehouse - Invoice - Setting Invoice */}
        <Route
          path="/central-warehouse/invoice/setting-invoice"
          element={<SettingInvoicePage isWarehouse={true} />}
        />

        {/* Central Warehouse - Alokasi */}
        <Route
          path="/central-warehouse/allocation"
          element={<AllocationListPage />}
        />
        <Route
          path="/central-warehouse/allocation/create-allocation"
          element={<AddAllocationPage />}
        />
        <Route
          path="/central-warehouse/allocation/detail/:id"
          element={<DetailAllocationPage />}
        />

        {/* Central Warehouse - Transfer */}
        <Route
          path="/central-warehouse/transfer"
          element={<TransferListPage />}
        />
        <Route
          path="/central-warehouse/transfer/create-transfer"
          element={<AddTransferPage />}
        />
        <Route
          path="/central-warehouse/transfer/detail/:id"
          element={<DetailTransferPage />}
        />

        {/* Central Warehouse - Retur to Warehouse */}
        <Route
          path="/central-warehouse/retur-warehouse"
          element={<ReturnWarehouseListPage />}
        />
        <Route
          path="/central-warehouse/retur-warehouse/create-retur"
          element={<AddReturnWarehousePage />}
        />
        <Route
          path="/central-warehouse/retur-warehouse/detail/:id"
          element={<DetailReturnWarehousePage />}
        />

        {/* Central Warehouse - Inbox */}
        <Route
          path="/central-warehouse/inbox/warehouse"
          element={<InboxWarehousePage type={1} />}
        />
        <Route
          path="/central-warehouse/inbox/outlet"
          element={<InboxWarehousePage type={2} />}
        />
        <Route
          path="/central-warehouse/inbox/warehouse/detail/:id"
          element={<DetailInboxWarehousePage type={1} />}
        />
        <Route
          path="/central-warehouse/inbox/outlet/detail/:id"
          element={<DetailInboxWarehousePage type={2} />}
        />

        {/* Central Warehouse - Request*/}
        <Route
          path="/central-warehouse/request-allocation"
          element={<RequestAllocationListPage />}
        />
        <Route
          path="/central-warehouse/request-transfer"
          element={<RequestTransferListPage />}
        />
        <Route
          path="/central-warehouse/request-allocation/create"
          element={<AddRequestAllocationPage />}
        />
        <Route
          path="/central-warehouse/request-transfer/create"
          element={<AddRequestTransferPage />}
        />
        <Route
          path="/central-warehouse/request-allocation/detail/:id"
          element={<DetailRequestAllocationPage />}
        />
        <Route
          path="/central-warehouse/request-transfer/detail/:id"
          element={<DetailRequestTransferPage />}
        />

        {/* Consignment */}
        <Route path="/inventory/consignment" element={<ConsignmentPage />} />
        <Route
          path="/inventory/add-consignment"
          element={<AddNewConsignmentPage />}
        />
        <Route
          path="/inventory/consignment/:params"
          element={<DetailConsignmentPage />}
        />
        <Route
          path="/inventory/consignment/product/:params"
          element={<DetailConsignmentProductPage />}
        />
        <Route
          path="/inventory/consignment/edit-product-details/:params"
          element={<EditConsignmentProductPage />}
        />
        <Route
          path="/inventory/consignment/update-price/:params"
          element={<EditPriceonsignmentProductPage />}
        />

        {/* Billing */}
        <Route path="/billing/unpaid" element={<UnpaidBillingPage />} />
        <Route path="/billing/paid" element={<PaidBillingPage />} />
        <Route path="/billing/paid/invoice/:id" element={<PaidInvoicePage />} />
        <Route path="/billing/overdue" element={<OverdueBillingPage />} />
        <Route
          path="/billing/waiting-payment"
          element={<WaitingBillingPage />}
        />
        <Route
          path="/billing/:status/detail/:id"
          element={<DetailBillingPage />}
        />
        <Route
          path="/billing/:status/payment-summary/:va/detail/:id"
          element={<DetailBillingPage />}
        />
        <Route
          path="/billing/:status/payment-summary/:va"
          element={<SummaryBillingPage />}
        />
        <Route
          path="/billing/payment-status/:status"
          element={<PaymentStatus />}
        />
        <Route
          path="/billing/payment-status/:status/:detail"
          element={<PaymentStatus />}
        />
        <Route
          path="/billing/:status/invoice-detail/:invoiceNum"
          element={<DetailBillingInvoicePage />}
        />

        <Route path="/manual-billing/" element={<BillingManualListPage />} />
        <Route
          path="/manual-billing/po-list"
          element={<CreateBillingManualPage />}
        />
        <Route
          path="/manual-billing/create-billing/:id/:flag"
          element={<BillingManualDetailPOPage />}
        />
        <Route
          path="/manual-billing/detail/:id/:billId"
          element={<BillingManualDetailPage />}
        />
        <Route
          path="/manual-billing/edit-draft/:id/:flag"
          element={<EditBillingManualDraftPage />}
        />

        {/* Retur */}
        <Route path="/retur/buy-retur" element={<ReturProductPage />} />
        <Route
          path="/retur/buy-retur/create-retur"
          element={<CreateReturProductPage />}
        />
        <Route
          path="/retur/buy-retur/detail-retur/:id"
          element={<ReturDetailProductPage />}
        />

        <Route path="/retur/sell-retur" element={<ReturSellPage />} />
        <Route
          path="/retur/sell-retur/create-retur"
          element={<CreateReturSellPage />}
        />
        <Route
          path="/retur/sell-retur/detail-retur/:id"
          element={<ReturDetailSellPage />}
        />

        {/* Reporting */}
        <Route path="/reporting" element={<Reporting />} />
        <Route path="/reporting/log-shift" element={<LogShiftPage />} />
        <Route path="/reporting/product" element={<ReportingProductPage />} />
        <Route
          path="/reporting/detail-log-shift"
          element={<DetailLogShiftPage />}
        />
        <Route
          path="/reporting/invoice"
          element={<DetailInvoiceReportSalesPage />}
        />
        <Route path="/operational/list" element={<OperationalCostPage />} />
        <Route
          path="/operational/create"
          element={<CreateOperationalCostPage />}
        />
        <Route
          path="/reporting/profit-loss"
          element={<ReportingProfitnLossPage />}
        />

        {/* Invoice */}
        <Route path="/invoice/customer" element={<CustomerInvoicePage />} />
        <Route
          path="/invoice/customer/detail-customer/:id"
          element={<DetailCustomerInvoicePage />}
        />
        <Route
          path="/invoice/customer/create-customer"
          element={<CreateCustomerInvoicePage />}
        />
        <Route path="/invoice/invoice" element={<InvoicePage />} />
        <Route
          path="/invoice/invoice/create-invoice"
          element={<CreateInvoicePage />}
        />
        <Route
          path="/invoice/invoice/detail-invoice/:invNum"
          element={<DetailInvoicePage />}
        />
        <Route path="/invoice/promo" element={<SettingPromoPage />} />
        <Route
          path="/invoice/promo/create-promo"
          element={<CreatePromoInvoicePage />}
        />
        <Route
          path="/invoice/setting-invoice"
          element={<SettingInvoicePage />}
        />

        {/* Discount */}
        <Route path="/discount" element={<Discount />} />
        <Route path="/discount/add" element={<AddDiscount />} />
        <Route path="/discount/detail/:id" element={<DetailDiscount />} />

        {/* Customer */}
        <Route path="/pelanggan" element={<Customer />} />
        <Route
          path="/pelanggan/detail-pelanggan/:id"
          element={<DetailCustomer />}
        />
        <Route path="/pelanggan/detail-order/:id" element={<DetailOrder />} />

        {/* Sales */}
        <Route path="/sales" element={<Sales />} />
        <Route path="/sales/detail-sales/:id" element={<DetailSales />} />

        {/* User Management */}
        <Route path="/user-management" element={<UserManagement />} />
        <Route
          path="/user-management/create-user"
          element={<TambahPengguna />}
        />
        <Route
          path="/user-management/detail-user/:id/:companyId"
          element={<DetailUserPage />}
        />

        {/* Settings */}
        <Route path="/settings/payment-method" element={<PaymentMethod />} />
        <Route path="/settings/invoice-setting" element={<InvoiceSetting />} />

        {/* HIDDEN / DISABLED MENU */}
        {/* Purchase */}
        <Route
          path="/purchase"
          element={
            <SelectedProductProvider>
              <Purchase />
            </SelectedProductProvider>
          }
        />
        <Route
          path="/purchase/principals"
          element={
            <SelectedProductProvider>
              <PrincipalPage />
            </SelectedProductProvider>
          }
        />

        <Route
          path="/purchase/categories"
          element={
            <SelectedProductProvider>
              <CategoryPage />
            </SelectedProductProvider>
          }
        />

        <Route
          path="/purchase/principal-detail/:id"
          element={
            <SelectedProductProvider>
              <SelectedPrincipalPage />
            </SelectedProductProvider>
          }
        />
        <Route
          path="/purchase/category-detail/:id"
          element={
            <SelectedProductProvider>
              <SelectedCategoryPage />
            </SelectedProductProvider>
          }
        />
        <Route
          path="/purchase/detail/:id"
          element={<DetailPurchaseProductPage />}
        />
        <Route
          path="/purchase/cart-detail"
          element={
            <VoucherProductProvider>
              <CartDetailsPage />
            </VoucherProductProvider>
          }
        />
        <Route
          path="/purchase/checkout"
          element={
            <VoucherProductProvider>
              <CheckoutPurchasePage />
            </VoucherProductProvider>
          }
        />
        <Route
          path="/purchase/payment/:invoice"
          element={<PurchasePaymentPage />}
        />
        <Route
          path="/purchase/list-transaction"
          element={<ListTransactionPurchasePage />}
        />
        <Route
          path="/purchase/waiting-payment"
          element={<WaitingPaymentPurchasePage />}
        />
        <Route
          path="/purchase/detail-transaction/:invoice"
          element={<DetailTransactionPage />}
        />
        <Route
          path="/purchase/complain/:invoice"
          element={<ComplainPurchasePage />}
        />
        <Route
          path="/purchase/transaction-invoice/:invoice"
          element={<InvoiceTransactionPurchasePage />}
        />

        {/* Order */}

        <Route
          path="/orders"
          element={
            <ChatboxProvider>
              <OrderPage />
            </ChatboxProvider>
          }
        />

        <Route path="/order/:invoiceNumber" element={<OrderDetailPage />} />

        {/* Chat */}
        <Route path="/chat" element={<ChatPage />} />

        {/* Purchase Order */}
        <Route path="/purchase-order/" element={<PurchaseOrder />} />
        <Route
          path="/purchase-order/import"
          element={<ImportPurchaseOrderPage />}
        />
        <Route
          path="/purchase-order/create-po"
          element={<CreatePurchaseOrder />}
        />
        <Route
          path="/purchase-order/detail-po/:param"
          element={<DetailPurchaseOrderPage />}
        />
        <Route
          path="/purchase-order/detail-invoice/:id/:billId"
          element={<BillingManualDetailPage />}
        />

        {/* Profile */}
        <Route
          path="/profile"
          element={
            <VerificationMethodProvider>
              <AccountProfilePage />
            </VerificationMethodProvider>
          }
        />
        <Route
          path="/subscription"
          element={
            <PaymentStatusProvider>
              <SubscriptionPage />
            </PaymentStatusProvider>
          }
        />
        <Route
          path="/subscription/invoice/:billNumber/:invoiceType"
          element={
            <PaymentStatusProvider>
              <InvoiceSubscriptionPage />
            </PaymentStatusProvider>
          }
        />
        <Route
          path="/subscription/checkout/:id"
          element={<CheckoutSubscriptionPage />}
        />
        <Route
          path="/subscription/purchase"
          element={
            <PaymentStatusProvider>
              <SubscriptionPurchasePage />
            </PaymentStatusProvider>
          }
        />
        <Route
          path="/payment-status"
          element={
            <PaymentStatusProvider>
              <PaymentSuccess />
            </PaymentStatusProvider>
          }
        />
        <Route path="/profile/detail" element={<AccountProfileDetailPage />} />
        <Route
          path="/profile/request-password"
          element={<RequestPasswordChangePage />}
        />
        <Route path="/profile/request-phone" element={<RequestPhoneChange />} />
        <Route
          path="/profile/verification"
          element={
            <VerificationMethodProvider>
              <VerificationMethodPage />
            </VerificationMethodProvider>
          }
        />
        <Route
          path="/profile/request-email"
          element={<RequestEmailChangePage />}
        />
        <Route
          path="/success"
          element={
            <VerificationMethodProvider>
              <SuccessPage />
            </VerificationMethodProvider>
          }
        />
      </Routes>
    </div>
  );
};

export default App;
