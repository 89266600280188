import React from "react";
import HOC from "../../components/HOC";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../components/constants/muiTheme";
import BreadcrumbRetur from "../../components/screen/Retur/BreadcrumbRetur";
import CreateReturSell from "../../components/screen/Retur/CreateReturSell";

const CreateReturSellPage = () => {
  return (
    <div className="">
      <PageTemplate
        header="Buat Retur Penjualan"
        mainComponent={
          <ThemeProvider theme={theme}>
            <CreateReturSell />
          </ThemeProvider>
        }
        breadcrumb={<BreadcrumbRetur />}
        pageRole={47}
        noPadding={true}
      />
    </div>
  );
};

export default HOC(CreateReturSellPage);
