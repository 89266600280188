import { Box, CircularProgress, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { fetchReturList } from "../../../../services/billingAPI";
import { useMutation } from "@tanstack/react-query";
import moment from "moment";
import PagePagination from "../../../common/particles.jsx/PagePagination";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const ModalNotaRetur = ({
  open,
  setOpen,
  selectNota,
  distArr,
  distributorId,
}) => {
  const [reqBody, setReqBody] = useState({
    end_date: "",
    limit: 10,
    offset: 1,
    period: 0,
    search: "",
    start_date: "",
    distributor_id: 0,
  });

  const handleClose = () => {
    selectNota(null);
    setReqBody({
      end_date: "",
      limit: 10,
      offset: 1,
      period: 0,
      search: "",
      start_date: "",
      distributor_id: 0,
    });
    setOpen(false);
  };

  const {
    mutate: mutateList,
    data: dataList,
    isLoading,
  } = useMutation({
    mutationFn: fetchReturList,
  });

  useEffect(() => {
    if (open)
      mutateList({
        ...reqBody,
        distributor_id: distributorId ? distributorId : 0,
      });
  }, [reqBody, open]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          ...style,
          maxWidth: "75%",
          overflowY: "auto",
          overflowX: "wrap",
          height: "80vh",
          padding: 0,
        }}
        className="text-left text-[12px] w-3/4 lg:w-1/3"
      >
        <div className="flex items-center px-8 py-4">
          <p className="font-bold text-lg text-center">Nota Retur</p>
        </div>

        <hr className="mb-4" />

        <div className="px-8 mb-4">
          <div className="flex w-full mb-8">
            <div className="w-full">
              <div className="block overflow-x-auto w-full rounded-md">
                <table className="w-full overflow-x-auto rounded-md border-collapse">
                  <thead className="bg-blue-500 text-white">
                    <th className="p-2 text-center">No</th>
                    <th className="p-2 text-center">Tanggal Retur</th>
                    <th className="p-2 text-center">Distributor</th>
                    <th className="p-2 text-center">No. Nota Retur</th>
                    <th className="p-2 text-center">Nominal Retur</th>
                    <th className="p-2 text-center">Action</th>
                  </thead>
                  <tbody>
                    {dataList && !isLoading && dataList.data ? (
                      dataList.data.map((item, i) => (
                        <tr className="border">
                          <td className="p-2 text-center">
                            {(reqBody.offset - 1) * reqBody.limit + (i + 1)}
                          </td>
                          <td className="p-2 text-center">
                            {moment(item.created_at).format("DD/MM/YYYY")}
                          </td>
                          <td className="p-2 text-center">
                            {item.distributor_name}
                          </td>
                          <td className="p-2 text-center">
                            {item.note_number}
                          </td>
                          <td className="p-2 text-center">
                            {toIDR(item.total_price)}
                          </td>
                          <td className="p-2 text-center">
                            {item.is_used ? (
                              <p className="text-gray-300">Telah digunakan </p>
                            ) : (
                              <button
                                className="bg-blue-100 border-blue-300 text-blue-500 border-2 rounded-md w-full hover:bg-gray-300 p-1 disabled:border-none disabled:bg-gray-300 disabled:text-white"
                                onClick={() => {
                                  selectNota(item);
                                  setOpen(false);
                                }}
                                type="button"
                                disabled={distArr.includes(item.note_number)}
                              >
                                Pilih
                              </button>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="border">
                        <td className="p-2 text-center" colSpan={6}>
                          {isLoading ? (
                            <CircularProgress size={20} />
                          ) : (
                            "Tidak ada data"
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {dataList && dataList.count > 0 && dataList.data !== null ? (
            <div className="mb-4">
              <PagePagination
                offset={reqBody.offset}
                setOffset={(e) =>
                  setReqBody((prevVal) => ({ ...prevVal, offset: e }))
                }
                limit={reqBody.limit}
                setLimit={(e) =>
                  setReqBody((prevVal) => ({ ...prevVal, limit: e }))
                }
                total={dataList.count}
              />
            </div>
          ) : null}
        </div>
      </Box>
    </Modal>
  );
};

export default ModalNotaRetur;
