import React from "react";
import HOC from "../../components/HOC";
import InvoiceList from "../../components/screen/invoiceCW/InvoiceList";
import PageTemplate from "../../components/common/particles.jsx/PageTemplate";

const InvoicePageCW = () => {
  return (
    <div className="">
      <PageTemplate
        header={"Central Warehouse Invoice List"}
        mainComponent={<InvoiceList isWarehouse={true} />}
        pageRole={42}
      />
    </div>
  );
};

export default HOC(InvoicePageCW);
