import { Box, CircularProgress, Modal, TextField } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import { useState } from "react";
import { FaMoneyBill } from "react-icons/fa";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import ModalBankBilling from "./ModalBankBilling";
import { payBilling } from "../../../../services/billingAPI";
import ModalNotaRetur from "./ModalNotaRetur";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const ModalPaymentBilling = ({ open, setOpen, billing, refetch }) => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [openBank, setOpenBank] = useState(false);
  const [openRetur, setOpenRetur] = useState(false);
  const [isFull, setIsFull] = useState(true);
  const [payArr, setPayArr] = useState([
    {
      method: 1,
      amount: 0,
    },
  ]);
  const [selectedPayment, setSelectedPayment] = useState({
    ...payArr[0],
    index: 0,
  });

  const handleClose = () => {
    setErrorMessage("");
    setPayArr([
      {
        method: 1,
        amount: 0,
      },
    ]);
    setSelectedPayment({
      method: 1,
      amount: 0,
      index: 0,
    });
    setIsFull(true);
    setOpen(false);
  };

  const handleChangeFull = (val) => {
    setIsFull(val);
  };

  const handleChangePaymentMethod = (newPayment) => {
    const i = selectedPayment.index;
    const paymentVal = {
      amount: payArr[i].amount,
      method: newPayment,
    };
    const bankVal = {
      bank_name: payArr[i].bank_name ? payArr[i].bank_name : "",
      bank_id: payArr[i].bank_id ? payArr[i].bank_id : null,
    };

    setPayArr([
      ...payArr.slice(0, i),
      newPayment === 1
        ? paymentVal
        : newPayment === 2
        ? {
            ...bankVal,
            ...paymentVal,
          }
        : {
            note_number: payArr[i].note_number ? payArr[i].note_number : "",
            ...paymentVal,
          },
      ...payArr.slice(i + 1),
    ]);
    setSelectedPayment(
      newPayment === 1
        ? { ...paymentVal, index: i }
        : newPayment === 2
        ? {
            ...bankVal,
            ...paymentVal,
            index: i,
          }
        : {
            note_number: payArr[i].note_number ? payArr[i].note_number : "",
            ...paymentVal,
            index: i,
          }
    );

    if (newPayment === 2) setOpenBank(true);
    if (newPayment === 3) setOpenRetur(true);
  };

  const splitPayment = () => {
    const i = payArr.length;
    setPayArr([...payArr, { method: 1, amount: 0 }]);
    setSelectedPayment({ method: 1, amount: 0, index: i });
  };

  const handleChangeAmount = (val) => {
    const i = selectedPayment.index;
    setPayArr([
      ...payArr.slice(0, i),
      {
        ...payArr[i],
        amount: val,
      },
      ...payArr.slice(i + 1),
    ]);
  };

  const selectBank = (bank) => {
    const i = selectedPayment.index;
    setPayArr([
      ...payArr.slice(0, i),
      bank
        ? {
            ...payArr[i],
            bank_id: bank.bank_id,
            bank_name: bank.bank_name,
          }
        : { amount: payArr[i].amount, method: 1 },
      ...payArr.slice(i + 1),
    ]);
    setSelectedPayment(
      bank
        ? { ...selectedPayment, method: 2 }
        : {
            amount: selectedPayment.amount,
            method: 1,
            index: selectedPayment.index,
          }
    );
  };

  const selectNota = (nota) => {
    const i = selectedPayment.index;
    setPayArr([
      ...payArr.slice(0, i),
      nota
        ? {
            ...payArr[i],
            amount: nota.total_price,
            note_number: nota.note_number,
          }
        : { amount: payArr[i].amount, method: 1 },
      ...payArr.slice(i + 1),
    ]);
    setSelectedPayment(
      nota
        ? { ...selectedPayment, method: 3 }
        : {
            amount: selectedPayment.amount,
            method: 1,
            index: selectedPayment.index,
          }
    );
  };

  const validPayment = () => {
    {
      /**
        Payment is valid if
        * full payment with nota equals to total or more
        * full payment without nota equals to total
        * other payment without nota less or equal to total
        * other payment with nota all sum
        */
    }

    return (
      (isFull &&
        payArr.map((item) => item.method).includes(3) &&
        payArr.reduce((acc, num) => acc + num.amount, 0) >=
          getRemainingTotal()) ||
      (isFull &&
        !payArr.map((item) => item.method).includes(3) &&
        payArr.reduce((acc, num) => acc + num.amount, 0) ===
          getRemainingTotal()) ||
      (!isFull &&
        !payArr.map((item) => item.method).includes(3) &&
        payArr.reduce((acc, num) => acc + num.amount, 0) <=
          getRemainingTotal()) ||
      (!isFull && payArr.map((item) => item.method).includes(3))
    );
  };

  const getRemainingTotal = () => {
    return billing
      ? billing.po_detail.total_amount - billing.po_detail.total_payment
      : 0;
  };

  const handlePay = () => {
    const body = {
      id: billing.billing_id,
      is_full: isFull,
      payment: payArr,
    };

    mutatePayment(body);
  };

  const { mutate: mutatePayment, isLoading } = useMutation({
    mutationFn: payBilling,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (err) =>
      setErrorMessage(
        err.message.id
          ? err.message.id
          : err.message
          ? err.message
          : err
          ? err
          : "Terjadi kesalahan saat membayar billing"
      ),
  });

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            ...style,
            maxWidth: "75%",
            overflowY: "auto",
            overflowX: "wrap",
            maxHeight: "80vh",
            padding: 0,
          }}
          className="text-left text-[12px] w-3/4 lg:w-1/3 h-full"
        >
          <div className="flex justify-between items-center px-8 py-4">
            <div className="w-12 h-12 bg-blue-100 p-1 rounded-full">
              <div className="w-full h-full bg-blue-300 p-2 rounded-full">
                <FaMoneyBill className="w-full h-full text-blue-600" />
              </div>
            </div>
            <RxCross1
              className="cursor-pointer hover:text-red-500"
              onClick={handleClose}
            />
          </div>

          <div className="px-8 mb-4">
            <p className="font-bold text-lg mb-4">Pembayaran Billing</p>
            <div className="mb-4">
              <p className="mb-2 font-bold text-[14px]">Nominal Pembayaran</p>
              <div className="mb-4 flex gap-2 items-center  text-[14px]">
                <div
                  className={`w-full p-2 rounded-md border-2 hover:bg-gray-300 text-center cursor-pointer ${
                    isFull ? "bg-blue-500 text-white border-none" : ""
                  }`}
                  onClick={() => handleChangeFull(true)}
                >
                  Bayar Penuh
                </div>
                <div
                  className={`w-full p-2 rounded-md border-2 hover:bg-gray-300 text-center cursor-pointer ${
                    isFull !== null && isFull
                      ? ""
                      : "bg-blue-500 text-white border-none"
                  }`}
                  onClick={() => handleChangeFull(false)}
                >
                  Bayar Nominal Lain
                </div>
              </div>
            </div>

            <hr className="mb-4" />

            <div className="mb-4">
              <p className="mb-2 font-bold  text-[14px]">Sumber Dana</p>
              <div className="mb-4 flex gap-2 items-center  text-[14px]">
                <div
                  className={`w-full p-2 rounded-md border-2 hover:bg-gray-300 text-center cursor-pointer ${
                    selectedPayment && selectedPayment.method === 1
                      ? "bg-blue-500 text-white border-none"
                      : ""
                  }`}
                  onClick={() => handleChangePaymentMethod(1)}
                >
                  Cash
                </div>
                <div
                  className={`w-full p-2 rounded-md border-2 hover:bg-gray-300 text-center cursor-pointer ${
                    selectedPayment && selectedPayment.method === 2
                      ? "bg-blue-500 text-white border-none"
                      : ""
                  }`}
                  onClick={() => handleChangePaymentMethod(2)}
                >
                  Transfer
                </div>
                <div
                  className={`w-full p-2 rounded-md border-2 hover:bg-gray-300 text-center cursor-pointer ${
                    selectedPayment && selectedPayment.method === 3
                      ? "bg-blue-500 text-white border-none"
                      : ""
                  }`}
                  onClick={() => handleChangePaymentMethod(3)}
                >
                  Nota Retur
                </div>
              </div>
            </div>
            <hr className="mb-4" />
            {payArr.map((item, i) => {
              return (
                <div className="flex gap-2 items-center">
                  <TextField
                    fullWidth
                    type="number"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                    disabled={item.method === 3}
                    onClick={() => setSelectedPayment({ ...item, index: i })}
                    onChange={(e) => {
                      if (!isNaN(Number(e.target.value))) {
                        handleChangeAmount(Number(e.target.value));
                      }
                    }}
                    value={item.amount ? item.amount.toString() : 0}
                    label={
                      item.method === 1
                        ? "Cash"
                        : item.method === 2
                        ? "Transfer - " + item.bank_name
                        : item.method === 3
                        ? "Nota Retur"
                        : ""
                    }
                    inputProps={{
                      style: {
                        padding: 12,
                        fontSize: 12,
                      },
                    }}
                    sx={{
                      marginBottom: 2,
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor:
                            selectedPayment.index === i ? "blue" : "",
                        },
                        "&:hover fieldset": {
                          borderColor: "blue",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "blue",
                        },
                      },
                    }}
                  />
                  {payArr.length > 1 ? (
                    <RxCross1
                      className="mb-3 cursor-pointer text-red-500 hover:text-red-700"
                      onClick={() => {
                        setPayArr([
                          ...payArr.slice(0, i),
                          ...payArr.slice(i + 1),
                        ]);
                        setSelectedPayment({ ...payArr[0], index: 0 });
                      }}
                    />
                  ) : null}
                </div>
              );
            })}
            <p
              className={`text-[10px] text-right  mb-4 ${
                payArr.reduce((acc, num) => acc + num.amount, 0) <
                getRemainingTotal()
                  ? "text-blue-500 cursor-pointer hover:underline"
                  : "text-gray-300 cursor-default"
              }`}
              onClick={() => {
                if (
                  payArr.reduce((acc, num) => acc + num.amount, 0) <
                  getRemainingTotal()
                )
                  splitPayment();
              }}
            >
              Split Payment
            </p>

            <div className="flex justify-between items-center mb-4">
              <div>
                <p>Jumlah yang harus dibayar</p>
                <p className="text-[14px] font-bold">
                  {toIDR(getRemainingTotal())}
                </p>
              </div>
              <div>
                <p>Jumlah yang diinput</p>
                <p className="text-[14px] font-bold">
                  {toIDR(payArr.reduce((acc, num) => acc + num.amount, 0))}
                </p>
              </div>
            </div>

            <p className="mb-4 text-red-500">{errorMessage}</p>

            <hr className="mb-4" />

            <div className=" flex gap-2 items-center">
              <button
                type="button"
                className="w-full p-2 rounded-md border-2 border-black hover:bg-gray-300"
                disabled={isLoading}
                onClick={handleClose}
              >
                Batal
              </button>
              <button
                type="button"
                className="w-full p-2 rounded-md border-2 border-blue-500 bg-blue-500 text-white hover:bg-gray-300 hover:border-gray-300 disabled:bg-gray-300 disabled:border-none"
                disabled={!validPayment() || isLoading}
                onClick={handlePay}
              >
                {isLoading ? <CircularProgress size={15} /> : "Bayar"}
              </button>
            </div>
          </div>
        </Box>
      </Modal>

      <ModalBankBilling
        open={openBank}
        setOpen={setOpenBank}
        selectBank={(bank) => selectBank(bank)}
      />

      <ModalNotaRetur
        open={openRetur}
        setOpen={setOpenRetur}
        selectNota={(nota) => selectNota(nota)}
        distArr={payArr
          .filter((item) => item.method === 3)
          .map((item) => item.note_number)}
        distributorId={billing.po_detail.distributor_id}
      />
    </>
  );
};

export default ModalPaymentBilling;
