import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { CircularProgress, Collapse, IconButton } from "@mui/material";
import moment from "moment";

import {
  getDetailCardStockNew,
  getBatchDetailCardStockNew,
} from "../../../../services/InventoryAPI";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import BreadcrumbInventory from "../BreadcrumbInventory";

const toRupiah = (val) =>
  val.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const CardStockDetailNew = ({ isWarehouse }) => {
  const { productId = "" } = useParams();
  const companyId = localStorage.getItem("company_id");
  const navigate = useNavigate();

  const [dataBuffer, setDataBuffer] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(null);

  const { data: dataDetail, isFetching: isFetchingDetail } = useQuery({
    queryKey: ["card-stock-detail-new", productId, companyId],
    queryFn: () =>
      getBatchDetailCardStockNew(
        productId,
        companyId,
        isWarehouse ? isWarehouse : false
      ),
    refetchOnWindowFocus: false,
  });

  const { data: dataDetailBatch, isFetching: isFetchingDetailBatch } = useQuery(
    {
      queryKey: ["detail-batch", selectedBatch],
      queryFn: () =>
        getDetailCardStockNew(
          productId,
          companyId,
          selectedBatch.id,
          isWarehouse ? isWarehouse : false
        ),
      enabled:
        !dataBuffer?.every((item) => !item.isOpen) && selectedBatch !== null,
    }
  );

  useEffect(() => {
    if (dataDetail)
      setDataBuffer(
        dataDetail.data?.map((item) => ({
          isOpen: false,
          data: [],
          ...item,
        }))
      );
  }, [dataDetail]);

  useEffect(() => {
    if (dataDetailBatch) {
      const temp = [...dataBuffer];
      const index = dataBuffer.findIndex(
        (item) => item.id === selectedBatch.id
      );
      temp[index].data = dataDetailBatch;
      setDataBuffer(temp);
    }
  }, [dataDetailBatch]);

  return (
    <div>
      <BreadcrumbInventory isWarehouse={isWarehouse} />

      {dataDetail && dataDetail !== undefined ? (
        <div className="flex justify-between w-full mt-3 mb-8 gap-4">
          <div className="w-full">
            <p>Product ID</p>
            <p>{dataDetail ? dataDetail.product_id : "-"}</p>
            <div className="w-full border border-black"></div>
          </div>
          <div className="w-full">
            <p>Nama Produk</p>
            <p>
              {dataDetail
                ? dataDetail.name === ""
                  ? "-"
                  : dataDetail.name
                : "-"}
            </p>
            <div className="w-full border border-black"></div>
          </div>
          <div className="w-full">
            <p>Total Produk</p>
            <p>{dataDetail ? dataDetail.total_stock : "-"}</p>
            <div className="w-full border border-black"></div>
          </div>
        </div>
      ) : null}

      <div>
        {dataDetail && !isFetchingDetail && dataBuffer?.length > 0 ? (
          dataBuffer?.map((item, i) => (
            <div key={i}>
              <div className="flex justify-between items-center border-b-2 my-2 p-2">
                <div>
                  <div>
                    <p className="inline-block min-w-[15vw]">No. Batch</p>
                    <p className="inline-block">: {item.batch}</p>
                  </div>
                  <div>
                    <p className="inline-block min-w-[15vw]">Expired Date</p>
                    <p className="inline-block">: {item.expired}</p>
                  </div>
                  <div>
                    <p className="inline-block min-w-[15vw]">Batch Stock</p>
                    <p className="inline-block">
                      : {item.stock} {item.packaging}{" "}
                    </p>
                  </div>
                </div>
                <IconButton
                  size="small"
                  onClick={() => {
                    const temp = [...dataBuffer];
                    temp[i] = { ...item, isOpen: !item.isOpen };
                    setDataBuffer(temp);
                    setSelectedBatch(item.isOpen ? null : item);
                  }}
                >
                  {item.isOpen ? <FaChevronUp /> : <FaChevronDown />}
                </IconButton>
              </div>
              <Collapse in={item.isOpen} unmountOnExit>
                {isFetchingDetailBatch && selectedBatch.id === item.id ? (
                  <div className="flex justify-center">
                    <CircularProgress size={20} />
                  </div>
                ) : (
                  <div className="block overflow-x-auto w-full rounded-md">
                    <table className="w-full border border-collapse">
                      <thead className="bg-blue-500 text-white text-center">
                        <tr>
                          <th className="py-2 border">Tanggal</th>
                          <th className="py-2 border">Stok Awal</th>
                          <th className="py-2 border">Stok Masuk</th>
                          <th className="py-2 border">Stok Keluar</th>
                          <th className="py-2 border">Stok Akhir</th>
                          <th className="py-2 border">Supplier</th>
                          <th className="py-2 border">No Invoice</th>
                          <th className="py-2 border">HNA</th>
                          <th className="py-2 border">Diskon</th>
                          <th className="py-2 border">PPN</th>
                          <th className="py-2 border">Harga Akhir</th>
                          <th className="py-2 border">Harga Jual</th>
                          <th className="py-2 border">User Log</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.data?.map((batchItem, index) => (
                          <tr className="text-center" key={index}>
                            <td className="p-2 border">
                              {moment(batchItem.created_at).format(
                                "DD-MM-YYYY"
                              )}
                            </td>
                            <td className="p-2 border">
                              {batchItem.stock_current}
                            </td>
                            <td className="p-2 border">{batchItem.stock_in}</td>
                            <td className="p-2 border">
                              {batchItem.stock_out}
                            </td>
                            <td className="p-2 border">
                              {batchItem.stock_current +
                                batchItem.stock_in -
                                batchItem.stock_out}
                            </td>
                            <td className="p-2 border">
                              {batchItem.supplier_name}
                            </td>
                            <td className="p-2 border">
                              {batchItem.po_number}
                            </td>
                            <td className="p-2 border">
                              {toRupiah(batchItem.pharmacy_net_price)}
                            </td>
                            <td className="p-2 border">
                              {batchItem.pharmacy_net_price_discount}%
                            </td>
                            <td className="p-2 border">
                              {batchItem.pharmacy_net_price_ppn_value}%
                            </td>
                            <td className="p-2 border">
                              {toRupiah(batchItem.pharmacy_net_price_real)}
                            </td>
                            <td className="p-2 border">
                              {toRupiah(batchItem.sell_price)}
                            </td>
                            <td className="p-2 border">{batchItem.user_log}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </Collapse>
            </div>
          ))
        ) : isFetchingDetail ? (
          <div className="w-full flex justify-center my-4">
            <CircularProgress size={20} />
          </div>
        ) : (
          <div className="w-full my-4 text-gray-400">
            Data detail tidak ditemukan!
          </div>
        )}
      </div>
    </div>
  );
};

export default CardStockDetailNew;
