// MUI Theme
import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: ['"Montserrat"', "Open Sans"].join(","),
    fontSize: 12,
    fontWeight: 500,
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: 11,
          fontSize: 12,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          padding: 4,
          fontSize: 12,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: "-0.4vh",
          fontSize: 10,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: 10,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 10,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          fontSize: 12,
        },
      },
    },
  },
});
