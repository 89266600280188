import { Dialog, DialogContent } from "@mui/material";
import { StaticDatePicker } from "@mui/x-date-pickers";

const PopupSingleCalendar = ({
  open,
  setOpen,
  handleClose,
  value,
  handleChange,
  disablePast,
  disabled,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <StaticDatePicker
          views={["year", "month", "day"]}
          disabled={disabled ? disabled : false}
          displayStaticWrapperAs="desktop"
          sx={{ width: "100%" }}
          value={value}
          onChange={(e) => handleChange(e)}
          disablePast={disablePast ? disablePast : false}
        />
        <button
          type="button"
          className="w-full bg-blue-500 p-2 text-white rounded-md hover:bg-gray-300"
          onClick={() => setOpen(false)}
        >
          Select Date
        </button>
      </DialogContent>
    </Dialog>
  );
};

export default PopupSingleCalendar;
