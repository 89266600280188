import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";

import { Delete as DeleteIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import Breadcumbs from "../../../common/particles.jsx/Breadcumbs";
import { BsImages } from "react-icons/bs";
import { RiImageAddLine } from "react-icons/ri";
import "react-datepicker/dist/react-datepicker.css";
import {
  getDetailInventory,
  getCategory,
  getGroups,
  updateProduct,
  getPlatform,
  fetchPrincipal,
} from "../../../../services/InventoryAPI";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment/moment";
import { useFormik } from "formik";

const FETCH_LIMIT = 10;
const STATUS_PRODUCT = 3;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const EditDetailsProductStockOut = ({ isWarehouse }) => {
  const { id } = useParams();

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const theme = useTheme();
  const [offset, setOffset] = useState(1);
  const [stockType, setStockType] = useState("");
  const [platform, setPlatform] = useState([]);
  const [count, setCount] = useState(1);

  const { data } = useQuery({
    queryKey: ["detail_product"],
    queryFn: () => getDetailInventory(id, isWarehouse ? isWarehouse : false),
    keepPreviousData: true,
  });

  const { data: categories } = useQuery({
    queryKey: ["category"],
    queryFn: () => getCategory(FETCH_LIMIT, offset),
    keepPreviousData: true,
  });

  const { data: platforms } = useQuery({
    queryKey: ["platform"],
    queryFn: () => getPlatform(),
    keepPreviousData: true,
  });

  const { mutate: mutatePrincipals, data: principals } = useMutation({
    mutationFn: fetchPrincipal,
    onSuccess: () => {
      queryClient.invalidateQueries("principals");
    },
  });

  const { mutate } = useMutation({
    mutationFn: updateProduct,
  });

  const formik = useFormik({
    initialValues: {
      warehouse: isWarehouse ? isWarehouse : false,
      product_id: parseInt(id, 10),
      nie_number: data && data !== undefined ? data.nie_number : "",
      principal_id: data && data !== undefined ? data.product_principal_id : "",
      category_id: data && data !== undefined ? data.product_category_id : [],
      label_id: data && data !== undefined ? data.label_product : "",
      product_name: data && data !== undefined ? data.product_name : "",
      product_image: data && data !== undefined ? data.media : [],
      product_description: {
        indication:
          data && data !== undefined ? data.description.indication : "",
        composition:
          data && data !== undefined ? data.description.composition : "",
        dosage: data && data !== undefined ? data.description.dosage : "",
        usage: data && data !== undefined ? data.description.usage : "",
        contraindication:
          data && data !== undefined ? data.description.contraindication : "",
        side_effect:
          data && data !== undefined ? data.description.side_effect : "",
        storage_description:
          data && data !== undefined
            ? data.description.storage_description
            : "",
      },
      require_prescription:
        data && data !== undefined ? data.description.require_prescription : "",
      packaging: {
        type_box: data && data !== undefined ? data.packing.type_box : "",
        total_box: data && data !== undefined ? data.packing.total_box : "",
        type_strip: data && data !== undefined ? data.packing.type_strip : "",
        total_strip: data && data !== undefined ? data.packing.total_strip : "",
        type_unit: data && data !== undefined ? data.packing.type_unit : "",
        total_unit: data && data !== undefined ? data.packing.total_unit : "",
      },
      stock: data && data !== undefined ? data.stock : "",
      expired_date: data && data !== undefined ? data.expired_date : "",
      price: {
        platform_price: data && data !== undefined ? null : [],
        pharmacy_net_price: data && data !== undefined ? data.hna : 0,
        sell_price: data && data !== undefined ? data.price : 0,
      },
      product_inventory_id:
        data && data !== undefined ? data.product_inventory_id : "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      mutate({ values });
    },
  });

  const { data: groups } = useQuery({
    queryKey: ["groups"],
    queryFn: () => getGroups(),
  });

  const handleUploadImage = useCallback(
    (e) => {
      try {
        const files = e.currentTarget.files;
        if (files === null) {
          throw new Error("Something went wrong when reading the image");
        }
        const file = files.item(0);
        if (file === null) {
          throw new Error("Something went wrong when reading the image");
        }

        const reader = new FileReader();

        reader.onload = (e) => {
          if (!e.target || (e.target && !e.target.result))
            throw new Error("Something went wrong when reading the image");

          const image = new Image();
          image.onload = () => {
            formik.setFieldValue("product_image", [image.src, image.src]);
          };
          image.onerror = () => {
            throw new Error("Something went wrong when reading the image");
          };
          image.src = e.target.result;
        };
        reader.onerror = () => {
          throw new Error("Something went wrong when reading the file");
        };

        reader.readAsDataURL(file);
      } catch (e) {
        if (e instanceof Error) {
        }
      }
    },
    [formik.setFieldValue]
  );

  const handleChangeSelect = useCallback(
    (e) => {
      const {
        target: { value },
      } = e;
      formik.setFieldValue(
        "category_id",
        typeof value === "string" ? value.split(",") : value
      );
    },
    [formik.values.category_id]
  );

  const intersection = new Set(platform.map(({ id }) => id));
  const result = platforms?.filter(({ id }) => !intersection.has(id));

  const handleAddPlatform = useCallback(() => {
    if (platforms && platforms !== undefined)
      setPlatform([
        ...platform,
        {
          id: result[0]?.id,
          price: "",
        },
      ]);
    else setPlatform([]);
  }, [platform, result]);

  const packages =
    data && data !== undefined
      ? [data.packing.type_box, data.packing.type_strip, data.packing.type_unit]
      : [];

  useEffect(() => {
    formik.setFieldValue("platform_price", platform);
  }, [platform]);

  useEffect(() => {
    mutatePrincipals({
      status: STATUS_PRODUCT,
      limit: FETCH_LIMIT,
      name: "",
      offset: offset,
    });
  }, []);

  return (
    <div className="container mx-auto w-full mt-16 lg:mt-0">
      {data && data !== undefined ? (
        <>
          {/* <h1 className="font-semibold text-[20px] lg:text-[30px] mb-3">
            Update {isWarehouse ? "Central Warehouse" : ""} Information
            Inventory {id}
          </h1> */}
          <Breadcumbs
            nama1="Inventory"
            slice1="/"
            nama2="Produk Aktif"
            slice2="/"
            nama3={`Update Information ${id}`}
          />
          <>
            <form onSubmit={formik.handleSubmit}>
              <div className="grid grid-col-1 shadow-md rounded-lg mt-5 p-3">
                <h1 className="font-medium text-[20px] lg:text-[25px]">
                  Detail Produk
                </h1>
                <TextField
                  id="outlined-basic"
                  label="Nomor Izin Edar"
                  value={formik.values.nie_number}
                  onChange={(e) =>
                    formik.setFieldValue("nie_number", e.target.value)
                  }
                  disabled
                  variant="outlined"
                  className="mt-3"
                />

                <div className="input-foto">
                  <div className="bg-blue-500 p-3 text-white font-medium flex mt-4 items-center gap-3 text-[20px] rounded-t-lg">
                    <span>
                      <BsImages />
                    </span>
                    Foto Produk
                  </div>
                  <div className="flex items-center">
                    {data && data.media !== undefined ? (
                      <label
                        htmlFor="drop"
                        className="cursor-pointer flex flex-row items-center justify-center w-full h-64 border-2 rounded-b-lg"
                      >
                        <div className="relative">
                          <img
                            src={formik.values.product_image[0]}
                            alt="foto produk"
                            className=" left-[50%] w-64 rounded-lg shadow-md"
                          />
                        </div>
                        <input
                          id="drop"
                          type="file"
                          hidden
                          accept="accept='image/jpeg,image/png,image/jpg"
                          onChange={(e) => handleUploadImage(e)}
                        />
                      </label>
                    ) : (
                      <label
                        htmlFor="dropzone-file1"
                        className="cursor-pointer flex flex-col items-center justify-center w-full h-64 border-2 rounded-b-lg"
                      >
                        <div className="flex flex-col items-center justify-center">
                          <RiImageAddLine className="text-[90px] text-blue-500" />
                          <p className="font-medium text-l text-blue-400 mt-3">
                            Upload foto product
                          </p>
                          <p className="text-center">
                            <span className="">Ukuran foto maksimal 1 mb</span>
                          </p>
                        </div>
                        <input
                          id="dropzone-file1"
                          type="file"
                          hidden
                          accept="accept='image/jpeg,image/png,image/jpg"
                          onChange={(e) => handleUploadImage(e)}
                        />
                      </label>
                    )}
                  </div>
                </div>
                <TextField
                  id="outlined-basic"
                  label="Nama Obat"
                  name="product_name"
                  variant="outlined"
                  value={formik.values.product_name}
                  onChange={(e) =>
                    formik.setFieldValue("product_name", e.target.value)
                  }
                  className="mt-3"
                />
                <FormControl fullWidth className="mt-3">
                  <InputLabel id="demo-multiple-simple-select-label">
                    Tambah Kategori
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="category_id"
                    multiple
                    value={formik.values.category_id}
                    onChange={handleChangeSelect}
                    input={<OutlinedInput label="Tambah Kategori" />}
                    MenuProps={MenuProps}
                  >
                    {categories &&
                      categories.map((item) => {
                        return (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            style={getStyles(
                              item.name,
                              formik.values.category_id,
                              theme
                            )}
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <TextField
                  id="outlined-basic"
                  name="indication"
                  value={formik.values.product_description.indication}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "product_description.indication",
                      e.target.value
                    )
                  }
                  label="Indikasi / Kegunaan"
                  variant="outlined"
                  className="mt-3"
                />
                <TextField
                  id="outlined-basic"
                  name="composition"
                  value={formik.values.product_description.composition}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "product_description.composition",
                      e.target.value
                    )
                  }
                  label="Kandungan / Komposisi"
                  variant="outlined"
                  className="mt-3"
                />
                <TextField
                  id="outlined-multiline-static"
                  name="dosage"
                  value={formik.values.product_description.dosage}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "product_description.dosage",
                      e.target.value
                    )
                  }
                  label="Dosis"
                  multiline
                  rows={3}
                  className="mt-3"
                />
                <TextField
                  id="outlined-multiline-static"
                  name="usage"
                  label="Cara Pemakaian"
                  multiline
                  rows={2}
                  value={formik.values.product_description.usage}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "product_description.usage",
                      e.target.value
                    )
                  }
                  className="mt-3"
                />
                <TextField
                  id="outlined-basic"
                  name="contraindication"
                  label="Kontradiksi / Jangan digunakan oleh"
                  value={formik.values.product_description.contraindication}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "product_description.contraindication",
                      e.target.value
                    )
                  }
                  variant="outlined"
                  className="mt-3"
                />
                <TextField
                  id="outlined-basic"
                  label="Efek Samping"
                  name="side_effect"
                  multiline
                  rows={3}
                  variant="outlined"
                  className="mt-3"
                  value={formik.values.product_description.side_effect}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "product_description.side_effect",
                      e.target.value
                    )
                  }
                />
                <TextField
                  id="outlined-basic"
                  label="Cara Penyimpanan"
                  name="storage_description"
                  variant="outlined"
                  className="mt-3"
                  value={formik.values.product_description.storage_description}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "product_description.storage_description",
                      e.target.value
                    )
                  }
                />

                <FormControl fullWidth className="mt-3">
                  <InputLabel id="demo-simple-select-label">
                    Golongan Obat
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    name="label_id"
                    value={formik.values.label_id}
                    label="Golongan Obat"
                    onChange={(e) =>
                      formik.setFieldValue("label_id", e.target.value)
                    }
                  >
                    {groups &&
                      groups.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <TextField
                  id="outlined-basic"
                  label="require-prescription"
                  variant="filled"
                  className="mt-3"
                  disabled
                  value={formik.values.require_prescription ? "Ya" : "Tidak"}
                  onChange={(e) =>
                    formik.setFieldValue("require_prescription", e.target.value)
                  }
                />
                <FormControl fullWidth className="mt-3">
                  <InputLabel id="principal">Principal</InputLabel>
                  <Select
                    id="principal"
                    name="principal_id"
                    value={formik.values.principal_id}
                    onChange={(e) =>
                      formik.setFieldValue("principal_id", e.target.value)
                    }
                  >
                    {principals &&
                      principals.map((principal) => {
                        return (
                          <MenuItem key={principal.id} value={principal.id}>
                            {principal.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>

                <div className="flex flex-row gap-5">
                  <div className="flex flex-col">
                    <h1 className="mt-4 font-bold text-[10px] lg:text-[20px]">
                      Kemasan
                    </h1>
                    <div className="grid grid-cols-2 gap-4">
                      <TextField
                        id="outlined-basic"
                        label="Qty"
                        variant="outlined"
                        className="mt-3"
                        disabled
                        value={formik.values.packaging.total_box}
                      />
                      <FormControl fullWidth className="mt-3">
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={formik.values.packaging.type_box}
                          disabled
                        >
                          <MenuItem value={data.packing.type_box}>
                            {data.packing.type_box}
                          </MenuItem>
                          <MenuItem value={data.packing.type_strip}>
                            Strip
                          </MenuItem>
                          <MenuItem value={data.packing.type_unit}>
                            Tablet
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <TextField
                        id="outlined-basic"
                        label="Qty"
                        variant="outlined"
                        className="mt-3"
                        disabled
                        value={formik.values.packaging.total_strip}
                      />
                      <FormControl fullWidth className="mt-3">
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          disabled
                          value={formik.values.packaging.type_strip}
                        >
                          <MenuItem value={data.packing.type_box}>
                            {data.packing.type_box}
                          </MenuItem>
                          <MenuItem value={data.packing.type_strip}>
                            {data.packing.type_strip}
                          </MenuItem>
                          <MenuItem value={data.packing.type_unit}>
                            {data.packing.type_unit}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <TextField
                        id="outlined-basic"
                        label="Qty"
                        variant="outlined"
                        className="mt-3"
                        disabled
                        value={formik.values.packaging.total_unit}
                      />
                      <FormControl fullWidth className="mt-3">
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          disabled
                          value={formik.values.packaging.type_unit}
                        >
                          <MenuItem value={data.packing.type_box}>
                            {data.packing.type_box}
                          </MenuItem>
                          <MenuItem value={data.packing.type_strip}>
                            {data.packing.type_strip}
                          </MenuItem>
                          <MenuItem value={data.packing.type_unit}>
                            {data.packing.type_unit}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <h1 className="mt-4 font-bold text-[10px] lg:text-[20px]">
                      Stock
                    </h1>
                    <div className="grid grid-cols-2 gap-4 items-center">
                      <div className="flex flex-row">
                        <button
                          className="bg-[#FF7A5D] p-3 mr-[10px] rounded-lg"
                          type="button"
                          onClick={() => {
                            if (formik.values.stock === 1 || count === 1)
                              return;
                            setCount((previousCount) => previousCount - 1);
                            formik.setFieldValue(
                              "stock",
                              formik.values.stock - 1
                            );
                          }}
                        >
                          -
                        </button>
                        <input
                          type="text"
                          className="border rounded-lg w-[69px] p-3"
                          value={count}
                          onChange={(e) => setCount(e.target.value)}
                        />
                        <button
                          className="bg-[#5DFFBB] p-3 ml-[10px] rounded-lg"
                          type="button"
                          onClick={() => {
                            setCount((previousCount) => previousCount + 1);
                            formik.setFieldValue(
                              "stock",
                              formik.values.stock + 1
                            );
                          }}
                        >
                          +
                        </button>
                      </div>
                      <FormControl fullWidth className="mt-3">
                        <InputLabel id="demo-simple-select-label">
                          Stock Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={stockType}
                          onChange={(e) => setStockType(String(e.target.value))}
                          label="stock-type"
                        >
                          {packages.map((pack, index) => {
                            return (
                              <MenuItem value={index} key={index}>
                                {pack}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>
                    <p className="mt-2">
                      <span className="bg-red-500 rounded-full text-white px-[10px] text-sm py-[3px] font-bold">
                        i
                      </span>{" "}
                      Stock akhir {formik.values.stock} tablet
                    </p>
                  </div>
                </div>

                <div className="grid grid-cols-1 mt-4">
                  <DesktopDatePicker
                    views={["year", "month", "day"]}
                    label="Tanggal Expired"
                    onChange={(newExpiredDate) => {
                      formik.setFieldValue("expired_date", newExpiredDate);
                    }}
                    value={formik.values.expired_date}
                    renderInput={(params) => {
                      return <TextField {...params} />;
                    }}
                    disabled={
                      formik.values.expired_date === null ? true : false
                    }
                    shouldDisableDate={(date) => {
                      moment(date).isBefore(formik.values.expired_date);
                    }}
                  />
                </div>

                <div className="grid grid-cols-2 justify-between mt-4 items-center">
                  <p className="font-bold">HNA</p>
                  <TextField
                    id="outlined-basic"
                    label="HNA"
                    variant="outlined"
                    className="w-full"
                    value={formik.values.price.pharmacy_net_price}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "price.pharmacy_net_price",
                        e.target.value
                      )
                    }
                  />
                </div>
                {isWarehouse ? null : (
                  <div className="grid grid-cols-2 justify-between mt-4 items-center">
                    <p className="font-bold">Harga Jual</p>
                    <TextField
                      id="outlined-basic"
                      label="Efek Samping"
                      variant="outlined"
                      className="w-full"
                      value={formik.values.price.sell_price}
                      onChange={(e) =>
                        formik.setFieldValue("price.sell_price", e.target.value)
                      }
                    />
                  </div>
                )}
                {isWarehouse ? null : (
                  <div>
                    {platforms && platforms ? (
                      <>
                        {platforms.length === platform.length ? null : (
                          <p
                            className="text-blue-500 hover:underline hover:text-blue-500 cursor-pointer mt-4"
                            onClick={handleAddPlatform}
                          >
                            +Tambah Platform lain
                          </p>
                        )}
                      </>
                    ) : null}
                    {platforms &&
                    platforms !== undefined &&
                    platform.length > 0 ? (
                      <>
                        {platform.map((plat, index) => {
                          return (
                            <div
                              className="flex justify-center items-center mt-[20px]"
                              key={plat.id}
                            >
                              <FormControl fullWidth sx={{ marginRight: 1 }}>
                                <InputLabel id="demo-simple-select-label">
                                  Kategori
                                </InputLabel>
                                <Select
                                  id="add-category"
                                  value={plat.id}
                                  onChange={(e) => {
                                    setPlatform(
                                      platform.map((p1) => {
                                        return p1.id === plat.id
                                          ? { ...plat, id: e.target.value }
                                          : p1;
                                      })
                                    );
                                  }}
                                >
                                  {platforms.map((p) => {
                                    return (
                                      <MenuItem
                                        key={p.id}
                                        value={p.id}
                                        disabled={
                                          platform.find((f) => f.id === p.id)
                                            ? true
                                            : false
                                        }
                                        sx={{
                                          display: platform.find(
                                            (f) => f.id === p.id
                                          )
                                            ? "none"
                                            : null,
                                        }}
                                      >
                                        {p.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">
                                  Jumlah Uang
                                </InputLabel>
                                <OutlinedInput
                                  id="outlined-adornment-password"
                                  type="text"
                                  onChange={(e) =>
                                    setPlatform(
                                      platform.map((p1) => {
                                        return p1.id === plat.id
                                          ? { ...plat, price: e.target.value }
                                          : p1;
                                      })
                                    )
                                  }
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        edge="end"
                                        onClick={() => {
                                          if (platform.length === 1) {
                                            setPlatform([]);
                                          } else {
                                            setPlatform([
                                              ...platform.slice(0, index),
                                              ...platform.slice(index + 1),
                                            ]);
                                          }
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  label="Password"
                                />
                              </FormControl>
                            </div>
                          );
                        })}
                      </>
                    ) : null}
                  </div>
                )}

                <div className="grid grid-cols-2 mt-10 gap-12">
                  <button
                    className="bg-red-700 text-white font-semibold p-3 rounded-lg"
                    onClick={() =>
                      navigate(
                        `${
                          isWarehouse ? "/central-warehouse" : ""
                        }/inventory/produk-aktif/detail-product/${id}`
                      )
                    }
                  >
                    Batal
                  </button>
                  <button
                    className="bg-orange-400 text-white font-semibold p-3 rounded-lg"
                    type="submit"
                  >
                    Konfirmasi
                  </button>
                </div>
              </div>
            </form>
          </>
        </>
      ) : (
        <p>Tidak Ditemukan!</p>
      )}
    </div>
  );
};

export default EditDetailsProductStockOut;
