import { Box, CircularProgress, Modal } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import { useState } from "react";
import { FaMoneyBill } from "react-icons/fa";
import { updateBillingNew } from "../../../../services/billingAPI";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const ModalSaveBilling = ({ open, setOpen, reqBody }) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [isDraft, setIsDraft] = useState(false);

  const handleClose = () => {
    setErrorMessage("");
    setOpen(false);
  };

  const isPartial = () => {
    var partial = false;
    reqBody.product.forEach((item) => {
      if (
        item.po_stock >
        item.batch.reduce((acc, num) => acc + num.quantity_received, 0)
      )
        partial = true; // filled qty less than po qty
      if (
        reqBody.product.filter((item) =>
          item.batch.every((batch) => batch.batch_number !== "")
        ).length === 0
      )
        partial = true; // has an empty batch
    });
    return partial;
  };

  const {
    mutate: mutateUpdateBillingNew,
    isLoading: isMutateUpdateBillingNewLoading,
  } = useMutation({
    mutationFn: updateBillingNew,
    onSuccess: () => {
      navigate(`/manual-billing`);
      handleClose();
    },
    onError: (err) => {
      setErrorMessage(err.message.id);
    },
  });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          ...style,
          maxWidth: "75%",
          overflowY: "auto",
          overflowX: "wrap",
          maxHeight: "50vh",
          padding: 0,
        }}
        className="text-left text-[14px] w-3/4 lg:w-1/3"
      >
        <div className="flex justify-between items-center px-8 py-4">
          <div className="w-12 h-12 bg-blue-100 p-1 rounded-full">
            <div className="w-full h-full bg-blue-300 p-2 rounded-full">
              <FaMoneyBill className="w-full h-full text-blue-600" />
            </div>
          </div>
          <RxCross1
            className="cursor-pointer hover:text-red-500"
            onClick={handleClose}
          />
        </div>

        <div className="px-8 mb-4">
          <p className="font-bold text-lg ">Simpan Billing</p>
          <p>
            Simpan sebagai manual billing atau sebagai draft untuk diubah
            nantinya
          </p>
          <p className="mt-2 text-red-500">{errorMessage}</p>
        </div>

        <div className="px-8 mb-4 flex gap-2 items-center">
          <button
            type="button"
            className="w-full p-2 rounded-md border-2 border-black hover:bg-gray-300"
            disabled={isMutateUpdateBillingNewLoading}
            onClick={() => {
              mutateUpdateBillingNew({
                ...reqBody,
                is_partial: isPartial(),
                is_draft: true,
              });
              setIsDraft(true);
            }}
          >
            {isMutateUpdateBillingNewLoading && isDraft ? (
              <CircularProgress size={10} />
            ) : (
              "Simpan Draft"
            )}
          </button>
          <button
            type="button"
            className="w-full p-2 rounded-md border-2 border-blue-500 bg-blue-500 text-white hover:bg-gray-300 hover:border-gray-300 disabled:bg-gray-300"
            disabled={isMutateUpdateBillingNewLoading}
            onClick={() => {
              mutateUpdateBillingNew({
                ...reqBody,
                is_partial: isPartial(),
                is_draft: false,
              });
              setIsDraft(false);
            }}
          >
            {isMutateUpdateBillingNewLoading && !isDraft ? (
              <CircularProgress size={10} />
            ) : (
              "Simpan"
            )}
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default ModalSaveBilling;
