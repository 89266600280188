import { useQuery } from "@tanstack/react-query";
import { getPoDetail } from "../../../services/purchaseOrderApi";
import { useParams } from "react-router-dom";
import moment from "moment";
import { CircularProgress } from "@mui/material";

const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const DetailPurchaseOrder = () => {
  const { param } = useParams();

  const idNum = atob(param.split("-")[0]);
  const isPartial = param.split("-")[1];

  const { data, isFetching } = useQuery({
    queryFn: () =>
      getPoDetail(idNum, isPartial === 1 ? true : false, false, false),
    queryKey: ["po-detail"],
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <div className="text-[12px] p-3 pb-[70px]">
        {data && !isFetching ? (
          <div className="flex w-full gap-2 items-start mb-4">
            <div className="w-full">
              <div className="w-full flex gap-2">
                <p className="w-1/3">Tanggal PO</p>
                <p>: {moment(data.po_date).format("DD/MM/YYYY")}</p>
              </div>
              <div className="w-full flex gap-2">
                <p className="w-1/3">No. PO</p>
                <p>: {data.po_number}</p>
              </div>
              <div className="w-full flex gap-2">
                <p className="w-1/3">Distributor</p>
                <p>: {data.distribution.name}</p>
              </div>
            </div>
            <div className="w-full">
              <div className="w-full flex gap-2">
                <p className="w-1/3">Metode Pembayaran</p>
                <p>
                  :{" "}
                  {data.payment_method === 1
                    ? "Tunai"
                    : data.payment_method
                    ? "Kredit"
                    : "-"}
                </p>
              </div>
              <div className="w-full flex gap-2">
                <p className="w-1/3">Jatuh Tempo</p>
                <p>: {moment(data.due_date).format("DD/MM/YYYY")}</p>
              </div>
              <div className="w-full flex gap-2">
                <p className="w-1/3">Status</p>
                <p>
                  :{" "}
                  {!data.get_purchase_order_billing_lists ||
                  data.get_purchase_order_billing_lists.length === 0
                    ? "Open"
                    : data.is_partial
                    ? "Partial"
                    : "Closed"}
                </p>
              </div>
            </div>
            <div className="hidden lg:block w-full" />
          </div>
        ) : (
          <div className="w-full mb-4">
            {isFetching ? <CircularProgress size={20} /> : null}
          </div>
        )}

        <div className="flex w-full mb-8 pb-[70px]">
          <div className="w-full">
            <div className="block overflow-x-auto w-full rounded-md">
              <table className="w-full overflow-x-auto rounded-md border-collapse border">
                <thead className="bg-blue-500 text-white">
                  <th className="p-2 text-center">No</th>
                  <th className="p-2 text-center whitespace-nowrap">Produk</th>
                  <th className="p-2 text-center whitespace-nowrap">Satuan</th>
                  <th className="p-2 text-center whitespace-nowrap">
                    Kuantitas
                  </th>
                  <th className="p-2 text-center whitespace-nowrap">
                    HNA Satuan
                  </th>
                  <th className="p-2 text-center whitespace-nowrap">
                    Diskon (%)
                  </th>
                  <th className="p-2 text-center whitespace-nowrap">PPN (%)</th>
                  <th className="p-2 text-center whitespace-nowrap">
                    Harga Akhir
                  </th>
                  <th className="p-2 text-center whitespace-nowrap">
                    Subtotal
                  </th>
                </thead>
                <tbody>
                  {data && !isFetching ? (
                    data.product.map((item, i) => (
                      <tr key={i} className={`${i === 0 ? "" : "border-t"}`}>
                        <td className="p-2 text-center">{i + 1}</td>
                        <td className="p-2 text-center">{item.name}</td>
                        <td className="p-2 text-center">
                          {item.type_stock === 0
                            ? item.packaging.type_box
                            : item.type_stock === 1
                            ? item.packaging.type_strip
                            : item.packaging.type_unit}
                        </td>
                        <td className="p-2 text-center">{item.po_stock}</td>
                        <td className="p-2 text-center">
                          {toIDR(item.po_pharmacy_net_price)}
                        </td>
                        <td className="p-2 text-center">
                          {item.pharmacy_net_price_discount}
                        </td>
                        <td className="p-2 text-center">
                          {item.pharmacy_net_price_ppn_value}
                        </td>
                        <td className="p-2 text-center">
                          {toIDR(item.pharmacy_net_price_real)}
                        </td>
                        <td className="p-2 text-center">
                          {toIDR(item.po_stock * item.pharmacy_net_price_real)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="p-2 text-center" colSpan={9}>
                        {isFetching ? (
                          <CircularProgress size={20} />
                        ) : (
                          "Tidak ada produk"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`bg-blue-500 fixed bottom-0 left-0 lg:left-[260px] min-h-[60px] px-[20px] py-[10px] w-full lg:w-[calc(100%-260px)] z-50`}
      >
        <div className="flex items-center justify-start items-center w-full">
          <div className="text-white">
            <p className="text-[14px]">Total</p>
            {isFetching ? (
              <CircularProgress size={20} />
            ) : (
              <p className="text-[18px] font-bold">
                {toIDR(
                  data
                    ? data.product.reduce(
                        (acc, num) =>
                          acc + num.po_stock * num.pharmacy_net_price_real,
                        0
                      )
                    : 0
                )}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailPurchaseOrder;
