import { Search } from "@mui/icons-material";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import {
  fetchReturListSell,
  getReturPdf,
  getReturSellDetail,
} from "../../../services/billingAPI";
import { useMutation, useQuery } from "@tanstack/react-query";
import PagePagination from "../../common/particles.jsx/PagePagination";
import moment from "moment";
import DatePicker from "react-datepicker";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { MONTHS, YEARS } from "../../constants/constant";
import PrintNota from "../../common/particles.jsx/PrintNota";
import { getPreviewInvoice } from "../../../services/ReportingApi";
import { useReactToPrint } from "react-to-print";
import LoadingBackdrop from "../../common/particles.jsx/LoadingBackdrop";

const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const toDate = (date) => moment(date).format("DD-MM-YYYY");

const ReturSellList = () => {
  const navigate = useNavigate();
  const printRef = useRef();

  const [openCalendar, setOpenCalendar] = useState(false);
  const [noteNumber, setNoteNumber] = useState(0);
  const [reqBody, setReqBody] = useState({
    end_date: "",
    limit: 10,
    offset: 1,
    period: 0,
    search: "",
    start_date: "",
  });

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onAfterPrint: () => setNoteNumber(0),
  });

  const { data: returPdfData, isFetching: isFetchingPrint } = useQuery({
    queryKey: ["retur-pdf", noteNumber],
    queryFn: () => getReturSellDetail(noteNumber),
    enabled: noteNumber !== 0,
  });

  const { data: dataLabel } = useQuery({
    queryKey: ["label-print"],
    queryFn: getPreviewInvoice,
  });

  const {
    mutate: mutateList,
    data: dataList,
    isLoading,
  } = useMutation({
    mutationFn: fetchReturListSell,
  });

  useEffect(() => {
    mutateList(reqBody);
  }, [reqBody]);

  useEffect(() => {
    if (returPdfData) handlePrint();
  }, [returPdfData]);

  const NotaInfo = () => {
    return (
      <div className="mb-4">
        <div className="flex gap-3">
          <div className="flex gap-2 w-full">
            <p className="w-1/2">Nama Customer</p>
            <p>:</p>
            <p>{returPdfData.customer}</p>
          </div>
          <div className="flex gap-2 w-full">
            <p className="w-1/2">No Retur</p>
            <p>:</p>
            <p>{returPdfData.note_number}</p>
          </div>
        </div>
        <div className="flex gap-3">
          <div className="flex gap-2 w-1/2 pr-2">
            <p className="w-1/2">No Telepon</p>
            <p>:</p>
            <p>{returPdfData.customer_phone}</p>
          </div>
        </div>
        <div className="flex gap-3">
          <div className="flex gap-2 w-1/2 pr-2">
            <p className="w-1/2">Alamat</p>
            <p>:</p>
            <p>{returPdfData.customer_address}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="text-[12px]">
      <LoadingBackdrop isLoading={isFetchingPrint} />
      <div className="flex items-center gap-2 mb-4">
        <TextField
          placeholder="Cari data"
          value={reqBody.search}
          onChange={(e) =>
            setReqBody((prevVal) => ({ ...prevVal, search: e.target.value }))
          }
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <Search />
              </InputAdornment>
            ),
          }}
          fullWidth
        />
        <FormControl sx={{ width: "50%" }}>
          <InputLabel>Periode</InputLabel>
          <Select
            value={reqBody.period === 2 ? 3 : reqBody.period}
            label="Periode"
            onChange={(e) => {
              setReqBody((prevVal) => ({
                ...prevVal,
                period: e.target.value === 3 ? 2 : e.target.value,
                start_date: e.target.value === 2 ? "" : prevVal.start_date,
                end_date: e.target.value === 2 ? "" : prevVal.end_date,
              }));
              if (e.target.value === 2) setOpenCalendar(true);
            }}
          >
            <MenuItem value={0}>Terbaru</MenuItem>
            <MenuItem value={1}>Terlama</MenuItem>
            <MenuItem value={2}>Pilih Tanggal</MenuItem>
            <MenuItem value={3} disabled sx={{ display: "none" }}>
              {reqBody.end_date === ""
                ? "Pilih Tanggal"
                : toDate(reqBody.start_date) + " - " + toDate(reqBody.end_date)}
            </MenuItem>
          </Select>
        </FormControl>
        <button
          type="button"
          className="border rounded-md flex items-center justify-between gap-2 w-fit py-2.5 px-2 bg-blue-500 text-white hover:bg-gray-300 whitespace-nowrap text-nowrap"
          onClick={() => navigate(`/retur/sell-retur/create-retur`)}
        >
          Buat Retur
          <IoMdAddCircleOutline className="text-[18px]" />
        </button>
      </div>

      <div className="flex w-full mb-8">
        <div className="w-full">
          <div className="block overflow-x-auto w-full rounded-md">
            <table className="w-full overflow-x-auto rounded-md border-collapse">
              <thead className="bg-blue-500 text-white">
                <th className="p-2 text-center">No</th>
                <th className="p-2 text-center whitespace-nowrap">
                  Tanggal Retur
                </th>
                <th className="p-2 text-center whitespace-nowrap">Customer</th>
                <th className="p-2 text-center whitespace-nowrap">
                  Nominal Retur
                </th>
                <th className="p-2 text-center whitespace-nowrap w-[200px]">
                  Action
                </th>
              </thead>
              <tbody className="border">
                {dataList && !isLoading && dataList.data ? (
                  dataList.data.map((item, i) => (
                    <tr className="border" key={i}>
                      <td className="p-2 text-center">
                        {(reqBody.offset - 1) * reqBody.limit + (i + 1)}
                      </td>
                      <td className="p-2 text-center">
                        {moment(item.created_at).format("DD/MM/YYYY")}
                      </td>
                      <td className="p-2 text-center">{item.customer_name}</td>
                      <td className="p-2 text-center">
                        {toIDR(item.total_price)}
                      </td>
                      <td className="p-2 text-center">
                        <div className="w-full flex gap-2 items-center">
                          <button
                            className="bg-gray-100 border-gray-300 text-gray-500 border-2 rounded-md w-full hover:bg-gray-300 p-1 disabled:border-none disabled:bg-gray-300 disabled:text-white"
                            onClick={() =>
                              navigate(
                                `/retur/sell-retur/detail-retur/${item.note_number.replaceAll(
                                  "/",
                                  "-"
                                )}`
                              )
                            }
                            type="button"
                          >
                            Detail
                          </button>
                          <button
                            className="bg-blue-100 text-blue-500 border-2 rounded-md w-full hover:bg-blue-300 p-1 disabled:bg-gray-300 disabled:text-white"
                            type="button"
                            onClick={() => {
                              setNoteNumber(item.note_number);
                            }}
                          >
                            Print
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="p-2 text-center border" colSpan={5}>
                      {isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        "Tidak ada data"
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {dataList && dataList.count > 0 && dataList.data !== null ? (
        <div className="mb-4">
          <PagePagination
            offset={reqBody.offset}
            setOffset={(e) =>
              setReqBody((prevVal) => ({ ...prevVal, offset: e }))
            }
            limit={reqBody.limit}
            setLimit={(e) =>
              setReqBody((prevVal) => ({ ...prevVal, limit: e }))
            }
            total={dataList.count}
          />
        </div>
      ) : null}

      <div className="hidden">
        <PrintNota
          dataLabel={dataLabel}
          data={returPdfData}
          dataList={returPdfData ? returPdfData.product : []}
          printRef={printRef}
          title="Nota Retur"
          tableContent={[
            {
              header: "No",
              key: "INDEX",
            },
            {
              header: "Nama Obat",
              key: "product_name",
            },
            {
              header: "Batch",
              key: "batch_number",
            },
            {
              header: "Exp Date",
              key: "expired_date",
            },
            {
              header: "No. Invoice",
              key: "invoice_number",
            },
            {
              header: "Retur Qty",
              key: "QTYRETUR",
            },
            {
              header: "Harga Satuan",
              key: "unit_price",
              type: "currency",
            },
            {
              header: "Diskon (%)",
              key: "discount",
            },
            {
              header: "PPN (%)",
              key: "ppn",
            },
            {
              header: "Harga Akhir",
              key: "subtotal",
              type: "currency",
            },
          ]}
          totalPrice={returPdfData ? returPdfData.total_price : 0}
          NotaInfo={NotaInfo}
          email={returPdfData ? returPdfData.owner_email : "-"}
        />
      </div>

      <Dialog
        open={openCalendar}
        onClose={() => {
          setOpenCalendar(false);
        }}
      >
        <DialogTitle>Pilih Tanggal</DialogTitle>
        <DialogContent sx={{ fontSize: 12 }}>
          <div className="mb-2 w-full">
            <DatePicker
              onChange={(dates) => {
                const [start, end] = dates;
                setReqBody((prevVal) => ({
                  ...prevVal,
                  start_date: start ? moment(start).format("YYYY-MM-DD") : "",
                  end_date: end ? moment(end).format("YYYY-MM-DD") : "",
                }));
              }}
              startDate={
                reqBody.start_date == "" ? null : new Date(reqBody.start_date)
              }
              endDate={
                reqBody.end_date === "" ? null : new Date(reqBody.end_date)
              }
              selectsRange={true}
              inline
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div className="flex justify-center gap-2 px-2">
                  <button
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    <FaChevronLeft />
                  </button>
                  <select
                    className="p-2 px-4 rounded-md cursor-pointer hover:text-white"
                    value={new Date(date).getFullYear()}
                    onChange={({ target: { value } }) => changeYear(value)}
                  >
                    {YEARS.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <select
                    className="p-2 px-4 rounded-md cursor-pointer hover:text-white"
                    value={MONTHS[new Date(date).getMonth()]}
                    onChange={({ target: { value } }) =>
                      changeMonth(MONTHS.indexOf(value))
                    }
                  >
                    {MONTHS.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <button
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    <FaChevronRight />
                  </button>
                </div>
              )}
            />
          </div>
          <button
            className="bg-indigo-500 rounded text-white px-2 py-1 w-full"
            onClick={() => {
              mutateList(reqBody);
              setOpenCalendar(false);
            }}
          >
            Pilih Tanggal
          </button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ReturSellList;
