import { CircularProgress, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  getCustomerDetail,
  getInvoiceDetail,
} from "../../../services/invoiceApi";
import { getSipaAndFullname, getUserProfile } from "../../../services/authApi";
import { useParams } from "react-router-dom";
import InvoiceBreadcrumb from "./InvoiceBreadcrumb";

const DetailInvoice = ({ isWarehouse }) => {
  const { invNum } = useParams();

  const { data: profile } = useQuery({
    queryKey: ["profile"],
    queryFn: () => getUserProfile(),
  });

  const { data: sipaAndFullnameData } = useQuery({
    queryKey: ["sipa-fullname", profile?.id],
    queryFn: () =>
      getSipaAndFullname(profile && profile !== undefined ? profile.id : 0),
  });

  const {
    data: dataDetail,
    isFetching: isFetchingDetail,
    error,
  } = useQuery({
    queryKey: ["detail-invoice", invNum, sipaAndFullnameData?.id],
    queryFn: () =>
      getInvoiceDetail(
        invNum.replaceAll("=", "/"),
        // sipaAndFullnameData && sipaAndFullnameData !== undefined
        //   ? sipaAndFullnameData.id
        //   : 0,
        isWarehouse ? isWarehouse : false
      ),
  });

  return (
    <>
      <InvoiceBreadcrumb />
      {dataDetail && !isFetchingDetail ? (
        <>
          <div className="flex w-full justify-between items-center mt-8">
            <p className="font-bold w-full basis-1/4">
              Invoice Date <span className="text-red-500">*</span>
            </p>
            <div className="w-full">
              <DesktopDatePicker
                className="w-full"
                sx={{ width: "100%" }}
                value={dataDetail.invoice_date}
                disabled
                renderInput={(params) => {
                  return <TextField {...params} />;
                }}
              />
            </div>
          </div>
          <div className="flex w-full justify-between items-center mt-8">
            <p className="font-bold w-full basis-1/4">
              Batas Pembayaran <span className="text-red-500">*</span>
            </p>
            <div className="w-full">
              <DesktopDatePicker
                className="w-full"
                sx={{ width: "100%" }}
                value={dataDetail.due_date}
                disabled
                renderInput={(params) => {
                  return <TextField {...params} />;
                }}
              />
            </div>
          </div>
          <div className="flex w-full justify-between items-center mt-8">
            <p className="font-bold w-full basis-1/4">
              Invoice Number <span className="text-red-500">*</span>
            </p>
            <div className="w-full">
              <TextField fullWidth disabled value={dataDetail.invoice_number} />
            </div>
          </div>
          <div className="flex w-full justify-between items-center mt-8">
            <p className="font-bold w-full basis-1/4">
              Customer <span className="text-red-500">*</span>
            </p>
            <div className="w-full">
              <TextField fullWidth disabled value={dataDetail.customer_name} />
            </div>
          </div>
          <div className="flex w-full justify-between items-center mt-8">
            <p className="font-bold w-full basis-1/4">Customer Discount</p>
            <div className="w-full">
              <TextField
                fullWidth
                disabled
                value={dataDetail.customer_discount}
              />
            </div>
          </div>
          <div className="flex w-full justify-between items-center mt-8">
            <p className="font-bold w-full basis-1/4">Kode Sales</p>
            <div className="w-full">
              <TextField fullWidth value={dataDetail.sales_code} disabled />
            </div>
          </div>
          <div className="flex w-full justify-between items-center mt-8">
            <p className="font-bold w-full basis-1/4">Apoteker</p>
            <div className="w-full">
              <TextField
                fullWidth
                value={
                  sipaAndFullnameData && sipaAndFullnameData !== undefined
                    ? sipaAndFullnameData.fullname
                    : null
                }
                disabled
              />
            </div>
          </div>
          <div className="flex w-full justify-between items-center mt-8">
            <p className="font-bold w-full basis-1/4">No. SIPA</p>
            <div className="w-full">
              <TextField
                fullWidth
                value={
                  sipaAndFullnameData && sipaAndFullnameData !== undefined
                    ? sipaAndFullnameData.sipa_id
                    : null
                }
                disabled
              />
            </div>
          </div>

          <hr className="my-4" />

          <div className="block overflow-x-auto">
            <table className="w-full">
              <thead className="text-center bg-gray-100">
                <tr>
                  <th className="border font-bold">Product ID</th>
                  <th className="border font-bold">Nama Produk</th>
                  <th className="border font-bold">Qty</th>
                  <th className="border font-bold">No. Batch</th>
                  <th className="border font-bold">Expired</th>
                  <th className="border font-bold">Harga Satuan</th>
                  <th className="border font-bold">Diskon</th>
                  <th className="border font-bold">Promo</th>
                  <th className="border font-bold">Total</th>
                  <th className="border font-bold">Total + Diskon</th>
                </tr>
              </thead>

              <tbody>
                {dataDetail.product?.length > 0 ? (
                  dataDetail.product.map((item, i) => {
                    return (
                      <tr className="text-center" key={i}>
                        <td className="border py-2">{item.product_id}</td>
                        <td className="border py-2">{item.product_name}</td>
                        <td className="border py-2">
                          {item.quantity + " " + item.packaging}
                        </td>
                        <td className="border py-2">{item.batch_no}</td>
                        <td className="border py-2">{item.expired_date}</td>
                        <td className="border py-2">
                          {item.price.toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                        </td>
                        <td className="border py-2">{item.discount}</td>
                        <td className="border py-2">
                          {item.promo === "" ? "-" : item.promo}
                        </td>
                        <td className="border py-2">
                          {item.sub_total.toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                        </td>
                        <td className="border py-2">
                          {item.total.toLocaleString("id-ID", {
                            style: "currency",
                            currency: "IDR",
                          })}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan={10}
                      className="border text-center py-4 text-gray-300"
                    >
                      Belum ada produk ditambahkan untuk invoice ini
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="flex justify-between mt-4">
            <div className="basis-2/3 w-full"></div>
            <div className="basis-1/3 w-full">
              <div className="flex">
                <p className="w-full font-bold">Jumlah</p>
                <p className="text-right">
                  Rp
                  {dataDetail.jumlah.toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  })}
                </p>
              </div>
              <div className="flex">
                <p className="w-full font-bold">Diskon</p>
                <p className="text-right">
                  {(
                    dataDetail.discount_1 + dataDetail.discount_2
                  ).toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  })}
                </p>
              </div>
              <div className="flex">
                <p className="w-full font-bold">Subtotal</p>
                <p className="text-right">
                  {dataDetail.subtotal.toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  })}
                </p>
              </div>
              <div className="flex">
                <p className="w-full font-bold">PPN</p>
                <p className="text-right">
                  {dataDetail.ppn.toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  })}
                </p>
              </div>

              <div className="flex mt-4 bg-green-100 rounded-md p-2">
                <p className="w-full font-bold">Jumlah Bayar</p>
                <p className="text-right">
                  {dataDetail.jumlah_bayar.toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                  })}
                </p>
              </div>
            </div>
          </div>
        </>
      ) : isFetchingDetail ? (
        <CircularProgress sx={{ marginY: 4 }} />
      ) : (
        <p className="text-red-500 mt-8">{error?.message.id}</p>
      )}
    </>
  );
};

export default DetailInvoice;
