import { Search } from "@mui/icons-material";
import {
  Checkbox,
  CircularProgress,
  InputAdornment,
  Popover,
  Popper,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { CiFilter } from "react-icons/ci";
import { FaChevronDown } from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";
import { MdCalendarMonth } from "react-icons/md";
import { TbFileExport } from "react-icons/tb";
import { getListBilling } from "../../../services/billingAPI";
import { useMutation } from "@tanstack/react-query";
import useDebounce from "../../hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import PagePagination from "../../common/particles.jsx/PagePagination";
import PopupRangeCalendar from "../../common/particles.jsx/PopupRangeCalendar";
import ExportBillingModal from "../billingManualCW/ExportBillingModal";
import ImportBillingModal from "../billingManualCW/ImportBillingModal";
import Papa from "papaparse";
import { BiPrinter } from "react-icons/bi";
import { exportCsvBilling } from "../../../services/purchaseOrderApi";
import LoadingBackdrop from "../../common/particles.jsx/LoadingBackdrop";

const toIDR = (val) =>
  val?.toLocaleString("id-ID", {
    style: "currency",
    currency: "IDR",
  });

const STATUS = [
  {
    id: 0,
    value: "Semua",
  },
  {
    id: 1,
    value: "Belum Dibayar",
  },
  {
    id: 5,
    value: "Belum Lunas",
  },
  {
    id: 2,
    value: "Telat Bayar",
  },
  {
    id: 3,
    value: "Lunas",
  },
  {
    id: 4,
    value: "Draft",
  },
];

const INITIAL_REQ = {
  offset: 1,
  limit: 10,
  status: 0,
  search: "",
  start_date: "",
  end_date: "",
};

const BillingManualList = () => {
  const navigate = useNavigate();

  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const [anchorElCal, setAnchorElCal] = useState(null);
  const [reqBody, setReqBody] = useState(INITIAL_REQ);
  const [moreProductArr, setMoreProductArr] = useState([]);
  const [anchorElMoreProduct, setAnchorElMoreProduct] = useState(null);
  const [openImport, setOpenImport] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [billingIdArr, setBillingIdArr] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const debounce = useDebounce(reqBody.search, 500);

  const handleAddBillingId = (id) => {
    const index = billingIdArr.findIndex((item) => item === id);
    if (index < 0) setBillingIdArr([...billingIdArr, id]);
    else
      setBillingIdArr([
        ...billingIdArr.slice(0, index),
        ...billingIdArr.slice(index + 1),
      ]);
  };

  const handleSelectAll = () => {
    const isEmpty = billingIdArr.length === 0;
    setBillingIdArr(
      isEmpty
        ? dataList.data
            .filter((item) => item.status !== 4)
            .map((item) => item.billing_id)
        : []
    );
  };

  useEffect(() => {
    setIsSelectAll(
      dataList && dataList.data && !isLoading && billingIdArr.length > 0
        ? dataList.data.filter((item) => item.status !== 4).length ===
            billingIdArr.length
        : false
    );
  }, [billingIdArr]);

  const handleJsonToCsvExisting = (csvExisting) => {
    const csv = Papa.unparse(csvExisting, {
      delimiter: ";",
    });

    const blob = new Blob([csv], { type: "application/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.download = "OLIN_Billing_" + moment().format("DD_MM_YYYY_hh:mm") + ".csv";
    a.href = url;

    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
  };

  const { mutate: mutateCsvBilling, isLoading: isCsvBillingLoading } =
    useMutation({
      mutationFn: exportCsvBilling,
      onSuccess: (data) => {
        handleJsonToCsvExisting(
          data.map((data) => ({
            ...data,
            price: "Rp. " + data.price,
            total_price: "Rp. " + data.total_price,
            due_date: moment(data.due_date).format("DD-MMMM-YYYY"),
            po_date: moment(data.po_date).format("DD-MMMM-YYYY"),
            expired_date: moment(data.expired_date).format("DD-MMMM-YYYY"),
            status: STATUS.find((item) => item.id === data.status)?.value,
          }))
        );
      },
    });

  const {
    mutate: mutateList,
    data: dataList,
    isLoading,
  } = useMutation({
    mutationFn: getListBilling,
  });

  useEffect(() => {
    mutateList({ ...reqBody, search: debounce });
  }, [reqBody, debounce]);

  return (
    <>
      <div className="text-[12px] px-3 pt-3 pb-[70px]">
        <LoadingBackdrop isLoading={isCsvBillingLoading} />
        <div className="flex items-center gap-2 mb-2">
          <TextField
            placeholder="Cari data"
            value={reqBody.search}
            onChange={(e) =>
              setReqBody((prevVal) => ({ ...prevVal, search: e.target.value }))
            }
            InputProps={{
              startAdornment: (
                <InputAdornment>
                  <Search />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
          <div
            className="border rounded-md flex items-center justify-between gap-4 w-fit py-2.5 px-2 text-gray-500 cursor-pointer hover:bg-gray-100"
            onClick={(e) => setAnchorElStatus(e.currentTarget)}
          >
            <div className="flex items-center gap-2">
              <CiFilter className="text-[18px]" /> Status
            </div>
            <FaChevronDown />
          </div>
          <div
            className="border rounded-md flex items-center justify-between gap-4 w-fit py-2.5 px-2 text-gray-500 cursor-pointer hover:bg-gray-100"
            onClick={(e) => setAnchorElCal(e.currentTarget)}
          >
            <div className="flex items-center gap-2">
              <MdCalendarMonth className="text-[18px]" /> Period
            </div>
            <FaChevronDown />
          </div>
          <div
            className="border rounded-md flex items-center justify-between gap-4 w-fit py-1.5 px-2 border-blue-500 bg-[#E5EBF1] text-blue-500 cursor-pointer hover:bg-gray-100"
            onClick={() => setOpenImport(true)}
          >
            Import
            <div className="bg-blue-500 text-white w-[24px] h-[24px] p-1 rounded-md ">
              <TbFileExport className="w-full h-full" />
            </div>
          </div>
          <div
            className="border rounded-md flex items-center justify-between gap-4 w-fit py-1.5 px-2 border-[#15803D] bg-[#AFECC6] text-[#15803D] cursor-pointer hover:bg-gray-100"
            onClick={() => setOpenExport(true)}
          >
            Export
            <div className="bg-[#15803D] text-white w-[24px] h-[24px] p-1 rounded-md ">
              <TbFileExport className="w-full h-full" />
            </div>
          </div>
          <button
            className="disabled:bg-gray-300 disabled:text-white text-blue-500 border-2 border-blue-500 rounded-md hover:bg-slate-100 w-fit flex items-center justify-center p-2"
            onClick={() => {
              mutateCsvBilling({
                warehouse: false,
                billing_id: billingIdArr,
              });
            }}
            disabled={isCsvBillingLoading || billingIdArr.length <= 0}
          >
            <BiPrinter className="text-[18px]" />
          </button>
          <button
            type="button"
            className="border rounded-md flex items-center justify-between gap-2 w-fit py-2.5 px-2 bg-blue-500 text-white hover:bg-gray-300 whitespace-nowrap text-nowrap"
            onClick={() => navigate(`/manual-billing/po-list`)}
          >
            Buat Billing
            <IoMdAddCircleOutline className="text-[18px]" />
          </button>
        </div>

        <div className="flex w-full mb-8">
          <div className="w-full">
            <div className="block overflow-x-auto w-full rounded-md">
              <table className="w-full overflow-x-auto rounded-md border-collapse">
                <thead className="bg-blue-500 text-white">
                  <th className="p-2 text-center">
                    <Checkbox
                      disabled={isLoading || !dataList}
                      onChange={handleSelectAll}
                      checked={isSelectAll}
                    />
                  </th>
                  <th className="p-2 text-center">No</th>
                  <th className="p-2 text-center whitespace-nowrap">Tanggal</th>
                  <th className="p-2 text-center whitespace-nowrap">
                    No Invoice
                  </th>
                  <th className="p-2 text-center whitespace-nowrap">
                    No Faktur Pajak
                  </th>
                  <th className="p-2 text-center whitespace-nowrap">
                    Distributor
                  </th>
                  <th className="p-2 text-left whitespace-nowrap">Produk</th>
                  <th className="p-2 text-center whitespace-nowrap">
                    Total Tagihan
                  </th>
                  <th className="p-2 text-center whitespace-nowrap">
                    Total Dibayar
                  </th>
                  <th className="p-2 text-center whitespace-nowrap">Status</th>
                  <th className="p-2 text-center whitespace-nowrap">
                    Jatuh Tempo
                  </th>
                  <th className="p-2 text-center whitespace-nowrap">Action</th>
                </thead>
                <tbody>
                  {dataList && !isLoading && dataList.data ? (
                    dataList.data.map((item, i) => (
                      <tr className="border" key={i}>
                        <td className="p-2 text-center">
                          <Checkbox
                            disabled={item.status === 4}
                            checked={billingIdArr.includes(item.billing_id)}
                            onChange={() => handleAddBillingId(item.billing_id)}
                          />
                        </td>
                        <td className="p-2 text-center">
                          {(reqBody.offset - 1) * reqBody.limit + (i + 1)}
                        </td>
                        <td className="p-2 text-center">
                          {moment(item.created_at).format("DD/MM/YYYY")}
                        </td>
                        <td className="p-2 text-center">
                          {item.invoice_number}
                        </td>
                        <td className="p-2 text-center">
                          {item.faktur_number}
                        </td>
                        <td className="p-2 text-center">{item.distributor}</td>
                        <td className="p-2 text-left">
                          <div className="w-full flex justify-between gap-4 items-center">
                            <p>
                              {item.product_name ? item.product_name[0] : "-"}
                            </p>
                            {item.product_name &&
                            item.product_name.length > 1 ? (
                              <p
                                className="text-blue-500 cursor-pointer"
                                onMouseEnter={(e) => {
                                  setMoreProductArr(item.product_name.slice(1));
                                  setAnchorElMoreProduct(e.currentTarget);
                                }}
                                onMouseLeave={() =>
                                  setAnchorElMoreProduct(null)
                                }
                              >
                                +{item.product_name.length - 1} Lainnya
                              </p>
                            ) : null}
                          </div>
                        </td>
                        <td className="p-2 text-center">
                          {toIDR(item.total_bill)}
                        </td>
                        <td className="p-2 text-center">
                          {toIDR(item.total_amount)}
                        </td>
                        <td className="p-2 text-center">
                          {item.status === 1
                            ? "Belum dibayar"
                            : item.status === 2
                            ? "Overdue"
                            : item.status === 3
                            ? "Lunas"
                            : item.status === 4
                            ? "Draft"
                            : item.status === 5
                            ? "Belum Lunas"
                            : "-"}
                        </td>
                        <td className="p-2 text-center">
                          {moment(item.overdue_date).format("DD/MM/YYYY")}
                        </td>
                        <td className="p-2 text-center">
                          <div className="w-full flex gap-2 items-center">
                            <button
                              className="bg-gray-100 border-gray-300 text-gray-500 border-2 rounded-md w-full hover:bg-gray-300 p-1 disabled:border-none disabled:bg-gray-300 disabled:text-white"
                              onClick={() =>
                                navigate(
                                  item.status === 4
                                    ? `/manual-billing/edit-draft/${item.po_id}/${item.is_partial}`
                                    : `/manual-billing/detail/${item.po_id}/${item.billing_id}`
                                )
                              }
                              type="button"
                            >
                              Detail
                            </button>
                            <button
                              className="bg-blue-100 text-blue-500 border-2 rounded-md w-full hover:bg-blue-300 p-1 disabled:bg-gray-300 disabled:text-white"
                              type="button"
                              disabled={item.status === 4}
                              onClick={() => {
                                mutateCsvBilling({
                                  warehouse: false,
                                  billing_id: [item.billing_id],
                                });
                              }}
                            >
                              Print
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="p-2 text-center border" colSpan={12}>
                        {isLoading ? (
                          <CircularProgress size={20} />
                        ) : (
                          "Tidak ada data"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        {dataList && dataList.count > 0 && dataList.data !== null ? (
          <>
            <div className="mb-4">
              <PagePagination
                offset={reqBody.offset}
                setOffset={(e) =>
                  setReqBody((prevVal) => ({ ...prevVal, offset: e }))
                }
                limit={reqBody.limit}
                setLimit={(e) =>
                  setReqBody((prevVal) => ({ ...prevVal, limit: e }))
                }
                total={dataList.count}
              />
            </div>
          </>
        ) : null}

        <Popover
          open={Boolean(anchorElStatus)}
          anchorEl={anchorElStatus}
          onClose={() => setAnchorElStatus(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <div className="w-full">
            {STATUS.map((item, i) => (
              <div
                className={`py-2 px-3 whitespace-nowrap w-full cursor-pointer hover:bg-gray-300 ${
                  i === 0 ? "" : "border-t"
                } ${reqBody.status === item.id ? "bg-gray-200" : ""}`}
                key={i}
                onClick={() => {
                  setReqBody((prevVal) => ({ ...prevVal, status: item.id }));
                  setAnchorElStatus(null);
                }}
              >
                {item.value}
              </div>
            ))}
          </div>
        </Popover>

        <Popper
          placement="bottom"
          open={Boolean(anchorElMoreProduct)}
          anchorEl={anchorElMoreProduct}
          sx={{ maxWidth: "200px" }}
          modifiers={[
            {
              name: "arrow",
              enabled: true,
              // options: {
              //   element: arrowRef,
              // },
            },
          ]}
        >
          <div className="bg-white text-[10px] p-2 rounded-md border text-wrap whitespace-wrap overflow-hidden break-words">
            {moreProductArr.map((item, i) => `${i + 1}. ${item}`).join(", ")}
          </div>
        </Popper>

        <PopupRangeCalendar
          anchorElCal={anchorElCal}
          setAnchorElCal={setAnchorElCal}
          startDate={reqBody.start_date}
          endDate={reqBody.end_date}
          setStartDate={(e) => {
            setReqBody((prevValue) => ({ ...prevValue, start_date: e }));
          }}
          setEndDate={(e) => {
            setReqBody((prevValue) => ({ ...prevValue, end_date: e }));
          }}
          resetFunc={() =>
            setReqBody((prevValue) => ({
              ...prevValue,
              offset: 1,
              start_date: INITIAL_REQ.start_date,
              end_date: INITIAL_REQ.end_date,
            }))
          }
        />

        <ExportBillingModal
          isWarehouse={false}
          openModal={openExport}
          setOpenModal={setOpenExport}
        />

        <ImportBillingModal
          isWarehouse={false}
          openModal={openImport}
          setOpenModal={setOpenImport}
        />
      </div>

      <div
        className={`bg-white fixed bottom-0 left-0 lg:left-[260px] min-h-[60px] px-[20px] py-[10px] w-full lg:w-[calc(100%-260px)] shadow-lg shadow-slate-900/20 shadow-b-2 shadow-r-[3px] -shadow-spread-2 text-[12px] flex items-center z-50`}
      >
        <div className="flex items-center justify-between items-center w-full">
          <div className="">
            {isLoading ? (
              <CircularProgress size={20} />
            ) : dataList && !isLoading ? (
              <>
                <p>{dataList.date}</p>
                <div className="flex gap-4">
                  <p>
                    Total Tagihan :{" "}
                    <span className="text-[16px] font-bold">
                      {toIDR(dataList.total_bill ? dataList.total_bill : 0)}
                    </span>
                  </p>
                  <p>
                    Total Dibayar :{" "}
                    <span className="text-[16px] font-bold">
                      {toIDR(
                        dataList.total_payment ? dataList.total_payment : 0
                      )}
                    </span>
                  </p>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingManualList;
